import { FC, useEffect, useRef } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import useAsyncEffect from 'use-async-effect';

import { showToast } from '@services/toasts';

import {
  Grid,
  Button,
  Modal,
  PromoCodeForm,
  Logo,
  Typography,
  PaymentCardSelect,
  BottomControlsWrapper,
} from '@components/common';

import CallingPaymentInfoClass from '@root/models/CallingPaymentInfoClass';
import PaymentCardClass from '@root/models/PaymentCardClass';

import CountryCode from '@root/interfaces/CountryCode';

import { ERRORS } from '@components/common/PaymentCardSelect/constants';

import { getFormattedAmount } from '@helpers/money';

import AutoRechargeButton from './components/AutoRechargeButton';
import ManualPromoDescription from './components/ManualPromoDescription';

import AutoRechargeToggler from '../../components/AutoRechargeToggler';
import SelectRecharge from '../../components/SelectRecharge';
import Balance from '../../components/Balance';

import Store from './Store';

export interface RechargeFinishParams {
  amount: number;
  isAutoRechargeEnabled: boolean;
  paymentCard: PaymentCardClass;
  validatedPromoCode?: string;
}

interface Props {
  isLoading?: boolean;
  dataTestPrefix?: string;
  paymentInfo: CallingPaymentInfoClass;
  isAutoRechargeEnabled: boolean;
  isCallingFeatureAvailable?: boolean;
  userCountryOfOrigin: CountryCode;
  onFinish: (params: RechargeFinishParams) => void;
  onManageArClick: () => void;
}

const Recharge: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    onFinish,
    paymentInfo,
    isAutoRechargeEnabled,
    userCountryOfOrigin,
    onManageArClick,
    dataTestPrefix,
    isCallingFeatureAvailable,
    isLoading: isRechargeLoading,
  } = props;

  const bottomControlsWrapperRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const store = useLocalStore(
    () =>
      new Store({
        isAutoRechargeEnabled,
        defaultSelectedAmount:
          paymentInfo.defaultRechargeAmount || paymentInfo.creditCardTiers[0].price,
      }),
  );

  const {
    selectProps,
    selectedPaymentCard,
    hasPaymentCards,
    openSelect,
    showPaymentCardDialog,
    isLoading,
    paymentCardReducedDetailsDialogProps,
  } = PaymentCardSelect.usePaymentCardSelect({
    isUsingCardsWithoutBillingAllowed: true,
    handleId: paymentInfo.handleId,
    userCountryOfOrigin,
  });

  const DATA_TEST_ID_PREFIX = `${dataTestPrefix}-recharge`;

  // Set Default recharge amount from the config
  useEffect(() => {
    const defaultAmountToSelect =
      paymentInfo.defaultRechargeAmount || paymentInfo.creditCardTiers[0].price;
    store.setSelectedAmount(defaultAmountToSelect);
  }, [paymentInfo.creditCardTiers, paymentInfo.defaultRechargeAmount, store]);

  const handleAmountSelect = async (value: string) => {
    store.resetPromo();
    store.setSelectedAmount(value);
  };

  // validate promo code on amount change
  useAsyncEffect(async () => {
    const currentPromoCode =
      store.appliedPromoCode ||
      (store.isAutoRechargeEnabled && paymentInfo.arPromoInfo?.code) ||
      undefined;

    if (currentPromoCode) {
      await store.getPromoInfoByCode({
        code: currentPromoCode,
        isArPromo:
          store.isAutoRechargeEnabled &&
          paymentInfo.arPromoInfo?.code === currentPromoCode,
      });
    }
  }, [store.selectedAmount]);

  const handleApplyPromo = async (value: string) => {
    await store.setAppliedPromoCode(value);
    await store.getPromoInfoByCode({
      code: value,
    });
  };

  const handleConfirmClick = () => {
    if (store.selectedAmount && selectedPaymentCard) {
      onFinish({
        amount: store.selectedAmount,
        isAutoRechargeEnabled: store.isAutoRechargeEnabled,
        paymentCard: selectedPaymentCard,
        validatedPromoCode: store.validatedPromoCode,
      });
      return undefined;
    }
    if (hasPaymentCards) {
      showToast.warning(ERRORS.CARD_NOT_SELECTED);
      openSelect();
    } else {
      showPaymentCardDialog();
    }
    return undefined;
  };

  const handlePromoCodeFormValuesChange = async () => {
    store.setAppliedPromoCode(undefined);
    if (store.promoInfo?.code !== paymentInfo.arPromoInfo?.code) {
      store.resetPromo();
      if (paymentInfo.arPromoInfo?.code && store.isAutoRechargeEnabled) {
        await store.getPromoInfoByCode({
          code: paymentInfo.arPromoInfo?.code,
          isArPromo: true,
        });
      }
    }
  };

  const handleArRecommendedToggleChange = async (value: boolean) => {
    if (value) {
      store.resetPromo();
      store.setIsAutoRechargeEnabled(value);
      if (paymentInfo.arPromoInfo?.code) {
        await store.getPromoInfoByCode({
          code: paymentInfo.arPromoInfo?.code,
          isArPromo: true,
        });
      }
    } else if (paymentInfo.arPromoInfo?.code) {
      store.showArTurnOffConfirmationModal();
    } else {
      store.resetPromo();
      store.setIsAutoRechargeEnabled(false);
    }
  };

  const handleArTurnOffConfirm = () => {
    store.resetPromo();
    store.setIsAutoRechargeEnabled(false);
    store.closeArTurnOffConfirmationModal();
  };

  const getDisplayPromoAmount = () => {
    if (store.promoInfo?.amount) {
      return `${store.promoInfo.currencySymbol}${getFormattedAmount(
        store.promoInfo.amount,
      )} ${store.promoInfo.currency}`;
    }

    return undefined;
  };

  return (
    <Grid.Container className="flex flex-col gap-8">
      <Grid.Row className="flex-col-reverse md:flex-row">
        <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} offset={{ md: 3, lg: 4 }}>
          <Balance
            title={t('Your current balance is')}
            currencySymbol={paymentInfo.currencySymbol}
            currency={paymentInfo.currency}
            amount={paymentInfo.balanceAmount}
          />
        </Grid.Col>
      </Grid.Row>

      {isCallingFeatureAvailable && (
        <>
          <Grid.Row>
            <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }}>
              <div className="text-center">
                <p className="mb-4 text-2xl font-bold">{t('Select a recharge amount')}</p>
                <SelectRecharge
                  value={store.selectedAmount}
                  options={paymentInfo.creditCardTiers}
                  onChange={handleAmountSelect}
                  displayPromoAmount={getDisplayPromoAmount()}
                  dataTestId={DATA_TEST_ID_PREFIX}
                />
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }}>
              <div className="text-center">
                {paymentInfo.isAutoRechargeRecommended ? (
                  <AutoRechargeToggler
                    value={store.isAutoRechargeEnabled}
                    onChange={handleArRecommendedToggleChange}
                    bonusPercentage={store.arPromoBonusPercent}
                    className="mb-4"
                    dataTestId={`${DATA_TEST_ID_PREFIX}-toggler`}
                  />
                ) : (
                  <div className="flex justify-center">
                    <AutoRechargeButton
                      onClick={onManageArClick}
                      isArEnabled={store.isAutoRechargeEnabled}
                      dataTestId={`${DATA_TEST_ID_PREFIX}-toggler`}
                    />
                  </div>
                )}

                <div>
                  <Button
                    shape="text"
                    size="sm"
                    className="font-medium"
                    onClick={store.showArDescriptionModal}
                    data-test-id={`${DATA_TEST_ID_PREFIX}-link`}
                  >
                    {t('What is auto-recharge?')}
                  </Button>
                </div>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} offset={{ md: 3, lg: 4 }}>
              <div className="text-center">
                <div className="m-auto max-w-[290px]">
                  <PromoCodeForm
                    onFormValuesChange={handlePromoCodeFormValuesChange}
                    onSubmit={handleApplyPromo}
                    placeholder={t('Enter promo code')}
                    isApplied={Boolean(
                      store.validatedPromoCode && store.appliedPromoCode,
                    )}
                    isLoading={store.isPromoValidationLoading}
                    dataTestPrefix={DATA_TEST_ID_PREFIX}
                  />

                  {store.promoInfo && !store.promoInfo.isAutoRechargePromo && (
                    <ManualPromoDescription promoInfo={store.promoInfo} />
                  )}
                </div>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} offset={{ md: 3, lg: 4 }}>
              <div className="m-auto max-w-[290px]">
                <div className="mb-[18px] text-center text-2xl font-bold">
                  {t('Payment Details')}
                </div>
                <PaymentCardSelect
                  {...selectProps}
                  minDropdownBottomOffset={bottomControlsWrapperRef.current?.clientHeight}
                />
              </div>
            </Grid.Col>
          </Grid.Row>

          <PaymentCardSelect.PaymentCardReducedDetailsDialog
            {...paymentCardReducedDetailsDialogProps}
          />

          <BottomControlsWrapper ref={bottomControlsWrapperRef}>
            <Button
              shape="pill"
              color="blue"
              size="lg"
              onClick={handleConfirmClick}
              dataTestId={`${DATA_TEST_ID_PREFIX}-сonfirm-adding`}
              className="w-full max-w-app-btn"
              disabled={isLoading || isRechargeLoading}
            >
              {t('Add {{amount}} to balance', {
                amount: `${paymentInfo.currencySymbol}${store.selectedAmount} ${paymentInfo.currency}`,
              })}
            </Button>
          </BottomControlsWrapper>

          {/* Auto Recharge Description Modal */}
          <Modal
            isOpen={store.isArDescriptionModalOpen}
            onRequestClose={store.closeArDescriptionModal}
            header={<Logo className="w-32" />}
          >
            <Typography.Title level={5} className="mb-6">
              {t('What is Auto-Recharge?')}
            </Typography.Title>
            <div className="text-xl">
              {t(
                `Never get off in the middle of a call. If your balance falls below {{thresholdAmount}} your account will automatically be recharged by {{rechargeAmount}} or change it to a different amount in settings.`,
                {
                  thresholdAmount: `${paymentInfo.currencySymbol}${paymentInfo.thresholdAmount} ${paymentInfo.currency}`,
                  rechargeAmount: `${paymentInfo.currencySymbol}${store.selectedAmount} ${paymentInfo.currency}`,
                },
              )}
            </div>
          </Modal>

          {/* Auto Recharge Turn Off Confirmation Modal */}
          <Modal
            isOpen={store.isArTurnOfConfirmationModalOpen}
            onRequestClose={store.closeArTurnOffConfirmationModal}
            header={<Logo className="w-32" />}
          >
            <Typography.Title level={5} className="mb-6">
              {t(
                'Wait! Do you really want to miss out on a {{displayBonusAmount}} Bonus?',
                {
                  displayBonusAmount: getDisplayPromoAmount(),
                },
              )}
            </Typography.Title>
            <div className="mb-6 text-xl">
              {t(
                "Don't miss out on your {{arPromoBonusPercent}}% bonus of {{displayBonusAmount}} by turning off Auto-Recharge",
                {
                  arPromoBonusPercent: store.arPromoBonusPercent,
                  displayBonusAmount: getDisplayPromoAmount(),
                },
              )}
            </div>
            <div className="flex items-center">
              <Button
                shape="pill"
                size="lg"
                className="m-2 w-1/2"
                onClick={handleArTurnOffConfirm}
              >
                {t('Turn Off')}
              </Button>
              <Button
                shape="pill"
                size="lg"
                className="m-2 w-1/2"
                onClick={store.closeArTurnOffConfirmationModal}
              >
                {t('Keep On')}
              </Button>
            </div>
          </Modal>
        </>
      )}
    </Grid.Container>
  );
};

export default observer(Recharge);
