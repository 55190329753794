import React, { FC } from 'react';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import TabOption from './TabOption';

export interface TabOptionProps extends TestIdDataAttr {
  title: string;
  value: string;
  isHidden?: boolean;
}

interface Props {
  options: TabOptionProps[];
  selectedOption?: string;
  className?: string;
  onChange: (value: string) => void;
}

const Tabs: FC<React.PropsWithChildren<Props>> = (props) => {
  const { options, selectedOption, className, onChange } = props;

  return (
    <div className={className}>
      {options.map((item, index) =>
        item.isHidden ? null : (
          <TabOption
            key={item.title}
            value={item.value}
            isSelected={item.value === selectedOption}
            onClick={onChange}
            dataTestId={item.dataTestId}
            className={index !== 0 ? 'ml-4' : ''}
          >
            {item.title}
          </TabOption>
        ),
      )}
    </div>
  );
};

export default Tabs;
