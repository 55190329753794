import React from 'react';

interface Props {
  className?: string;
  name: string;
  label?: string;
  children?: React.ReactNode;
  required?: boolean;
}

const Label = (props: Props) => {
  const { className, name, children, label, required } = props;
  return (
    <label className={className} htmlFor={name}>
      {label && (
        <div className="text-lg font-medium pl-4 mb-2">
          {label}
          {required && <span className="text-red-400 ml-1">*</span>}
        </div>
      )}
      {children}
    </label>
  );
};

export default Label;
