import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import i18nextHttpBackend from 'i18next-http-backend';

import { LANGUAGE_STORE_KEY } from '@root/constants/languages';

import { Languages } from '@root/interfaces/Languages';

const isLocalHost = window.location.origin.includes('localhost');
const isProd = process.env.NODE_ENV === 'production';
const isTest = process.env.NODE_ENV === 'test';

const deployUrl = isLocalHost ? window.location.origin : process.env.REACT_APP_DEPLOY_URL;

i18n
  .use(LanguageDetector)
  .use(i18nextHttpBackend)
  .use(initReactI18next)
  .init({
    debug: !(isProd || isTest || isLocalHost), // disable for prod, test and localhost
    backend: {
      loadPath: `${deployUrl}/locales/{{lng}}.json`,
    },
    nsSeparator: false,
    keySeparator: false,
    load: 'languageOnly',
    fallbackLng: Languages.En,
    react: {
      useSuspense: false,
    },
    returnEmptyString: false,
    // LanguageDetector options
    detection: {
      order: ['path', 'querystring', 'localStorage'],
      lookupLocalStorage: LANGUAGE_STORE_KEY,
      lookupQuerystring: LANGUAGE_STORE_KEY,
      lookupFromPathIndex: 0, // my-awesome-site.com/{0}/{1}/{2} - needed for widgets added to the static pages
    },
    supportedLngs: [Languages.En, Languages.Es, Languages.Fr],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
