import { makeAutoObservable } from 'mobx';
import { isFuture } from 'date-fns';

import CountryCode from '@root/interfaces/CountryCode';
import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';
import { PaymentCard, PaymentCardIssuer } from '@root/interfaces/contract/PaymentCards';

import {
  PaymentCardFundingType,
  PaymentCardTypeShort,
} from '@root/constants/moneyTransfer/paymentCards';

// TODO: use enum here
const getMappedIssuer: (
  issuer: PaymentCardIssuer,
) => PaymentCardIssuerKeys | undefined = (issuer) => {
  switch (issuer) {
    case 'mastercard':
      return 'MC';
    case 'visa':
      return 'V';
    case 'amex':
      return 'AE';
    default:
      return undefined;
  }
};

const PaymentCardTypeMap = {
  [PaymentCardFundingType.credit]: PaymentCardTypeShort.CC,
  [PaymentCardFundingType.debit]: PaymentCardTypeShort.DC,
  [PaymentCardFundingType.prepaid]: PaymentCardTypeShort.PP,
};

class PaymentCardClass {
  id = '';

  handleId = '';

  paySource?: PaymentCardIssuerKeys;

  accountHolder = '';

  maskedNumber = '';

  expDate: Date;

  verified = false;

  address1: string | null = '';

  address2: string | null = '';

  city = '';

  zip = '';

  state = '';

  country?: CountryCode;

  cvv = '';

  type: PaymentCardTypeShort;

  constructor(card: PaymentCard) {
    makeAutoObservable(this);

    this.id = card.handle_id;
    this.handleId = card.handle_id; // TODO: remove amd use id instead to avoid confusion
    this.paySource = getMappedIssuer(card.brand);
    this.accountHolder = card.holder_name;
    this.maskedNumber = card.masked_card_number;
    this.address1 = card.street_address_1;
    this.address2 = card.street_address_2;
    this.city = card.city;
    this.zip = card.postal_code;
    this.state = card.province;
    this.country = card.country_code;
    this.type = PaymentCardTypeMap[card.funding];
    this.expDate = new Date(card.exp_year, card.exp_month, 0);
  }

  get hasBillingAddress() {
    return [this.address1, this.city, this.zip, this.state, this.country].every((item) =>
      Boolean(item),
    );
  }

  get hasCardHolderName() {
    return Boolean(this.accountHolder);
  }

  get isExpired() {
    return !isFuture(this.expDate);
  }

  setCvv(cvv: string) {
    this.cvv = cvv;
  }

  get hasBillingInfo() {
    return this.state && this.city && this.zip && this.address1;
  }
}

export default PaymentCardClass;
