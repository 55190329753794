import React, { FC } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import useDidUpdate from '@root/hooks/useDidUpdate';

import { externalUrlRegexp } from '@root/constants/regexpPatterns';

import { Grid, Button, RouteLeavingGuard } from '@components/common';
import BottomControlsWrapper from '@components/common/BottomControlsWrapper';

import CallingPaymentInfoClass from '@root/models/CallingPaymentInfoClass';

import SelectRecharge from '../../components/SelectRecharge';
import AutoRechargeToggler from '../../components/AutoRechargeToggler';

import Store from './Store';

interface Props {
  paymentInfo: CallingPaymentInfoClass;
  isAutoRechargeEnabled: boolean;
  onAutoRechargeToggle: (value: boolean) => void;
  onAutoRechargeUpdate: (value: number) => void;
  dataTestPrefix?: string;
}

const AutoRecharge: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    paymentInfo,
    isAutoRechargeEnabled,
    onAutoRechargeToggle,
    onAutoRechargeUpdate,
    dataTestPrefix,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const store = useLocalStore(
    () =>
      new Store({
        arAmount: paymentInfo.arAmount,
        isAutoRechargeEnabled,
      }),
  );

  const DATA_TEST_ID_PREFIX = `${dataTestPrefix}-auto-recharge`;

  useDidUpdate(() => {
    store.setSelectedAmount(paymentInfo.arAmount);
  }, [paymentInfo.arAmount]);

  useDidUpdate(() => {
    store.setAutoRechargeEnabled(isAutoRechargeEnabled);
  }, [isAutoRechargeEnabled]);

  const handleAmountSelect = (value: string) => {
    store.setSelectedAmount(value);
    store.setAutoRechargeEnabled();
    store.setHasUnsavedChanges();
  };

  const handleAutoRechargeChange = (value: boolean) => {
    onAutoRechargeToggle(value);
    store.setAutoRechargeEnabled(value);
    if (!value) {
      store.setSelectedAmount(undefined);
      store.setHasUnsavedChanges(false);
    }
  };

  const handleArUpdateConfirmation = () => {
    if (store.selectedAmount) {
      onAutoRechargeUpdate(store.selectedAmount);
      store.setHasUnsavedChanges(false);
    }
  };

  const navigateBack = () => {
    store.setHasUnsavedChanges(false);
    history.goBack();
  };

  const arActiveDescription = t(
    'Choose the amount that will be added to your account whenever your balance falls below {{amount}}.',
    {
      amount: `${paymentInfo.currencySymbol}${paymentInfo.thresholdAmount} ${paymentInfo.currency}`,
    },
  );

  const arDisabledDescription = t(
    'Turn auto-recharge on, then choose an auto-recharge amount.',
  );

  return (
    <>
      <RouteLeavingGuard
        title={t('Do you want to save changes?')}
        when={store.hasUnsavedChanges}
        shouldBlockNavigation={(nextLocation) => {
          return store.hasUnsavedChanges || externalUrlRegexp.test(nextLocation.pathname); // pathname includes https?:// or www.
        }}
        buttonConfig={[
          {
            label: t('Yes'),
            type: 'OK',
            onClick: handleArUpdateConfirmation,
          },
          {
            label: t('No'),
            type: 'Cancel',
            onClick: navigateBack,
          },
        ]}
      />
      <Grid.Container className="flex flex-col gap-8">
        <Grid.Row className="text-center">
          <Grid.Col span={{ sm: 12, lg: 4 }} offset={{ lg: 4 }} className="mb-4">
            <p className="text-2xl font-bold">
              {t('Choose an auto-recharge amount and never run out of talk time')}
            </p>
          </Grid.Col>

          <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }}>
            <SelectRecharge
              value={store.selectedAmount}
              options={paymentInfo.creditCardTiers}
              onChange={handleAmountSelect}
              className="mx-auto"
              dataTestId={`${DATA_TEST_ID_PREFIX}-select-recharge`}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row className="text-center">
          <Grid.Col span={{ sm: 12, lg: 8 }} offset={{ lg: 2 }} className="mb-10">
            <AutoRechargeToggler
              value={store.isAutoRechargeEnabled}
              onChange={handleAutoRechargeChange}
              dataTestId={`${DATA_TEST_ID_PREFIX}-toggler`}
            />
          </Grid.Col>

          <Grid.Col span={{ sm: 12, lg: 6 }} offset={{ lg: 3 }}>
            <p className="text-gray-400">
              {store.isAutoRechargeEnabled ? arActiveDescription : arDisabledDescription}
            </p>
          </Grid.Col>

          <BottomControlsWrapper>
            <Button
              shape="pill"
              color="blue"
              size="lg"
              onClick={handleArUpdateConfirmation}
              disabled={!store.hasUnsavedChanges}
              dataTestId={`${DATA_TEST_ID_PREFIX}-save`}
            >
              {t('Save changes')}
            </Button>
          </BottomControlsWrapper>
        </Grid.Row>
      </Grid.Container>
    </>
  );
};

export default observer(AutoRecharge);
