import {
  PaymentMethodKeys,
  PaymentMethods,
} from '@root/interfaces/paymentMethods/PaymentMethods';

export const PAYMENT_METHODS: {
  [key in PaymentMethodKeys]: PaymentMethods;
} = {
  CREDIT_CARD: 'CC',
  CASH: 'CASH',
  PAS: 'PAS',
  DEBIT_CARD: 'DC',
  BANK_ACCOUNT: 'BA',
  WALLET: 'BMW',
  PREPAID: 'PP',
  ACH: 'ACH',
};

export default PAYMENT_METHODS;
