import React, { FC, useLayoutEffect, useRef } from 'react';

import useOnClickOutside from '@root/hooks/useOnClickOutside';
import useNavigation from '@root/hooks/useNavigation';

import { normalizeStringCompound } from '@utils/string';

import Drawer from '../../Drawer';
import DropdownEar from './DropdownEar';
import Scrollbar from '../../Scrollbar';

import Grid from '../../Grid';

import MediaMatcher, { useIsMobile, useIsNotMobile } from '../../MediaQueryMatchers';

interface Props {
  isOpen?: boolean;
  className?: string;
  overlay: React.ReactNode;
  onClickOutside(): void;
}

const overlayInlineStyles: React.CSSProperties = {
  top: '-0.5rem',
  left: '-0.5rem',
  backgroundColor: 'white',
  width: 'calc(100% + 1rem)',
  height: 'calc(100% + 1rem)',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)', // TODO: put in Tailwind utils
};

const contentWrapperStyles = {
  maxWidth: '100%',
  maxHeight: '550px',
  height: '50%',
  borderTopLeftRadius: '1.25rem',
  borderTopRightRadius: '1.25rem',
  overflow: 'hidden',
};

const Dropdown: FC<React.PropsWithChildren<Props>> = (props) => {
  const { isOpen, children, className, overlay, onClickOutside } = props;

  const pageTopOffset = useRef(window.pageYOffset);
  const dropdownEarRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const countryCodesListRef = useRef<HTMLDivElement>(null);
  const mobileListRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useNavigation(containerRef, 'button.text-left.px-3.py-2.flex.items-center', [overlay]);

  const [countryCodeInput, maskedInput] = React.Children.toArray(children);

  const isMobile = useIsMobile();
  const isNotMobile = useIsNotMobile();

  useOnClickOutside(
    onClickOutside,
    dropdownEarRef,
    overlayRef,
    countryCodesListRef,
    mobileListRef,
  );

  const isDropdownOpen = isNotMobile && isOpen;
  const isDrawerOpen = isMobile && isOpen;

  useLayoutEffect(() => {
    if (isOpen) {
      pageTopOffset.current = window.pageYOffset;
    } else {
      window.scroll(0, pageTopOffset.current);
    }
  }, [isOpen]);

  const classNames = normalizeStringCompound(['relative', className]);

  return (
    <div className={classNames} ref={containerRef}>
      <div className="flex relative">
        <div ref={dropdownEarRef} className="w-84">
          <DropdownEar isOpen={isDropdownOpen} className="mr-5 z-30">
            {countryCodeInput}
          </DropdownEar>
        </div>
        {maskedInput}
        <div className="absolute mt-4 top-full w-full z-20 bg-white">
          {isDropdownOpen && (
            <>
              <div
                ref={overlayRef}
                className="absolute rounded-b-2xl rounded-tr-2xl w-full h-full"
                style={overlayInlineStyles}
              />
              <div ref={countryCodesListRef}>
                <Scrollbar className="max-h-64" forceVisible autoHide={false}>
                  {overlay}
                </Scrollbar>
              </div>
            </>
          )}
        </div>
      </div>
      <MediaMatcher isMobile>
        <Drawer
          width="100%"
          placement="bottom"
          isOpen={isDrawerOpen}
          level={null}
          handler={false}
          getContainer="html" // disable page scroll
          contentWrapperStyle={contentWrapperStyles}
        >
          <Grid.Container className="min-h-full">
            <div ref={mobileListRef} className="min-h-full">
              <div className="sticky top-0 py-5 bg-white z-10">{countryCodeInput}</div>
              {overlay}
            </div>
          </Grid.Container>
        </Drawer>
      </MediaMatcher>
    </div>
  );
};

export default Dropdown;
