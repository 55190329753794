import { useRef, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDidUpdate = (effect: () => Function | void, dependencies: Array<any>) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return undefined;
    }
    const cleanUpFunc = effect();
    return () => cleanUpFunc && cleanUpFunc();
  }, dependencies);
};

export default useDidUpdate;
