/* eslint-disable import/prefer-default-export */
export enum RoundingScheme {
  UP = 'U',
  Down = 'D',
  Proper = 'P',
  Scaled = 'N',
  up = 'up',
  down = 'down',
  proper = 'proper',
}
