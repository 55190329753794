import React, { FC } from 'react';
import { FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';
import { addYears } from 'date-fns';

import Yup from '@utils/validation/yup';

import { Form } from '@components/common';

import { LEGAL_AGE_YEARS } from '@root/constants/financeProfile';

import SubmitButton from '../SubmitButton';

export interface ChangeDobFormParams {
  dob?: Date;
}

interface Props {
  onSubmit: (values: ChangeDobFormParams) => void;
  dob?: Date;
  btnText?: string;
  className?: string;
  dataTestPrefix?: string;
}

const ChangeDobForm: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onSubmit, dob, btnText, className, dataTestPrefix } = props;

  const { t } = useTranslation();

  const maxLegalAgeDate = addYears(new Date(), -LEGAL_AGE_YEARS);

  const formConfig: FormikConfig<ChangeDobFormParams> = {
    enableReinitialize: true,
    initialValues: {
      dob,
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      dob: Yup.date().dateOfBirthLegal().required(),
    }),
  };

  return (
    <Form className={className} config={formConfig}>
      <Form.Field
        type="datePicker"
        name="dob"
        label={t('Date of Birth')}
        className="mb-4"
        maxDate={maxLegalAgeDate}
        required
        dataTestId={`${dataTestPrefix}-dob-input`}
      />
      <div className="text-center mt-10">
        <Form.Button
          customComponent={SubmitButton}
          dataTestId={`${dataTestPrefix}-dob-submit-btn`}
        >
          {btnText ?? t('Save changes')}
        </Form.Button>
      </div>
    </Form>
  );
};

export default ChangeDobForm;
