import React, { useState } from 'react';

import ImtuPromoClass from '@root/models/ImtuPromoClass';

import ImtuPromoCard from '@components/modules/Imtu/components/CardItem/ImtuPromoCard';

import PromoDetailsModal from '../DetailsModal/PromoDetailsModal';

interface Props {
  data: ImtuPromoClass[];
  onItemSelect?(value: string): void;
}

const ImtuPromosSection: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, onItemSelect } = props;

  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<ImtuPromoClass | undefined>(
    undefined,
  );

  const showDetailsModal = () => {
    setDetailsModalVisible(true);
  };

  const hideDetailsModal = () => {
    setDetailsModalVisible(false);
  };

  const handlePromoSelect = (e: React.MouseEvent, value: string) => {
    onItemSelect?.(value);
  };

  const handlePromoDetailsClick = (e: React.MouseEvent, value: string) => {
    const promoDetails = data.find((item) => {
      return item.id === value;
    });
    if (promoDetails) {
      setSelectedDetails(promoDetails);
      showDetailsModal();
    }
  };

  const handlePromoDetailsClose = () => {
    hideDetailsModal();
    setSelectedDetails(undefined);
  };

  return (
    <>
      <div className="flex flex-wrap justify-center gap-y-4 gap-x-16">
        {data.map((item) => {
          return (
            <ImtuPromoCard
              key={item.id}
              data={item}
              onClick={handlePromoSelect}
              onDetailsClick={handlePromoDetailsClick}
            />
          );
        })}
      </div>
      <PromoDetailsModal
        countryCode={selectedDetails?.countryCode}
        carrierLogoUrl={selectedDetails?.carrier.logoUrl}
        promo={selectedDetails}
        isOpen={isDetailsModalVisible}
        onRequestClose={handlePromoDetailsClose}
      />
    </>
  );
};

export default ImtuPromosSection;
