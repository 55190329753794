import { FC } from 'react';

import { Typography } from '@components/common';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { normalizeStringCompound } from '@utils/string';

import './styles.scss';

interface Props extends TestIdDataAttr {
  path: string;
  index?: number;
  title: string;
  isCurrent: boolean;
  isDisabled: boolean;
}

const BredCrumbItem: FC<React.PropsWithChildren<Props>> = (props) => {
  const { path, index, title, isCurrent, isDisabled, dataTestId } = props;

  const DECORATION_BASE_CLASS = 'round-stepper-item-decoration';

  const containerClassNames = normalizeStringCompound([
    DECORATION_BASE_CLASS,
    index === 0 ? `${DECORATION_BASE_CLASS}__first` : undefined,
    isDisabled ? `${DECORATION_BASE_CLASS}__disabled` : undefined,
    isDisabled && !isCurrent ? 'opacity-20' : undefined,
    'group-first:before:content-none',
  ]);

  const roundClassNames = normalizeStringCompound([
    'flex justify-center items-center w-10 h-10 lg:h-8 lg:w-8  rounded-full border-2 bg-green-900',
    'text-xl mx-auto group-focus-visible:focus-ring',
    !isDisabled && !isCurrent ? 'bg-green-900 text-white' : undefined,
    isDisabled || isCurrent ? 'text-green-900' : undefined,
  ]);

  const node = (
    <>
      <div
        className={normalizeStringCompound([
          'stepper-item relative',
          index === 0 ? 'before important:before:content-none' : '',
        ])}
      >
        <div className="flex flex-col items-center mx-auto">
          <div className={roundClassNames} />
        </div>
      </div>
      {title && <div className="text-sm text-center">{title}</div>}
    </>
  );

  return (
    <div className={containerClassNames}>
      {isDisabled || isCurrent ? (
        node
      ) : (
        <Typography.Link className="group" to={path} dataTestId={dataTestId}>
          {node}
        </Typography.Link>
      )}
    </div>
  );
};

export default BredCrumbItem;
