import React, { useState, FC, useEffect } from 'react';

import { RadioGroupProps } from '@root/interfaces/components/Radio';

import Scrollbar from '../Scrollbar';
import Radio from '../Radio';

const RadioGroup: FC<React.PropsWithChildren<RadioGroupProps>> = (props) => {
  const {
    className,
    optionClassName,
    defaultValue,
    options,
    disabled,
    value,
    onChange,
    dataTestId,
  } = props;

  const [checkedValue, setCheckedValue] = useState(value || defaultValue);

  useEffect(() => {
    setCheckedValue(value);
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedValue(event.target.value);

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <Scrollbar>
      <div className={className}>
        {options.map((item, index) => (
          <Radio
            key={item.value}
            {...item}
            disabled={item.disabled || disabled}
            onChange={handleChange}
            checked={item.checked || checkedValue === item.value}
            dataTestId={dataTestId ? `${dataTestId}-radio-${index}` : undefined}
            className={optionClassName}
          />
        ))}
      </div>
    </Scrollbar>
  );
};

export default RadioGroup;
