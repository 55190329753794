import { AxiosRequestConfig } from 'axios';

import { API_ERROR_MSG } from '@root/constants/errorMessages';

import storageInterface from '@services/storage';
import { SESSION_STORE_KEY } from '@services/auth/constants';
import { getErrorMessageByCode } from '@services/auth/helpers';
import { logError } from '@services/logging';

import SessionClass from '@root/models/SessionClass';

import Axios from './Axios';

import { handleHttpError, repeatCall } from './helpers';

import { getSessionDataFromStorage, handleExpiredSession } from '../auth';

class DtcApi extends Axios {
  constructor(config: AxiosRequestConfig) {
    super(config);

    this.instance.interceptors.request.use((axiosConfig) => {
      const sessionString = storageInterface.getItem(SESSION_STORE_KEY);

      if (sessionString) {
        const session = JSON.parse(sessionString) as SessionClass;

        if (axiosConfig.headers) {
          // eslint-disable-next-line no-param-reassign
          axiosConfig.headers.Authorization = `Bearer ${session.accessToken}`;
        }
      }

      return axiosConfig;
    });

    this.instance.interceptors.response.use(undefined, (error) => {
      logError(error, error?.response || error, getSessionDataFromStorage());

      // http errors handling
      if (error.response) {
        return handleHttpError(error.response.status, {
          400: () => {
            const errorCode = error.response.data.error_code;
            return Promise.reject(Error(getErrorMessageByCode(errorCode)));
          },
          401: async () => {
            try {
              return await handleExpiredSession({
                axiosInstance: this.instance,
                error,
              });
            } catch (err) {
              return Promise.reject(Error(API_ERROR_MSG.APP_DEFAULT));
            }
          },
          404: () => {
            return Promise.reject(Error(API_ERROR_MSG.NOT_FOUND));
          },
          409: () => {
            return Promise.reject(Error(API_ERROR_MSG.APP_ALREADY_IN_USE));
          },
          429: () => {
            const errorCode = error.response.data.error_code;
            return Promise.reject(Error(getErrorMessageByCode(errorCode)));
          },
          451: () => {
            const errorCode = error.response.data.error_code;
            return Promise.reject(Error(getErrorMessageByCode(errorCode)));
          },
          500: () => {
            const errorCode = error.response.data.error_code;
            return Promise.reject(Error(getErrorMessageByCode(errorCode)));
          },
        });
      }

      // repeat call on network error
      return repeatCall(this.instance, error.config);
    });
  }
}

export default DtcApi;
