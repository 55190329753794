import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import useStore from '@root/hooks/useStore';
import useDidMount from '@root/hooks/useDidMount';

import { LoginStore } from '@root/stores';

import { trackButtonPress } from '@services/analytics';

import { AnalyticsContext } from '@root/interfaces/Analytics';

import { visibleBackButtonPaths } from '../constants';

interface Props {
  children: JSX.Element;
}

const BACK_BTN_TEXT = 'Back';

const Header: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children } = props;

  const history = useHistory();
  const location = useLocation();

  const loginStore: LoginStore = useStore('LoginStore');

  useDidMount(() => {
    loginStore.getSupportedCountries();
  });

  const handleBackClick = () => {
    trackButtonPress({
      button_name: BACK_BTN_TEXT,
      context: AnalyticsContext.Login,
      // screen: TODO - decide how to find out actual screen
    });
    history.goBack();
  };

  const isBackHandler = visibleBackButtonPaths.includes(location.pathname);

  return React.cloneElement(children, {
    onBackClick: isBackHandler ? handleBackClick : undefined,
  });
};

export default Header;
