import { getPhoneDialCodeByCountryCode, getPhoneInfoByNumber } from '@helpers/phone';

import { CountryCodeOption } from '@root/interfaces/components/PhoneInput';
import CountryCode from '@root/interfaces/CountryCode';

import { replaceAccentCharacters } from '@utils/string';

const hasOnlyNumbers = (query: string) => /^\+?([0-9]?)+$/.test(query);

const hasOnlyLetters = (query: string) =>
  /^\+?([a-z\s]?)+$/i.test(replaceAccentCharacters(query));

export const filterCountryCodeOptionByDialCode = (
  option: CountryCodeOption,
  query: string,
) => {
  return option.dialCode?.includes(query);
};

export const filterCountryCodeOptionByCountryName = (
  option: CountryCodeOption,
  query: string,
) => {
  const normalizedCountryName = replaceAccentCharacters(
    (option.countryName || '').toLowerCase(),
  );
  const normalizedQuery = replaceAccentCharacters(query.toLowerCase());
  return normalizedCountryName.includes(normalizedQuery);
};

export const filterCountryCodeOption = (option: CountryCodeOption, query: string) => {
  if (hasOnlyNumbers(query)) {
    return Boolean(filterCountryCodeOptionByDialCode(option, query));
  }
  if (hasOnlyLetters(query)) {
    return Boolean(filterCountryCodeOptionByCountryName(option, query));
  }
  return false;
};

export const getDefaultCountryCode = (props: {
  initialPhoneNumber?: string;
  defaultCountryCode?: CountryCode;
  availableCountryCodes?: CountryCode[];
}) => {
  const { initialPhoneNumber, defaultCountryCode, availableCountryCodes } = props;
  const { country: parsedDefaultCountry } = getPhoneInfoByNumber(
    initialPhoneNumber ?? '',
  );

  if (parsedDefaultCountry) {
    return parsedDefaultCountry;
  }
  if (defaultCountryCode && availableCountryCodes?.includes(defaultCountryCode)) {
    return defaultCountryCode;
  }
  return availableCountryCodes?.length === 1 && !defaultCountryCode
    ? availableCountryCodes[0]
    : undefined;
};

export const getDefaultPhoneNumber = (initialPhoneNumber?: string) => {
  const asYouType = getPhoneInfoByNumber(initialPhoneNumber ?? '');
  return asYouType.getNationalNumber();
};

export const getNormalizedCountryDialCode = (countryCode?: CountryCode) =>
  countryCode ? `+${getPhoneDialCodeByCountryCode(countryCode)}` : undefined;
