import { FC } from 'react';

import { OptionListProps } from '@root/interfaces/components/Select';

import Option from './Option';

const OptionList: FC<React.PropsWithChildren<OptionListProps>> = (props) => {
  const { options, onSelect, dataTestId, optionBtnClassName } = props;

  return (
    <>
      {options.map((item) => (
        <Option
          key={item.value}
          onClick={onSelect}
          dataTestId={`${dataTestId}-${item.value}`}
          className={optionBtnClassName}
          {...item}
        />
      ))}
    </>
  );
};

export default OptionList;
