/* eslint-disable @typescript-eslint/no-use-before-define */
import { observer } from 'mobx-react';
import { useLayoutEffect, useRef, useState } from 'react';

import useLanguageSwitcher from '@root/hooks/useLanguageSwitcher';

import { LanguagesList } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

import { LanguageOption } from '@root/interfaces/components/LanguageSwitcher';

import Toggler from './Toggler';
import Option from './LanguageOption';

import './styles.scss';

interface Props {
  languagesOptions: LanguageOption[];
  className?: string;
  onChange?(value: LanguageOption): void;
}

const GUTTER_WIDTH = 20;
const LanguageSwitcher = (props: Props) => {
  const { className, languagesOptions, onChange } = props;

  const {
    languageSwitcherRef,
    onTogglerClick,
    isOpen,
    onLanguageOptionSelect,
    currentLanguage,
    isDisabled,
  } = useLanguageSwitcher({
    languagesOptions,
    onChange,
  });

  const classNames = normalizeStringCompound([
    className,
    'i18n-language-switcher relative',
  ]);

  const [isReversedPosition, setIsReversedPosition] = useState(false);

  const lngSwitcherDropDownRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isOpen) {
      const windowWidth = document.documentElement.clientWidth;
      const lngSwitcherDropDownRect = lngSwitcherDropDownRef.current?.getBoundingClientRect();
      setIsReversedPosition(
        Boolean(
          lngSwitcherDropDownRect &&
            lngSwitcherDropDownRect.right > windowWidth - GUTTER_WIDTH,
        ),
      );
    }
  }, [isOpen]);

  return (
    <div className={classNames} ref={languageSwitcherRef}>
      {currentLanguage && (
        <>
          <Toggler
            value={currentLanguage}
            onClick={onTogglerClick}
            isDisabled={isDisabled}
          />
          {isOpen && (
            <div
              ref={lngSwitcherDropDownRef}
              className="absolute right-0 md:left-0 md:right-auto mt-3 z-10"
            >
              <div
                className="bg-white w-4 h-4 right-4 md:left-3 lg:left-[53px] absolute transform rotate-45 shadow"
                style={{ top: '-6px' }}
              />
              <div
                className={normalizeStringCompound([
                  'shadow-md rounded-lg overflow-hidden',
                  isReversedPosition ? 'md:[transform:translateX(calc(-40%))]' : null,
                ])}
              >
                <LanguagesList
                  options={languagesOptions}
                  onSelect={onLanguageOptionSelect}
                  selectedLanguage={currentLanguage}
                  renderItem={(...optionProps) => (
                    <Option
                      option={optionProps[0]}
                      onClick={optionProps[1]}
                      isSelected={currentLanguage === optionProps[0].value}
                    />
                  )}
                />
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default observer(LanguageSwitcher);
