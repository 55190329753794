import React, { FC } from 'react';

import { ButtonProps } from '@root/interfaces/components/Button';

import { normalizeStringCompound } from '@utils/string';

import { Button } from '@components/common';

type OmittedProps = 'onClick' | 'shape' | 'size' | 'outlined' | 'style';

interface Props extends Omit<ButtonProps, OmittedProps> {
  isSelected: boolean;
  onClick: (value: string) => void;
  value: string;
}

const TabOption: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, value, isSelected, onClick, className, ...rest } = props;

  const handleClick = () => {
    onClick(value);
  };

  return (
    <Button
      shape="pill"
      size="sm"
      isOutlined={!isSelected}
      className={normalizeStringCompound(['lg:w-40', className])}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default TabOption;
