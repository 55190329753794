import React, { FC } from 'react';
import Lottie from 'lottie-react-web';

import { normalizeStringCompound } from '@utils/string';

import { SpinnerProps } from '@root/interfaces/components/Spinner';

import animation from './liquid_b.json';

const sizes = {
  sm: 140,
  md: 240,
  lg: 340,
};

const Spinner: FC<React.PropsWithChildren<SpinnerProps>> = (props) => {
  const {
    isSpinning,
    children,
    size = 'md',
    isGlobal,
    hasNoBg,
    className,
    isReplaceChildren,
  } = props;

  const containerClassNames = normalizeStringCompound([
    'w-full relative',
    children ? 'h-full' : undefined,
    className,
  ]);

  const spinnerClassNames = normalizeStringCompound([
    'inset-0 flex items-center justify-center z-30',
    hasNoBg ? undefined : 'bg-white bg-opacity-75',
    isGlobal ? 'fixed z-1000' : 'absolute',
  ]);

  return (
    <div
      className={containerClassNames}
      style={isSpinning ? { minHeight: `${sizes[size]}px` } : undefined}
      aria-hidden="true"
    >
      {isSpinning && isReplaceChildren ? null : children}
      {isSpinning && (
        <div className={spinnerClassNames}>
          <Lottie
            options={{ animationData: animation }}
            ariaRole="img"
            height={sizes[size]}
          />
        </div>
      )}
    </div>
  );
};

Spinner.defaultProps = {
  size: 'md',
  isGlobal: false,
};

export default Spinner;
