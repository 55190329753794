import React from 'react';
import { Img } from 'react-image';
import { useTranslation } from 'react-i18next';

import ImtuProductClass from '@root/models/ImtuProductClass';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { Button, Flag } from '@components/common';

import CarrierLogoGeneric from '@components/modules/Imtu/components/CarrierLogoGeneric';

import CardItem from './index';

interface Props extends TestIdDataAttr {
  data: ImtuProductClass;
  isActive?: boolean;
  onClick: (e: React.MouseEvent, value: string) => void;
  onDetailsClick?(e: React.MouseEvent, value: string): void;
}

const ImtuProductCard: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, isActive, onClick, onDetailsClick } = props;

  const { t } = useTranslation();

  const handleClick = (e: React.MouseEvent) => {
    onClick(e, data.code);
  };

  const handleDetailsClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    onDetailsClick?.(e, data.code);
  };

  return (
    <CardItem
      leftPart={
        <Img
          className="w-22 h-22 p-4"
          src={data.carrier.logoUrl}
          unloader={<CarrierLogoGeneric className="w-22 h-22" />}
        />
      }
      middlePart={
        <div className="flex flex-col h-full justify-between text-left mr-2">
          <div className="flex flex-col text-2xl md:text-xl uppercase leading-6">
            <div>{data.senderBaseAmountText}</div>
            {data.recipientAmount ? <div>{`(${data.recipientAmountText})`}</div> : null}
          </div>
          {data.name && (
            <div className="flex flex-col text-lg md:text-base text-gray-400 leading-6">
              <div className="overflow-hidden text-ellipsis">{`${data.name}`}</div>
            </div>
          )}
        </div>
      }
      rightPart={
        <div className="flex flex-col h-full justify-between items-center">
          <Flag
            code={data.countryCode}
            className="w-10 h-10 border-0 rounded-full overflow-hidden"
          />
          <Button shape="text" size="sm" onClick={handleDetailsClick}>
            <span className="group-focus-visible:focus-ring">{t('Details')}</span>
          </Button>
        </div>
      }
      value={data.name}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};

export default ImtuProductCard;
