import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/common';

interface Props {
  onBackClick?: () => void;
  containerClassName?: string;
}

const BackButton: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onBackClick, containerClassName } = props;

  const { t } = useTranslation();

  return (
    <div className={containerClassName}>
      {onBackClick && (
        <Icon
          name="ChevronLeft"
          onClick={onBackClick}
          className="text-xl"
          dataTestId="login-back-btn"
          title={t('Back')}
        />
      )}
    </div>
  );
};

export default BackButton;
