export enum PaymentCardTypeShort {
  CC = 'CC',
  DC = 'DC',
  PP = 'PP',
}

export enum PaymentCardFundingType {
  credit = 'credit', // t('credit') // t('Credit')
  debit = 'debit', // t('debit') // t('Debit')
  prepaid = 'prepaid', // t('prepaid') t('Prepaid')
}
