import React from 'react';
import { useTranslation } from 'react-i18next';
import { Img } from 'react-image';

import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';

import { Flag, PromoCodeForm } from '@components/common';

import { getCountryNameByCode } from '@services/countryList';

import { normalizeStringCompound } from '@utils/string';

import { ImtuStep } from '../../constants';

import EditButton from './EditButton';

interface Props {
  countryCode: CountryCode;
  recipientPhoneNumber?: string;
  carrier?: ImtuCarrierClass;
  product?: ImtuProductClass;
  promoInfo?: ImtuPromoOfferDetailsClass;
  isLoading?: boolean;
  isPromoSectionVisible?: boolean;
  onPromoApply?(promoCode: string): void;
  onPromoChange?(): void;
  onEditStepSelect?(selectedStep: ImtuStep): void;
}

const ImtuTxnInfoSection: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    countryCode,
    recipientPhoneNumber,
    carrier,
    product,
    promoInfo,
    isLoading,
    isPromoSectionVisible,
    onPromoApply,
    onPromoChange,
    onEditStepSelect,
  } = props;

  const { t } = useTranslation();

  const cmpClassNames =
    'flex flex-col w-full max-w-sm text-left border rounded-2xl shadow-lg border-gray-300 p-6';
  const rowClassNames = 'text-xl py-2';
  const rowHeaderClassNames = 'font-bold capitalize';
  const valueRowClassNames = 'flex flex-row items-center justify-between';
  const rowIconContainerClassNames = 'w-8 h-8 shadow-md';
  const rowValueContainerLeftClassNames = 'flex flex-row gap-4 items-center';

  const divider = <div className="w-full border-b border-gray-300" />;

  const handleEditStepSelect = (stepToEdit: ImtuStep) => {
    onEditStepSelect?.(stepToEdit);
  };

  const handleApplyPromo = async (value: string) => {
    onPromoApply?.(value);
  };

  const handlePromoCodeFormValuesChange = () => {
    onPromoChange?.();
  };

  return (
    <div className={cmpClassNames}>
      <div className={rowClassNames}>
        <div className={rowHeaderClassNames}>{t("recipient's country")}</div>
        <div className={valueRowClassNames}>
          <div className={rowValueContainerLeftClassNames}>
            <div
              className={normalizeStringCompound([
                rowIconContainerClassNames,
                'flex rounded-full overflow-hidden',
              ])}
            >
              <Flag className="w-full" code={countryCode} />
            </div>
            <div>{t(getCountryNameByCode(countryCode) || '')}</div>
          </div>
          <EditButton step={ImtuStep.Country} onClick={handleEditStepSelect} />
        </div>
      </div>
      {recipientPhoneNumber && (
        <div className={rowClassNames}>
          <div className={rowHeaderClassNames}>{t('phone number to top up')}</div>
          <div className={valueRowClassNames}>
            <div>{recipientPhoneNumber}</div>
            <EditButton step={ImtuStep.Phone} onClick={handleEditStepSelect} />
          </div>
        </div>
      )}
      {carrier && (
        <div className={rowClassNames}>
          <div className={rowHeaderClassNames}>{t('carrier')}</div>
          <div className={valueRowClassNames}>
            <div className={rowValueContainerLeftClassNames}>
              <div className={rowIconContainerClassNames}>
                <Img src={carrier.logoUrl} />
              </div>
              <div>{carrier.name}</div>
            </div>
            <EditButton step={ImtuStep.Carrier} onClick={handleEditStepSelect} />
          </div>
        </div>
      )}
      {product && (
        <div className={rowClassNames}>
          <div className={rowHeaderClassNames}>{t('top-up plan')}</div>
          <div className={valueRowClassNames}>
            <div>
              {product.senderBaseAmountText}{' '}
              {product?.recipientAmount && !product.hasSameSenderRecipientAmount ? (
                <span className="opacity-40 whitespace-nowrap">{`(${product.recipientAmountText})`}</span>
              ) : null}
            </div>
            <EditButton step={ImtuStep.Plan} onClick={handleEditStepSelect} />
          </div>
        </div>
      )}

      {isPromoSectionVisible && (
        <>
          <div className="text-xl py-4">
            <div className={rowHeaderClassNames}>{t('promo code')}</div>
            <div className="pt-2">
              <PromoCodeForm
                onFormValuesChange={handlePromoCodeFormValuesChange}
                onSubmit={handleApplyPromo}
                initialValues={{
                  promoCode: promoInfo?.promoId || '',
                }}
                placeholder={t('Enter promo code')}
                isApplied={Boolean(promoInfo)}
                isLoading={isLoading}
              />
            </div>
          </div>
          {promoInfo && (
            <div className="flex flex-row justify-between text-xl lg:text-lg pt-2">
              <div className="pr-2">{promoInfo.description}</div>
              <div className="flex-shrink-0 w-32 lg:w-30 text-right text-blue-400 whitespace-nowrap mt-auto">{`- ${promoInfo.discountText}`}</div>
            </div>
          )}
        </>
      )}

      {product?.fee !== undefined ? (
        <div className="flex flex-row justify-between text-xl lg:text-lg py-2">
          <div className="capitalize">{t('service fee')}</div>
          <div>{product?.feeText}</div>
        </div>
      ) : null}

      {product?.senderAmount ? (
        <>
          {divider}
          <div className="flex flex-row justify-between text-2xl md:text-xl lg:text-2xl font-medium pt-4">
            <div className="capitalize">{t('total')}</div>
            <div>
              {promoInfo?.priceTotalDiscountedText || product?.senderTotalAmountText}
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ImtuTxnInfoSection;
