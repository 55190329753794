import React from 'react';

import { normalizeStringCompound } from '@utils/string';

import { OptionButtonProps } from '@root/interfaces/components/OptionButton';

import Icon from '../Icon';

import './styles.scss';

const BASIC_CLASS = 'option-button';

const OptionButton = (props: OptionButtonProps) => {
  const {
    iconName,
    className,
    iconClassName,
    size,
    isOutlined,
    label,
    onClick,
    dataTestId,
  } = props;

  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${size}`,
    isOutlined ? `${BASIC_CLASS}-outline` : '',
    className,
  ]);

  const iconClassNames = normalizeStringCompound([
    size === 'sm' ? 'text-xl' : '',
    size === 'md' ? 'text-2xl' : '',
    size === 'lg' ? 'text-3xl' : '',
    iconClassName,
  ]);

  return (
    <>
      {onClick ? (
        <button onClick={onClick} className={classNames} data-test-id={dataTestId}>
          <Icon name={iconName} className={iconClassNames} />
        </button>
      ) : (
        <div className={classNames}>
          <Icon name={iconName} className={iconClassNames} />
        </div>
      )}
      {label && (
        <div className="text-lg text-center font-medium mt-3 leading-5">{label}</div>
      )}
    </>
  );
};

OptionButton.defaultProps = {
  size: 'md',
};

export default OptionButton;
