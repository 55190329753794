import React, { FC } from 'react';

import { Icon } from '@components/common';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

interface Props extends TestIdDataAttr {
  onClick: () => void;
  isDisabled?: boolean;
}

const AddButton: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, onClick, dataTestId, isDisabled } = props;

  return (
    <button
      className="flex items-center group"
      onClick={onClick}
      data-test-id={dataTestId}
      disabled={isDisabled}
    >
      <div className="rounded-full w-16 h-16 border-2 shadow-md bg-gray-200 border-white flex justify-center items-center group-focus-visible:focus-ring">
        <Icon name="Plus" className="text-3xl" />
      </div>
      <span className="ml-4 text-lg">{children}</span>
    </button>
  );
};

export default AddButton;
