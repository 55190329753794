import { ImtuStatus } from '@root/interfaces/MobileTopUp';

// eslint-disable-next-line import/prefer-default-export
export const getImtuReceiptPageTitle = (txnStatus?: ImtuStatus): string => {
  const receiptTxnStatusTitleMap: { [key in ImtuStatus]: string } = {
    success: 'top-up sent', // t('top-up sent')
    queued: 'order queued', // t('order queued')
    failed: 'order failed', // t('order failed')
  };
  return txnStatus ? receiptTxnStatusTitleMap?.[txnStatus] : 'receipt';
};
