import React from 'react';

import ImtuProductClass from '@root/models/ImtuProductClass';

import DetailsModal from './index';

interface Props extends React.ComponentProps<typeof DetailsModal> {
  product?: ImtuProductClass;
}

const ProductDetailsModal: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { testId, product, isOpen, onRequestClose } = props;

  return (
    <DetailsModal
      countryCode={product?.countryCode}
      carrierLogoUrl={product?.carrier.logoUrl}
      testId={testId}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {product && (
        <div className="flex flex-col gap-4">
          <div className="text-2xl font-bold">
            <div>{product.senderBaseAmountText}</div>
            {product.recipientAmount && <div>{`(${product.recipientAmountText})`}</div>}
          </div>
          <div className="text-2xl">{product.name}</div>
          <div className="text-gray-400 text-xl md:text-lg">{product.description}</div>
        </div>
      )}
    </DetailsModal>
  );
};

export default ProductDetailsModal;
