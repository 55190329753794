import React from 'react';
import { useFormikContext } from 'formik';

import { Button } from '@components/common';

interface FormButtonProps extends Omit<Parameters<typeof Button>[0], 'type'> {
  customComponent?: typeof Button;
  shouldOverrideDisabledBehaviour?: boolean; // in case you don't want a form to control disabled state
}

const FormButton = (props: FormButtonProps) => {
  const { isValid } = useFormikContext();

  const {
    disabled,
    shouldOverrideDisabledBehaviour,
    customComponent,
    ...restProps
  } = props;

  const isDisabledStateComputed = shouldOverrideDisabledBehaviour
    ? disabled
    : !isValid || disabled;

  if (customComponent) {
    const CustomComponent = customComponent;
    return (
      <CustomComponent {...restProps} disabled={isDisabledStateComputed} type="submit" />
    );
  }

  return <Button {...restProps} disabled={isDisabledStateComputed} type="submit" />;
};

export default FormButton;
