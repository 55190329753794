import React, { FunctionComponent } from 'react';

import HeaderContainer from '@components/common/layout/HeaderContainer';
import FooterContainer from '@components/common/layout/FooterContainer';

import HeaderNavigation from '@components/modules/HeaderNavigation';
import Footer from '@components/modules/Footer';

const CommonTemplate: FunctionComponent<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;

  return (
    <div className="bg-white w-full flex flex-col justify-between pt-16 md:pt-12.5 print:pt-0">
      <HeaderContainer>
        <HeaderNavigation />
      </HeaderContainer>

      <div className="min-h-screen mb-20 print:min-h-0">
        <div>{children}</div>
      </div>

      <FooterContainer>
        <Footer />
      </FooterContainer>
    </div>
  );
};

export default CommonTemplate;
