import { makeAutoObservable } from 'mobx';

import PaymentCardClass from '@root/models/PaymentCardClass';

import { fetchPaymentCards } from '@services/api/v1/paymentCards';

import { showToast } from '@services/toasts';

import PAYMENT_METHODS from '@root/constants/moneyTransfer/paymentMethods';

class PaymentCardsStore {
  isLoading = false;

  paymentCards?: PaymentCardClass[];

  constructor() {
    makeAutoObservable(this);
  }

  get validCards() {
    return this.paymentCards?.filter((card) => {
      return !card.isExpired;
    });
  }

  get hasCards() {
    return Boolean(this.paymentCards?.length);
  }

  get creditCards() {
    return this.paymentCards?.filter((item) => item.type === PAYMENT_METHODS.CREDIT_CARD);
  }

  get debitCards() {
    return this.paymentCards?.filter(
      (item) => item.type === PAYMENT_METHODS.DEBIT_CARD || !item.type,
    );
  }

  get prepaidCards() {
    return this.paymentCards?.filter((item) => item.type === PAYMENT_METHODS.PREPAID);
  }

  getPaymentCards = async () => {
    this.isLoading = true;
    try {
      this.paymentCards = await fetchPaymentCards();
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.isLoading = false;
    }
  };

  setPaymentCards = (cards: PaymentCardClass[]) => {
    this.paymentCards = cards;
  };

  getPaymentCardById = (id: string) => {
    return this.validCards?.find((card) => card.id === id);
  };

  getPaymentCardByLastFourDigits = (value: string) => {
    return this.validCards?.find((card) => card.maskedNumber.slice(-4) === value);
  };
}

export default PaymentCardsStore;
