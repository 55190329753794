import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import useLanguageSwitcher from '@root/hooks/useLanguageSwitcher';
import { LanguageOption } from '@root/interfaces/components/LanguageSwitcher';

import { Icon, LanguagesList, Typography } from '@components/common';

import { LANGUAGES } from '@root/constants/languages';

import LanguageOptionItem from './LanguageOptionItem';

import './styles.scss';

const ANIMATION_DURATION = 300;

interface Props {
  languagesOptions: LanguageOption[];
  className?: string;
}

const FooterSettings = (props: Props) => {
  const { className, languagesOptions } = props;

  const {
    isOpen,
    onLanguageOptionSelect,
    onTogglerClick,
    languageSwitcherRef,
    currentLanguage,
  } = useLanguageSwitcher({
    languagesOptions,
  });

  const { t } = useTranslation();

  return (
    <div className={className}>
      <Typography.Title level={5} className="mb-6">
        {t('Settings')}
      </Typography.Title>
      <div className="inline-block relative">
        <button
          className="text-gray-900 text-base text-left inline-block relative"
          onClick={onTogglerClick}
        >
          {t('Language')}:{' '}
          {!isOpen && currentLanguage && (
            <>
              {LANGUAGES[currentLanguage]?.name}
              <Icon name="ChevronDown" className="ml-1 text-xs" />
            </>
          )}
        </button>
        <CSSTransition
          in={isOpen}
          classNames="i18n-switcher-footer-list"
          timeout={ANIMATION_DURATION}
          unmountOnExit
        >
          <div className="absolute left-full transform translate-x-1 -translate-y-1/2">
            <div
              ref={languageSwitcherRef}
              className="border border-black-100 shadow-lg rounded-lg overflow-hidden"
            >
              {currentLanguage && (
                <LanguagesList
                  selectedLanguage={currentLanguage}
                  onSelect={onLanguageOptionSelect}
                  options={languagesOptions}
                  renderItem={(option, onClick) => (
                    <LanguageOptionItem
                      onClick={onClick}
                      option={option}
                      isSelected={currentLanguage === option.value}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

export default FooterSettings;
