import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { compareDesc } from 'date-fns';

import { removeDuplicates } from '@utils/array';

import ImtuHistoryStore from '@components/modules/TransactionHistory/ImtuHistoryStore';

import useDidMount from '@root/hooks/useDidMount';

import { Spinner } from '@components/common';

import PATHS from '@root/routes/paths';

import TransactionsList from './components/TransactionsList';
import Tabs from './components/Tabs';

import {
  convertImtuTransactionsToListItems,
  convertCallingRechargesToListItems,
} from './helpers';

import RechargeHistoryStore from './RechargeHistoryStore';
import Store, { FilterType } from './Store';

const DATA_TEST_ID_PREFIX = 'transaction-history';

const TransactionHistory = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const store = useLocalStore(() => new Store());
  const imtuHistoryStore = useLocalStore(() => new ImtuHistoryStore());
  const rechargeHistoryStore = useLocalStore(() => new RechargeHistoryStore());

  useDidMount(async () => {
    imtuHistoryStore.getTransactions();
    rechargeHistoryStore.getTransactions();
  });

  const handleTransactionDetailsSelect = (id: string, type: FilterType) => {
    if (type === FilterType.Imtu) {
      history.push(`${PATHS.IMTU_RECEIPT}/${id}`);
    }
  };

  const handleTabChange = (tabValue: string) => {
    store.setTypeFilter(tabValue);
  };

  const txns = useMemo(() => {
    const modifiedTransactionList = [
      ...convertImtuTransactionsToListItems(imtuHistoryStore.transactions),
      ...convertCallingRechargesToListItems(rechargeHistoryStore.transactions),
    ]
      .filter((item) => {
        return store.typeFilter !== FilterType.All
          ? item.type === store.typeFilter
          : true;
      })
      .sort((prev, next) => compareDesc(prev.date, next.date));
    const uniqueTransactionList = removeDuplicates(modifiedTransactionList, 'id');

    return uniqueTransactionList;
  }, [
    rechargeHistoryStore.transactions,
    imtuHistoryStore.transactions,
    store.typeFilter,
  ]);

  const pendingTxns = useMemo(() => txns.filter((item) => item.isPending), [txns]);
  const completedTxns = useMemo(() => txns.filter((item) => !item.isPending), [txns]);

  const tabsOptions = [
    {
      title: t('All'),
      value: FilterType.All,
      dataTestId: `${DATA_TEST_ID_PREFIX}-filter-all-btn`,
    },
    {
      title: t('Top-Ups'),
      value: FilterType.Imtu,
      dataTestId: `${DATA_TEST_ID_PREFIX}-filter-imtu-btn`,
      isHidden: !imtuHistoryStore.transactions.length,
    },
    {
      title: t('Calling balance'),
      value: FilterType.Calling,
      dataTestId: `${DATA_TEST_ID_PREFIX}-filter-calling-btn`,
      isHidden: !rechargeHistoryStore.transactions.length,
    },
  ];

  const isLoading = imtuHistoryStore.isLoading || rechargeHistoryStore.isLoading;

  const hasNoTransactions = !isLoading && !pendingTxns.length && !completedTxns.length;

  return (
    <>
      <Spinner isSpinning={isLoading}>
        <Tabs
          selectedOption={store.typeFilter}
          options={tabsOptions}
          onChange={handleTabChange}
          className="mb-10"
        />

        {hasNoTransactions && (
          <>
            <div className="text-xl font-bold">{`${t(
              'You have no transactions yet',
            )}.`}</div>
            <div className="text-gray-400">{`${t(
              'Use the menu above to choose the kind of transaction you would like to make',
            )}.`}</div>
          </>
        )}

        {pendingTxns.length ? (
          <TransactionsList
            title={t('Pending Transactions')}
            data={pendingTxns}
            onClick={handleTransactionDetailsSelect}
            dataTestPrefix={`${DATA_TEST_ID_PREFIX}-pending-txn`}
          />
        ) : null}

        {completedTxns.length ? (
          <TransactionsList
            title={t('Completed Transactions')}
            data={completedTxns}
            onClick={handleTransactionDetailsSelect}
            dataTestPrefix={`${DATA_TEST_ID_PREFIX}-completed-txn`}
          />
        ) : null}
      </Spinner>
    </>
  );
};

export default observer(TransactionHistory);
