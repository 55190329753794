import React, { FC, PropsWithChildren } from 'react';

import { Button, Typography } from '@components/common';

import { ButtonProps } from '@root/interfaces/components/Button';
import { TestIdDataAttr } from '@root/interfaces/components/Common';

interface ProfilePaymentCardsItemProps extends TestIdDataAttr {
  id: string;
  title: string | null;
  errorText?: React.ReactNode;
  image: React.ReactNode;
  text: Array<React.ReactNode>;
  buttonText: string;
  buttonProps?: Omit<ButtonProps, 'shape' | 'size' | 'onClick'>;
  onButtonClick: (id: string) => void;
}

const ProfilePaymentCardsItem: FC<PropsWithChildren<ProfilePaymentCardsItemProps>> = (
  props,
) => {
  const {
    id,
    title,
    image,
    text,
    buttonText,
    onButtonClick,
    buttonProps,
    dataTestId,
  } = props;

  const handleClick = () => {
    onButtonClick(id);
  };

  return (
    <div>
      <div className="mb-3" aria-hidden="true">
        {image}
      </div>
      <Typography.Title level={5} data-recording-sensitive>
        {title}
      </Typography.Title>
      {/* eslint-disable react/no-array-index-key */}
      {text.map((item, index) =>
        item ? (
          <p key={index} data-recording-sensitive>
            {item}
          </p>
        ) : null,
      )}
      {/* eslint-enable react/no-array-index-key */}
      <Button
        shape="text"
        size="sm"
        {...buttonProps}
        onClick={handleClick}
        dataTestId={dataTestId}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ProfilePaymentCardsItem;
