import qs from 'qs';

import { Languages } from '@root/interfaces/Languages';

import { LANGUAGE_STORE_KEY } from '@root/constants/languages';
import { AUTH_DEFAULT_COUNTRY } from '@root/constants/queryParams';
import { MARKETING_SITE_COUNTRIES } from '@components/modules/Seo/constants';

const languageValues = Object.values(Languages);
const seoHrefLangs = languageValues
  .map((lang) => {
    return MARKETING_SITE_COUNTRIES.map((countryCode) => {
      return `${lang}-${countryCode}`.toLowerCase();
    });
  })
  .flat();

/**
 * This will generate all possible variations of canonical/alternate links with hreflangs for urls with language and default country parameters
 * @param query
 * @param hrefParam
 */
// eslint-disable-next-line import/prefer-default-export
export const getCanonicalAlternateUrlVariantsCfg = (
  query: string,
  hrefParam = window.location.href,
) => {
  const parsedQuery = qs.parse(query, { ignoreQueryPrefix: true });
  const queryLanguage = parsedQuery[LANGUAGE_STORE_KEY];
  const queryDefaultCountry = parsedQuery[AUTH_DEFAULT_COUNTRY];
  if (
    queryLanguage &&
    queryDefaultCountry &&
    typeof queryLanguage === 'string' &&
    typeof queryDefaultCountry === 'string' &&
    Object.keys(parsedQuery).length === 2 // only when query contains these 2 parameters
  ) {
    return seoHrefLangs.map((hrefLang) => {
      const linkType =
        `${queryLanguage}-${queryDefaultCountry.toLowerCase()}` === hrefLang
          ? 'canonical'
          : 'alternate';

      const [hrefLangLanguage, hrefLangCountryCode] = hrefLang.split('-');
      const href = `${
        hrefParam.split('?')[0]
      }?${LANGUAGE_STORE_KEY}=${hrefLangLanguage}&${AUTH_DEFAULT_COUNTRY}=${hrefLangCountryCode.toUpperCase()}`;
      return {
        linkType,
        href,
        hrefLang,
      };
    });
  }

  return [];
};
