import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import i18n from 'i18next';

import { Toaster } from '@components/common';

import RootRouter from '@root/routes';
import { AppConfigStore, UserAccountStore } from '@root/stores';

import useStore from '@root/hooks/useStore';
import useDidMount from '@root/hooks/useDidMount';

import LoginModal from '@components/modules/LoginModal';
import HealthChecker from '@components/modules/HealthChecker';
import MtModal from '@components/modules/MtModal';

import { initThreatMetrix } from '@services/threatMetrix';
import { liveChatRefresh, liveChatSetupUser } from '@services/liveChat';
import { Languages } from '@root/interfaces/Languages';

const App: FC<React.PropsWithChildren<unknown>> = () => {
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const history = useHistory();

  useDidMount(async () => {
    /**
     * Handle url changes
     */
    history.listen((location) => {
      window.scrollTo(0, 0);

      if (Boolean(userAccountStore.getSession()) !== userAccountStore.isUserLoggedIn) {
        userAccountStore.updateLoggedInStatus();
      }

      // BRP-1801 - try to disable manual page view tracking to prevent doubling of the page views
      // trackPageView(location.pathname + location.search);
    });

    appConfigStore.getRemoteConfig();
  });

  useAsyncEffect(async () => {
    const { isUserLoggedIn } = await userAccountStore.updateLoggedInStatus();

    if (isUserLoggedIn) {
      initThreatMetrix();
      appConfigStore.getDtcConfig();
    }
  }, [userAccountStore.isUserLoggedIn]);

  useEffect(() => {
    appConfigStore.setUserCountryCode(userAccountStore.userCountryOfOrigin);
  }, [userAccountStore.userCountryOfOrigin]);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    liveChatRefresh(); // this will force live chat to catch new document language
  }, [userAccountStore.profile.language]);

  // setup intercom user when it is defined
  useEffect(() => {
    if (userAccountStore.financeProfile) {
      liveChatSetupUser({
        id: userAccountStore.profile.id,
        name:
          userAccountStore.profile.shortName || userAccountStore.financeProfile.shortName,
        phoneNumber: userAccountStore.profile.phone,
        email: userAccountStore.profile.email,
      });
    }
  }, [userAccountStore.profile, userAccountStore.financeProfile]);

  useEffect(() => {
    // TODO: this is just a hotfix. Looks like we have no single source of truth for language
    // current language, profile language and i18n might be different values
    // must be fixed because this cause troubles
    if (
      userAccountStore.isUserLoggedIn &&
      userAccountStore.profile.phone &&
      userAccountStore.userCountryOfOrigin === 'US' &&
      i18n.language &&
      ![Languages.Es, Languages.En].includes(i18n.language as Languages)
    ) {
      userAccountStore.changeLanguage(Languages.En);
    }
    return undefined;
  }, [
    userAccountStore.isUserLoggedIn,
    userAccountStore.profile.phone,
    userAccountStore.userCountryOfOrigin,
    i18n.language,
  ]);

  return (
    <HealthChecker>
      <RootRouter />

      <LoginModal />
      <MtModal />

      <Toaster />
    </HealthChecker>
  );
};

export default observer(App);
