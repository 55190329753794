export enum MaintenanceCodes {
  Funding = 'funding',
  Calling = 'calling',
  MajorOutage = 'all',
  WebOutage = 'web_all',
  SignUp = 'sign_up',
}

export enum MaintenanceLanguages {
  En = 'en',
  Fr = 'fr',
  Es = 'es',
  De = 'de',
}
