import { makeAutoObservable } from 'mobx';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import CountryCode from '@root/interfaces/CountryCode';

import { Promo } from '@root/interfaces/contract/ImtuInitMobileTopUp';

type PromoData = Omit<Promo, 'background_color' | 'foreground_color' | 'locale'>;

class ImtuPromoClass {
  id = 'undefined_imtu_promo_id';

  carrier = new ImtuCarrierClass();

  name = '';

  description = '';

  countryName = '';

  title = '';

  subTitle = '';

  countryCode?: CountryCode;

  promoStartDate: Date | null = null;

  promoEndDate: Date | null = null;

  constructor(data?: PromoData) {
    makeAutoObservable(this);

    if (data) {
      this.id = `${data.name}-${data.country}-${data.carrier.code}-${data.start_at}-${data.end_at}`;
      this.name = data.name;
      this.description = data.description;
      this.carrier = new ImtuCarrierClass(data.carrier);
      this.countryName = data.country_name;
      this.title = data.title;
      this.subTitle = data.sub_header;
      this.countryCode = data.country;
      this.promoStartDate = new Date(data.start_at);
      this.promoEndDate = new Date(data.end_at);
    }
  }
}

export default ImtuPromoClass;
