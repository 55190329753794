import React from 'react';

import { Button, Modal } from '@components/common';

import { trackButtonPress } from '@services/analytics';

import { AnalyticsContext } from '@root/interfaces/Analytics';

import { ButtonConfigItem } from './index';

export interface ModalProps {
  title: string;
  subTitle?: string;
  isModalVisible: boolean;
  buttonConfig: ButtonConfigItem[];
  onOk: () => void;
  onCancel: () => void;
}

const RlgModal = (props: ModalProps) => {
  const { title, subTitle, isModalVisible, buttonConfig, onOk, onCancel } = props;

  const handleOkClick = (item: ButtonConfigItem) => () => {
    if (item.onClick) {
      item.onClick();
      trackButtonPress({
        button_name: item.label,
        context: AnalyticsContext.MoneyTransfer,
      });
    }
    onOk();
  };

  const handleCancelClick = (item: ButtonConfigItem) => () => {
    if (item.onClick) {
      item.onClick();
      trackButtonPress({
        button_name: item.label,
        context: AnalyticsContext.MoneyTransfer,
      });
    }
    onCancel();
  };

  return (
    <Modal isOpen={isModalVisible} onRequestClose={onCancel}>
      <div className="text-center">
        <p>{title}</p>
        {subTitle ? <p>{subTitle}</p> : null}
      </div>
      <div className="flex flex-col">
        {buttonConfig.map((item) => {
          return (
            <Button
              key={item.label}
              shape="pill"
              isOutlined
              className="my-4 mx-20"
              onClick={item.type === 'OK' ? handleOkClick(item) : handleCancelClick(item)}
            >
              {item.label}
            </Button>
          );
        })}
      </div>
    </Modal>
  );
};

export default RlgModal;
