import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CallingHelpQrCode } from '@src/static/assets/images/calling-help-qr-code.svg';

import Link from '@components/common/Typography/Link';
import { Modal } from '@components/common';
import { useIsNotMobile } from '@components/common/MediaQueryMatchers';
import {
  getHelpPhonesLandline,
  getHelpPhonesTolAccess,
} from '@components/modules/Calling/components/Receipt/helpers';

import CountryCode from '@root/interfaces/CountryCode';

import { STORE_APP_URLS } from '@root/constants/storeUrls';

import { SUPPORT_INFO_CONFIG } from './constants';

interface Props {
  isOpen: boolean;
  countryCode: CountryCode;
  onRequestClose(): void;
}

const HelpModal: React.FunctionComponent<Props> = (props: Props) => {
  const { countryCode, isOpen, onRequestClose } = props;

  const { t } = useTranslation();

  const isNotMobile = useIsNotMobile();

  const optionItemContainerClassNames = 'grid gap-4';
  const optionTitleClassNames = 'text-lg font-medium';
  const optionMainTextClassNames = 'text-lg font-bold';

  const renderPhonesList = (stringsArr: { language: string; phone: string }[]) => {
    // tailwind marker: utility doesn't work some reason and there is limitations in styling of markers
    return (
      <ul>
        {stringsArr.map((item) => {
          return (
            <li
              className="before:my-1 before:mr-2 before:inline-block before:h-1 before:w-1 before:rotate-45 before:bg-blue-400 before:content-['']"
              key={`${item.language}${item.phone}`}
            >
              <span className="text-lg text-black-100 opacity-60">{`${t(
                item.language,
              )}: ${item.phone}`}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const phonesLandline = getHelpPhonesLandline({ countryCode });
  const phonesTolAccess = getHelpPhonesTolAccess({ countryCode });

  return (
    <Modal
      isFluidModal
      header={<div className="text-2xl font-bold">{t('How to make a call')}</div>}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="grid gap-6">
        <div className="flex flex-row">
          <div className={optionItemContainerClassNames}>
            <div className={optionTitleClassNames}>{`${t('Option')}  #1`}</div>
            <div className={optionMainTextClassNames}>
              {t(
                'Download the BOSS Revolution app, login with your phone number and start calling using your current balance right away',
              )}
            </div>
            <div>
              <div>
                <Link
                  to={STORE_APP_URLS.APP_STORE.BOSS_MONEY}
                  className="text-blue-400"
                  isNative
                >
                  {`${t('Download app for iOS')} →`}
                </Link>
              </div>
              <div>
                <Link
                  to={STORE_APP_URLS.GOOGLE_PLAY.BOSS_MONEY}
                  className="text-blue-400"
                  isNative
                >
                  {`${t('Download app for Android')} →`}
                </Link>
              </div>
            </div>
          </div>
          {isNotMobile && (
            <div className="m-2 p-2">
              <CallingHelpQrCode />
            </div>
          )}
        </div>

        {phonesLandline.length ? (
          <div className={optionItemContainerClassNames}>
            <div className={optionTitleClassNames}>{`${t('Option')}  #2`}</div>
            <div className={optionMainTextClassNames}>
              {`${t(
                'Use a local access number to call with any landline or mobile phone',
              )}:`}
            </div>
            <div>{renderPhonesList(phonesLandline)}</div>
          </div>
        ) : null}

        {phonesTolAccess.length ? (
          <div className={optionItemContainerClassNames}>
            <div className={optionTitleClassNames}>{`${t('Option')}  #3`}</div>
            <div className={optionMainTextClassNames}>
              {`${t('Choose a BOSS Revolution toll-free access number')}*:`}
            </div>
            <div>{renderPhonesList(phonesTolAccess)}</div>
            {SUPPORT_INFO_CONFIG[countryCode] && (
              <div>
                {t(SUPPORT_INFO_CONFIG[countryCode]?.textTpl, {
                  ...SUPPORT_INFO_CONFIG[countryCode]?.variables,
                })}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </Modal>
  );
};

export default HelpModal;
