import React, { FC } from 'react';

import { ReactComponent as BossLogoBlackText } from '@src/static/assets/images/bossLogo.svg';
import { ReactComponent as BossLogoWhiteText } from '@src/static/assets/images/bossLogoWhite.svg';

interface Props {
  className?: string;
  isWhiteLogo?: boolean;
}

const Logo: FC<React.PropsWithChildren<Props>> = (props) => {
  const { isWhiteLogo, ...restProps } = props;

  return isWhiteLogo ? (
    <BossLogoWhiteText {...restProps} focusable="false" aria-hidden="true" />
  ) : (
    <BossLogoBlackText {...restProps} focusable="false" aria-hidden="true" />
  );
};

export default Logo;
