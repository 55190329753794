import React from 'react';

import Grid from '@components/common/Grid';

interface Props {
  children: React.ReactNode;
}

const Index = (props: Props) => {
  const { children } = props;

  return (
    <div className="pt-20 bg-gray-200 print:hidden">
      <Grid.Container>
        <Grid.Row>
          <Grid.Col offset={{ md: 1 }} span={{ sm: 12, md: 10 }}>
            {children}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
};

export default Index;
