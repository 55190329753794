import React from 'react';

import { Typography } from '@components/common';

import { NavigationListProps } from '../../types';

const ActionsList = (props: NavigationListProps) => {
  const { links, onItemSelect, dataTestPrefix } = props;

  const navigationItemClassNames =
    'flex h-full w-full items-center self-stretch px-2 my-1 hover:text-red-400 whitespace-nowrap';

  // TODO: split this component into separate ones
  return (
    <div className="bg-white rounded-lg shadow-md px-5 pt-4 pb-3 border-gray-400">
      <ul>
        {links.map((link) => {
          const handleNavItemClick = () => {
            if (onItemSelect) {
              onItemSelect();
            }
            if (link.onClick) {
              link.onClick();
            }
          };
          return (
            <li
              key={link.id}
              className="h-10 not-last-child:border-b not-last-child:border-gray-300"
            >
              {link.path ? (
                <Typography.Link
                  to={link.path}
                  onClick={handleNavItemClick}
                  dataTestId={`${dataTestPrefix}-${link.id}`}
                  className={navigationItemClassNames}
                  activeClassName="text-red-400"
                  exact
                >
                  {link.name}
                </Typography.Link>
              ) : (
                <button
                  onClick={handleNavItemClick}
                  data-test-id={`${dataTestPrefix}-${link.id}`}
                  className={navigationItemClassNames}
                >
                  {link.name}
                </button>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ActionsList;
