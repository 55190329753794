import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { liveChatStart } from '@services/liveChat';

import { Grid, MediaMatcher, PageTitle, Button } from '@components/common';

import HeaderContainer from '@components/common/layout/HeaderContainer';
import FooterContainer from '@components/common/layout/FooterContainer';

import HeaderNavigation from '@components/modules/HeaderNavigation';
import UserAccountNavigation from '@components/modules/UserAccountNavigation';
import Footer from '@components/modules/Footer';

interface Props {
  children: ReactNode;
  titleConfig: React.ComponentProps<typeof PageTitle>;
}

const SideBarTemplate = ({ children, titleConfig }: Props) => {
  const { t } = useTranslation();

  const handleHelpButtonClick = () => {
    liveChatStart();
  };

  return (
    <div className="bg-white w-full flex flex-col pt-16 md:pt-12.5">
      <HeaderContainer>
        <HeaderNavigation />
      </HeaderContainer>

      <PageTitle {...titleConfig} hasFixedPosition>
        <Button
          shape="pill"
          isOutlined
          size="sm"
          color="blue"
          onClick={handleHelpButtonClick}
        >
          {t('Help')}
        </Button>
      </PageTitle>

      <div className="mb-20 md:mt-8 min-h-screen">
        <Grid.Container>
          <Grid.Row>
            <MediaMatcher isNotMobile>
              <Grid.Col span={3}>
                <UserAccountNavigation />
              </Grid.Col>
            </MediaMatcher>

            <Grid.Col span={{ sm: 12, md: 9 }} className="mt-8 md:mt-0">
              {children}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </div>

      <FooterContainer>
        <Footer />
      </FooterContainer>
    </div>
  );
};

export default SideBarTemplate;
