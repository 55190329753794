import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStore from '@root/hooks/useStore';
import useDidMount from '@root/hooks/useDidMount';

import { LoginStore } from '@root/stores';

import { ErrorAlert } from '@components/common';

import { getCountryNameByCode } from '@services/countryList';

import { getQueryString } from '@utils/url';

import PhoneNumberForm, { PhoneNumberFormValues } from './components/PhoneNumberForm';

import TitleText from '../../components/TitleText';
import ContentText from '../../components/ContentText';

import { LOGIN_PATHS } from '../../constants';

interface Props {
  dataTestPrefix?: string;
}

const MIN_AVAILABLE_COUNTRIES_TO_DISPLAY = 1;
const MAX_AVAILABLE_COUNTRIES_TO_DISPLAY = 3;

const PhoneLogin: FC<React.PropsWithChildren<Props>> = (props) => {
  const { dataTestPrefix } = props;
  const { t } = useTranslation();

  const history = useHistory();

  const loginStore: LoginStore = useStore('LoginStore');

  useDidMount(() => {
    loginStore.resetError();
  });

  const handleSubmitPhoneNumber = async (values?: PhoneNumberFormValues) => {
    await loginStore.sessionLogOut(); // TODO: need this due to fake email verification step. Remove when switched to pin
    await loginStore.submitPhoneNumber(values?.phoneNumber);

    // preserve query string since it may contain continueUrl
    history.push(`${LOGIN_PATHS.PHONE_CODE_VERIFICATION}${getQueryString()}`);
  };

  const PHONE_NUMBER_DATA_TEST_PREFIX = `${dataTestPrefix}-phone-number`;

  const localizedAvailableCountriesNames = loginStore.supportedCountries
    .map((cc) => t(getCountryNameByCode(cc)))
    .sort();

  /* eslint-disable @typescript-eslint/indent */
  const getAvailableCountriesToDisplay = () => {
    return localizedAvailableCountriesNames.reduce(
      (acc, localizedCountryName, index, localizedCountryNames) => {
        const isFirstItem = index === 0;
        const isLastItem = index === localizedCountryNames.length - 1;
        return isLastItem
          ? acc?.concat(
              ' ',
              t('and {{countryName}}', {
                countryName: localizedCountryName,
              }),
            )
          : acc?.concat(`${isFirstItem ? '' : ', '} ${localizedCountryName}`);
      },
      '',
    );
  };
  /* eslint-disable @typescript-eslint/indent */

  const getLoginText = (): string | null => {
    switch (true) {
      case localizedAvailableCountriesNames.length === MIN_AVAILABLE_COUNTRIES_TO_DISPLAY:
        return t(
          'Currently you can login only with {{countryName}} mobile phone numbers. More countries coming soon!',
          { countryName: localizedAvailableCountriesNames[0] },
        );
      case localizedAvailableCountriesNames.length > MIN_AVAILABLE_COUNTRIES_TO_DISPLAY &&
        localizedAvailableCountriesNames.length <= MAX_AVAILABLE_COUNTRIES_TO_DISPLAY:
        return t(
          'Currently you can login only with {{countries}} mobile phone numbers. More countries coming soon!',
          {
            countries: getAvailableCountriesToDisplay(),
          },
        );
      default:
        return null;
    }
  };

  const loginText = getLoginText();

  return (
    <div data-test-id="screen-main-login">
      <TitleText>{t('Sign up or log in with your mobile phone number')}</TitleText>
      {loginText && <ContentText>{loginText}</ContentText>}
      {loginStore.error && <ErrorAlert>{loginStore.error?.message}</ErrorAlert>}
      <PhoneNumberForm
        // localization, getting countries names and sorting is performed inside Form component
        availableCountries={loginStore.supportedCountries}
        initialPhoneNumber={loginStore.phoneNumber}
        initialCountryCode={loginStore.defaultCountry}
        onSubmit={handleSubmitPhoneNumber}
        dataTestPrefix={PHONE_NUMBER_DATA_TEST_PREFIX}
      />
    </div>
  );
};

export default observer(PhoneLogin);
