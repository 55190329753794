import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';

import Field from './Field';

interface Props {
  className?: string;
  dataTestId: string;
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
}

const CardExpirationDateField = (props: Props) => {
  const { className, dataTestId, name, label, placeholder, disabled, required } = props;

  return (
    <Field
      type="maskedText"
      name={name}
      label={label}
      inputMode="numeric"
      placeholder={placeholder ?? CARD_EXP_DATE_FORMAT.toUpperCase()}
      mask={CARD_EXP_DATE_FORMAT.replace(/[a-z]/gi, '9')} // MM/yy => 99/99
      dataTestId={`${dataTestId}-exp-date-input`}
      className={className}
      required={required}
      disabled={disabled}
    />
  );
};

export default CardExpirationDateField;
