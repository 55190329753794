const BASE_PATHS = {
  BASE: '/',
  ACCOUNT: '/account',
  ACCOUNT_PROFILE: '/account/profile',
  TRANSACTIONS_HISTORY: '/history',
  TRANSACTION_DETAILS: '/history',
  IMTU_RECEIPT: '/imtu-receipt',
  SEND_MONEY: '/send-money',
  SEND_MONEY_RECEIPT: '/send-money-receipt',
  DOCUMENTS_UPLOADING: '/documents-uploading',
  CALLING: '/calling',
  IMTU: '/top-up/new',
  LOGIN: '/login',
  LOGIN_MODAL: '#/login',
  MT_MODAL: '#/money-transfer',
  ACC_DELETE_MODAL: '#/delete-account',
  LOCATOR: '/locator', // for testing purposes, delete after accomplishing money flow
};

export default BASE_PATHS;
