import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { liveChatStart } from '@services/liveChat';

// import { SearchInput } from '@components/common';

import BASE_PATHS from '@root/routes/paths';

import { defaultMainFeatures } from '@root/constants/appConfig';

import LanguageSwitcher from '../LanguageSwitcher';
import TopControls from './TopControls';
import MenuList from './MenuList';

import { NavigationListItemProps, NavigationProps } from '../../types';

import './styles.scss';

const ANIMATION_DURATION = 300;

const DATA_TEST_ID_PREFIX = 'navigation-header';

const NavigationMenuDropdown = (props: NavigationProps) => {
  const {
    avatarAltText,
    avatarUrl,
    languageLocale,
    languagesOptions,
    featureAvailability = defaultMainFeatures,
    onLogoutClick,
  } = props;

  const mobileNavigationRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen((isToggled) => !isToggled);
  };

  function hideMobileMenu() {
    setIsMenuOpen(false);
  }

  const handleLogoutClick = () => {
    if (onLogoutClick) {
      onLogoutClick();
    }
  };

  const handleChatLinkClick = () => {
    liveChatStart();
  };

  useOnClickOutside(hideMobileMenu, mobileNavigationRef);

  const NAVIGATION_ITEM_IDS = {
    CALLING: 'calling',
    MT: 'send-money',
    IMTU: 'top-up',
    SUPPORT: 'support',
    HISTORY: 'history',
    PROFILE: 'profile',
    CHAT: 'chat',
    LOGOUT: 'logout',
  };

  const navigationItemIdsToFeatureAvailabilityMap = {
    [NAVIGATION_ITEM_IDS.CALLING]: featureAvailability.isCallingAvailable,
    [NAVIGATION_ITEM_IDS.MT]: featureAvailability.isMtAvailable,
    [NAVIGATION_ITEM_IDS.IMTU]: featureAvailability.isImtuAvailable,
    [NAVIGATION_ITEM_IDS.SUPPORT]: true, // is always visible
    [NAVIGATION_ITEM_IDS.HISTORY]: true, // is always visible
    [NAVIGATION_ITEM_IDS.PROFILE]: true, // is always visible
    [NAVIGATION_ITEM_IDS.CHAT]: true, // is always visible
    [NAVIGATION_ITEM_IDS.LOGOUT]: true, // is always visible
  };

  // TODO: put in constant and replace onClick field with actionName field  (see supportLink constant)
  const NAVIGATION_SCHEME: NavigationListItemProps = [
    {
      id: NAVIGATION_ITEM_IDS.CALLING,
      path: BASE_PATHS.CALLING,
      name: t('Calling'),
    },
    {
      id: NAVIGATION_ITEM_IDS.MT,
      path: BASE_PATHS.MT_MODAL,
      name: t('Send Money'),
    },
    {
      id: NAVIGATION_ITEM_IDS.IMTU,
      path: BASE_PATHS.IMTU,
      name: t('Top-Up'),
    },
    {
      id: NAVIGATION_ITEM_IDS.SUPPORT,
      path: `https://www.bossrevolution.com/${languageLocale}/support`,
      name: t('Support'),
      isNative: true,
    },
    {
      id: NAVIGATION_ITEM_IDS.HISTORY,
      path: BASE_PATHS.TRANSACTIONS_HISTORY,
      name: t('Transaction history'),
    },
    {
      id: NAVIGATION_ITEM_IDS.PROFILE,
      path: BASE_PATHS.ACCOUNT_PROFILE,
      name: t('Profile'),
    },
    {
      id: NAVIGATION_ITEM_IDS.CHAT,
      name: t('Chat'),
      onClick: handleChatLinkClick,
    },
    {
      id: NAVIGATION_ITEM_IDS.LOGOUT,
      name: t('Logout'),
      onClick: handleLogoutClick,
    },
  ].filter((item) => {
    // filter out not supported features
    return navigationItemIdsToFeatureAvailabilityMap[item.id];
  });

  return (
    <div ref={mobileNavigationRef}>
      <TopControls
        isToggleButtonToggled={isMenuOpen}
        onToggleButtonClick={handleMenuToggle}
        avatarUrl={avatarUrl}
        avatarAltText={avatarAltText}
        languageLocale={languageLocale}
      />
      <CSSTransition
        in={isMenuOpen}
        timeout={ANIMATION_DURATION}
        classNames="mobile-menu-list"
        unmountOnExit
      >
        <div className="absolute left-0 w-full bg-white shadow-md z-1 top-full">
          <div className="overflow-auto mobile-nav">
            {/* <div className="sticky top-0 px-5 py-4 bg-red-400">
              <SearchInput size="sm" placeholder={t('Search BOSSrevolution.com')} />
            </div> */}
            <div className="flex justify-end px-5 mt-5">
              <LanguageSwitcher
                className="md:text-white"
                languagesOptions={languagesOptions}
              />
            </div>
            <div className="px-5 pt-2 bg-white">
              <MenuList
                links={NAVIGATION_SCHEME}
                onItemSelect={hideMobileMenu}
                dataTestPrefix={DATA_TEST_ID_PREFIX}
              />
            </div>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default NavigationMenuDropdown;
