import { makeAutoObservable } from '@nodeModules/mobx';
import CountryCode from '@root/interfaces/CountryCode';

class Store {
  countryCode?: CountryCode;

  constructor({ countryCode }: { countryCode?: CountryCode }) {
    makeAutoObservable(this);
    this.countryCode = countryCode;
  }

  setCountryCode(countryCode: CountryCode) {
    this.countryCode = countryCode;
  }
}

export default Store;
