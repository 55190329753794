import { LinkOptions } from './components/LinksList';

export const socialLinks: LinkOptions[] = [
  {
    text: 'Twitter',
    to: 'https://twitter.com/bossrevolution',
    isNative: true,
    target: '_blank',
  },
  {
    text: 'Facebook',
    to: 'https://www.facebook.com/bossrevolution',
    isNative: true,
    target: '_blank',
  },
  {
    text: 'YouTube',
    to: 'https://www.youtube.com/user/BossRevolutionUS',
    isNative: true,
    target: '_blank',
  },
  {
    text: 'Instagram',
    to: 'https://www.instagram.com/boss.revolution/',
    isNative: true,
    target: '_blank',
  },
];

export const LINK_ACTIONS = {
  CHAT_NOW: 'chatNow',
};

export const sellLinks: LinkOptions[] = [
  {
    text: 'Sell the revolution', // t('Sell the revolution')
    to: 'https://sellbossrev.com/', // the single entry point for everyone
    isNative: true,
    target: '_blank',
  },
];
