import ReactDOM from 'react-dom';
import { useRef, useState } from 'react';

import { Modal } from '@components/common';

import { ModalRef } from '@components/common/Modal';

interface Params {
  content: (confirm: () => void, decline: () => void) => React.ReactNode;
}

const useConfirmationDialog = (params: Params) => {
  const { content } = params;

  const [isDialogMounted, setIsDialogMounted] = useState<boolean>();

  const modalRoot = useRef<HTMLDivElement>();
  const modalRef = useRef<ModalRef>(null);

  let confirmAction: (value: boolean | PromiseLike<boolean>) => void;

  const createModalRoot = () => {
    modalRoot.current = document.createElement('div');

    return modalRoot.current;
  };

  const close = () => {
    if (modalRoot.current) {
      confirmAction(false);
      ReactDOM.unmountComponentAtNode(modalRoot.current);
      setIsDialogMounted(false);
    }
  };

  const open = () => {
    return new Promise<boolean>((confirm) => {
      confirmAction = confirm;

      const handleConfirm = () => {
        confirmAction(true);
      };

      const handleDecline = () => {
        confirmAction(false);
      };

      ReactDOM.render(
        <Modal onRequestClose={close} isOpen ref={modalRef}>
          {/* TODO: provide default UI */}
          {content(handleConfirm, handleDecline)}
        </Modal>,
        createModalRoot(),
      );
      setIsDialogMounted(true);
    });
  };

  return {
    open,
    close,
    modalRef,
    isDialogMounted,
  };
};

export default useConfirmationDialog;
