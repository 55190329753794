import React, { forwardRef, useRef, useState } from 'react';
import { useTranslation } from '@nodeModules/react-i18next';
import { format } from 'date-fns';

import { Icon, Select } from '@components/common';

import { OptionProps } from '@root/interfaces/components/Select';

import PaymentCardClass from '@root/models/PaymentCardClass';

import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';

import { SelectForwardRef, SelectRef } from '@components/common/Select';

import { capitalizeFirstLetterInEachWord, normalizeStringCompound } from '@utils/string';

import { PaymentCardFundingMap } from '@root/constants/paymentCards/paymentCardIssuers';
import { PaymentCardTypeShort } from '@root/constants/moneyTransfer/paymentCards';
import SelectSkin from '@components/common/Select/constants';

import usePaymentCardSelect from '@components/common/PaymentCardSelect/usePaymentCardSelect';
import { showToast } from '@services/toasts';

import { ReactComponent as CardSkeletonLoader } from './images/card-skeleton-loader.svg';

import Option from './Option';
import AddCardButton from './AddCardButton';
import TopControls from './TopControls';
import SelectButton from './SelectButton';
import PlaceHolder from './PlaceHolder';
import PaymentCardAllDetailsDialog from '../PaymentCardAllDetailsDialog';

import { CARDS_LIST_DRAWER_CONTAINER_CLASSNAME } from './constants';

import PaymentCardReducedDetailsDialog from '../PaymentCardReducedDetailsDialog';

interface Props {
  value?: string;
  data?: { data: PaymentCardClass; isSupported?: boolean }[];
  paymentTypeFeeMap?: Dictionary<number>;
  isLoading?: boolean;
  onCardAdd?(): void;
  onCardEdit?(value?: string): void;
  onChange?(cardId: string): void;
  onEdit?(cardId: string): void;
  selectRef?: SelectForwardRef;
  skin?: SelectSkin;
  isOpenForced?: boolean;
  clickOutsideIgnoreRefs?: React.RefObject<HTMLDivElement>[];
  isUsingCardsWithoutBillingAllowed?: boolean;
  isInert?: boolean;
  minDropdownBottomOffset?: number;
}

export interface CompoundedComponent
  extends React.ForwardRefExoticComponent<Props & React.RefAttributes<SelectRef>> {
  usePaymentCardSelect: typeof usePaymentCardSelect;
  PaymentCardAllDetailsDialog: typeof PaymentCardAllDetailsDialog;
  PaymentCardReducedDetailsDialog: typeof PaymentCardReducedDetailsDialog;
}

const MAX_CARDS_AMOUNT = 5;

const PaymentCardSelect = forwardRef((props: Props, ref: SelectForwardRef) => {
  const {
    data,
    value,
    paymentTypeFeeMap,
    onCardAdd,
    onChange,
    onCardEdit,
    onEdit,
    isOpenForced,
    clickOutsideIgnoreRefs,
    isLoading,
    isInert,
    minDropdownBottomOffset,
  } = props;

  const { t } = useTranslation();

  const [isEditMode, setIsEditMode] = useState(false);

  const selectRef = useRef<SelectRef | null>(null);
  const cardFormDrawerContainerRef = useRef<HTMLDivElement>(null);

  const activePaymentCard = data?.find((c) => value === c.data.handleId);

  // TODO: extract sorting logic into a helper
  const mapPaymentCardsToSelectOptions = (paymentCards: typeof data): OptionProps[] => {
    const creditCards: OptionProps[] = [];
    const debitCards: OptionProps[] = [];
    const notSupportedCards: OptionProps[] = [];
    const expiredCards: OptionProps[] = [];

    paymentCards?.forEach((pCard) => {
      const pCardNumAndExpDate = `x${pCard.data.maskedNumber
        .slice(-4)
        .toUpperCase()} ${format(pCard.data.expDate, CARD_EXP_DATE_FORMAT)}`;

      let cardStatus;
      let cardFee;
      switch (true) {
        case pCard.data.isExpired:
          cardStatus = <span className="text-red-400">{t('Expired')}</span>;
          break;
        case paymentTypeFeeMap && pCard?.isSupported:
          cardFee = `${t('Fee')} $${paymentTypeFeeMap?.[pCard.data.type].toFixed(2)}`;
          break;
        default:
          break;
      }

      const isSelected = value === pCard.data.handleId;

      const iconRight =
        isSelected && !isEditMode ? (
          <div className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-green-900 text-white">
            <Icon name="Checkmark" />
          </div>
        ) : (
          <Icon
            name={!isSelected && !isEditMode ? 'ChevronBack' : 'PencilEdit'}
            className={normalizeStringCompound([
              'text-[18px]',
              isEditMode ? 'text-blue-400' : 'text-gray-300',
              pCard.data.isExpired && !isEditMode ? 'opacity-0' : '',
            ])}
          />
        );

      const cardType = capitalizeFirstLetterInEachWord(
        // TODO: backend sometimes sends "unknown" funding type, PaymentCardFundingMap doesn't have such key, using "??" operator we handle undefined value
        PaymentCardFundingMap[pCard.data.type] ?? '',
      );

      const optionProps = {
        value: pCard.data.handleId,
        text: pCardNumAndExpDate,
        isDisabled: pCard.data.isExpired && !isEditMode,
        label: pCard.data.paySource && (
          <Option
            paySource={pCard.data.paySource}
            maskedNumber={pCard.data.maskedNumber.slice(-4).toUpperCase()}
            cardType={cardType}
            feeText={cardFee}
            cardStatus={cardStatus}
            iconRight={iconRight}
            className={normalizeStringCompound([!pCard.isSupported ? 'opacity-60' : ''])}
          />
        ),
      };

      const isAvailbaleCard = !pCard.data.isExpired && pCard.isSupported;

      if (pCard.data.type === PaymentCardTypeShort.CC && isAvailbaleCard) {
        creditCards.push(optionProps);
      } else if (pCard.data.type === PaymentCardTypeShort.DC && isAvailbaleCard) {
        debitCards.push(optionProps);
      } else if (pCard.data.isExpired) {
        expiredCards.push(optionProps);
      } else {
        notSupportedCards.push(optionProps);
      }
    });

    return [...creditCards, ...debitCards, ...expiredCards, ...notSupportedCards];
  };

  const handleChange = (newValue: string) => {
    onChange?.(newValue);
  };

  const cardOptions = data ? mapPaymentCardsToSelectOptions(data) : [];

  const inputClassNames = value
    ? undefined
    : 'placeholder-shown:placeholder:text-red-400 placeholder-shown:placeholder:font-bold placeholder-shown:placeholder:text-2xl';

  const handleCloseBtnClick = () => {
    selectRef.current?.setSelectIsOpen(false);
  };

  const handleOnEditBtnClick = () => {
    setIsEditMode(!isEditMode);
  };

  const dropdownAddonTop = onCardEdit && (
    <TopControls
      onEditClick={handleOnEditBtnClick}
      onCloseBtnClick={handleCloseBtnClick}
      isEditMode={isEditMode}
      className="sticky top-0 left-0 right-full w-full bg-white pb-[20px] md:static md:bg-initial md:pb-[initial]"
    />
  );

  const handleCardAddClick = () => {
    if (data && data.length >= MAX_CARDS_AMOUNT) {
      showToast.warning(
        t(`Maximum allowed amount of payment cards is {{maxCardsAmount}}`, {
          maxCardsAmount: MAX_CARDS_AMOUNT,
        }),
      );
      return undefined;
    }
    onCardAdd?.();
    return undefined;
  };

  const dropdownAddonBottom = onCardAdd && Boolean(data?.length) && (
    <AddCardButton
      onClick={handleCardAddClick}
      btnText={t('Add a card')}
      className="sticky bottom-0 mb-[20px] w-full bg-white md:static md:bg-initial"
    />
  );

  const renderButtonContent = () => {
    if (activePaymentCard) {
      return (
        activePaymentCard.data.paySource && (
          <Option
            isSelected
            iconRight={
              <Icon name="ChevronDownAlt" className="text-[18px] text-gray-300" />
            }
            paySource={activePaymentCard.data.paySource}
            maskedNumber={activePaymentCard.data.maskedNumber.slice(-4).toUpperCase()}
            cardType={capitalizeFirstLetterInEachWord(
              // TODO: backend sometimes sends "unknown" funding type, PaymentCardFundingMap doesn't have such key, using "??" operator we handle undefined value
              PaymentCardFundingMap[activePaymentCard.data.type] ?? '',
            )}
            feeText={
              paymentTypeFeeMap &&
              `${t('Fee')} $${paymentTypeFeeMap?.[activePaymentCard.data.type]?.toFixed(
                2,
              )}`
            }
          />
        )
      );
    }

    if (isLoading) {
      return <CardSkeletonLoader />;
    }

    return <PlaceHolder hasCards={Boolean(data?.length)} />;
  };

  const renderSelectButton = (isOpen: boolean, onClick: () => void) => {
    return (
      <SelectButton
        isDisabled={isLoading}
        isOpen={isOpen}
        onClick={data?.length ? onClick : onCardAdd}
      >
        {renderButtonContent()}
      </SelectButton>
    );
  };

  const handleOpen = () => {
    setIsEditMode(false);
  };

  const handleOptionSelect = (id: string) => {
    if (isEditMode) {
      onEdit?.(id);
      return undefined;
    }
    onChange?.(id);
    return undefined;
  };

  const clickOutsideIgnoredRefs = [
    cardFormDrawerContainerRef,
    ...(clickOutsideIgnoreRefs ?? []),
  ];

  return (
    <>
      <Select
        inputClassName={inputClassNames}
        dropdownClassName="bg-[#F6F5F4] !w-full left-0 right-0 pt-0 z-50"
        optionBtnClassName="hover:bg-white md:hover:bg-transparent"
        onChange={handleChange}
        onSelectOverridden={handleOptionSelect}
        options={cardOptions}
        value={value}
        skin={SelectSkin.FLAT}
        dropdownAddonTop={dropdownAddonTop}
        dropdownAddonBottom={dropdownAddonBottom}
        drawerContainerClassName={`.${CARDS_LIST_DRAWER_CONTAINER_CLASSNAME}`}
        isOpenForced={isOpenForced}
        ref={selectRef}
        componentRef={ref}
        isDrawerChildrenVisible={false}
        clickOutsideIgnoreRefs={clickOutsideIgnoredRefs}
        onOpen={handleOpen}
        isInert={isInert}
        minDropdownBottomOffset={minDropdownBottomOffset}
      >
        {renderSelectButton}
      </Select>
      <div
        className={normalizeStringCompound([
          CARDS_LIST_DRAWER_CONTAINER_CLASSNAME,
          '[--drawer-content-wrapper-height:auto]',
        ])}
      />
    </>
  );
}) as CompoundedComponent;

PaymentCardSelect.usePaymentCardSelect = usePaymentCardSelect;
PaymentCardSelect.PaymentCardAllDetailsDialog = PaymentCardAllDetailsDialog;
PaymentCardSelect.PaymentCardReducedDetailsDialog = PaymentCardReducedDetailsDialog;

export default PaymentCardSelect;
