import React from 'react';

import './styles.scss';

// TODO: just temporary placeholder, actual implementation will be done with getting final design for app's skeletons
const Skeleton = () => {
  return <div className="pulseLoader flex h-10 w-10 py-1" />;
};

export default Skeleton;
