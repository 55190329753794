import React, { FC } from 'react';

import { Icon, Typography } from '@components/common';

import { IconNames } from '@root/interfaces/components/Icon';
import { TestIdDataAttr } from '@root/interfaces/components/Common';
import { RequireAtLeastOne } from '@root/interfaces/helpers';

interface Props extends TestIdDataAttr {
  title: string;
  iconName?: IconNames;
  component?: React.ReactNode;
  onClick?: () => void;
  onItemSelect?: () => void;
  link?: string;
}

export type NavigationButtonProps = RequireAtLeastOne<Props, 'link' | 'onClick'>;

const NavigationButton: FC<React.PropsWithChildren<Props>> = (props) => {
  const { title, onClick, onItemSelect, link, iconName, component, dataTestId } = props;

  const icon = () => {
    if (iconName) {
      return <Icon name={iconName} />;
    }

    if (component) {
      return component;
    }

    return null;
  };

  const linkContent = (
    <>
      <div className="hidden md:flex flex-shrink-0 justify-center items-center w-10 h-10 p-1 text-2xl">
        {icon()}
      </div>
      <span className="font-sans text-2xl md:text-lg p-3">{title}</span>
    </>
  );

  const handleClick = () => {
    if (onClick) {
      onClick();
    }

    if (onItemSelect) {
      onItemSelect();
    }
  };

  return link ? (
    <Typography.Link
      to={link}
      className="flex items-center"
      activeClassName="text-red-400"
      onClick={onItemSelect}
      dataTestId={dataTestId}
    >
      {linkContent}
    </Typography.Link>
  ) : (
    <button
      onClick={handleClick}
      className="flex w-full text-left items-center hover:text-red-400"
      data-test-id={dataTestId}
    >
      {linkContent}
    </button>
  );
};

export default NavigationButton;
