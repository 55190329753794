import React from 'react';

import { Button } from '@components/common';

import { ButtonProps } from '@root/interfaces/components/Button';

import { normalizeStringCompound } from '@utils/string';

// just because we have the same button reused almost everywhere in MT flow
const StepButton: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  const { children, className, ...rest } = props;

  const classNames = normalizeStringCompound([
    className,
    'w-full max-w-app-btn first-letter:uppercase',
  ]);

  return (
    <Button shape="pill" color="blue" size="lg" className={classNames} {...rest}>
      {children}
    </Button>
  );
};

export default StepButton;
