import React from 'react';
import { useField } from 'formik';

import { normalizeStringCompound } from '@utils/string';
import { isString } from '@utils/typeGuards';

import { FirstFunctionParam } from '@root/types';
import {
  FormFieldProps,
  FormFieldType,
  FieldComponentProps,
  AutoCapitalizeValues,
  AUTOCAPITALIZE_KEY,
} from '@root/interfaces/components/Form';

import MaskedInput from '../MaskedInput';
import PhoneInput from '../PhoneInput';
import Select from '../Select';
import RadioGroup from '../RadioGroup';
import CheckboxGroup from '../CheckboxGroup';
import DatePicker from '../DatePicker';
import PasswordInput from '../PasswordInput';
import Input from '../Input';

import Label from './Label';
import ErrorMessage from './ErrorMessage';

type ChangeEvent =
  | FirstFunctionParam<Pick<FieldComponentProps, 'onChange'>['onChange']>[0]
  | React.ChangeEvent<HTMLInputElement>;

const Inputs: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key in FormFieldType]: any; // TODO: think how to get actual component's props params and return types definition
} = {
  datePicker: DatePicker,
  phone: PhoneInput,
  email: Input,
  text: Input,
  password: PasswordInput,
  radioGroup: RadioGroup,
  checkboxGroup: CheckboxGroup,
  select: Select,
  maskedText: MaskedInput,
};

function Field(props: FormFieldProps) {
  const { name, label, type, className, required, ...restProps } = props;

  const [field, meta, helpers] = useField(name);

  const containerClassName = normalizeStringCompound([
    'w-full not-last-child:mb-5',
    meta.error && meta.touched ? 'form-field_error' : undefined,
    className,
  ]);

  const Component = Inputs[type];

  const handleChange = (value: ChangeEvent): void => {
    // narrowing union type of 'value'
    const newValue =
      value && typeof value === 'object' && 'target' in value
        ? value.target.value
        : value;

    const normalizedValue =
      isString(newValue) &&
      AUTOCAPITALIZE_KEY in restProps &&
      // @ts-ignore
      restProps[AUTOCAPITALIZE_KEY] === AutoCapitalizeValues.Characters
        ? newValue.toUpperCase()
        : newValue || '';

    helpers.setValue(normalizedValue); // empty string handle empty field type validation
  };

  return (
    <div className={containerClassName}>
      {label && <Label name={field.name} label={label} required={required} />}
      <Component {...field} id={label} onChange={handleChange} {...restProps} />
      {meta.error && meta.touched && <ErrorMessage text={meta.error} />}
    </div>
  );
}

export default Field;
