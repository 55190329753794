import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { Img } from 'react-image';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';
import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import PaymentCardClass from '@root/models/PaymentCardClass';
import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';

import { Flag } from '@components/common';
import PaymentCardIcon from '@components/common/PaymentCardIcon';

import { getCountryNameByCode } from '@services/countryList';

import { normalizeStringCompound } from '@utils/string';

import Status from './components/Status';

import './styles.scss';

interface Props {
  txnStatus: ImtuStatus;
  countryCode: CountryCode;
  recipientPhoneNumber: string;
  carrier?: ImtuCarrierClass;
  product?: ImtuProductClass;
  paymentInfo?: PaymentCardClass | null;
  promoInfo?: ImtuPromoOfferDetailsClass;
  txnErrorCode?: string;
}

const Receipt: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    txnStatus,
    carrier,
    product,
    paymentInfo,
    countryCode,
    recipientPhoneNumber,
    promoInfo,
    txnErrorCode,
  } = props;

  const { t } = useTranslation();

  const rowClassNames = 'py-2';
  const rowHeaderClassNames = 'flex flex-col font-bold capitalize';
  const rowIconContainerClassNames = 'w-8 h-8 shadow-md';
  const rowValueContainerClassNames = 'flex flex-row gap-4 items-center';

  const divider = <div className="w-full border-b border-gray-300 pt-4 mb-4" />;

  const isPositiveStatus = ['success', 'queued'].includes(txnStatus);

  return (
    <div className="mx-auto w-full max-w-md border border-b-0 border-gray-300 mb-40">
      <div className="flex flex-col items-center w-full p-4">
        <Status txnStatus={txnStatus} txnErrorCode={txnErrorCode} />

        {isPositiveStatus && (
          <>
            {divider}

            <div className="flex flex-col w-full text-left text-xl p-6">
              <div className={rowClassNames}>
                <div className={rowHeaderClassNames}>{t("recipient's country")}</div>
                <div className={rowValueContainerClassNames}>
                  <div
                    className={normalizeStringCompound([
                      rowIconContainerClassNames,
                      'flex rounded-full overflow-hidden',
                    ])}
                  >
                    <Flag className="w-full" code={countryCode} />
                  </div>
                  {t(getCountryNameByCode(countryCode) || '')}
                </div>
              </div>
              <div className={rowClassNames}>
                <div className={rowHeaderClassNames}>{t('phone number to top up')}</div>
                <div>{recipientPhoneNumber}</div>
              </div>
              {carrier && (
                <div className={rowClassNames}>
                  <div className={rowHeaderClassNames}>{t('carrier')}</div>
                  <div className={rowValueContainerClassNames}>
                    <div className={rowIconContainerClassNames}>
                      <Img src={carrier.logoUrl} />
                    </div>
                    <div>{carrier.name}</div>
                  </div>
                </div>
              )}
              {product && (
                <div className={rowClassNames}>
                  <div className={rowHeaderClassNames}>{t('top-up plan')}</div>
                  <div>
                    {product.senderBaseAmountText}{' '}
                    {product?.recipientAmount &&
                      (!product.hasSameSenderRecipientAmount ? (
                        <span className="opacity-40  whitespace-nowrap">
                          {`(${product.recipientAmountText})`}
                        </span>
                      ) : null)}
                  </div>
                </div>
              )}
              {paymentInfo && (
                <div className={rowClassNames}>
                  <div className={rowHeaderClassNames}>{t('payment info')}</div>
                  <div className={rowValueContainerClassNames}>
                    {paymentInfo.paySource && (
                      <div className={rowIconContainerClassNames}>
                        <PaymentCardIcon issuer={paymentInfo.paySource} />
                      </div>
                    )}
                    <div>{paymentInfo.maskedNumber}</div>
                  </div>
                </div>
              )}
            </div>

            {(product?.fee !== undefined || promoInfo) && divider}

            {promoInfo && (
              <div className="flex flex-col w-full text-left text-xl  px-6 pb-2">
                <div className="flex flex-row justify-between lg:text-lg">
                  <div className="capitalize">{`${t('promo code')} (${
                    promoInfo.promoId
                  })`}</div>
                  <div className="flex-shrink-0 w-32 lg:w-30 text-right text-blue-400 whitespace-nowrap mt-auto">{`- ${promoInfo?.discountText}`}</div>
                </div>
              </div>
            )}

            {product?.fee !== undefined ? (
              <div className="flex flex-col w-full text-left text-xl px-6">
                <div className="flex flex-row justify-between lg:text-lg">
                  <div className="capitalize">{t('service fee')}</div>
                  <div>{product?.feeText}</div>
                </div>
              </div>
            ) : null}

            {divider}

            <div className="flex justify-between w-full text-2xl md:text-xl lg:text-2xl font-bold capitalize px-6 pb-4">
              <div>{t('your total cost')}</div>
              <div>
                {promoInfo?.priceTotalDiscountedText || product?.senderTotalAmountText}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="imtu-wavy-bottom-border" />
    </div>
  );
};

export default observer(Receipt);
