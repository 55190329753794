import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import SessionClass from '@root/models/SessionClass';

import {
  API_ERROR_MSG,
  CALLING_BACK_END_ERRORS,
  NETWORK_ERROR_MESSAGE,
} from '@root/constants/errorMessages';

import { SESSION_STORE_KEY } from '@src/services/auth/constants';
import storageInterface from '@services/storage';

const MAX_RETRY = 3;
const RETRY_DELAY = 3000;

export const isValidResponse = (response: AxiosResponse) => {
  // BMB response check
  if ('success' in response.data) {
    return response.data.success;
  }

  // GQL response check
  return !('errors' in response.data);
};

export const getApiErrorMessage = (response: AxiosResponse) => {
  // GQL error messages
  if ('errors' in response.data) {
    // eslint-disable-next-line no-console
    console.log('GQL error', response.config.data, response);
    return API_ERROR_MSG.APP_DEFAULT;
  }

  // BMB transaction setup error
  if (
    'cvvMismatch' in response.data ||
    'processed' in response.data ||
    'notSent' in response.data
  ) {
    // eslint-disable-next-line no-console
    console.log('BMB transaction error', response);
    return API_ERROR_MSG.APP_MT_TRANSACTION_FAILED;
  }

  // BMB error messages
  // eslint-disable-next-line no-console
  console.log('BMB error', response);
  return response.data.message || API_ERROR_MSG.APP_DEFAULT;
};

const repeatedCallsCounters: Dictionary<number> = {};

export const repeatCall = <T>(
  axios: AxiosInstance,
  configOrig: AxiosRequestConfig,
): AxiosResponse<T> | Promise<AxiosResponse<T>> => {
  const cfgRepeat = { ...configOrig };

  const requestId = `${cfgRepeat.url}${JSON.stringify(cfgRepeat.data)}`;

  const requestRepeatCounter = repeatedCallsCounters[requestId]
    ? repeatedCallsCounters[requestId] - 1
    : MAX_RETRY;
  if (requestRepeatCounter) {
    repeatedCallsCounters[requestId] = requestRepeatCounter;
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        return axios
          .request(cfgRepeat)
          .then((response) => {
            resolve(response);
          })
          .catch(() => {
            reject(new Error(NETWORK_ERROR_MESSAGE));
          })
          .finally(() => {
            delete repeatedCallsCounters[requestId];
          });
      }, RETRY_DELAY);
    });
  }

  return Promise.reject(new Error(API_ERROR_MSG.APP_DEFAULT));
};

export const getLocalStorageSession = () => {
  const sessionString = storageInterface.getItem(SESSION_STORE_KEY);

  if (sessionString) {
    const session = JSON.parse(sessionString) as SessionClass;

    return session;
  }

  return null;
};

interface CustomHttpErrorCodeHandlers {
  [key: number]: () => Promise<unknown> | AxiosResponse<unknown>;
}

export const handleHttpError = (
  errorCode: number,
  customHttpErrorCodeHandlers?: CustomHttpErrorCodeHandlers,
): Promise<unknown> | AxiosResponse<unknown> => {
  // http errors handling
  switch (true) {
    // custom handlers handling
    case customHttpErrorCodeHandlers !== undefined &&
      errorCode in customHttpErrorCodeHandlers:
      return customHttpErrorCodeHandlers
        ? customHttpErrorCodeHandlers[errorCode]()
        : Promise.reject(Error(API_ERROR_MSG.APP_DEFAULT));
    default:
      return Promise.reject(Error(API_ERROR_MSG.APP_DEFAULT));
  }
};

export const getCallingApiErrorMessage = (errorCode: string): string | null => {
  const errorMessageKey = errorCode.toUpperCase();
  if (errorMessageKey in CALLING_BACK_END_ERRORS) {
    return CALLING_BACK_END_ERRORS[
      errorMessageKey as keyof typeof CALLING_BACK_END_ERRORS
    ];
  }

  return null;
};
