import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalProps } from '@root/interfaces/components/Modal';

import { Button, Input, Modal } from '@components/common';
import { ButtonProps } from '@root/interfaces/components/Button';

interface Props extends ModalProps {
  onConfirmClick(): void;
}

interface ModalButtonPros extends ButtonProps {
  text: string;
}

const ModalButton: React.FunctionComponent<ModalButtonPros> = (
  props: ModalButtonPros,
) => {
  const { text } = props;

  return (
    <Button {...props} shape="pill" className="w-1/2" isOutlined>
      {text}
    </Button>
  );
};

const AccountDeletionModal: React.FunctionComponent<Props> = (props: Props) => {
  const { isOpen, onRequestClose, onConfirmClick } = props;

  const [confirmationText, setConfirmationText] = useState<string>('');

  const { t } = useTranslation();

  const deleteKeyword = t('Delete');

  useEffect(() => {
    setConfirmationText('');
  }, [isOpen]);

  const handleConfirmationTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationText(e.target.value);
  };

  const isConfirmationTextMatchingDeleteKeyword = confirmationText === deleteKeyword;

  return (
    <Modal {...props} isOpen={isOpen}>
      <div className="flex flex-col">
        <div className="font-bold text-2xl md:text-xl py-2">
          {t(
            'By accepting this action, your account and all associated data will be permanently removed.',
          )}
        </div>
        <div className="text-xl md:text-lg lg:text-md py-2">
          {t(
            'Your BOSS pin-less account, account balance, and paid calling history will be removed. This action cannot be undone.',
          )}
        </div>
        <div className="flex flex-col gap-2 py-4">
          <div className="text-lg md:text-base font-semibold">
            {t('Type “{{deleteKeyword}}” to continue', {
              deleteKeyword,
            })}
          </div>
          <Input value={confirmationText} onChange={handleConfirmationTextChange} />
        </div>
        <div className="flex flex-row justify-between py-2 gap-4">
          <ModalButton text={t('Cancel')} onClick={onRequestClose} />
          <ModalButton
            text={t('Delete')}
            color="red"
            disabled={!isConfirmationTextMatchingDeleteKeyword}
            onClick={onConfirmClick}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AccountDeletionModal;
