import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStore, observer } from 'mobx-react';
import { showToast } from '@services/toasts';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';

import PaymentCardClass from '@root/models/PaymentCardClass';

import BottomControlsWrapper from '@components/common/BottomControlsWrapper';
import StepButton from '@components/common/Button/StepButton';

import PaymentCardSelect from '@components/common/PaymentCardSelect';
import { CvvModal } from '@components/common';

import { ERRORS } from '@components/common/PaymentCardSelect/constants';

import Store from './Store';

import SectionHeader from '../../components/Layout/SectionHeader';
import SectionContainer from '../../components/Layout/SectionContainer';

interface Props extends StepModule {
  selectedCardId?: string;
  amountText?: string;
  isLoading?: boolean;
  userCountryOfOrigin: CountryCode;
  onFinish(args: { paymentInfo: PaymentCardClass; cvvCode: string }): void;
}

const Summary: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    selectedCardId,
    amountText,
    isLoading,
    userCountryOfOrigin,
    dataTestPrefix,
    onFinish,
  } = props;

  const { t } = useTranslation();

  const store = useLocalStore(() => new Store());

  const bottomControlsWrapper = useRef<HTMLDivElement>(null);

  const {
    hasPaymentCards,
    selectedPaymentCard,
    selectProps,
    paymentCardReducedDetailsDialogProps,
    openSelect,
    showPaymentCardDialog,
  } = PaymentCardSelect.usePaymentCardSelect({
    handleId: selectedCardId,
    isUsingCardsWithoutBillingAllowed: true,
    userCountryOfOrigin,
  });

  const handleFinish = () => {
    // this differs from money transfer because the flow use Receipt screen in case of error
    if (store.cvvCode && selectedPaymentCard) {
      onFinish({
        paymentInfo: selectedPaymentCard,
        cvvCode: store.cvvCode,
      });
    }
    if (selectedPaymentCard && !store.cvvCode) {
      store.showCvvModal();
    } else if (!selectedPaymentCard) {
      showToast.warning(ERRORS.CARD_NOT_SELECTED);
      if (hasPaymentCards) {
        openSelect();
      } else {
        showPaymentCardDialog();
      }
    }
  };

  const handleCvvConfirm = (cvv: string) => {
    store.setCvvCode(cvv);
    store.hideCvvModal();
    handleFinish();
  };

  return (
    <SectionContainer>
      <SectionHeader color="blue">{t('payment details')}</SectionHeader>
      <div className="w-full max-w-sm">
        <PaymentCardSelect
          {...selectProps}
          minDropdownBottomOffset={bottomControlsWrapper.current?.offsetHeight}
        />
      </div>

      <PaymentCardSelect.PaymentCardReducedDetailsDialog
        {...paymentCardReducedDetailsDialogProps}
      />

      <CvvModal
        isOpen={store.isCvvRequired}
        onConfirm={handleCvvConfirm}
        onCancel={store.hideCvvModal}
      />

      <BottomControlsWrapper ref={bottomControlsWrapper}>
        <StepButton
          disabled={isLoading}
          onClick={handleFinish}
          dataTestId={`${dataTestPrefix}-next-btn`}
        >
          {t('send {{amount}} top-up', {
            amount: amountText,
          })}
        </StepButton>
      </BottomControlsWrapper>
    </SectionContainer>
  );
};

export default observer(Summary);
