const CA_PROVINCES = [
  'AB', // ALBERTA
  'BC', // BRITISH COLUMBIA
  'MB', // MANITOBA
  'NB', // NEW BRUNSWICK
  'NL', // NEWFOUNDLAND AND LABRADOR
  'NT', // NORTHWEST TERRITORIES
  'NS', // NOVA SCOTIA
  'NU', // NUNAVUT
  'ON', // ONTARIO
  'PE', // PRINCE EDWARD ISLAND
  'QC', // QUEBEC
  'SK', // SASKATCHEWAN
  'YT', // YUKON
];

export default CA_PROVINCES;
