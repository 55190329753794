import CryptoJS from 'crypto-js';

import storageInterface from '@services/storage';
import { getSessionDataFromStorage } from '../auth';

const encrypt = (data: string, key: string) => {
  return CryptoJS.AES.encrypt(data, key).toString();
};

const decrypt = (data: string, key: string) => {
  try {
    const bytes = CryptoJS.AES.decrypt(data, key);

    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    return null;
  }
};

// TODO: think about better solution. Doesn't look smooth, obvious or natural right now

export default class<T> {
  storeKey: string;

  constructor(storeKey: string) {
    this.storeKey = storeKey;
  }

  getEncryptKey = () => {
    return getSessionDataFromStorage()?.profileId;
  };

  getCollection: () => T[] = () => {
    const encryptionKey = this.getEncryptKey();
    const storedData = storageInterface.getItem(this.storeKey);
    if (storedData && encryptionKey) {
      const decryptedData = decrypt(storedData, encryptionKey);
      const collection = JSON.parse(decryptedData || '[]');
      if (Array.isArray(collection)) {
        return collection as T[];
      }
    }

    return [];
  };

  addItem = (item: T) => {
    const encryptionKey = this.getEncryptKey();

    if (encryptionKey) {
      const collection = this.getCollection();
      const newCollection = [...collection, item];

      storageInterface.setItem(
        this.storeKey,
        encrypt(JSON.stringify(newCollection), encryptionKey),
      );

      return newCollection;
    }

    return [];
  };

  deleteItemByIndex = (index: number) => {
    const encryptionKey = this.getEncryptKey();
    const collection = this.getCollection();

    if (encryptionKey) {
      if (collection && index > -1) {
        collection.splice(index, 1);
      }

      if (collection.length) {
        storageInterface.setItem(
          this.storeKey,
          encrypt(JSON.stringify(collection), encryptionKey),
        );
      } else {
        storageInterface.removeItem(this.storeKey);
      }
    }

    return collection;
  };

  clear = () => {
    storageInterface.removeItem(this.storeKey);
  };
}
