import firebase from 'firebase/app';
import 'firebase/auth';

interface ConfigProps {
  appOptions: { [key: string]: string };
  appName: string;
}

export default class {
  readonly app: firebase.app.App;

  readonly auth: firebase.auth.Auth;

  constructor(config: ConfigProps) {
    this.app = firebase.initializeApp(config.appOptions, config.appName);
    this.auth = firebase.auth(this.app);

    this.auth.useDeviceLanguage();
  }

  signOut = async () => {
    await this.auth.signOut();
  };
}
