import React from 'react';
import { useTranslation } from 'react-i18next';

import SideBarTemplate from '@components/templates/SideBarTemplate';

import UserAccount from '@components/modules/UserAccount';

const UserAccountPage = () => {
  const { t } = useTranslation();

  return (
    <SideBarTemplate
      titleConfig={{
        title: t('my dashboard'),
      }}
    >
      <UserAccount />
    </SideBarTemplate>
  );
};

export default UserAccountPage;
