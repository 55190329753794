import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';
import { SecureDataAttr } from '@root/interfaces/components/Common';

interface Props extends SecureDataAttr {
  title: string;
  data?: string | number;
  className?: string;
  isLast?: boolean;
}

const Balance: FC<React.PropsWithChildren<Props>> = (props) => {
  const { title, data, className, isLast, isSecureContent } = props;

  const classNames = normalizeStringCompound([
    'flex justify-between text-lg',
    isLast ? undefined : 'mb-4',
    className,
  ]);

  return (
    <div
      className={classNames}
      data-recording-sensitive={isSecureContent ? 'true' : 'none'}
    >
      <span>{title}</span>
      <span>{data}</span>
    </div>
  );
};

export default Balance;
