import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns-tz';

import { normalizeStringCompound } from '@utils/string';

import { Grid, Flag, Button, Icon } from '@components/common';

import CountryCode from '@root/interfaces/CountryCode';
import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { FilterType } from '../../Store';

export interface ItemProps extends TestIdDataAttr {
  id: string;
  recipient?: string;
  displayType?: string;
  date: Date;
  status: string;
  amount: string;
  description?: string;
  isPending?: boolean;
  isFailed?: boolean;
  errorText?: string | null;
  countryCode?: CountryCode;
  type: FilterType;
}

interface Props extends ItemProps {
  className?: string;
  onClick?: (id: string, type: FilterType) => void;
  onReceiptClick?: () => void;
}

const Item = (props: Props) => {
  const {
    id,
    className,
    recipient = '',
    displayType = '',
    date,
    amount,
    status,
    description = '',
    countryCode,
    dataTestId,
    type,
    errorText,
    isFailed = false,
    onReceiptClick,
    onClick,
  } = props;

  const { t } = useTranslation();

  const handleCardClick = () => {
    if (onClick) {
      onClick(id, type);
    }
  };

  const classNames = normalizeStringCompound([
    'block rounded-xl w-full text-left shadow-sm border p-5 mb-3',
    className,
  ]);

  // TODO: not sure about naming this const
  const recipientFullNameWithFallback = recipient
    ? t('To {{recipient}}', { recipient })
    : t('International Money Transfer');

  const renderInner = (
    <>
      <Grid.Row className="text-xl">
        <Grid.Col span={8}>
          <div className="font-medium" data-recording-sensitive>
            {type === FilterType.Calling ? displayType : recipientFullNameWithFallback}
          </div>
          <div className="text-base font-medium">
            {format(date, `PP '${t('at')}' h:mm a`)}
          </div>
          <div className="flex items-center my-1">
            {countryCode && (
              <Flag
                code={countryCode}
                className="rounded-full overflow-hidden w-7 h-7 mr-2"
              />
            )}
            <span className="text-gray-400">{description}</span>
          </div>
        </Grid.Col>
        <Grid.Col span={4}>
          <div className="flex flex-col h-full justify-between items-end">
            <div className="font-medium flex items-center leading-none">
              <span>{amount}</span>
              {onClick && <Icon name="ChevronRight" className="text-base ml-2" />}
            </div>
            {onReceiptClick && (
              <Button
                shape="text"
                size="lg"
                onClick={onReceiptClick}
                dataTestId={`${dataTestId}-print-receipt`}
              >
                {t('Print confirmation')}
              </Button>
            )}
          </div>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="text-xl">
        <Grid.Col span={12}>
          {/* TODO: expired and cancelled statuses must be in red color by design */}
          <div className={isFailed ? 'text-red-400' : 'text-green-400'}>{`${status}${
            errorText ? `: ${t(errorText)}` : ''
          }`}</div>
        </Grid.Col>
      </Grid.Row>
    </>
  );

  return onClick ? (
    <button
      className={classNames}
      onClick={handleCardClick}
      data-test-id={`${dataTestId}-details`}
    >
      {renderInner}
    </button>
  ) : (
    <div className={classNames}>{renderInner}</div>
  );
};

export default Item;
