import React from 'react';
import { useTranslation } from 'react-i18next';

import SideBarTemplate from '@components/templates/SideBarTemplate';

import UserAccountProfile from '@components/modules/UserAccountProfile';

const UserAccountProfilePage = () => {
  const { t } = useTranslation();

  return (
    <SideBarTemplate
      titleConfig={{
        title: t('my profile'),
      }}
    >
      <UserAccountProfile />
    </SideBarTemplate>
  );
};

export default UserAccountProfilePage;
