import React from 'react';

interface Props {
  text: string;
}

const ErrorMessage = (props: Props) => {
  const { text } = props;
  return <div className="px-4 mt-1 text-red-400">{text}</div>;
};

export default ErrorMessage;
