import { RoundingScheme } from '@root/constants/moneyTransfer/roundingRules';

import { isNumber } from '@utils/typeGuards';

export interface RateRoundingScheme {
  increment: number;
  scheme: RoundingScheme;
}

export const getRoundedAmountByScheme = (
  roundingParams: RateRoundingScheme,
  amount: number,
) => {
  const { increment, scheme } = roundingParams;
  let res = amount;

  if (res) {
    // amount > 0
    switch (scheme) {
      case RoundingScheme.Proper:
        res = Math.round(amount / increment) * increment;
        break;
      case RoundingScheme.UP:
        res = Math.ceil(amount / increment) * increment;
        break;
      case RoundingScheme.Down:
        res = Math.floor(amount / increment) * increment;
        break;
      case RoundingScheme.Scaled:
        // eslint-disable-next-line no-case-declarations
        const normalizedInc = increment / 100;
        res = Math.round(amount / normalizedInc) * normalizedInc;
        break;
      default:
        break;
    }

    if (res < increment) {
      res = increment;
    }
  }

  return res;
};

// for weird amount values without dot like '499' which should ne converted to '4.99'
export const parseDotlessFloatAmount = (value: string | number) => {
  return (Number(value) || 0) / 100;
};

/**
 * convert to normalized amount
 * @param amount
 * @param defaultAmount returns if amount invalid
 */
export const getNormalizedAmount = (amount: string | number, defaultAmount?: number) => {
  const amountStr = isNumber(amount) ? String(amount) : amount?.replace(/,/g, '');

  const [integer, decimal = ''] = amountStr.split('.');

  const normalizedAmount = Math.abs(Number(`${integer}.${decimal.slice(0, 2)}`));

  return defaultAmount ? normalizedAmount || defaultAmount : normalizedAmount;
};

/**
 * @param amount
 * @param maxDecimal default 2
 * @param maxZeros default 2
 * @returns example: 123,456,789.12
 */
export const getFormattedAmount = (
  amount?: number | string,
  maxDecimal = 2,
  maxZeros = 2,
) => {
  const numberAmount = Number(amount);

  if (Number.isNaN(numberAmount)) {
    return '0';
  }

  let formattedAmount = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: maxDecimal,
  }).format(numberAmount);

  const split = formattedAmount.split('.');

  if (split[1]) {
    let decimal = split[1].substring(0, maxDecimal);

    const missingDecimals = maxDecimal - decimal.length;

    if (missingDecimals < maxZeros) {
      decimal = decimal.concat(Array(missingDecimals).fill(0).join(''));
    } else {
      decimal = decimal.concat(Array(maxZeros).fill(0).join(''));
    }

    formattedAmount = `${split[0]}.${decimal}`;
  }

  return formattedAmount;
};

/**
 * Default money formatting
 *
 * 1 -> 1.00 USD
 * 35.5323 -> 35.53 USD
 *
 * @param amount
 * @param currencyText - USD, CAD etc.
 */
export const formatMoneyToText = (amount: number, currencyText: string) => {
  return `${amount.toFixed(2)} ${currencyText}`;
};
