// eslint-disable-next-line import/prefer-default-export
export function removeDuplicates<T>(myArr: T[], prop?: keyof T): T[] {
  if (prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }
  return myArr.filter((item, index) => myArr.indexOf(item) === index);
}

export function groupBy<T>(arr: T[], keys: (keyof T)[]): { [key: string]: T[] } {
  return arr.reduce((storage, item) => {
    const objKey = keys.map((key) => `${item[key]}`).join(':');
    if (storage[objKey]) {
      storage[objKey].push(item);
    } else {
      // eslint-disable-next-line no-param-reassign
      storage[objKey] = [item];
    }
    return storage;
  }, {} as { [key: string]: T[] });
}

export function getSortByKeyFn<T>(key: keyof T): (item1: T, item2: T) => number {
  return (item1, item2) => {
    const keyValue1 = item1[key];
    const keyValue2 = item2[key];
    if (keyValue1 && keyValue2) {
      if (keyValue1 < keyValue2) {
        return -1;
      }
      if (keyValue1 > keyValue2) {
        return 1;
      }
    }
    return 0;
  };
}
