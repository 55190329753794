import React, { FC } from 'react';

import { Icon } from '@components/common';

interface Props {
  onBackClick?: () => void;
  onModalClose: () => void;
  containerClassName?: string;
}

const ModalHeader: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onBackClick, onModalClose, containerClassName } = props;

  return (
    <div className={containerClassName}>
      <div>
        {onBackClick && (
          <Icon name="ChevronLeft" onClick={onBackClick} className="text-xl" />
        )}
      </div>
      <div>
        <Icon name="Cross" onClick={onModalClose} className="text-gray-400 text-xl" />
      </div>
    </div>
  );
};

export default ModalHeader;
