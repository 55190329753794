import firebase from 'firebase';
import 'firebase/remote-config';

import { logError } from '@services/logging';

import { CountryMainFeaturesMap } from '@root/interfaces/appConfig';

const CONFIG_REQUEST_TTL = process.env.NODE_ENV === 'production' ? 5 * 60 * 1000 : 0;

// The name is defined in the Firebase remote config
const KNOWN_CONFIG_PARAMETERS = {
  COUNTRY_MAIN_FEATURES_MAP: 'countryMainFeaturesMap',
};

const ERROR_TYPE_PREFIX = 'Remote config error';

// The structure is defined in the Firebase remote config
export interface RemoteConfigResponse {
  countryMainFeaturesMap?: CountryMainFeaturesMap;
}

class RemoteConfigApi {
  app: firebase.app.App;

  constructor({ app }: { app: firebase.app.App }) {
    this.app = app;
  }

  getConfig = async (): Promise<RemoteConfigResponse> => {
    firebase.remoteConfig(
      this.app,
    ).settings.minimumFetchIntervalMillis = CONFIG_REQUEST_TTL;
    await firebase.remoteConfig(this.app).fetchAndActivate();
    const countryMainFeaturesMapConfigResponse = firebase
      .remoteConfig(this.app)
      .getValue(KNOWN_CONFIG_PARAMETERS.COUNTRY_MAIN_FEATURES_MAP);

    let countryMainFeaturesMap;
    try {
      countryMainFeaturesMap = JSON.parse(
        countryMainFeaturesMapConfigResponse.asString(),
      ) as CountryMainFeaturesMap;
    } catch (err) {
      logError(
        Error(
          `${ERROR_TYPE_PREFIX}: cannot parse ${KNOWN_CONFIG_PARAMETERS.COUNTRY_MAIN_FEATURES_MAP}`,
        ),
        countryMainFeaturesMapConfigResponse.asString(),
      );
    }

    const res = {
      countryMainFeaturesMap,
    };

    return res;
  };
}

export default RemoteConfigApi;
