import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';

import BottomControlsWrapper from '@components/common/BottomControlsWrapper';
import StepButton from '@components/common/Button/StepButton';

import { showToast } from '@services/toasts';

import SectionHeader from '../../components/Layout/SectionHeader';
import SectionContainer from '../../components/Layout/SectionContainer';

import PhoneForm from './components/PhoneForm';
import { VALIDATION_ERRORS } from './constants';

interface Props extends StepModule {
  phone?: string;
  countryCode: CountryCode;
  onFinish(args: { recipientPhoneNumber: string }): void;
}

/**
 * Well this component doesn't use any store or complex logic like containers do
 * but to keep things consistent let's assume this is a container.
 * The other solution is to put it to Imtu components.
 * @param props
 * @constructor
 */
const Phone: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { phone, countryCode, dataTestPrefix, onFinish } = props;

  const { t } = useTranslation();

  const submitButtonRef = useRef<{ submit?(): void } | null>(null);

  const handleFinish = (phoneNumber: string) => {
    if (phoneNumber) {
      onFinish({ recipientPhoneNumber: phoneNumber });
    }
  };

  const handleRemoteSubmit = () => {
    submitButtonRef.current?.submit?.();
  };

  const handlePhoneValidationError = () => {
    showToast.warning(VALIDATION_ERRORS.PHONE);
  };

  return (
    <SectionContainer>
      <SectionHeader color="blue">{t('enter a phone number to top-up')}</SectionHeader>
      <PhoneForm
        phone={phone}
        ref={submitButtonRef}
        countryCode={countryCode}
        onSubmit={handleFinish}
        onSubmitValidationError={handlePhoneValidationError}
      />
      <BottomControlsWrapper>
        <StepButton
          onClick={handleRemoteSubmit}
          dataTestId={`${dataTestPrefix}-next-btn`}
        >
          {t('continue')}
        </StepButton>
      </BottomControlsWrapper>
    </SectionContainer>
  );
};

export default Phone;
