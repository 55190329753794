import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LanguageOption } from '@root/interfaces/components/LanguageSwitcher';
import { Languages } from '@root/interfaces/Languages';

import { UserAccountStore } from '@root/stores';

import { LANGUAGE_STORE_KEY } from '@root/constants/languages';

import useStore from './useStore';

import useOnClickOutside from './useOnClickOutside';

interface Props {
  languagesOptions: LanguageOption[];
  onChange?(value: LanguageOption): void;
}

const useLanguageSwitcher = (props: Props) => {
  const { languagesOptions, onChange } = props;

  const { i18n } = useTranslation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const languageSwitcherRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  useOnClickOutside(handleClickOutside, languageSwitcherRef);

  const handleTogglerClick = () => {
    setIsOpen((isOpened) => !isOpened);
  };

  const handleLanguageOptionSelect = (option: LanguageOption) => {
    userAccountStore.changeLanguage(option.value);
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set(LANGUAGE_STORE_KEY, option.value);
      const newUrl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${searchParams.toString()}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);
    }
    setIsOpen(false);

    if (onChange) {
      onChange(option);
    }
  };

  return {
    languageSwitcherRef,
    onTogglerClick: handleTogglerClick,
    onLanguageOptionSelect: handleLanguageOptionSelect,
    isOpen,
    languagesOptions,
    currentLanguage: i18n.language as Languages | undefined,
    isDisabled: userAccountStore.isLoading,
  };
};

export default useLanguageSwitcher;
