/* eslint-disable import/prefer-default-export */

export const FORM_ERROR_MESSAGE = {
  REQUIRED: 'Required field', // t('Required field')
  MAX_LENGTH_LIMIT: 'Character limit exceeded', // t('Character limit exceeded')
  MUST_BE_DIGITS_ONLY: 'Must be digits only', // t('Must be digits only')
  ONLY_LETTERS_QUOTES_HYPHEN_ALLOWED: 'Only letters, hyphens and apostrophes are allowed', // t('Only letters, hyphens and apostrophes are allowed')
  INVALID_STATE_CODE: 'Unknown state code', // t('Unknown state code')
  UNKNOWN_STATE_PROVINCE_REGION_CODE: 'Unknown State/Province/Region code', // t('Unknown State/Province/Region code')
  INVALID_LENGTH_STATE_PROVINCE_REGION_CODE:
    'State/Province/Region must be exactly 2 characters', // t('State/Province/Region must be exactly 2 characters')
  INVALID_ZIP_CODE: 'Invalid Zip code', // t('Invalid Zip code')
  INVALID_ZIP_POSTAL_CODE: 'Invalid Zip/Postal code', // t('Invalid Zip/Postal code')
  INVALID_POST_CODE: 'Invalid Post Code', // t('Invalid Post Code)
  INVALID_EMAIL: 'Enter valid email', // t('Enter valid email')
  INVALID_PHONE_NUMBER: 'Enter valid phone number', // t('Enter valid phone number')
  DIGITS_ONLY: 'Must be only digits', // t('Must be only digits')
  DOB_MAX_LEGAL: 'You must be {{yearsValue}} years or older', // t('You must be {{yearsValue}} years or older')
  DOB_MIN: 'Please use value above {{yearsValue}}', // t('Please use value above {{yearsValue}}')
  INVALID_EXPIRATION_DATE: 'Expiration date is not valid', // t('Expiration date is not valid')
  INVALID_CARD_NUMBER: 'Card number is not valid', // t('Card number is not valid')
  EMPTY_ERROR_MESSAGE: '',
};

export const ZIP_CODE_LENGTH = 5;
/**
 * The current postal codes in the United States range from 00001 – 99950.
 */
export const ZIP_CODE_FIVE_ZEROS = '00000'; // invalid zip code
