import { makeAutoObservable } from 'mobx';
import ImtuProductClass from '@root/models/ImtuProductClass';

class Store {
  product?: ImtuProductClass;

  constructor({ product }: { product?: ImtuProductClass }) {
    makeAutoObservable(this);

    this.product = product;
  }

  setProduct(product: ImtuProductClass) {
    this.product = product;
  }
}

export default Store;
