import firebase from 'firebase';
import 'firebase/analytics';

import { firebaseAuthAPI } from '@services/firebase';
import brazeAPI from '@services/braze';
import smartlookAPI from '@services/smartlook';
import kochavaApi from '@services/kochava';
import GoogleAnalyticsApi from '@services/googleAnalytics';

import UserAccountClass from '@root/models/UserAccountClass';

import {
  ActionParams,
  AnalyticsErrorEventParams,
  BackendFailureParams,
  ButtonPressEventParams,
  DeliveryMethodParams,
  ImtuPurchaseMarketingServiceParams,
  MtPurchaseItem,
  PurchaseImtuOptions,
  PurchaseMtOptions,
  SelectEventParams,
  SentCallingRechargeTransactionParams,
  ImtuTxnTrackingParams,
  SentMtTransactionParams,
  TrackingEventParams,
} from '@root/interfaces/Analytics';

import CountryCode from '@root/interfaces/CountryCode';
import { ImtuProductType } from '@root/interfaces/MobileTopUp';

import { isNumber } from '@utils/typeGuards';

import { IMTU_PRODUCT_TYPES } from '@root/constants/mobileTopUp/imtuProductTypes';

import { getGaPurchaseImtuParams, getGaPurchaseMtParams } from './helpers';

import {
  BrazeEvent,
  GoogleAnalyticsEvent,
  PURCHASE_ITEM,
  SelectorName,
} from './constants';

const { app: brazeApp } = brazeAPI;

const { app: firebaseApp } = firebaseAuthAPI;

const { app: smartlookApp } = smartlookAPI;

const gtmUniversalAnalytics = new GoogleAnalyticsApi();
const firebaseGa4Analytics = firebaseApp.analytics();

// replace all analytics functions with console log when logging is disabled
if (process.env.REACT_APP_ANALYTICS !== 'active') {
  // eslint-disable-next-line no-console
  firebaseGa4Analytics.logEvent = console.log;
  Object.keys(gtmUniversalAnalytics).forEach((key) => {
    // @ts-ignore
    if (
      key in gtmUniversalAnalytics &&
      // @ts-ignore
      typeof gtmUniversalAnalytics[key] === 'function'
    ) {
      // @ts-ignore
      // eslint-disable-next-line func-names
      gtmUniversalAnalytics[key] = function (...args) {
        // eslint-disable-next-line no-console
        console.log(`analytics ${key} stub: `, args);
      };
    }
  });
}

const setUserPropsForAnalytics = (user?: UserAccountClass) => {
  if (user) {
    firebaseGa4Analytics.setUserId(user.id);
    firebaseGa4Analytics.setUserProperties({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      avatarURL: user.avatarURL,
    });
    gtmUniversalAnalytics.setUserId(user.id);
    gtmUniversalAnalytics.setUserProperties({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      avatarURL: user.avatarURL,
    });
  } // else anonymous user
};

const setUserPropsForBraze = (user?: UserAccountClass) => {
  if (user) {
    brazeApp.changeUser(user.id);
    const currentBrazeUser = brazeApp.getUser();
    currentBrazeUser.setFirstName(user.firstName);
    currentBrazeUser.setLastName(user.lastName);
    currentBrazeUser.setEmail(user.email);
    currentBrazeUser.setAvatarImageUrl(user.avatarURL ?? '');
    currentBrazeUser.setPhoneNumber(user.phone);
    currentBrazeUser.setLanguage(user.language ?? '');
  }
};

const setUserPropsForSmartLook = (user?: UserAccountClass) => {
  // TODO: remove user.id check after figuring out what actual id we should use - DTC or BMBCustomerId
  if (user && user.id) {
    smartlookApp?.identify(user.id, {
      name: user.fullName,
      email: user.email,
    });
  }
};

const setUserPropsForKochava = (user?: UserAccountClass) => {
  if (user) {
    kochavaApi.setUserId({
      userId: user.id,
      phoneNumber: user.phone,
      email: user.email,
    });
  }
};

// TODO: setUserPropForIntercom

export const setupUser = (user?: UserAccountClass) => {
  setUserPropsForAnalytics(user);
  setUserPropsForBraze(user);
  setUserPropsForSmartLook(user);
  setUserPropsForKochava(user);
  // setUserPropsForIntercom(user);
};

const logoutBrazeUser = (userId: string) => {
  brazeApp.changeUser(`logout-${userId}`);
};

export const trackGoogleAnalyticsEvent = (
  name: GoogleAnalyticsEvent,
  params?: TrackingEventParams,
) => {
  firebaseGa4Analytics.logEvent(name as string, params);
  gtmUniversalAnalytics.logEvent(name as string, params);
};

export const trackGaMtModalAppLinkClickEvent = (params?: TrackingEventParams) => {
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.MtModalAppLinkClick, params);
};

export const trackPageView = (location: string) => {
  gtmUniversalAnalytics.trackPageView(location);
  // BRP-1637 - disabled for investigation of the PageView event duplication
  // firebaseGa4Analytics.logEvent(GoogleAnalyticsEvent.PageView, {
  //   firebase_screen: location,
  // });
};

export const trackBrazeEvent = (eventName: string, params?: TrackingEventParams) => {
  brazeApp.logCustomEvent(eventName, params);
};

// TODO: trackIntercomEvent(eventName, params)

export const trackCustomEvent = (
  eventName: GoogleAnalyticsEvent,
  eventParams?: TrackingEventParams,
) => {
  trackGoogleAnalyticsEvent(eventName, eventParams);
  trackBrazeEvent(eventName, eventParams);
  // TODO: trackIntercomEvent(eventName, eventParams);
};

export const trackButtonPress = (eventParams?: ButtonPressEventParams) => {
  trackCustomEvent(GoogleAnalyticsEvent.ButtonPress, eventParams);
  if (eventParams?.button_name) {
    trackBrazeEvent(
      `${GoogleAnalyticsEvent.ButtonPress}_${eventParams.button_name}`,
      eventParams,
    );
  }
};

export const trackSelect = (params?: SelectEventParams) => {
  const selectEventParams = { ...params };
  trackCustomEvent(GoogleAnalyticsEvent.Select, selectEventParams);
};

export const trackSelectCountryCurrency = (country: CountryCode, currency: string) => {
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.Select, {
    country_name: country,
    currency,
  });
  trackBrazeEvent(BrazeEvent.SelectedCountry, { country, currency });
  // TODO: trackIntercomEvent("MT selected country", { event: `${ country } ${ currency }` });
};

export const trackSelectDeliveryMethod = (params: DeliveryMethodParams) => {
  const eventParams = { ...params };
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.Select, {
    selector_name: SelectorName.DeliveryMethods,
    data: params,
  });
  trackBrazeEvent(BrazeEvent.SelectedDeliveryMethod, eventParams);
  // TODO: implement logging event to Intercom
  // Example code from mobile application
  // Intercom.logEvent("MT selected delivery", {
  // amount: `${amount}`,
  //   method, country, currency,
  // }).catch (err => console.log(err)).then(() => null);
};

export const trackBrazePurchaseEvent = (
  product: string,
  amount: number,
  params: Dictionary<undefined | string | number | object>,
) => {
  const currency = PURCHASE_ITEM.CURRENCY;
  brazeApp.logPurchase(product, amount, currency, PURCHASE_ITEM.QUANTITY, {
    delivery_method: product,
    ...params,
  });
};

export const trackBrazeMtPurchaseEvent = (
  recipientPhone: string,
  firstName: string,
  lastName: string,
) => {
  trackBrazeEvent(BrazeEvent.PurchaseMt, { recipientPhone, firstName, lastName });
};

export const trackBrazeImtuPurchaseEvent = (
  type: string,
  recipientNumber: string,
  recipientCountry?: CountryCode,
) => {
  trackBrazeEvent(`${type}_${BrazeEvent.PurchaseImtu}`, {
    recipientNumber,
    recipientCountry,
  });
};

export const trackBrazeCustomAttribute = (
  attribute: string,
  value: string | number,
  shouldIncrementAttribute?: boolean,
) => {
  const currentBrazeUser = brazeApp.getUser();
  if (shouldIncrementAttribute && isNumber(value)) {
    currentBrazeUser.incrementCustomUserAttribute(attribute, value);
  } else {
    currentBrazeUser.addToCustomAttributeArray(attribute, String(value));
  }
};

// copied code with small (related to the web only) changes from mobile application
export const trackPurchaseRefund = (
  transactionId: string,
  item: firebase.analytics.Item,
  options: PurchaseMtOptions,
  amount: number,
  userCountryCode: CountryCode,
) => {
  const trackingParams = getGaPurchaseMtParams({
    eventName: GoogleAnalyticsEvent.Refund,
    transactionId,
    item,
    amount,
    userCountryCode,
    options,
  });
  firebaseGa4Analytics.logEvent(GoogleAnalyticsEvent.Refund as string, trackingParams);
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.Refund,
    trackingParams,
  );
};

interface GaPurchaseMtParams {
  transactionId: string;
  item: firebase.analytics.Item;
  amount: number;
  options: PurchaseMtOptions;
  userCountryCode: CountryCode;
}

export const trackGaPurchaseMt = ({
  transactionId,
  item,
  amount,
  options,
  userCountryCode,
}: GaPurchaseMtParams) => {
  const trackingParams = getGaPurchaseMtParams({
    eventName: GoogleAnalyticsEvent.PurchaseMt,
    transactionId,
    item,
    amount,
    userCountryCode,
    options,
  });
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseMt as string,
    trackingParams,
  );
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseDefault as string,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseMt,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseDefault,
    trackingParams,
  );
};

export const trackGaPurchasePinless = ({
  userCountryCode,
}: {
  userCountryCode: CountryCode;
}) => {
  // No params needed so far
  const trackingParams = {
    userCountryCode,
  };
  firebaseGa4Analytics.logEvent(GoogleAnalyticsEvent.PurchasePinless, trackingParams);
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseDefault as string,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchasePinless,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseDefault,
    trackingParams,
  );
};

export const trackGaPurchaseImtu = ({
  transactionId,
  item,
  amount,
  userCountryCode,
  options,
}: {
  transactionId: string;
  item: firebase.analytics.Item;
  amount: number;
  userCountryCode: CountryCode;
  options: PurchaseImtuOptions;
}) => {
  const trackingParams = getGaPurchaseImtuParams({
    transactionId,
    item,
    amount,
    userCountryCode,
    options,
  });
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseImtu as string,
    trackingParams,
  );
  firebaseGa4Analytics.logEvent(
    GoogleAnalyticsEvent.PurchaseDefault as string,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseImtu,
    trackingParams,
  );
  gtmUniversalAnalytics.trackTransactionAction(
    GoogleAnalyticsEvent.PurchaseDefault,
    trackingParams,
  );
};

export const trackMtPurchaseToMarketingServices = (
  transactionId: string,
  item: MtPurchaseItem,
  amount: number,
  fee?: number,
) => {
  const total = fee ? amount + fee : amount;
  const {
    recipientPhone,
    recipientFirstName,
    recipientLastName,
    recipientCountry,
    payerId,
    subPayerId,
  } = item.params;

  trackBrazePurchaseEvent(item.name, total, {
    transactionId,
    currency: PURCHASE_ITEM.CURRENCY,
    product_id: item.id,
    ...item.params,
    ...(fee ? { fee } : {}),
  });

  trackBrazeMtPurchaseEvent(recipientPhone, recipientFirstName, recipientLastName);

  trackBrazeCustomAttribute(`mt_send_${recipientCountry}`, 1, true);
  trackBrazeCustomAttribute(`mt_send_date`, String(new Date()));
  trackBrazeCustomAttribute(`mt_send_date_${recipientCountry}`, String(new Date()));
  trackBrazeCustomAttribute(`mt_recent_payer`, payerId);
  trackBrazeCustomAttribute(`mt_recent_subpayer`, subPayerId);
  trackBrazeCustomAttribute(`mt_recent_recipient`, recipientPhone);
};

export const trackImtuPurchaseToMarketingServices = (
  params: ImtuPurchaseMarketingServiceParams,
) => {
  const {
    amount,
    transactionId,
    item: {
      params: { phone, type, country, carrier, plan },
    },
  } = params;

  trackBrazePurchaseEvent(params.item.name, amount, {
    transactionId,
    product_id: params.item.id,
    currency: PURCHASE_ITEM.CURRENCY,
    ...params.item.params,
  });

  trackBrazeImtuPurchaseEvent(
    `${type}${PURCHASE_ITEM.NAME_IMTU.toLowerCase()}`,
    phone,
    country,
  );

  trackBrazeCustomAttribute(`${type}mtu_send_${country}`, 1, true);
  trackBrazeCustomAttribute(`${type}mtu_send_${carrier}`, 1, true);
  trackBrazeCustomAttribute(`${type}mtu_send_${plan}`, 1, true);
  trackBrazeCustomAttribute(`${type}mtu_send_date`, String(new Date()));
  trackBrazeCustomAttribute(`${type}mtu_send_date_${country}`, String(new Date()));
};

export const trackSentMtTransaction = (params: SentMtTransactionParams) => {
  const {
    transactionCpasId,
    amount,
    fee,
    paymentMethod,
    promoCode,
    userCountryCode,
    purchaseParams: {
      payerId,
      subPayerId,
      pocId,
      deliveryMethod,
      recipientCountry,
      recipientCurrency,
      localAmount,
    },
  } = params;

  const itemId = `${deliveryMethod}/${recipientCountry}/${recipientCurrency}/${payerId}/${subPayerId}/${pocId}`;

  trackGaPurchaseMt({
    transactionId: transactionCpasId,
    item: {
      item_id: itemId,
      item_name: PURCHASE_ITEM.NAME_MT,
      item_brand: PURCHASE_ITEM.BRAND,
      item_category: deliveryMethod,
      item_category2: recipientCurrency,
      item_category3: payerId,
      item_category4: subPayerId,
      item_category5: pocId,
      item_variant: recipientCountry,
      item_list_name: deliveryMethod,
      quantity: PURCHASE_ITEM.QUANTITY,
    },
    amount,
    options: { paymentMethod, fee, promoCode, localAmount },
    userCountryCode,
  });
  const purchaseItemParams = {
    id: itemId,
    name: PURCHASE_ITEM.NAME_MT,
    params: params.purchaseParams,
  };

  const total = fee ? amount + fee : amount;

  kochavaApi.trackMtPurchase({
    ...params,
    product: PURCHASE_ITEM.NAME_MT,
    product_id: itemId,
    amount,
    ...(fee ? { fee } : {}),
    total,
    currency: PURCHASE_ITEM.CURRENCY,
  });

  trackMtPurchaseToMarketingServices(transactionCpasId, purchaseItemParams, amount, fee);
};

export const trackSentCallingRechargeTransaction = (
  args: SentCallingRechargeTransactionParams,
) => {
  kochavaApi.trackCallingPurchase({ ...args });
  trackGaPurchasePinless({ userCountryCode: args.userCountryCode });
};

export const trackSentImtuTransaction = (params: ImtuTxnTrackingParams) => {
  const {
    amount,
    transactionId,
    paymentType,
    userCountryCode,
    purchaseParams: { type, country, phone, carrier, plan },
  } = params;

  const itemName = `${type}${PURCHASE_ITEM.NAME_IMTU}`;
  const itemId = `${itemName}/${carrier}/${country}/$${amount}/${plan}/${phone}`;
  const category =
    type === ImtuProductType.Domestic
      ? IMTU_PRODUCT_TYPES.DOMESTIC
      : IMTU_PRODUCT_TYPES.INTERNATIONAL;

  const analyticsItem = {
    item_id: itemId,
    item_name: itemName,
    item_category: `${category} Airtime Topup`,
    item_category2: carrier,
    item_category3: plan,
    item_category4: phone,
    item_variant: country,
    item_list_name: carrier,
  };

  trackGaPurchaseImtu({
    transactionId,
    item: analyticsItem,
    amount,
    userCountryCode,
    options: { paymentType },
  });

  kochavaApi.trackImtuPurchase({
    ...analyticsItem,
    transactionId,
    amount,
    paymentType,
  });

  const purchaseItemParams = {
    id: itemId,
    name: PURCHASE_ITEM.NAME_IMTU,
    params: params.purchaseParams,
  };
  trackImtuPurchaseToMarketingServices({
    transactionId,
    item: purchaseItemParams,
    amount,
  });
};

export const trackAction = (params: ActionParams) => {
  const eventParams = { ...params };
  trackCustomEvent(GoogleAnalyticsEvent.Action, eventParams);
  trackBrazeEvent(`${GoogleAnalyticsEvent.Action}_${eventParams.name}`, {
    context: eventParams.context,
  });
};

export const trackError = (params?: AnalyticsErrorEventParams) => {
  const eventParams = { ...params };
  trackCustomEvent(GoogleAnalyticsEvent.Error, eventParams);
};

export function trackBackendFailure(params: BackendFailureParams) {
  trackCustomEvent(GoogleAnalyticsEvent.BackendError, { ...params });
}

export const trackLogin = () => {
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.Login);
};

export const trackLogout = (userId: string) => {
  logoutBrazeUser(userId);
  // TODO: Intercom logout & register unidentified user
};

export const trackSignUp = (userId: string) => {
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.SignUp);
  kochavaApi.trackSignUp(userId);
};

export const trackBrcToDtcMigration = (params: {
  userId: string;
  brCustomerId: string;
  migrationPath: string | null;
  activationDate: string | null;
  activationDateSet: boolean;
}) => {
  trackGoogleAnalyticsEvent(GoogleAnalyticsEvent.MigrationBrcToDtc, {
    dtc_profile_id: params.userId,
    br_customer_id: params.brCustomerId,
    migration_path: params.migrationPath ?? undefined,
    activation_date: params.activationDate ?? undefined,
    activation_date_set: params.activationDateSet,
    location: 'session',
  });
};
