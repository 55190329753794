import React from 'react';
import PATHS from '@root/routes/paths';

import ServiceTemplate from '@components/templates/ServiceTemplate';

import Imtu from '@components/modules/Imtu';

const ImtuPage = () => {
  return (
    <ServiceTemplate>
      <Imtu basePath={PATHS.IMTU} />
    </ServiceTemplate>
  );
};

export default ImtuPage;
