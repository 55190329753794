import { normalizeStringCompound } from '@utils/string';

import { Icon } from '..';

interface Props {
  title: string;
  className?: string;
  onClose(): void;
  onGoBack?(): void;
}

const PaymentCardDialogHeader = (props: Props) => {
  const { title, className, onClose, onGoBack } = props;

  return (
    <div
      className={normalizeStringCompound(['text-center relative mt-[15px]', className])}
    >
      {onGoBack && (
        <Icon
          name="ChevronLeftAlt"
          onClick={onGoBack}
          className="absolute left-0 top-0 text-[17px]"
        />
      )}
      <div className="font-medium text-[20px] py-[10px] px-[20px]">{title}</div>
      <Icon
        name="Cross"
        onClick={onClose}
        className="absolute right-0 top-0 text-[24px]"
      />
    </div>
  );
};

export default PaymentCardDialogHeader;
