import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flag, Select } from '@components/common';

import CountryCode from '@root/interfaces/CountryCode';
import { OptionProps, SelectProps } from '@root/interfaces/components/Select';

import { isString } from '@utils/typeGuards';
import { getSortByKeyFn } from '@utils/array';

type CountryItem = {
  code: CountryCode;
  value: string;
  textLabel: string;
};

interface Props extends Omit<SelectProps, 'options' | 'disabled' | 'setForcedOpen'> {
  data: CountryItem[];
  isSelectForcedOpen?: boolean; // TODO: extend from Select pros when boolean naming is fixed
  isDisabled?: boolean; // TODO: extend from Select pros when boolean naming is fixed
  onChange?(value: string): void;
  minDropdownBottomOffset?: number;
}

const CountrySelect: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    data,
    value,
    placeholder,
    dropdownClassName,
    drawerContainerClassName,
    drawerContentWrapperClassName,
    isLoading,
    isSelectForcedOpen,
    isDisabled,
    dataTestId,
    onChange,
    isOpen,
    minDropdownBottomOffset,
  } = props;

  const [selectedCountryCode] = isString(value) ? value.split('-') : []; // value example: MX-MXN

  const { t } = useTranslation();

  const selectAddonBefore = selectedCountryCode && (
    <Flag
      className="ml-4 -mr-2 h-7 w-7 overflow-hidden rounded-full"
      code={selectedCountryCode as CountryCode}
    />
  );

  const countryOptions: OptionProps[] = data
    .map((item) => {
      // localise
      return {
        ...item,
        name: t(item.textLabel),
      };
    })
    .sort(getSortByKeyFn('name'))
    .map((item) => {
      return {
        value: item.value,
        text: item.name,
        label: (
          <div className="flex items-center not-last-child:mb-2 md:not-last-child:mb-0">
            <Flag
              className="mr-2 h-14 w-14 overflow-hidden rounded-full md:h-10 md:w-10"
              code={item.code}
            />
            <span className="text-xl md:text-base">{item.name}</span>
          </div>
        ),
      };
    });

  const handleChange = (newValue: string) => {
    onChange?.(newValue);
  };

  return (
    <Select
      addonBefore={selectAddonBefore}
      className="mb-8"
      placeholder={placeholder}
      dropdownClassName={dropdownClassName}
      drawerContainerClassName={drawerContainerClassName}
      drawerContentWrapperClassName={drawerContentWrapperClassName}
      options={countryOptions}
      value={value}
      dataTestId={dataTestId}
      isLoading={isLoading}
      hasSearch
      isOpen={isOpen}
      isOpenForced={isSelectForcedOpen}
      disabled={isDisabled}
      onChange={handleChange}
      minDropdownBottomOffset={minDropdownBottomOffset}
    />
  );
};

export default CountrySelect;
