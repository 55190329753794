import { ReactNode } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import { ReactComponent as VisaIcon } from '@src/static/assets/images/pcards/visa.svg';
import { ReactComponent as MasterCardIcon } from '@src/static/assets/images/pcards/master-card.svg';
import { ReactComponent as DiscoverIcon } from '@src/static/assets/images/pcards/discover.svg';
import { ReactComponent as AmExIcon } from '@src/static/assets/images/pcards/american-express.svg';

interface Props {
  paySource: PaymentCardIssuerKeys;
  cardType: string;
  cardStatus?: ReactNode;
  feeText?: ReactNode;
  maskedNumber: string;
  className?: string;
  isSelected?: boolean;
  iconRight?: React.ReactNode;
}

const ccImg: { [key in PaymentCardIssuerKeys]: React.ReactNode } = {
  MC: <MasterCardIcon className="w-[40px]" />,
  V: <VisaIcon className="w-[40px]" />,
  AE: <AmExIcon className="w-[40px]" />,
  D: <DiscoverIcon className="w-[40px]" />,
};

const Option = (props: Props) => {
  const {
    paySource,
    cardType,
    maskedNumber,
    cardStatus,
    feeText,
    className,
    isSelected,
    iconRight,
  } = props;

  const wrapperClassName = normalizeStringCompound([
    'flex font-light min-h-[50px]',
    className,
  ]);

  const ccImgClassName = normalizeStringCompound([
    'flex items-center',
    isSelected ? '' : 'pb-[12px]',
  ]);

  const ccInfoClassName = normalizeStringCompound([
    'flex items-center w-full text-right leading-[19px]',
    isSelected ? '' : 'pb-[12px] border-b border-gray-300 self-stretch',
  ]);

  const rightIconClassName = normalizeStringCompound([
    'flex items-center justify-center ml-[25px] self-stretch',
    isSelected ? '' : 'pb-[12px]',
  ]);

  return (
    <div className={wrapperClassName}>
      <div className={ccImgClassName}>{ccImg[paySource]}</div>
      <div className="flex w-full items-center ml-[15px]">
        <div className={ccInfoClassName}>
          <span className="text-[19px] mr-[10px] leading-none">{`X${maskedNumber}`}</span>
          <div className="flex flex-col items-end ml-auto text-[16px] text-gray-400">
            {cardStatus || feeText ? <span>{cardStatus ?? feeText}</span> : undefined}
            {!cardStatus && <span>{cardType}</span>}
          </div>
        </div>
        {iconRight && <div className={rightIconClassName}>{iconRight}</div>}
      </div>
    </div>
  );
};

export default Option;
