import { fromUnixTime } from 'date-fns';

import { AddAmountToBalanceResponse } from '@root/interfaces/contract/callingRechargePinless/AddAmountToBalance';

class CallingRechargeReceiptClass {
  currencySymbol: string;

  currency: string;

  oldBalance: number;

  date: Date;

  rechargeAmount: number;

  promoAmount?: number;

  newBalance: number;

  constructor(data: AddAmountToBalanceResponse) {
    this.currencySymbol = data.transaction.balance.currency_symbol;
    this.currency = data.transaction.balance.currency;
    this.oldBalance = Number(data.transaction.old_balance.display_amount);
    this.date = fromUnixTime(Math.floor(data.transaction.balance.timestamp / 1000));
    this.rechargeAmount = Number(data.transaction.amount_charged.display_amount);
    this.promoAmount = Number(data.transaction.promotion?.total_amount.display_amount);
    this.newBalance = Number(data.transaction.balance.display_amount);
  }
}

export default CallingRechargeReceiptClass;
