import Cookies, { CookieAttributes } from 'js-cookie';

// eslint-disable-next-line import/prefer-default-export
export const setCookie = (name: string, value?: unknown, options?: CookieAttributes) => {
  Cookies.set(name, String(value), options);
};

export const deleteCookie = (name: string, options?: CookieAttributes) => {
  Cookies.remove(name, options);
};
