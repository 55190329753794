import React, { FC } from 'react';

interface Props {
  className?: string;
}

const Text: FC<React.PropsWithChildren<Props>> = (props) => {
  return <span {...props} />;
};

export default Text;
