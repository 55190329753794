import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { IPlacement } from 'rc-drawer/lib/IDrawerPropTypes';

import { PaymentCardAllDetailsDialogProps } from '@root/interfaces/components/PaymentCardDialog';

import { Drawer, Modal, Spinner, MediaMatcher, Button } from '..';

import PaymentCardBillingAddressForm, {
  PaymentCardBillingAddressFormValues,
} from '../PaymentCardBillingAddressForm';
import PaymentCardDetailsForm, {
  PaymentCardDetailsFormValues,
} from '../PaymentCardDetailsForm';
import PaymentCardDialogHeader from '../PaymentCardDialogFormHeader';
import { useIsMobile } from '../MediaQueryMatchers';

type StateType = PaymentCardDetailsFormValues & PaymentCardBillingAddressFormValues;

type PropsParams = PaymentCardBillingAddressFormValues & PaymentCardDetailsFormValues;

const PaymentCardAllDetailsDialog = (
  props: PaymentCardAllDetailsDialogProps<PropsParams>,
) => {
  const {
    isOpen,
    isLoading,
    hasMissedDetails,
    handleId,
    values,
    countryCode,
    onClose,
    onSubmit,
    onRemove,
    onDialogCloseAnimationEnd,
  } = props;

  const [formsValues, setFormsValues] = useState<StateType>(values);

  const [isBillingAddressFormActive, setIsBillingAddressFormActive] = useState(false);

  const { t } = useTranslation();

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      const detailsDrawerRoot = document.createElement('div');
      detailsDrawerRoot.className =
        'z-[11111] relative details-form-container [--drawer-content-wrapper-height:auto]';
      const billingAddressDrawerRoot = document.createElement('div');
      billingAddressDrawerRoot.className =
        'z-[11111] relative billing-address-form-container [--drawer-content-wrapper-height:auto]';

      document.body.appendChild(detailsDrawerRoot);
      document.body.appendChild(billingAddressDrawerRoot);

      return () => {
        document.body.removeChild(detailsDrawerRoot);
        document.body.removeChild(billingAddressDrawerRoot);
      };
    }
    return undefined;
  }, [isMobile]);

  useEffect(() => {
    setFormsValues(values);
  }, [values]);

  const hideBillingAddressForm = () => {
    setIsBillingAddressFormActive(false);
  };

  const showBillingAddressForm = () => {
    setIsBillingAddressFormActive(true);
  };

  const handleToBillingAddressBtnClick = (
    cardDetailsValues: PaymentCardDetailsFormValues,
  ) => {
    setFormsValues({ ...formsValues, ...cardDetailsValues });
    showBillingAddressForm();
  };

  const handleCloseDialog = () => {
    hideBillingAddressForm();
    onClose();
    if (!isMobile) {
      onDialogCloseAnimationEnd?.();
    }
  };

  const handleOnSubmit = async (
    billingAddressValues: PaymentCardBillingAddressFormValues,
  ) => {
    await onSubmit({ ...formsValues, ...billingAddressValues });
    hideBillingAddressForm();
  };

  const handleOnRemove = () => {
    if (handleId) {
      onRemove(handleId);
    }
  };

  const renderFormSubtitle = () =>
    !isLoading &&
    hasMissedDetails &&
    handleId && (
      <p className="text-blue-400 text-center mt-2 text-xl px-4">
        {t('We need more info about your payment card')}
      </p>
    );

  const cardDetailsFormHeader = (
    <>
      <PaymentCardDialogHeader
        title={handleId ? t('Edit payment card') : t('Add payment method')}
        onClose={handleCloseDialog}
      />
      {renderFormSubtitle()}
    </>
  );

  const cardBillingAddressFormHeader = (
    <>
      <PaymentCardDialogHeader
        title={handleId ? t('Edit billing address') : t('Enter billing address')}
        onClose={handleCloseDialog}
        onGoBack={hideBillingAddressForm}
      />
      {renderFormSubtitle()}
    </>
  );

  const removeCardButton = (
    <Button shape="text" color="red" onClick={handleOnRemove} className="my-3">
      {t('Remove this card')}
    </Button>
  );

  const paymentCardDetailsForm = (
    <Spinner isSpinning={Boolean(isLoading)} className="flex">
      <PaymentCardDetailsForm
        isEdit={Boolean(handleId)}
        shouldValidateOnMount={hasMissedDetails}
        dataTestPrefixId="" // TODO
        className="flex flex-col px-[25px] w-full h-full"
        submitBtnText={t('To billing address form')}
        values={formsValues}
        formHeader={cardDetailsFormHeader}
        formControls={handleId && removeCardButton}
        key={handleId ?? uuidv4()}
        onSubmit={handleToBillingAddressBtnClick}
        isLoading={isLoading}
      />
    </Spinner>
  );

  const paymentCardBillingAddressForm = (
    <Spinner
      isSpinning={Boolean(isLoading && isBillingAddressFormActive)}
      className="flex h-full"
    >
      <PaymentCardBillingAddressForm
        countryCode={countryCode}
        shouldValidateOnMount={hasMissedDetails}
        dataTestPrefixId="" // TODO
        className="flex flex-col px-[25px] h-full"
        submitBtnText={handleId ? t('Update card') : t('Save card')}
        isEdit={Boolean(handleId)}
        values={formsValues}
        formHeader={cardBillingAddressFormHeader}
        formControls={handleId && removeCardButton}
        key={handleId ?? uuidv4()}
        onSubmit={handleOnSubmit}
      />
    </Spinner>
  );

  const modalFormContent = isBillingAddressFormActive
    ? paymentCardBillingAddressForm
    : paymentCardDetailsForm;

  const commonDrawerProps = {
    onClose: handleCloseDialog,
    placement: 'bottom' as IPlacement,
    handler: false as const, // bug in the typings of the RcDrawer
  };

  return (
    <>
      <MediaMatcher isMobile>
        <Drawer
          {...commonDrawerProps}
          isOpen={isOpen}
          getContainer=".details-form-container"
          onCloseAnimationEnd={onDialogCloseAnimationEnd}
        >
          {paymentCardDetailsForm}
        </Drawer>
        <Drawer
          {...commonDrawerProps}
          isOpen={isOpen && isBillingAddressFormActive}
          getContainer=".billing-address-form-container"
        >
          {paymentCardBillingAddressForm}
        </Drawer>
      </MediaMatcher>
      <MediaMatcher isNotMobile>
        <Modal isOpen={isOpen} onRequestClose={handleCloseDialog} isClosable={false}>
          {modalFormContent}
        </Modal>
      </MediaMatcher>
    </>
  );
};

export default PaymentCardAllDetailsDialog;
