import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { GridRowProps } from '@root/interfaces/components/Grid';

const Row: FC<React.PropsWithChildren<GridRowProps>> = (props) => {
  const { children, className } = props;

  const classNames = normalizeStringCompound(['row', className]);

  return <div className={classNames}>{children}</div>;
};

export default Row;
