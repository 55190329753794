import { AxiosError } from 'axios';

import { dtcLoggingAxios } from '@services/axios';

import UserAccountClass from '@root/models/UserAccountClass';
import SessionClass from '@root/models/SessionClass';
import { DTC_LOGGING_ENDPOINTS } from '@services/api/v1/constants/dtc';

const cleanupLogParameter = (parameter: unknown): string => {
  const jsonStringRes = JSON.stringify(parameter);
  // JSON.stringify === undefined but typescript says it is always string
  if (jsonStringRes) {
    const newLineRegexp = /\\n|\\/g;
    const extraSpaceRegexp = /\s+/g;
    const res = jsonStringRes
      .replace(newLineRegexp, '')
      .replace(extraSpaceRegexp, ' ')
      .trim();
    return res;
  }

  return 'undefined';
};

const getUserSessionLogObject = (
  userInfo?: UserAccountClass | SessionClass | null,
): { [key: string]: string } => {
  const userSessionObj = JSON.parse(JSON.stringify(userInfo));
  const logUserSessionObj: { [key: string]: string } = {};
  if (userSessionObj && typeof userSessionObj === 'object') {
    Object.keys(userSessionObj).forEach((key) => {
      logUserSessionObj[`_sessionObj_${key}`] = cleanupLogParameter(userSessionObj[key]);
    });
  }
  return logUserSessionObj;
};

const extractRequestInfo = (error: Error | AxiosError) => {
  if ('config' in error) {
    return {
      url: error?.config?.url,
      data: error?.config?.data,
    };
  }

  return undefined;
};

// TODO: rework list of arguments to an object - arguments are hard to read

// eslint-disable-next-line import/prefer-default-export
export const logError = (
  error: Error | AxiosError,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorInfo: any,
  userInfo?: UserAccountClass | SessionClass | null,
) => {
  try {
    const userSessionLogObj = getUserSessionLogObject(userInfo);
    const requestInfo = extractRequestInfo(error);

    dtcLoggingAxios.post(DTC_LOGGING_ENDPOINTS.HTTP_GELF, {
      ...userSessionLogObj,
      version: process.env.REACT_APP_VERSION,
      host: window.location.hostname,
      short_message: `BRP: ${error?.name} ${error?.message}`,
      level: 3,
      _error_stack: cleanupLogParameter(error?.stack),
      _error_info: cleanupLogParameter(errorInfo),
      _request_info: cleanupLogParameter(requestInfo),
      _location_href: window.location.href,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('====> Logger: ', error, errorInfo, userInfo);
  }
};

export const logMessage = (message: string) => {
  try {
    dtcLoggingAxios.post(DTC_LOGGING_ENDPOINTS.HTTP_GELF, {
      version: process.env.REACT_APP_VERSION,
      host: window.location.hostname,
      short_message: `BRP: ${message}`,
      level: 3,
      _location_href: window.location.href,
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('====> Logger: ', message);
  }
};
