import { ValidatePromoResponse } from '@root/interfaces/contract/callingRechargePinless/ValidatePromo';

class CallingPromoClass {
  isValid: boolean;

  code?: string;

  type: ValidatePromoResponse['promo']['type'];

  percentage?: number;

  description?: string;

  amount: number;

  currency: string;

  currencySymbol: string;

  isAutoRechargePromo?: boolean;

  constructor({
    data,
    promoCode,
    isAutoRechargePromo,
  }: {
    data: ValidatePromoResponse;
    promoCode?: string;
    isAutoRechargePromo?: boolean;
  }) {
    this.isValid = data.is_promo_valid;

    this.code = promoCode;

    this.type = data.promo.type;
    this.percentage = data.promo.percentage;
    this.description = data.promo.description;

    this.amount = data.promo.amount.amount / data.promo.amount.currency_divisor;
    this.currency = data.promo.amount.currency;
    this.currencySymbol = data.promo.amount.currency_symbol;

    this.isAutoRechargePromo = isAutoRechargePromo;
  }
}

export default CallingPromoClass;
