import CountryCode from '@root/interfaces/CountryCode';
import { FormFieldProps } from '@root/interfaces/components/Form';

import { PickFromUnion } from '@root/types';

import { ZIP_CODE_MASK } from '@root/constants/inputMasks';

export const zipFieldTypeDefault = 'text';

type ZipFieldType = PickFromUnion<FormFieldProps['type'], 'maskedText' | 'text'>;
export const zipFieldTypeMap: {
  [key in CountryCode]?: ZipFieldType;
} = {
  CA: zipFieldTypeDefault,
  GB: zipFieldTypeDefault,
  US: 'maskedText',
};

export const zipFieldMaskDefault = '';

export const zipFieldMaskMap: { [key in CountryCode]?: string } = {
  CA: zipFieldMaskDefault,
  GB: zipFieldMaskDefault,
  US: ZIP_CODE_MASK,
};

export const zipFieldLabelDefault = 'ZIP/Postal Code'; // t('ZIP/Postal Code')

export const zipFieldLableMap: { [key in CountryCode]?: string } = {
  CA: zipFieldLabelDefault,
  GB: 'Post Code', // t('Post Code')
  US: 'ZIP code', // t('ZIP Code')
};

export const zipFiledInputModeDefault = 'text';

export const zipFiledInputModeMap: {
  [key in CountryCode]?: 'text' | 'numeric';
} = {
  CA: zipFiledInputModeDefault,
  GB: zipFiledInputModeDefault,
  US: 'numeric',
};

export const stateFieldLabelDefault = 'State/Province/Region'; // t('State/Province/Region');

export const stateFieldLabelMap: { [key in CountryCode]?: string } = {
  CA: stateFieldLabelDefault,
  US: 'State', // t('State')
};

export const zipFieldAutoCapitalize: { [key in CountryCode]?: boolean } = {
  GB: true,
};

export const COUNTRIES_WITHOUT_STATE_PROVINCE_VALIDATION: CountryCode[] = ['GB'];
export const ZIP_POSTAL_MAX_LENGTH = 8;
