/* eslint-disable @typescript-eslint/no-explicit-any */

class LocalStoragePolyfill {
  private data: Dictionary<any> = {};

  setItem(id: string, val: any) {
    this.data[id] = String(val);
    return this.data[id];
  }

  getItem(id: string) {
    // eslint-disable-next-line no-prototype-builtins
    return this.data.hasOwnProperty(id) ? this.data[id] : undefined;
  }

  removeItem(id: string) {
    return delete this.data[id];
  }

  clear() {
    this.data = {};
  }
}

const storageInterface =
  window.localStorage || window.sessionStorage || new LocalStoragePolyfill();

export default storageInterface;
