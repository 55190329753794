import React from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  children: React.ReactNode;
  className?: string;
}

const HeaderContainer = (props: Props) => {
  const { children, className } = props;

  const classNames = normalizeStringCompound([
    'bg-red-400 fixed top-0 left-0 z-110 w-full print:hidden',
    className,
  ]);

  return <header className={classNames}>{children}</header>;
};

export default HeaderContainer;
