import React, { FC } from 'react';
import { HashRouter, useLocation, useHistory } from 'react-router-dom';

import { Modal } from '@components/common';

import ROUTE_PATHS from '@root/routes/paths';

import Login from '../Login';

import ModalHeader from './components/ModalHeader';

import './styles.scss';

const LoginModal: FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory();
  const location = useLocation();

  const handleModalCloseClick = () => {
    history.push(location.pathname);
  };

  const headerComponent = (
    <ModalHeader
      containerClassName="login-modal-header"
      onModalClose={handleModalCloseClick}
    />
  );

  const isOpen = location.hash.includes(ROUTE_PATHS.LOGIN_MODAL);

  return (
    <Modal isOpen={isOpen}>
      <HashRouter basename={ROUTE_PATHS.LOGIN_MODAL.split('#')[1]}>
        <div className="login-modal">
          <Login onComplete={handleModalCloseClick} headerComponent={headerComponent} />
        </div>
      </HashRouter>
    </Modal>
  );
};

export default LoginModal;
