import React, { FC } from 'react';
import { FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';

import Yup from '@utils/validation/yup';

import { Form } from '@components/common';

import { FIELD_LABELS } from './constants';
import {
  MIN_FIRST_LAST_MIDDLE_MOTHERS_NAME_LENGTH,
  MAX_FIRST_LAST_MIDDLE_MOTHERS_NAME_LENGTH,
} from '../constants';

export interface ChangeNameFormParams {
  firstName: string;
  lastName: string;
}

interface Props {
  onSubmit: (values: ChangeNameFormParams) => void;
  firstName?: string;
  lastName?: string;
  dataTestPrefix?: string;
}

const ChangeNameForm: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onSubmit, firstName = '', lastName = '', dataTestPrefix } = props;
  const { t } = useTranslation();

  const DATA_TEST_ID_PREFIX = `${dataTestPrefix}-change-name`;

  const formConfig: FormikConfig<ChangeNameFormParams> = {
    enableReinitialize: true,
    initialValues: {
      firstName,
      lastName,
    },
    onSubmit,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .letters()
        .label(FIELD_LABELS.firstName.label)
        .min(MIN_FIRST_LAST_MIDDLE_MOTHERS_NAME_LENGTH)
        .max(MAX_FIRST_LAST_MIDDLE_MOTHERS_NAME_LENGTH)
        .required(),
      lastName: Yup.string()
        .letters()
        .label(FIELD_LABELS.lastName.label)
        .min(MIN_FIRST_LAST_MIDDLE_MOTHERS_NAME_LENGTH)
        .max(MAX_FIRST_LAST_MIDDLE_MOTHERS_NAME_LENGTH)
        .required(),
    }),
  };

  return (
    <Form config={formConfig}>
      <Form.Field
        type="text"
        name={FIELD_LABELS.firstName.name}
        label={FIELD_LABELS.firstName.label}
        className="mb-4"
        required
        dataTestId={`${DATA_TEST_ID_PREFIX}-first-name-input`}
      />
      <Form.Field
        type="text"
        name={FIELD_LABELS.lastName.name}
        label={FIELD_LABELS.lastName.label}
        className="mb-4"
        required
        dataTestId={`${DATA_TEST_ID_PREFIX}-last-name-input`}
      />
      <div className="mt-10 text-center">
        <Form.Button
          shape="pill"
          color="blue"
          isOutlined
          dataTestId={`${DATA_TEST_ID_PREFIX}-submit-btn`}
        >
          {t('Save changes')}
        </Form.Button>
      </div>
    </Form>
  );
};

export default ChangeNameForm;
