import { makeAutoObservable } from 'mobx';
import { AxiosError } from 'axios';

import { logOut, saveSessionDataToStorage } from '@services/auth';
import {
  createSessionWithSmsToken,
  fetchSupportedCountries,
  requestSmsCodeToPhoneNumber,
  verifyPhoneWithSmsCode,
} from '@services/api/v1/auth';

import CountryCode from '@root/interfaces/CountryCode';

import SessionClass from '@root/models/SessionClass';

interface LoginResult {
  success?: boolean;
}

class LoginStore {
  supportedCountries: CountryCode[] = [];

  defaultCountry: CountryCode = 'US';

  dtcEmail?: string;

  postLoginRedirectUrl?: string;

  migrationEmail?: string; // in case of email selection we preserve typed email but verify selected one

  // initial phone number could be set there
  phoneNumber = '';

  smsToken = '';

  phoneToken?: string;

  error: AxiosError | null = null;

  isLoadingCountries = false;

  isLoggingIn = false;

  savedSession?: SessionClass;

  constructor() {
    makeAutoObservable(this);
  }

  setPostLoginRedirectUrl = (url: string) => {
    this.postLoginRedirectUrl = url;
  };

  get isLoading() {
    return this.isLoadingCountries || this.isLoggingIn;
  }

  setLoggingIn = (value: boolean) => {
    this.isLoggingIn = value;
  };

  setLoadingCountries = (value = true) => {
    this.isLoadingCountries = value;
  };

  setDefaultCountry = (countryCode: CountryCode) => {
    this.defaultCountry = countryCode;
  };

  setError = (error: AxiosError) => {
    this.error = error || null;
  };

  resetError = () => {
    this.error = null;
  };

  getSupportedCountries = async () => {
    this.resetError();
    this.setLoadingCountries();
    try {
      this.supportedCountries = await fetchSupportedCountries();
    } catch (err) {
      this.setError(err);
    } finally {
      this.setLoadingCountries(false);
    }
  };

  submitPhoneNumber = async (phoneNumber?: string) => {
    this.resetError();

    if (phoneNumber) {
      this.phoneNumber = (phoneNumber[0] === '+'
        ? phoneNumber
        : `+${phoneNumber}`
      ).replace('+11', '+1'); // TODO: hotfix AND workaround for BRP-1016 as it was required to be implemented (strip out extra 1 country code for US only)
    }

    this.setLoggingIn(true);
    try {
      await requestSmsCodeToPhoneNumber(this.phoneNumber);
    } catch (err) {
      this.setError(err);
      throw err;
    } finally {
      this.setLoggingIn(false);
    }
  };

  setSavedSession = (session: SessionClass) => {
    this.savedSession = session;
  };

  applySession = () => {
    if (this.savedSession) {
      saveSessionDataToStorage(this.savedSession);
    }
  };

  loginSimpleWithoutMigration = async (
    smsCode: string,
  ): Promise<LoginResult | undefined> => {
    this.resetError();
    this.setLoggingIn(true);

    try {
      this.smsToken = (
        await verifyPhoneWithSmsCode({
          phoneNumber: this.phoneNumber,
          verificationCode: smsCode,
        })
      ).token;

      const phoneSession = await createSessionWithSmsToken(
        this.phoneNumber,
        this.smsToken,
      );
      this.setSavedSession(phoneSession);

      this.phoneToken = phoneSession.accessToken;

      return { success: true };
    } catch (err) {
      this.setError(err);
      return undefined;
    } finally {
      this.setLoggingIn(false);
    }
  };

  sessionLogOut = async () => {
    await logOut();
  };
}

export default LoginStore;
