import React from 'react';
import { useTranslation } from 'react-i18next';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';

import { ReactComponent as ImtuReceiptSuccessIcon } from '@src/static/assets/images/icons/imtu-receipt-success.svg';
import { ReactComponent as ImtuReceiptFailedIcon } from '@src/static/assets/images/icons/imtu-receipt-failed.svg';
import { ReactComponent as ImtuReceiptQueuedIcon } from '@src/static/assets/images/icons/imtu-receipt-queued.svg';
import { getCallingApiErrorMessage } from '@services/axios/helpers';

interface Props {
  txnStatus: ImtuStatus;
  txnErrorCode?: string | null;
}

const Status: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { txnStatus, txnErrorCode } = props;

  const { t } = useTranslation();

  const statusTextMap: {
    [statusKey in ImtuStatus]: {
      [messageKey in 'title' | 'subTitle' | 'icon']: React.ReactNode;
    };
  } = {
    success: {
      title: t('success'),
      icon: <ImtuReceiptSuccessIcon />,
      subTitle: t('top-up sent'),
    },
    queued: {
      title: t('order queued'),
      icon: <ImtuReceiptQueuedIcon />,
      subTitle: t('we are processing your order now'),
    },
    failed: {
      title: (
        <>
          {`${t('sorry')}!`}
          <br />
          {t('your order is unsuccessful')}
        </>
      ),
      icon: <ImtuReceiptFailedIcon />,
      subTitle: txnErrorCode ? t(getCallingApiErrorMessage(txnErrorCode)) : '',
    },
  };

  return (
    <div className="flex flex-col items-center text-center">
      <div className="m-4">{statusTextMap[txnStatus].icon}</div>
      <div className="text-4xl font-bold capitalize">
        {statusTextMap[txnStatus].title}
      </div>
      <div className="text-2xl font-medium capitalize">
        {statusTextMap[txnStatus].subTitle}
      </div>
    </div>
  );
};

export default Status;
