interface UserIdentificationParams {
  userId: string;
  phoneNumber: string;
  email: string;
}

enum EVENTS {
  ACTIVATION = 'Activation',
  MT_TRANSACTION = 'MoneyTransfer',
  IMTU_TRANSACTION = 'IMTUTransfer',
  CALLING_RECHARGE = 'PinlessRecharge',
}

const setUserId = (params: UserIdentificationParams) => {
  window.kochava?.identify({
    customer_id: params.userId,
    customer_phone: params.phoneNumber,
    customer_email: params.email,
  });
};

const trackSignUp = (userId: string) => {
  window.kochava?.activity(EVENTS.ACTIVATION, {
    customer_id: userId,
  });
};

// we don't have specific requirements on what to track
const trackMtPurchase = (args: { [key: string]: unknown }) => {
  window.kochava?.activity(EVENTS.MT_TRANSACTION, {
    ...args,
  });
};

// we don't have specific requirements on what to track
const trackImtuPurchase = (args: { [key: string]: unknown }) => {
  window.kochava?.activity(EVENTS.IMTU_TRANSACTION, {
    ...args,
  });
};

// we don't have specific requirements on what to track
const trackCallingPurchase = (args: { [key: string]: unknown }) => {
  window.kochava?.activity(EVENTS.CALLING_RECHARGE, {
    ...args,
  });
};

export default {
  setUserId,
  trackSignUp,
  trackMtPurchase,
  trackImtuPurchase,
  trackCallingPurchase,
};
