export const ASSETS_URL = {
  COUNTRY_IMAGES: `${process.env.REACT_APP_ASSETS_URL_BOSS_MEDIA}/br-com/country-images`,
  COUNTRY_FLAGS: 'https://cdn.bossrevolution.com/dtc-new/img/flags/SVG',
  CDN_BR: 'https://cdn.bossrevolution.com',
  CDN_ACMS: 'https://cdn.bossrevolution.com/cms-content',
};
export const SERVICES_URL = {
  // gen card security token
  CARDSEC: process.env.REACT_APP_CARDSEC_API_URL,
};
