import React from 'react';

import RcDrawer from 'rc-drawer';

import { IDrawerProps } from '@nodeModules/rc-drawer/lib/IDrawerPropTypes';

import { normalizeStringCompound } from '@utils/string';

import './styles.scss';

interface Props extends Omit<IDrawerProps, 'open'> {
  style?: React.CSSProperties;
  isOpen?: boolean;
  onOpenAnimationEnd?(): void;
  onCloseAnimationEnd?(): void;
}

const Drawer = (props: Props) => {
  const {
    children,
    style,
    className,
    isOpen,
    contentWrapperStyle,
    onOpenAnimationEnd,
    onCloseAnimationEnd,
    ...rest
  } = props;

  const classNames = normalizeStringCompound([className, 'dtc-drawer']);

  const handleAnimationEnd = () => {
    if (isOpen) {
      onOpenAnimationEnd?.();
    } else {
      onCloseAnimationEnd?.();
    }
  };

  return (
    <RcDrawer
      {...rest}
      style={style}
      open={isOpen}
      className={classNames}
      contentWrapperStyle={contentWrapperStyle}
      afterVisibleChange={handleAnimationEnd}
    >
      {children}
    </RcDrawer>
  );
};

export default Drawer;
