import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, MediaMatcher, Icon } from '@components/common';

import { TypographyLinkProps } from '@root/interfaces/components/typography/Link';

export interface LinkOptions extends Pick<TypographyLinkProps, 'to' | 'target'> {
  text: string;
  isNative?: boolean;
  actionName?: string;
}

interface Props {
  title: string;
  list: LinkOptions[];
  listActions?: Record<string, () => void>;
}

const Footer: FC<React.PropsWithChildren<Props>> = (props) => {
  const { title, list, listActions } = props;

  const { t } = useTranslation();

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleTitleClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="mb-12 md:mb-20">
      <MediaMatcher isNotMobile>
        <Typography.Title level={5} className="mb-10">
          {title}
        </Typography.Title>
      </MediaMatcher>

      <MediaMatcher isMobile>
        <button
          onClick={handleTitleClick}
          className="flex w-full items-center justify-between mb-3"
        >
          <Typography.Title level={6}>{title}</Typography.Title>
          {/* TODO: Plus and Cross svg icons have different sizes */}
          {isCollapsed ? (
            <Icon name="Plus" className="text-2xl" />
          ) : (
            <Icon name="Cross" className="text-lg pr-1" />
          )}
        </button>
      </MediaMatcher>

      <ul className={isCollapsed ? 'hidden md:block' : 'block'}>
        {list.map((item) => {
          return (
            <li key={item.text} className="mb-3 flex">
              {item.actionName ? (
                <button
                  key={item.text}
                  className="text-base text-gray-900 w-full text-left hover:text-red-400 transition"
                  onClick={
                    listActions && item.actionName
                      ? listActions[item.actionName]
                      : undefined
                  }
                >
                  {t(item.text)}
                </button>
              ) : (
                <Typography.Link
                  to={item.to}
                  isNative={item.isNative}
                  className="text-base text-gray-900 w-full"
                  target={item.target}
                >
                  {t(item.text)}
                </Typography.Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Footer;
