import { makeAutoObservable } from '@nodeModules/mobx';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

class Store {
  carrier?: ImtuCarrierClass;

  constructor({ carrier }: { carrier?: ImtuCarrierClass }) {
    makeAutoObservable(this);

    this.carrier = carrier;
  }

  setCarrier(carrier: ImtuCarrierClass) {
    this.carrier = carrier;
  }
}

export default Store;
