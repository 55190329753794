import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from '@nodeModules/react-i18next';

import { Button, Modal } from '@components/common';
import { useIsMobile } from '@components/common/MediaQueryMatchers';

import ROUTE_PATHS from '@root/routes/paths';

import { normalizeStringCompound } from '@utils/string';

import Content from './components/Content';

import './styles.scss';

const MtModal: FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory();
  const location = useLocation();

  const isOpen = location.hash.includes(ROUTE_PATHS.MT_MODAL);
  const [isExpanded, setIsExpanded] = useState(false);
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const handleModalCloseClick = () => {
    history.push(location.pathname);
  };

  const handleExpandButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  const containerClassNames = normalizeStringCompound([
    'mt-modal--content',
    isExpanded ? 'expanded' : '',
  ]);

  const buttonText = isExpanded ? t('Show less') : t('Show more');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalCloseClick}
      wrapperClassNames="mt-modal--wrapper"
    >
      <Content containerClassNames={containerClassNames} />
      <Button
        shape="text"
        className="font-medium"
        size={isMobile ? 'lg' : 'md'}
        onClick={handleExpandButtonClick}
      >
        {buttonText}
      </Button>
    </Modal>
  );
};

export default MtModal;
