import React from 'react';
import { useTranslation } from 'react-i18next';

import OneScreenTemplate from '@components/templates/OneScreenTemplate';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <OneScreenTemplate>
      <div style={{ fontSize: '50px', fontWeight: 'bold' }} className="text-center">
        {`404 ${t('Page not found')}`}
      </div>
    </OneScreenTemplate>
  );
};

export default NotFoundPage;
