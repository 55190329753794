/* eslint-disable import/prefer-default-export */
export const FIELD_LABELS = {
  firstName: {
    name: 'firstName',
    label: 'First Name', // t('First Name')
  },
  lastName: {
    name: 'lastName',
    label: 'Last Name', // t('Last Name')
  },
};
