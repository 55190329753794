export function isString(x: unknown): x is string {
  return typeof x === 'string';
}

export function isNumber(x: unknown): x is number {
  return typeof x === 'number' && Number.isFinite(x); // isFinite: additional check against NaN, Infinity and -Infinity
}

export const isNull = (x: unknown): x is null => {
  return x === null;
};

export const isHtmlElement = (x: unknown): x is Element => {
  return x instanceof Element;
};

export function isObjKey<T>(key: PropertyKey, obj: T): key is keyof T {
  return key in obj;
}
