import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import IconProps from '@root/interfaces/components/Icon';

import * as Icons from '../Icons';

// solution how to create icon font without external services
// https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4

const Icon: FC<React.PropsWithChildren<IconProps>> = (props) => {
  const {
    name,
    className,
    width,
    height,
    onClick,
    disabled,
    arialLabel,
    dataTestId,
    title,
  } = props;

  const SVG = Icons[name];

  const svgProps = {
    width,
    height,
  };

  const svgComponent = <SVG {...svgProps} />;

  const classNames = normalizeStringCompound([
    'inline-flex items-center normal-case',
    className,
  ]);

  return onClick ? (
    <button
      arial-label={arialLabel}
      className={classNames}
      onClick={onClick}
      disabled={disabled}
      type="button"
      data-test-id={dataTestId}
      title={title}
    >
      {svgComponent}
    </button>
  ) : (
    <span role="img" arial-label={arialLabel} className={classNames}>
      {svgComponent}
    </span>
  );
};

Icon.defaultProps = {
  width: '1em',
  height: '1em',
};

export default Icon;
