import React, { FC, ReactNode } from 'react';

import ROUTE_PATHS from '@root/routes/paths';

import { Logo, Grid, Typography, Icon } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

import './styles.scss';

interface Props {
  title?: string;
  middleNode?: ReactNode;
  buttonsNode?: ReactNode;
  backButtonNode?: ReactNode;
  className?: string;
  isTitleAlsoBackLink?: boolean;
  handleBack?(): void;
  hasBossLogoLink?: boolean;
  hasFixedPosition?: boolean;
}

/**
 * This is the common title for all the pages.
 * if you got the new design which have a different style for a title
 * then have a talk with designer on how to handle it properly.
 * Make him know that this is going to be reused across the app.
 *
 * @param props
 * @constructor
 */
const PageTitle: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    title,
    middleNode,
    buttonsNode,
    className,
    handleBack,
    hasBossLogoLink,
    hasFixedPosition,
  } = props;

  const cmpClassNames = 'h-14 print:hidden';

  const contentWrapperClassNames = normalizeStringCompound([
    'page-sub-title capitalize h-14 flex items-center border-t border-b border-gray-300 w-full',
    'bg-white backdrop-filter backdrop-blur-sm bg-opacity-30 firefox:bg-opacity-100',
    'print:hidden',
    hasFixedPosition ? 'fixed z-100 bg-white' : undefined,
    className,
  ]);

  return (
    <div className={cmpClassNames}>
      <div className={contentWrapperClassNames}>
        <Grid.Container>
          <Grid.Row className="justify-between items-center">
            <Grid.Col span={4}>
              <div className="flex items-center">
                {handleBack && (
                  <button
                    className="flex items-center justify-center rounded-full p-1 pl-0 md:mr-1 relative hover:text-blue-400"
                    onClick={handleBack}
                  >
                    <Icon name="ChevronLeftAlt" />
                  </button>
                )}
                {hasBossLogoLink && (
                  <Typography.Link to={ROUTE_PATHS.ACCOUNT}>
                    <Logo className="w-24 mr-5" />
                  </Typography.Link>
                )}
                {title && (
                  <Typography.Title className="whitespace-nowrap leading-none" level={5}>
                    {title}
                  </Typography.Title>
                )}
              </div>
            </Grid.Col>
            <Grid.Col span={4} className="flex justify-end before:border-white">
              {middleNode}
            </Grid.Col>
            <Grid.Col span={4} className="flex justify-end before:border-white gap-4">
              {buttonsNode}
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </div>
    </div>
  );
};

export default PageTitle;
