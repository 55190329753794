import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikTouched, setNestedObjectValues } from 'formik';

import Yup from '@utils/validation/yup';
import { normalizeStringCompound } from '@utils/string';

import FormProps from '@root/interfaces/Form';
import { AutoCapitalizeValues, FormConfig } from '@root/interfaces/components/Form';

import { Form } from '..';
import {
  stateFieldLabelMap,
  zipFieldLableMap,
  zipFieldMaskMap,
  zipFieldTypeMap,
  zipFiledInputModeMap,
  COUNTRIES_WITHOUT_STATE_PROVINCE_VALIDATION,
  ZIP_POSTAL_MAX_LENGTH,
  zipFieldAutoCapitalize,
  stateFieldLabelDefault,
  zipFieldTypeDefault,
  zipFieldMaskDefault,
  zipFieldLabelDefault,
} from './constants';
import { getZipPostalCodeValidationFn, getStateProvinceValidationFn } from './helpers';

export interface PaymentCardBillingAddressFormValues {
  street1: string;
  street2: string;
  state: string;
  zip: string;
  city: string;
}

type InitiallyTouched = FormikTouched<PaymentCardBillingAddressFormValues> | undefined;

const PaymentCardBillingAddressForm = (
  props: FormProps<PaymentCardBillingAddressFormValues>,
) => {
  const {
    shouldValidateOnMount,
    dataTestPrefixId,
    submitBtnText,
    className,
    values,
    formControls,
    formHeader,
    onSubmit,
    countryCode,
  } = props;

  const { t } = useTranslation();

  const validationSchemaShape = {
    city: Yup.string().base().required(),
    street1: Yup.string().base().required(),
    street2: Yup.string().base(),
    ...(COUNTRIES_WITHOUT_STATE_PROVINCE_VALIDATION.includes(countryCode)
      ? {}
      : { state: getStateProvinceValidationFn(countryCode) }),
    zip: getZipPostalCodeValidationFn(countryCode),
  };

  const handleOnSubmit = (
    formValues: PaymentCardBillingAddressFormValues,
    actions?: FormikHelpers<PaymentCardBillingAddressFormValues>,
  ) => {
    onSubmit(formValues, actions);
  };

  const initialTouched: InitiallyTouched = shouldValidateOnMount
    ? setNestedObjectValues(values, true)
    : undefined;

  const formConfig: FormConfig<PaymentCardBillingAddressFormValues> = {
    enableReinitialize: true,
    validateOnMount: shouldValidateOnMount,
    validationSchema: Yup.object().shape(validationSchemaShape),
    initialValues: values,
    initialTouched,
    onSubmit: handleOnSubmit,
  };

  const formSubmitBtn = (
    <Form.Button
      shape="pill"
      size="lg"
      className="mt-auto w-full"
      dataTestId={`${dataTestPrefixId}-save-card-btn`}
    >
      {submitBtnText}
    </Form.Button>
  );

  const formControlsSection = (
    <div className="py-[5px] text-center">
      {formSubmitBtn}
      {formControls ?? null}
    </div>
  );

  return (
    <Form config={formConfig} className={normalizeStringCompound([className, 'w-full'])}>
      {formHeader && formHeader}
      <div className="overflow-y-auto py-[24px]">
        <Form.Field
          type="text"
          name="street1"
          label={t('Street 1')}
          required
          dataTestId={`${dataTestPrefixId}-street1-input`}
        />
        <Form.Field
          type="text"
          name="street2"
          label={t('Street 2')}
          dataTestId={`${dataTestPrefixId}-street2-input`}
        />
        <Form.Field
          type="text"
          name="city"
          label={t('City')}
          required
          dataTestId={`${dataTestPrefixId}-city-input`}
        />
        <div className="flex">
          <Form.Field
            type="maskedText"
            mask="aa"
            name="state"
            label={t(stateFieldLabelMap[countryCode] || stateFieldLabelDefault)}
            required
            dataTestId={`${dataTestPrefixId}-state-input`}
            className="flex-1 not-last-child:mr-5"
          />

          <Form.Field
            type={zipFieldTypeMap[countryCode] || zipFieldTypeDefault}
            mask={zipFieldMaskMap[countryCode] || zipFieldMaskDefault}
            name="zip"
            label={t(zipFieldLableMap[countryCode] || zipFieldLabelDefault)}
            required
            dataTestId={`${dataTestPrefixId}-zip-input`}
            className="flex-1 not-last-child:mr-5"
            inputMode={zipFiledInputModeMap[countryCode]}
            maxlength={ZIP_POSTAL_MAX_LENGTH}
            {...(zipFieldAutoCapitalize[countryCode] && {
              autoCapitalize: AutoCapitalizeValues.Characters,
            })}
          />
        </div>
      </div>
      {formControlsSection}
    </Form>
  );
};

export default PaymentCardBillingAddressForm;
