import { TFunction } from 'i18next';

import PaymentCardClass from '@root/models/PaymentCardClass';

import { PAYMENT_CARD_NAME } from '@root/constants/paymentCards/paymentCardIssuers';

import { PaymentCardIssuer } from '@root/interfaces/components/PaymentCard';

import { ReactComponent as VisaImage } from '@src/static/assets/images/visa.svg';
import { ReactComponent as MasterCardImage } from '@src/static/assets/images/mastercard.svg';
import { ReactComponent as AmExImage } from '@src/static/assets/images/amex.svg';

import { Button } from '../..';

interface Props {
  onConfirm(): void;
  onDecline(): void;
  cardInfo: PaymentCardClass | null;
  t: TFunction;
}

const issuerImage: { [key in PaymentCardIssuer]: React.ReactNode } = {
  MC: <MasterCardImage />,
  V: <VisaImage />,
  AE: <AmExImage />,
  D: null,
};

const PaymentCardRemoveConfirmUi = (props: Props) => {
  const { onConfirm, onDecline, cardInfo, t } = props;

  return (
    <div className="text-center mb-4">
      <div className="flex justify-center -mt-4">
        <div className="w-16">
          {cardInfo?.paySource && issuerImage[cardInfo?.paySource]}
        </div>
      </div>
      <div className="mb-10 text-2xl">
        <div className="font-bold my-8">
          {`${t('You are about to remove the following card')}:`}
        </div>
        <div className="font-bold">
          {cardInfo?.paySource && PAYMENT_CARD_NAME[cardInfo?.paySource]}
        </div>
        <div data-recording-sensitive>{cardInfo?.maskedNumber}</div>
        <div data-recording-sensitive>{cardInfo?.accountHolder}</div>
      </div>
      <div>
        <Button shape="text" color="red" size="lg" onClick={onConfirm}>
          {t('Yes, remove it')}
        </Button>
      </div>
      <Button shape="pill" size="lg" color="blue" onClick={onDecline}>
        {t('No, keep it in my profile')}
      </Button>
    </div>
  );
};

export default PaymentCardRemoveConfirmUi;
