import React, { ReactElement } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import PATHS from '@root/routes/paths';

import { getIsLoggedInStatusFromStorage } from '@services/auth';

export default ({ component: Component, ...rest }: RouteProps): ReactElement => {
  const shouldRedirect = getIsLoggedInStatusFromStorage();
  return shouldRedirect ? (
    <Redirect to={PATHS.BASE} />
  ) : (
    <Route {...rest} component={Component} />
  );
};
