import CountryCode from '@root/interfaces/CountryCode';
import ImtuPromoClass from '@root/models/ImtuPromoClass';

// eslint-disable-next-line import/prefer-default-export
export const getFilteredPromos = ({
  promos,
  countryCode,
  carrierCode,
}: {
  promos: ImtuPromoClass[];
  countryCode: CountryCode;
  carrierCode?: string;
}) => {
  return promos.filter((item) => {
    if (carrierCode) {
      return item.countryCode === countryCode && item.carrier.code === carrierCode;
    }

    return item.countryCode === countryCode;
  });
};
