import React from 'react';

import { LanguageOption } from '@root/interfaces/components/LanguageSwitcher';
import { Languages } from '@root/interfaces/Languages';

interface Props {
  options: LanguageOption[];
  selectedLanguage: Languages;
  onSelect(option: LanguageOption): void;
  renderItem(
    option: LanguageOption,
    onSelect: (value: LanguageOption) => void,
    currentLanguage: Languages,
  ): JSX.Element;
}

const LanguagesList = (props: Props) => {
  const { options, onSelect, selectedLanguage, renderItem } = props;

  return (
    <ul className="relative p-4 bg-white">
      {options.map((option) =>
        React.cloneElement(renderItem(option, onSelect, selectedLanguage), {
          key: option.text,
        }),
      )}
    </ul>
  );
};

export default LanguagesList;
