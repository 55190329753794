import React, { RefObject, useMemo, useRef, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useTranslation } from 'react-i18next';
import { showToast } from '@services/toasts';
import { format } from 'date-fns';

import usePaymentCards from '@root/hooks/usePaymentCards';
import useConfirmationDialog from '@root/hooks/useConfirmationDialog';

import { PaymentCardSelect } from '@components/common';

import { SelectRef } from '@components/common/Select';

import delay from '@utils/delay';

import CountryCode from '@root/interfaces/CountryCode';
import {
  PaymentCardAllDetailsDialogProps,
  PaymentCardReducedDetailsDialogProps,
} from '@root/interfaces/components/PaymentCardDialog';
import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';
import { PaymentCardTypeShort } from '@root/constants/moneyTransfer/paymentCards';

import PaymentCardRemoveConfirmUi from './PaymentCardRemoveConfirmUi';

import { PaymentCardBillingAddressFormValues } from '../PaymentCardBillingAddressForm';
import { PaymentCardReducedDetailsFormValues } from '../PaymentCardReducedDetailsForm';
import { PaymentCardDetailsFormValues } from '../PaymentCardDetailsForm';
import UnsupportedCardDialogContent from './UnsupportedCardDialogContent';

import { checkIsSupportedCard } from './helpers';

interface UsePaymentCardSelectParams {
  clickOutsideIgnoreElemRefs?: RefObject<HTMLDivElement>[];
  disabledPaymentMethods?: PaymentCardTypeShort[];
  disabledPaymentCardsIssuers?: PaymentCardIssuerKeys[];
  isUsingCardsWithoutBillingAllowed?: boolean;
  handleId?: string;
  userCountryOfOrigin: CountryCode;
}

const SHOW_TOAST_DELAY = 600;

const usePaymentCardSelect = (params: UsePaymentCardSelectParams) => {
  const {
    disabledPaymentMethods,
    disabledPaymentCardsIssuers,
    isUsingCardsWithoutBillingAllowed = false,
    handleId,
    userCountryOfOrigin,
  } = params;

  const [isPaymentCardDialogOpen, setIsPaymentCardDialogOpen] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState<string | undefined>();
  const [prevInitialHandleId, setPrevInitialHandleId] = useState<string | undefined>();
  const [unsupportedCardId, setUnsupportedCardId] = useState<string | undefined>();

  const selectRef = useRef<SelectRef>(null);

  const { t } = useTranslation();

  const {
    isLoading,
    hasPaymentCards,
    paymentCards,
    editableCard,
    addCard,
    updateCard,
    removeCard,
    setEditableCardId,
    editableCardId,
  } = usePaymentCards();

  const paymentCardsWithSupportedInfo = paymentCards?.map((card) => {
    return {
      data: card,
      isSupported: checkIsSupportedCard({
        card,
        disabledPaymentCardsIssuers,
        disabledPaymentMethods,
      }),
    };
  });

  const [prevPaymentCards, setPrevPaymentCards] = useState(paymentCards);

  if (prevPaymentCards !== paymentCards) {
    setPrevPaymentCards(paymentCards);
    // preselect card if there is only one option
    if (paymentCards && (paymentCards.length > 1 || paymentCards.length === 0)) {
      setSelectedCardId(undefined);
    }
    if (
      paymentCards?.length === 1 &&
      paymentCardsWithSupportedInfo?.[0].isSupported &&
      !paymentCards[0].isExpired &&
      paymentCards[0].hasBillingAddress
    ) {
      setSelectedCardId(paymentCards[0].id);
    }
  }

  if (prevInitialHandleId !== handleId && paymentCards) {
    setPrevInitialHandleId(handleId);
    const lastUsedCard = paymentCards?.find(
      (card) => !card.isExpired && card.id === handleId,
    );
    // set last used card id as selected
    if (lastUsedCard) {
      setSelectedCardId(handleId);
    }
  }

  const showPaymentCardDialog = () => {
    setIsPaymentCardDialogOpen(true);
  };

  const hidePaymentCardDialog = () => {
    setIsPaymentCardDialogOpen(false);
  };

  const openSelect = () => {
    selectRef?.current?.setSelectIsOpen(true);
  };

  const closeSelect = () => {
    selectRef?.current?.setSelectIsOpen(false);
  };

  const handleDialogClose = () => {
    hidePaymentCardDialog();
  };

  const confirmationDialogContent = (onConfirm: () => void, onDecline: () => void) => (
    <PaymentCardRemoveConfirmUi
      t={t}
      cardInfo={editableCard ?? null}
      onConfirm={onConfirm}
      onDecline={onDecline}
    />
  );

  const unsupportedCardDialogContent = (onConfirm: () => void) => {
    const unsupportedCard = paymentCards?.find((c) => c.id === unsupportedCardId);

    return (
      <UnsupportedCardDialogContent
        {...{
          disabledPaymentCardsIssuers,
          disabledPaymentMethods,
          unsupportedCard,
          onConfirm,
          t,
        }}
      />
    );
  };

  const {
    open: openConfirmationDialog,
    close: closeConfirmationDialog,
  } = useConfirmationDialog({ content: confirmationDialogContent });

  const {
    open: openNotSupportedModal,
    close: closeNotSupportedModal,
    isDialogMounted: isUnsupportedDialogMounted,
  } = useConfirmationDialog({
    content: unsupportedCardDialogContent,
  });

  useAsyncEffect(async () => {
    if (unsupportedCardId) {
      await openNotSupportedModal();
      setUnsupportedCardId(undefined);
      closeNotSupportedModal();
    }
  }, [unsupportedCardId]);

  const handleOnCardAdd = () => {
    showPaymentCardDialog();
  };

  const handleEditCardClick = (id: string) => {
    setEditableCardId(id);
    showPaymentCardDialog();
  };

  const handleEditCard = (id: string) => {
    openSelect(); // force select to be opened
    setEditableCardId(id);
    showPaymentCardDialog();
  };

  const handleChangeCardId = (id: string) => {
    // here we handle a case when a card needs info for using in MT
    const card = paymentCardsWithSupportedInfo?.find((c) => c.data.id === id);

    if (card) {
      const isValidCard =
        isUsingCardsWithoutBillingAllowed ||
        (card.data.hasCardHolderName && card.data.hasBillingAddress);

      if (isValidCard && card.isSupported) {
        setSelectedCardId(id);
        closeSelect();
      } else {
        if (!card.isSupported) {
          setUnsupportedCardId(id);
          return undefined;
        }
        openSelect(); // force select to be opened
        setEditableCardId(id);
        showPaymentCardDialog();
      }
      return undefined;
    }
    return undefined;
  };

  const selectedPaymentCard = useMemo(() => {
    return paymentCards?.find((card) => card.id === selectedCardId);
  }, [selectedCardId]);

  const value =
    (selectedPaymentCard?.hasCardHolderName && selectedPaymentCard.hasBillingAddress) ||
    isUsingCardsWithoutBillingAllowed
      ? selectedCardId
      : undefined;

  const handleRemoveCard = async () => {
    const isConfirmed = await openConfirmationDialog();
    if (isConfirmed && editableCardId) {
      closeConfirmationDialog();
      await removeCard(editableCardId);
      hidePaymentCardDialog();
      closeSelect();
      if (selectedCardId === editableCardId) {
        setSelectedCardId(undefined);
      }
      setEditableCardId(undefined);
      await delay(SHOW_TOAST_DELAY);
      showToast.success(`Card was successfully deleted`);
    } else {
      closeConfirmationDialog();
    }
  };

  /* eslint-disable @typescript-eslint/indent */
  const handleCardSubmit = async (
    values:
      | (PaymentCardDetailsFormValues & PaymentCardBillingAddressFormValues)
      | PaymentCardReducedDetailsFormValues,
  ) => {
    const card = await addCard(values);

    if (
      card &&
      checkIsSupportedCard({
        card,
        disabledPaymentCardsIssuers,
        disabledPaymentMethods,
      })
    ) {
      setSelectedCardId(card?.id);
    }
    hidePaymentCardDialog();
    closeSelect();
    if (card) {
      await delay(SHOW_TOAST_DELAY);
      showToast.success(`New card ${card?.maskedNumber} was added`);
    }
    return undefined;
  };
  /* eslint-disable @typescript-eslint/indent */

  type Type =
    | (PaymentCardDetailsFormValues & PaymentCardBillingAddressFormValues)
    | PaymentCardReducedDetailsFormValues;

  const handleCardUpdate = async (values: Type) => {
    if (editableCardId) {
      const card = await updateCard(values, editableCardId);
      const cardWithSupportedInfo = paymentCardsWithSupportedInfo?.find(
        (c) => c.data.id === card?.id,
      );
      if (card) {
        hidePaymentCardDialog();
        if (cardWithSupportedInfo?.isSupported) setSelectedCardId(card.handleId);
        hidePaymentCardDialog();
        closeSelect();
        await delay(SHOW_TOAST_DELAY);
        showToast.success(`Card {{maskedCardNumber}} was updated`, {
          maskedCardNumber: card.maskedNumber,
        });
      }
    }
  };

  const expDateString = editableCard?.expDate
    ? format(editableCard?.expDate, CARD_EXP_DATE_FORMAT)
    : '';

  const hasMissedDetails = Boolean(
    editableCardId && (!editableCard?.hasCardHolderName || !editableCard?.hasBillingInfo),
  );

  // hook takes care of providing all necessary <PaymentCardSelect /> props
  const selectProps: React.ComponentProps<typeof PaymentCardSelect> = {
    isLoading,
    isUsingCardsWithoutBillingAllowed,
    isInert: isPaymentCardDialogOpen || isUnsupportedDialogMounted,
    value,
    ref: selectRef,
    data: paymentCardsWithSupportedInfo,
    onChange: handleChangeCardId,
    onEdit: handleEditCard,
    onCardAdd: handleOnCardAdd,
    onCardEdit: handleEditCardClick,
  };

  const handleDialogAnimationEnd = () => {
    setEditableCardId(undefined);
  };

  const commonDialogProps = {
    isOpen: isPaymentCardDialogOpen,
    onClose: handleDialogClose,
    onSubmit: editableCardId ? handleCardUpdate : handleCardSubmit,
    onRemove: handleRemoveCard,
    handleId: editableCardId,
    isLoading,
    onDialogCloseAnimationEnd: handleDialogAnimationEnd,
  };

  // hook takes care of providing all necessary PaymentCardReducedDetailsDialog props
  const paymentCardReducedDetailsDialogProps: PaymentCardReducedDetailsDialogProps = {
    ...commonDialogProps,
    values: {
      cardNumber: editableCard?.maskedNumber ?? '',
      expDateString,
      zip: editableCard?.zip ?? '',
      cvv: editableCard?.cvv ?? '',
    },
    countryCode: userCountryOfOrigin,
  };

  // hook take care of providing all necessary PaymentCardAllDetailsDialog props
  const paymentCardAllDetailsDialogProps: PaymentCardAllDetailsDialogProps<
    PaymentCardBillingAddressFormValues & PaymentCardDetailsFormValues
  > = {
    ...commonDialogProps,
    values: {
      expDateString,
      cardNumber: editableCard?.maskedNumber ?? '',
      holderName: editableCard?.accountHolder ?? '',
      zip: editableCard?.zip ?? '',
      street1: editableCard?.address1 ?? '',
      street2: editableCard?.address2 ?? '',
      city: editableCard?.city ?? '',
      state: editableCard?.state ?? '',
    },
    hasMissedDetails,
    countryCode: userCountryOfOrigin,
  };

  return {
    selectProps,
    paymentCardAllDetailsDialogProps,
    paymentCardReducedDetailsDialogProps,
    paymentCards,
    isLoading,
    hasPaymentCards,
    selectedPaymentCard,
    showPaymentCardDialog,
    hidePaymentCardDialog,
    openSelect,
  };
};

export default usePaymentCardSelect;
