import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Img } from 'react-image';

import useDidUpdate from '@root/hooks/useDidUpdate';

import { Modal, Typography, Logo } from '@components/common';

interface Props {
  iconSrc?: string;
  title: string;
  message: string;
  isClosable?: boolean;
  isOpen: boolean;
}

const MaintenanceModal = (props: Props) => {
  const { iconSrc, title, message, isClosable, isOpen } = props;

  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  const location = useLocation();

  const handleClose = () => {
    if (isClosable) {
      setIsModalOpen(false);
    }
  };

  useDidUpdate(() => {
    setIsModalOpen(isOpen);
  }, [location]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleClose}
      isClosable={isClosable}
      header={<Logo className="w-24" />}
    >
      {iconSrc?.length && (
        <div className="h-24 mb-5">
          <Img src={iconSrc} className="m-auto w-24 h-full" />
        </div>
      )}
      <Typography.Title className="mb-3" level={5}>
        {title}
      </Typography.Title>
      <Typography.Paragraph className="font-medium">{message}</Typography.Paragraph>
    </Modal>
  );
};

export default MaintenanceModal;
