import React, { useState } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { RadioProps } from '@src/root/interfaces/components/Radio';

import './styles.scss';

const Radio = (props: RadioProps) => {
  const {
    id,
    className,
    label,
    checked,
    disabled,
    onGroupChange,
    onFocus,
    onBlur,
    onChange,
    component,
    dataTestId,
    ...restProps
  } = props;

  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (checked === undefined) {
      setIsChecked(true);
    } else if (onGroupChange) {
      onGroupChange(event);
    }
    if (onChange) {
      onChange(event);
    }
  };

  const radioButtonLabelClassNames = normalizeStringCompound([
    'radio-button-label inline-flex items-center leading-none group',
    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    checked ? 'checked' : undefined,
    className,
  ]);

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const classNames = normalizeStringCompound([
    'radio-button-inner-circle transform transition-transform',
    isChecked || checked ? 'scale-100' : 'scale-0',
    'bg-green-900 rounded-full w-full h-full',
  ]);

  return (
    <label htmlFor={id} className={radioButtonLabelClassNames}>
      <input
        onChange={handleChange}
        checked={checked !== undefined ? checked : isChecked}
        className="sr-only"
        type="radio"
        id={id}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
        data-test-id={dataTestId}
        {...restProps}
      />
      {component || (
        <>
          <div className="radio-button relative flex items-center justify-center border border-solid border-gray-300 rounded-full bg-gray-200 w-6 h-6 p-1 group-hover:border-green-900 transition">
            <div className={classNames} />
          </div>
          <div className="px-2">{label}</div>
        </>
      )}
    </label>
  );
};

export default Radio;
