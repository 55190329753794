import { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  isOpen?: boolean;
  onClick?(): void;
  isDisabled?: boolean;
}

const SelectButton: FC<React.PropsWithChildren<Props>> = (props) => {
  const { isOpen, onClick, children, isDisabled } = props;

  return (
    <button
      className={normalizeStringCompound([
        'bg-gray-200 h-20 md:h-16 w-full rounded-2xl px-4 text-left',
        isOpen
          ? 'pointer-events-none -z-10 md:opacity-0 transition-opacity delay-[50ms] duration-[0ms]'
          : '',
      ])}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
};

export default SelectButton;
