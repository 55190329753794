import { FormikTouched, setNestedObjectValues } from 'formik';
import { useTranslation } from 'react-i18next';

import Yup from '@utils/validation/yup';

import FormProps from '@root/interfaces/Form';

import { FormConfig } from '@root/interfaces/components/Form';

import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';

import { Form } from '..';

export interface PaymentCardDetailsFormValues {
  cardNumber: string;
  holderName: string;
  expDateString: string;
}

type InitiallyTouched = FormikTouched<PaymentCardDetailsFormValues> | undefined;

const PaymentCardDetailsForm = (
  props: Omit<FormProps<PaymentCardDetailsFormValues>, 'countryCode'>,
) => {
  const {
    isEdit,
    shouldValidateOnMount,
    dataTestPrefixId,
    className,
    submitBtnText,
    formControls,
    formHeader,
    values,
    onSubmit,
    isLoading,
  } = props;

  const { t } = useTranslation();

  const validationSchema = {
    cardNumber: Yup.lazy(() => {
      return isEdit ? Yup.string() : Yup.string().cardNumber().required();
    }),
    holderName: Yup.string().base().required(),
    expDateString: Yup.string().expirationDateString().required(),
  };

  const initialTouched: InitiallyTouched = shouldValidateOnMount
    ? setNestedObjectValues(values, true)
    : undefined;

  const formConfig: FormConfig<PaymentCardDetailsFormValues> = {
    enableReinitialize: true,
    validateOnMount: shouldValidateOnMount,
    initialValues: values,
    initialTouched,
    validationSchema: isLoading ? undefined : Yup.object().shape(validationSchema),
    onSubmit,
  };

  const formSubmitBtn = (
    <Form.Button
      dataTestId={`${dataTestPrefixId}-submit-button`}
      shape="pill"
      size="lg"
      className="w-full"
    >
      {submitBtnText}
    </Form.Button>
  );

  const formControlsSection = (
    <div className="text-center py-[5px] z">
      {formSubmitBtn}
      {formControls ?? null}
    </div>
  );

  return (
    <Form config={formConfig} className={className}>
      {formHeader && formHeader}
      <div className="overflow-y-auto py-[24px]">
        <Form.CardNumberField
          disabled={isEdit}
          required
          name="cardNumber"
          label={t('Card number')}
          dataTestId={`${dataTestPrefixId}-card-number-input`}
        />
        <Form.Field
          required
          type="text"
          name="holderName"
          label={t('Cardholder name')}
          dataTestId={`${dataTestPrefixId}-holder-name-input`}
        />
        <Form.CardExpirationDateField
          required
          name="expDateString"
          dataTestId={`${dataTestPrefixId}-exp-date-input`}
          label={t('Expiration date {{format}}', {
            format: `(${CARD_EXP_DATE_FORMAT.toLowerCase()})`,
          })}
        />
      </div>
      {formControlsSection}
    </Form>
  );
};

export default PaymentCardDetailsForm;
