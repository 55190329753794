export const CUSTOMER_SUPPORT_EMAILS = {
  CA: 'support@bossrevolution.ca',
  GB: 'support@bossrevolution.co.uk',
  US: 'support@bossrevolution.com',
};

export const CUSTOMER_SUPPORT_PHONES = {
  CA: { value: '18007863112', text: '1-800-786-3112' },
  GB: { value: '03307771374', text: '0330-777-1374' },
  US: { value: '17162152677', text: '1-716-215-BOSS (2677)' },
};
