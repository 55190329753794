import React, { FC } from 'react';
import InputMask from 'react-input-mask';

import { MaskedInputProps } from '@root/interfaces/components/MaskedInput';

import Input from '../Input';

const MaskedInput: FC<React.PropsWithChildren<MaskedInputProps>> = (props) => {
  const {
    mask,
    value,
    onFocus,
    onBlur,
    onChange,
    inputRef,
    disabled,
    isPassword,
    ...rest
  } = props;

  return (
    <InputMask
      mask={mask}
      maskChar=""
      value={value}
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    >
      {() => (
        <Input
          disabled={disabled}
          ref={inputRef}
          {...(isPassword ? { type: 'password' } : {})}
          {...rest}
        />
      )}
    </InputMask>
  );
};

export default MaskedInput;
