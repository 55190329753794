import React from 'react';
import { Button } from '@components/common';

import { ButtonProps } from '@root/interfaces/components/Button';

const SubmitButton: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  const { children, ...rest } = props;
  return (
    <Button
      shape="pill"
      color="blue"
      size="lg"
      isOutlined
      className="w-full max-w-app-btn"
      {...rest}
    >
      {children}
    </Button>
  );
};

export default SubmitButton;
