export const STORE_APP_URLS = {
  GOOGLE_PLAY: {
    ALL_APPS: 'https://smart.link/5d28eed7010d8',
    MT_MODAL: 'https://smart.link/ioszwudwsqk9m',
    BOSS_MONEY:
      'https://control.kochava.com/v1/cpi/click?campaign_id=kobr-money-android-lax9ls7mb55044d16410ac&network_id=9408&ko_exchange=true&site_id=1&device_id=device_id',
  },
  APP_STORE: {
    ALL_APPS: 'https://smart.link/5d28f1c8039ed',
    MT_MODAL: 'https://smart.link/ioszwudwsqk9m',
    BOSS_MONEY:
      'https://control.kochava.com/v1/cpi/click?campaign_id=kobr-money-iphone-2kffc3e4fab9d2e7&network_id=9408&ko_exchange=true&site_id=1&device_id=device_id',
  },
};

export const SUPPORT_URL_TEMPLATES = {
  FAQ: 'https://www.bossrevolution.com/{{langLocale}}/support',
  CALLING: 'https://www.bossrevolution.com/{{langLocale}}/support#international-calling',
  MT: 'https://www.bossrevolution.com/{{langLocale}}/support#money-transfer',
};

export const GENERAL_TOC_URL_TPL = 'https://www.bossrevolution.com/{{langLocale}}/terms';

export const PRIVACY_POLICY_URL_TPL =
  'https://www.bossrevolution.com/{{langLocale}}/privacy-policy';
