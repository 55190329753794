import React, { FC } from 'react';
import { useToaster } from 'react-hot-toast';

import Toast from './Toast';

import './styles.scss';

const DURATION = 6000; // ms

const Toaster: FC<React.PropsWithChildren<unknown>> = () => {
  const { toasts } = useToaster({ duration: DURATION });

  return toasts.length ? (
    <div className="toaster fixed z-[11112] top-32 right-0 w-full px-4 pb-4 md:mt-4">
      <div className="flex flex-col-reverse">
        {toasts.map((item) => {
          return <Toast key={item.id} {...item} />;
        })}
      </div>
    </div>
  ) : null;
};

export default Toaster;
