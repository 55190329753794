import CountryCode from '@root/interfaces/CountryCode';
import {
  CALLING_HELP_PHONES_LANDLINE,
  CALLING_HELP_PHONES_TOL_ACCESS,
} from './constants';

export const getHelpPhonesLandline = ({ countryCode }: { countryCode: CountryCode }) => {
  return CALLING_HELP_PHONES_LANDLINE[countryCode] || [];
};

export const getHelpPhonesTolAccess = ({ countryCode }: { countryCode: CountryCode }) => {
  return CALLING_HELP_PHONES_TOL_ACCESS[countryCode] || [];
};
