import React, { FC } from 'react';
import { useTranslation } from '@nodeModules/react-i18next';
import { Img } from 'react-image';

import { ReactComponent as AppStoreBadgeIcon } from '@src/static/assets/images/appStoreBadge.svg';
import { ReactComponent as GooglePlayBadgeIcon } from '@src/static/assets/images/googlePlayBadge.svg';
import { ReactComponent as CopyIcon } from '@src/static/assets/images/ic-16-copy-clipboard.svg';

import { Typography } from '@components/common';
import { useIsNotMobile } from '@components/common/MediaQueryMatchers';
import Link from '@components/common/Typography/Link';

import { STORE_APP_URLS } from '@root/constants/storeUrls';
import { getDeviceInfo } from '@helpers/device';
import { normalizeStringCompound } from '@utils/string';
import { ASSETS_URL } from '@services/api/v1/constants/cdnAndOthers';

import { trackGaMtModalAppLinkClickEvent } from '@services/analytics';

interface Props {
  containerClassNames?: string;
}

const PROMO_CODE_TEXT = 'APPBONUS';
const SEO_IDS = {
  APP_STORE_LINK_ID: 'mt-app-store-popup-acc',
  GOOGLE_PLAY_LINK_ID: 'mt-google-play-popup-acc',
};
const NUMBER_OF_FREE_TRANSACTIONS = 3;

const Content: FC<React.PropsWithChildren<Props>> = (props) => {
  const { containerClassNames } = props;
  const { t } = useTranslation();

  const isNotMobile = useIsNotMobile();

  const titleText = t(
    'Money Transfer service is now available only in the BOSS Money app',
  );
  const freeTransactionsText = `${t(
    'Download the BOSS Money app to get {highlight}{{numberOfTransactions}} transactions free*{/highlight} with promo code',
    {
      numberOfTransactions: NUMBER_OF_FREE_TRANSACTIONS,
    },
  )}:`;
  const hugeRemarkText = `${t(
    '*Promo available only to customers who have not made a money transfer with the BOSS Money app or the BOSS Revolution app (collectively, the “App”). Eligible customers can enter promo code {{promoCode}} in the App at time of payment and receive 100% off the standard money transfer fee for three transfers. All three transfers must be made with a debit card in the App by June 30, 2024. Transfers to Mexico and Kenya excluded. Standard fees apply to all other money transfers. Special offers are available only where IDT Payment is licensed. Offer can’t be combined with any other offer',
    {
      promoCode: PROMO_CODE_TEXT,
    },
  )}.`;
  const tocText = `${t(
    'Boss Revolution money transfer products and payment services are offered and serviced by IDT Payment Services, Inc., licensed money transmitter (NMLS 935577, MA FT935577), or IDT Payment Services of New York LLC, licensed as a Money Transmitter by the New York State Department of Financial Services (collectively, “IDT Payment”). IDT Payment may make money when it changes your dollars into foreign currency. Fees may vary by sales channel and location based on a number of factors and are subject to change without prior notice. Access to money transfer services and the transfer of funds may be limited, delayed or unavailable during certain periods and/or based on certain transaction conditions. Additional restrictions may apply. See {link}Terms{/link} for details.',
  )}.`;
  const TocDocLink =
    'https://cdn.bossrevolution.com/Content/pdf/TermsofUse_RegulatedProducts.pdf';

  const deviceInfo = getDeviceInfo();
  const appLinksCfg = [
    {
      id: SEO_IDS.APP_STORE_LINK_ID,
      link: STORE_APP_URLS.APP_STORE.MT_MODAL,
      icon: <AppStoreBadgeIcon className="h-14 max-w-full" />,
      isIos: true,
    },
    {
      id: SEO_IDS.GOOGLE_PLAY_LINK_ID,
      link: STORE_APP_URLS.GOOGLE_PLAY.MT_MODAL,
      icon: <GooglePlayBadgeIcon className="h-14 max-w-full" />,
      isAndroid: true,
    },
  ].filter((item) => {
    return (
      isNotMobile ||
      (item.isIos && deviceInfo.isIos) ||
      (item.isAndroid && deviceInfo.isAndroid)
    );
  });

  // split text with some marker into parts to use it to replace the middle part.
  const getTextPartsToReplace = (text: string, markerName: string) => {
    const openingTag = `{${markerName}}`;
    const closingTag = `{/${markerName}}`;
    const replaceTag = '||';
    const parts = text
      .replace(openingTag, replaceTag)
      .replace(closingTag, replaceTag)
      .split(replaceTag);
    const hasOnlyOneReplacementPart = parts.length === 3;
    const leftPart = text.split(openingTag)[0];
    const rightPart = text.split(closingTag)[1];
    return {
      parts,
      leftPart,
      rightPart,
      hasOnlyOneReplacementPart,
    };
  };

  const renderTextWithHighLight = (text: string, markerName: string) => {
    const {
      parts,
      leftPart,
      rightPart,
      hasOnlyOneReplacementPart,
    } = getTextPartsToReplace(text, markerName);

    if (hasOnlyOneReplacementPart) {
      return parts.map((part) => {
        if ([leftPart, rightPart].includes(part)) {
          return part;
        }
        return (
          <span key={part} className="whitespace-nowrap font-bold text-accent2-100">
            {part}
          </span>
        );
      });
    }

    return text;
  };

  const renderTextWithHighLink = (text: string, markerName: string) => {
    const {
      parts,
      leftPart,
      rightPart,
      hasOnlyOneReplacementPart,
    } = getTextPartsToReplace(text, markerName);

    if (hasOnlyOneReplacementPart) {
      return parts.map((part) => {
        if ([leftPart, rightPart].includes(part)) {
          return part;
        }
        return (
          <Link
            key={part}
            to={TocDocLink}
            className="text-blue-400 underline opacity-100"
            isNative
          >
            {part}
          </Link>
        );
      });
    }

    return text;
  };

  const handleCopyButtonClick = async () => {
    await navigator.clipboard.writeText(PROMO_CODE_TEXT);
  };

  const handleAppLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.currentTarget?.id) {
      trackGaMtModalAppLinkClickEvent({
        click_id: e.currentTarget.id,
      });
    }
  };

  const containerClsNamesCompound = normalizeStringCompound([
    containerClassNames,
    'max-w-[334px] md:max-w-[512px] lg:max-w-[724px] flex flex-col overflow-hidden',
  ]);

  return (
    <div className={containerClsNamesCompound}>
      <div className="mb-5 flex flex-row md:mb-8 lg:mb-10">
        <div className="">
          <div className="mb-2 text-3xl font-bold leading-relaxed lg:text-4xl">
            {titleText}
          </div>
          <div className="mb-4 text-xl font-medium leading-relaxed">
            <div>
              {renderTextWithHighLight(freeTransactionsText, 'highlight')}{' '}
              <button
                className="my-1 inline-flex cursor-pointer items-center gap-2 rounded-xl px-3 py-1 text-lg font-medium bg-accent2-100/10 md:rounded-lg"
                onClick={handleCopyButtonClick}
              >
                {PROMO_CODE_TEXT}
                <CopyIcon className="h-4 w-4 cursor-pointer md:h-4 md:w-4 lg:h-5 lg:w-5" />
              </button>
            </div>
          </div>
          <div className="flex flex-row">
            {appLinksCfg.map((item) => {
              return (
                <div key={item.id} className="mb-2 flex-initial md:mr-1 md:mr-4 md:mb-0">
                  <Typography.Link
                    id={item.id}
                    onClick={handleAppLinkClick}
                    isNative
                    target="_blank"
                    to={item.link}
                    className="block"
                  >
                    {item.icon}
                  </Typography.Link>
                </div>
              );
            })}
          </div>
        </div>
        {isNotMobile && (
          <div className="flex flex-col items-center">
            <div className="mb-1 lg:w-44">
              <Img src={`${ASSETS_URL.CDN_ACMS}/qr/MTQR.webp`} />
            </div>
            <div className="md:text-sm lg:text-base">{t('Scan to download')}</div>
          </div>
        )}
      </div>
      <div className="mb-2 md:text-sm">{hugeRemarkText}</div>
      <div className="text-gray-350 md:text-sm">
        {renderTextWithHighLink(tocText, 'link')}
      </div>
    </div>
  );
};

export default Content;
