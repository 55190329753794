import { PaymentCardIssuerInfo } from '@root/interfaces/PaymentCard';

// eslint-disable-next-line import/prefer-default-export
export const paymentCardIssuersInfo: PaymentCardIssuerInfo[] = [
  {
    paySource: 'V',
    formattedType: 'Visa',
    lengths: [16, 19],
    pattern: /^4/,
    code: {
      name: 'CVV',
      size: 3,
    },
  },
  {
    paySource: 'MC',
    formattedType: 'Master Card',
    pattern: /^5[1-5]/,
    lengths: [16],
    code: {
      name: 'CVC',
      size: 3,
    },
  },
  {
    paySource: 'AE',
    formattedType: 'American Express',
    pattern: /^3[47]/,
    lengths: [15],
    code: {
      name: 'CID',
      size: 4,
    },
  },
];
