export const calculateAge = (dob: Date) => {
  const now: Date = new Date();
  const year = dob.getFullYear();
  const month = dob.getMonth();
  const date = dob.getDate();
  let age = now.getFullYear() - year;
  if (now.getMonth() < month || (now.getMonth() === month && now.getDate() < date)) {
    age -= 1;
  }
  return age || -1;
};

export const getTimeFromMs = (ms: number) => {
  const ss = (ms / 1000) % 60;
  const mm = Math.floor(ms / 60000);

  return `${String(mm).padStart(2, '0')}:${String(ss).padStart(2, '0')}`;
};
