import ROUTE_PATHS from '@root/routes/paths';

import { LanguageLocale } from '@root/interfaces/Languages';
import CountryCode from '@root/interfaces/CountryCode';

import { getFallbackLocaleForLegalUrls } from '@helpers/locale';

import {
  GENERAL_TOC_URL_TPL,
  PRIVACY_POLICY_URL_TPL,
  SUPPORT_URL_TEMPLATES,
} from '@root/constants/storeUrls';
import { CUSTOMER_SUPPORT_EMAILS } from '@root/constants/customerSupport';

import { isObjKey } from '@utils/typeGuards';

import { LINK_ACTIONS } from './constants';
import { LinkOptions } from './components/LinksList';

interface FeatureAvailabilityParams {
  isCallingAvailable?: boolean;
  isMtAvailable?: boolean;
  isImtuAvailable?: boolean;
}

interface LinkOptionsTemplate extends LinkOptions {
  isAvailable: boolean;
}

export const getServiceLinks = ({
  isCallingAvailable,
  isMtAvailable,
  isImtuAvailable,
}: FeatureAvailabilityParams) => {
  const servicesLinks: LinkOptionsTemplate[] = [
    {
      isAvailable: Boolean(isCallingAvailable),
      text: 'Calling', // t('Calling')
      to: ROUTE_PATHS.CALLING,
    },
    {
      isAvailable: Boolean(isMtAvailable),
      text: 'Send Money', // t('Send Money')
      to: ROUTE_PATHS.MT_MODAL,
    },
    {
      isAvailable: Boolean(isImtuAvailable),
      text: 'Top-Up', // t('Top-Up')
      to: ROUTE_PATHS.IMTU,
    },
  ].filter((item) => {
    return item.isAvailable;
  });

  return servicesLinks;
};

interface GetSupportLinksParams extends FeatureAvailabilityParams {
  langLocale: LanguageLocale;
  countryCode: CountryCode;
  mailtoSubject: string;
}

export const getSupportLinks = ({
  isCallingAvailable,
  isMtAvailable,
  langLocale,
  countryCode,
  mailtoSubject,
}: GetSupportLinksParams) => {
  const supportEmail = isObjKey(countryCode, CUSTOMER_SUPPORT_EMAILS)
    ? CUSTOMER_SUPPORT_EMAILS[countryCode]
    : CUSTOMER_SUPPORT_EMAILS.US;
  const fallbackLocale = getFallbackLocaleForLegalUrls(langLocale);
  const supportLinks: LinkOptionsTemplate[] = [
    {
      isAvailable: true,
      text: 'Chat Now', // t('Chat Now') // TODO add the functionality of opening the live chat
      to: '/',
      actionName: LINK_ACTIONS.CHAT_NOW,
    },
    {
      isAvailable: true,
      text: 'Email', // t('Email')
      to: `mailto:${supportEmail}?subject=${mailtoSubject}`,
      isNative: true,
    },
    {
      isAvailable: Boolean(isCallingAvailable),
      text: 'BOSS Revolution Calling', // t('BOSS Revolution Calling')
      to: SUPPORT_URL_TEMPLATES.CALLING.replace('{{langLocale}}', fallbackLocale),
      isNative: true,
    },
    {
      isAvailable: Boolean(isMtAvailable),
      text: 'BOSS Revolution Money', // t('BOSS Revolution Money')
      to: SUPPORT_URL_TEMPLATES.MT.replace('{{langLocale}}', fallbackLocale),
      isNative: true,
    },
    {
      isAvailable: true,
      text: 'FAQ', // t('FAQ')
      to: SUPPORT_URL_TEMPLATES.FAQ.replace('{{langLocale}}', fallbackLocale),
      isNative: true,
    },
  ].filter((item) => {
    return item.isAvailable;
  });

  return supportLinks;
};

export const getBottomLinks = ({
  countryCode,
  langLocale,
}: {
  countryCode: CountryCode;
  langLocale: LanguageLocale;
}) => {
  const fallbackLocale = getFallbackLocaleForLegalUrls(langLocale);
  const bottomLinks = [
    {
      isAvailable: true,
      text: 'Terms and Conditions', // t('Terms and Conditions')
      to: GENERAL_TOC_URL_TPL.replace('{{langLocale}}', fallbackLocale),
      isNative: true,
      target: '_blank',
    },
    {
      isAvailable: countryCode === 'US',
      text: 'California Consumer Privacy Notice', // t('California Consumer Privacy Notice')
      to: 'https://www.idt.net/ccpa',
      isNative: true,
      target: '_blank',
    },
    {
      isAvailable: countryCode === 'US',
      text: 'Do Not Sell My Personal Information', // t('Do Not Sell My Personal Information')
      to: 'https://www.idt.net/ccpa-do-not-sell',
      isNative: true,
      target: '_blank',
    },
    {
      isAvailable: true,
      text: 'Privacy Policy', // t('Privacy Policy')
      to: PRIVACY_POLICY_URL_TPL.replace('{{langLocale}}', fallbackLocale),
      isNative: true,
      target: '_blank',
    },
  ].filter((item) => {
    return item.isAvailable;
  });

  return bottomLinks;
};
