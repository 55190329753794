import { Icon } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  onClick?(): void;
  btnText: string;
  className?: string;
}

const AddCardButton = (props: Props) => {
  const { onClick, btnText, className } = props;

  return (
    <button
      onClick={onClick}
      className={normalizeStringCompound([
        'px-3 md:px-5 py-2 flex items-center w-full',
        className,
      ])}
    >
      <div className="w-[40px] h-[40px] text-[20px] rounded-full bg-gray-200 md:bg-white flex items-center justify-center">
        <Icon name="PlusAlt" className="text-[#2A2825] text-[12px]" />
      </div>
      <span className="text-[17px] md:text-[19px] font-light ml-[16px] text-blue-400 leading-none">
        {btnText}
      </span>
      <Icon name="ChevronRightAlt" className="ml-auto text-[18px] text-[#E1E0DF]" />
    </button>
  );
};

export default AddCardButton;
