import {
  PaymentCardIssuer,
  PaymentCardIssuerKeys,
  PaymentCardIssuerReadableName,
} from '@root/interfaces/PaymentCard';
import {
  PaymentCardFundingType,
  PaymentCardTypeShort,
} from '../moneyTransfer/paymentCards';

export const PAYMENT_CARD_BRANDS: {
  [key in PaymentCardIssuer]: PaymentCardIssuerReadableName;
} = {
  VISA: 'Visa',
  MASTERCARD: 'Mastercard',
  AMERICAN_EXPRESS: 'American Express',
  DISCOVER: 'Discover',
};

export const PAYMENT_CARD_NAME: { [key in PaymentCardIssuerKeys]: string } = {
  V: PAYMENT_CARD_BRANDS.VISA,
  MC: PAYMENT_CARD_BRANDS.MASTERCARD,
  AE: PAYMENT_CARD_BRANDS.AMERICAN_EXPRESS,
  D: PAYMENT_CARD_BRANDS.DISCOVER,
};

export const PaymentCardFundingMap = {
  [PaymentCardTypeShort.CC]: PaymentCardFundingType.credit,
  [PaymentCardTypeShort.DC]: PaymentCardFundingType.debit,
  [PaymentCardTypeShort.PP]: PaymentCardFundingType.prepaid,
};
