/* eslint-disable import/prefer-default-export */

export const charsEquivalentsForAccentsMap: { [key: string]: string } = {
  á: 'a',
  å: 'a',
  ñ: 'n',
  ú: 'u',
  ü: 'u',
  ö: 'o',
  ô: 'o',
  ó: 'o',
  é: 'e',
  è: 'e',
  í: 'i',
  ï: 'i',
};
