import React, { FC, useMemo, useState } from 'react';
import { FormikConfig } from 'formik';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Form, Button, Modal, Logo } from '@components/common';

import Yup from '@utils/validation/yup';

import CountryCode from '@root/interfaces/CountryCode';

import { trackButtonPress } from '@services/analytics';

import { AnalyticsContext, AnalyticsScreen } from '@root/interfaces/Analytics';

import SubmitButton from '../../../components/SubmitButton';
import SupportText from '../../../components/SupportText';

export interface PhoneNumberFormValues {
  phoneNumber: string;
}

interface Props {
  availableCountries: CountryCode[];
  initialPhoneNumber: string;
  initialCountryCode?: CountryCode;
  onSubmit: (values: PhoneNumberFormValues) => void;
  dataTestPrefix?: string;
}

const BTN_TEXT = 'Get a code by SMS';

const EnterPhoneNumber: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    availableCountries,
    initialPhoneNumber,
    initialCountryCode,
    onSubmit,
    dataTestPrefix,
  } = props;
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLostAccessBtnClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (values: PhoneNumberFormValues) => {
    trackButtonPress({
      button_name: BTN_TEXT,
      context: AnalyticsContext.Login,
      screen: AnalyticsScreen.SendPhoneNumber,
    });
    onSubmit(values);
  };

  const formInitializeProps: FormikConfig<PhoneNumberFormValues> = useMemo(
    () => ({
      enableReinitialize: true,
      initialValues: {
        phoneNumber: initialPhoneNumber,
        defaultCountry: initialCountryCode,
      },
      onSubmit: handleSubmit,
      validationSchema: Yup.object().shape({
        phoneNumber: Yup.string().internationalPhoneNumber().required(),
      }),
      validateOnMount: true,
    }),
    [initialCountryCode],
  );

  return (
    <>
      <Form config={formInitializeProps}>
        <Form.Field
          type="phone"
          name="phoneNumber"
          availableCountries={availableCountries}
          placeholder={t('Enter your number')}
          dataTestId={`${dataTestPrefix}-input`}
          defaultCountry={initialCountryCode}
          initialPhoneNumber={initialPhoneNumber}
        />
        <Form.Button
          customComponent={SubmitButton}
          dataTestId={`${dataTestPrefix}-submit-btn`}
        >
          {t(BTN_TEXT)}
        </Form.Button>
      </Form>
      <div className="text-center mt-6">
        <Button
          shape="text"
          size="sm"
          onClick={handleLostAccessBtnClick}
          data-test-id={`${dataTestPrefix}-link`}
        >
          <span className="hover:underline mx-auto text-xl text-blue-400">
            {t("I've changed my phone number")}
          </span>
        </Button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        header={<Logo className="w-24" />}
      >
        <SupportText />
      </Modal>
    </>
  );
};

export default observer(EnterPhoneNumber);
