import CountryCode from '@root/interfaces/CountryCode';

export const LOGIN_TITLES: Record<string | CountryCode, string> = {
  US: 'BOSS Revolution in the USA: My Account | Sign up or Log in', // t('BOSS Revolution in the USA: My Account | Sign up or Log in')
  CA: 'BOSS Revolution in Canada: My Account | Sign up or Log in', // t('BOSS Revolution in Canada: My Account | Sign up or Log in')
  ES: 'BOSS Revolution in Spain: My Account | Sign up or Log in', // t('BOSS Revolution in Spain: My Account | Sign up or Log in')
  DE: 'BOSS Revolution in Germany: My Account | Sign up or Log in', // t('BOSS Revolution in Germany: My Account | Sign up or Log in')
  SG: 'BOSS Revolution in Singapore: My Account | Sign up or Log in', // t('BOSS Revolution in Singapore: My Account | Sign up or Log in')
  AU: 'BOSS Revolution in Australia: My Account | Sign up or Log in', // t('BOSS Revolution in Australia: My Account | Sign up or Log in')
  GB: 'BOSS Revolution in the UK: My Account | Sign up or Log in', // t('BOSS Revolution in the UK: My Account | Sign up or Log in')
};

export const LOGIN_DESCRIPTIONS: Record<string | CountryCode, string> = {
  US: 'Log in or sign up to manage your BOSS Revolution account in the USA', // t('Log in or sign up to manage your BOSS Revolution account in the USA')
  CA: 'Log in or sign up to manage your BOSS Revolution account in Canada', // t('Log in or sign up to manage your BOSS Revolution account in Canada')
  ES: 'Log in or sign up to manage your BOSS Revolution account in Spain', // t('Log in or sign up to manage your BOSS Revolution account in Spain')
  DE: 'Log in or sign up to manage your BOSS Revolution account in Germany', // t('Log in or sign up to manage your BOSS Revolution account in Germany')
  SG: 'Log in or sign up to manage your BOSS Revolution account in Singapore', // t('Log in or sign up to manage your BOSS Revolution account in Singapore')
  AU: 'Log in or sign up to manage your BOSS Revolution account in Australia', // t('Log in or sign up to manage your BOSS Revolution account in Australia')
  GB: 'Log in or sign up to manage your BOSS Revolution account in the UK', // t('Log in or sign up to manage your BOSS Revolution account in the UK')
};
