import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

import useDidMount from '@root/hooks/useDidMount';

import { normalizeStringCompound } from '@utils/string';
import { isString } from '@utils/typeGuards';

import { ModalProps } from '@root/interfaces/components/Modal';

import Typography from '../Typography';
import Icon from '../Icon';
import Spinner from '../Spinner';

import './styles.scss';

export type ModalRef = {
  modalPortalNode: HTMLDivElement;
  wrapperClassNames?: string;
};

const Modal = forwardRef((props: ModalProps, ref: ForwardedRef<ModalRef>) => {
  const {
    children,
    className,
    wrapperClassNames,
    header,
    onRequestClose,
    bodyClassName,
    isLoading,
    closeBtnDataTestId,
    isFluidModal,
    isClosable = true,
    ...rest
  } = props;

  const { t } = useTranslation();

  const modalRef = useRef<ReactModal>(null);

  useDidMount(() => {
    ReactModal.setAppElement('#root');
  });

  useImperativeHandle(ref, () => ({
    // @ts-ignore // TODO: bug in ReactModal types
    modalPortalNode: modalRef.current.node, // TODO: bug in ReactModal types
  }));

  const wrapperContentClassName = normalizeStringCompound([
    isFluidModal ? 'modal-popup-fluid-content' : 'modal-popup-fixed-content',
    'relative outline-none flex-grow m-auto z-[10000] relative',
    wrapperClassNames,
  ]);

  const headerWrapperClassName = normalizeStringCompound([
    header ? 'relative text-center mb-10' : 'flex justify-end',
    'z-10',
  ]);

  const closeBtnClassName = normalizeStringCompound([
    header ? 'absolute right-0 top-0' : '',
    'text-xl',
  ]);

  const contentClassName = normalizeStringCompound([
    'bg-white border border-gray-400 rounded-2xl shadow-lg max-w-full',
    className,
  ]);

  const handleRequestClose = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
  ) => {
    if (onRequestClose) {
      onRequestClose(e);
    }
  };

  return (
    <ReactModal
      overlayClassName="fixed z-1000 inset-0 flex bg-gray-900 bg-opacity-50 overflow-y-auto p-4"
      className={wrapperContentClassName} // content class names
      bodyOpenClassName="overflow-hidden"
      onRequestClose={handleRequestClose}
      portalClassName="relative z-[100000]"
      ref={modalRef}
      {...rest}
    >
      <div className={contentClassName}>
        <Spinner isSpinning={Boolean(isLoading)}>
          <div className="py-10 px-6 md:px-10">
            <div className={headerWrapperClassName}>
              {isString(header) ? (
                <Typography.Title level={4} className="px-10">
                  {header}
                </Typography.Title>
              ) : (
                header
              )}
              {isClosable && (
                <Icon
                  name="Cross"
                  onClick={onRequestClose}
                  className={closeBtnClassName}
                  dataTestId={closeBtnDataTestId}
                  title={t('Close')}
                />
              )}
            </div>
            <div className={bodyClassName}>{children}</div>
          </div>
        </Spinner>
      </div>
    </ReactModal>
  );
});

export default Modal;
