/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { TFunction } from '@nodeModules/i18next';
import { useSearch } from 'react-use-search';

import { getPhoneDialCodeByCountryCode } from '@helpers/phone';

import { getSortByKeyFn } from '@utils/array';

import { CountryCodeOption } from '@root/interfaces/components/PhoneInput';
import CountryCode from '@root/interfaces/CountryCode';

import { getCountryNameByCode } from '@services/countryList';

import { filterCountryCodeOption } from '@components/common/PhoneInput/helpers';

export const useCountryCodePhoneOptions = (
  availableCountries: CountryCode[],
  selectedCountry?: CountryCode,
  t?: TFunction,
) => {
  const countryCodesOptions: CountryCodeOption[] = useMemo(() => {
    return availableCountries
      ?.map((code) => {
        const countryName = getCountryNameByCode(code) || '';
        const localCountryName = t ? t(countryName) : countryName;
        return {
          code,
          countryName: localCountryName,
          dialCode: `+${getPhoneDialCodeByCountryCode(code)}`,
        };
      })
      .sort(getSortByKeyFn('countryName'));
  }, [availableCountries, t]);

  const countryOptions = useMemo(() => {
    if (selectedCountry) {
      const index = countryCodesOptions.findIndex((c) => c.code === selectedCountry);
      // selected option goes first in the list for user's convenience
      const options = [...countryCodesOptions];
      return [...options.splice(index, 1), ...options];
    }
    return countryCodesOptions;
  }, [countryCodesOptions, selectedCountry]);

  const [
    filteredCountryCodeOptions,
    searchQuery,
    handleCountryCodeInputChange,
  ] = useSearch(countryOptions, filterCountryCodeOption, {
    filter: true,
  });

  return {
    filteredOptions: filteredCountryCodeOptions,
    handleSearch: handleCountryCodeInputChange,
    searchQuery,
  };
};
