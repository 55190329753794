import React, { FC, PropsWithChildren } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { CheckboxProps } from '@src/root/interfaces/components/Checkbox';

import Icon from '../Icon';

import './styles.scss';

const Checkbox: FC<PropsWithChildren<CheckboxProps>> = (props) => {
  const {
    id,
    children,
    checked,
    onFocus,
    onChange,
    onBlur,
    disabled,
    className,
    value,
    ...restProps
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const classNames = normalizeStringCompound([
    'checkbox-label inline-flex items-center',
    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    className,
  ]);

  return (
    <label htmlFor={id} className={classNames}>
      <input
        id={id}
        className="sr-only"
        type="checkbox"
        checked={checked && checked}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        value={value}
        {...restProps}
        data-recording-sensitive
      />
      <div className="checkbox flex-shrink-0">
        <Icon name="Checkmark" className="checkmark" />
      </div>
      <div className="checkbox-text px-2">{children}</div>
    </label>
  );
};

export default Checkbox;
