import React, { FC } from 'react';

import { Typography, Button } from '@components/common';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

interface Props extends TestIdDataAttr {
  title: string;
  value: string;
  editButtonText: string;
  onEdit: () => void;
}

const GeneralUserInfoItem: FC<React.PropsWithChildren<Props>> = (props) => {
  const { title, value, editButtonText, onEdit, dataTestId } = props;

  return (
    <>
      <Typography.Title level={5}>{title}</Typography.Title>
      <div data-recording-sensitive>{value}</div>
      <Button shape="text" size="sm" onClick={onEdit} dataTestId={dataTestId}>
        {editButtonText}
      </Button>
    </>
  );
};

export default GeneralUserInfoItem;
