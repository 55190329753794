import { getErrorMessageByGeopositionErrorCode } from '@utils/geoPosition';

const GEOPOSITION_TIMEOUT = 5000;

// eslint-disable-next-line import/prefer-default-export
export const getGeoposition = async (options?: PositionOptions) => {
  try {
    return await new Promise(
      (resolve: PositionCallback, reject?: PositionErrorCallback) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          timeout: GEOPOSITION_TIMEOUT,
          ...options,
        });
      },
    );
  } catch (err) {
    throw Error(
      getErrorMessageByGeopositionErrorCode((err as GeolocationPositionError).code),
    );
  }
};
