import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography } from '@components/common';

import { getCountryNameByCode } from '@services/countryList';

import { SUPPORTED_COUNTRIES } from '@root/constants/countries';
import {
  CUSTOMER_SUPPORT_EMAILS,
  CUSTOMER_SUPPORT_PHONES,
} from '@root/constants/customerSupport';
import { SupportedCountries } from '@root/interfaces/SupportedCountries';

// TODO: probably we need better naming since we support any country now
const sortedSupportedCountries = SUPPORTED_COUNTRIES.reduce(
  (acc: SupportedCountries[], code) => {
    return code === 'US' ? [code, ...acc] : [...acc, code];
  },
  [],
);

const SupportText: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation(); // I don't like the translation inside a component but didn't find a workaround for Linkify to properly format URL and phone at the same time so Just wrapper it into a component

  return (
    <div className="font-bold text-xl md:text-base">
      {t('Please contact customer support at')}
      <ul className="mt-3 space-y-2">
        {sortedSupportedCountries.map((countryCode) => {
          const supportEmail =
            CUSTOMER_SUPPORT_EMAILS[countryCode] || CUSTOMER_SUPPORT_EMAILS.US;
          const supportPhoneInfo =
            CUSTOMER_SUPPORT_PHONES[countryCode] || CUSTOMER_SUPPORT_PHONES.US;
          return (
            <li key={countryCode}>
              <Typography.Link
                to={`mailto:${supportEmail}?subject=${t('Support')}`}
                className="text-blue-400 hover:text-blue-400"
                isNative
              >
                {supportEmail}
              </Typography.Link>
              {t(' or call ')}
              <Typography.Link
                to={`tel:+${supportPhoneInfo.value}`}
                className="text-blue-400 hover:text-blue-400"
                isNative
              >
                <span className="whitespace-nowrap">{supportPhoneInfo.text}</span>
              </Typography.Link>{' '}
              <span className="whitespace-nowrap">
                <>(</>
                {t(getCountryNameByCode(countryCode))}
                <>)</>
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SupportText;
