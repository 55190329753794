import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useStore from '@root/hooks/useStore';
import { LoginStore, UserAccountStore } from '@root/stores';
import ROUTE_PATHS from '@root/routes/paths';

import { trackLogout } from '@services/analytics';

import { UserAvatar } from '@components/common';

import NavigationButton, { NavigationButtonProps } from './components/NavigationButton';

type Button = NavigationButtonProps & { isHidden?: boolean };

interface Props {
  onItemSelect?: () => void;
  className?: string;
}

const DATA_TEST_ID_PREFIX = 'user-account-navigation';

const UserAccountNavigation: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onItemSelect, className } = props;

  const { t } = useTranslation();
  const loginStore: LoginStore = useStore('LoginStore');
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const handleLogoutClick = async () => {
    await loginStore.sessionLogOut();
    trackLogout(userAccountStore.financeProfile?.brCustomerId || ''); // TODO: replace bmbCustomerId to profile.id after completely switch to DTC backend
    window.location.reload();
  };

  const config: Button[] = [
    {
      title: t('My Profile'),
      component: (
        <UserAvatar
          avatarUrl={userAccountStore.profile.avatarURL}
          altText={userAccountStore.shortName}
        />
      ),
      link: ROUTE_PATHS.ACCOUNT_PROFILE,
      dataTestId: `${DATA_TEST_ID_PREFIX}-my-profile`,
    },
    // {
    //   title: t('Contacts'),
    //   iconName: 'User',
    //   link: '/settings',
    //   isHidden: !userAccountStore.isUserLoggedIn,
    //   dataTestId: `${DATA_TEST_ID_PREFIX}-contacts`,
    // },
    // {
    //   title: t('Invite Friends'),
    //   iconName: 'Plus',
    //   link: '/settings',
    //   dataTestId: `${DATA_TEST_ID_PREFIX}-invite-friends`,
    // },
    {
      title: t('Transaction history'),
      iconName: 'Clock',
      link: '/history',
      dataTestId: `${DATA_TEST_ID_PREFIX}-funding-history`,
    },
    // {
    //   title: t('Call History'),
    //   iconName: 'CallHistory',
    //   link: '/settings',
    //   isHidden: !userAccountStore.isUserLoggedIn,
    //   dataTestId: `${DATA_TEST_ID_PREFIX}-call-history`,
    // },
    // {
    //   title: t('Help'),
    //   iconName: 'Support',
    //   link: '/settings',
    //   dataTestId: `${DATA_TEST_ID_PREFIX}-help`,
    // },
    // {
    //   title: t('Security'),
    //   iconName: 'Lock',
    //   link: ROUTE_PATHS.DOCUMENTS_UPLOADING,
    //   dataTestId: `${DATA_TEST_ID_PREFIX}-security`,
    // },
    {
      title: t('Logout'),
      iconName: 'Logout',
      onClick: handleLogoutClick,
      dataTestId: `${DATA_TEST_ID_PREFIX}-logout`,
    },
  ];

  return (
    <ul className={className}>
      {config.map((item) =>
        item.isHidden ? null : (
          <li key={item.title} className="mb-1">
            <NavigationButton {...item} onItemSelect={onItemSelect} />
          </li>
        ),
      )}
    </ul>
  );
};

export default observer(UserAccountNavigation);
