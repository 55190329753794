import { Redirect } from 'react-router-dom';

import { Grid } from '@components/common';

import ROUTE_PATHS from '@root/routes/paths';

import CommonTemplate from '@components/templates/CommonTemplate';

const MainPage = () => {
  return (
    <CommonTemplate>
      <Grid.Row>
        <Grid.Col span={12}>
          <Redirect to={ROUTE_PATHS.ACCOUNT} />
        </Grid.Col>
      </Grid.Row>
    </CommonTemplate>
  );
};

export default MainPage;
