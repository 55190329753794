import React from 'react';
import { SecureDataAttr } from '@root/interfaces/components/Common';

const ContentText: React.FC<React.PropsWithChildren<SecureDataAttr>> = (props) => {
  const { children, isSecureContent } = props;
  return (
    <p
      className="text-center text-xl mb-10"
      data-recording-sensitive={isSecureContent ? 'true' : 'none'}
    >
      {children}
    </p>
  );
};

export default ContentText;
