import { LanguageLocale } from '@root/interfaces/Languages';

// Same for US, CA and for UK now. Probably the naming will be updated when we know more
const IMTU_TERMS_PDF_US = {
  EN: 'https://cdn.bossrevolution.com/Content/pdf/MTU_Service_Terms.pdf',
  ES: 'https://cdn.bossrevolution.com/Content/pdf/DMTU_IMTU_Disclosures_US_Spanish.pdf',
};

// eslint-disable-next-line import/prefer-default-export
export const IMTU_TERMS_PDF_URLS = {
  [LanguageLocale.EnUs]: IMTU_TERMS_PDF_US.EN,
  [LanguageLocale.EsUs]: IMTU_TERMS_PDF_US.ES,
  [LanguageLocale.EnCa]: IMTU_TERMS_PDF_US.EN,
  [LanguageLocale.EsCa]: IMTU_TERMS_PDF_US.ES,
  [LanguageLocale.FrCa]: IMTU_TERMS_PDF_US.EN, // there is no FR terms. Checked on CA marketing site
  [LanguageLocale.EnGb]: IMTU_TERMS_PDF_US.EN,
  [LanguageLocale.EsGb]: IMTU_TERMS_PDF_US.ES,
};
