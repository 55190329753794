import PaymentCardClass from '@root/models/PaymentCardClass';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import { PaymentCardTypeShort } from '@root/constants/moneyTransfer/paymentCards';

// eslint-disable-next-line import/prefer-default-export
export const checkIsSupportedCard = (params: {
  card: PaymentCardClass;
  disabledPaymentCardsIssuers?: PaymentCardIssuerKeys[];
  disabledPaymentMethods?: PaymentCardTypeShort[];
}) => {
  const { card, disabledPaymentCardsIssuers, disabledPaymentMethods } = params;
  return (
    (card.paySource ? !disabledPaymentCardsIssuers?.includes(card.paySource) : false) &&
    !disabledPaymentMethods?.includes(card.type)
  );
};
