import {
  PaymentCardConfigData,
  PaymentCardIssuer,
} from '@root/interfaces/contract/PaymentCards';

import { PaymentCardIssuerKeys } from '../interfaces/PaymentCard';

export interface PaymentCardConfigParams {
  issuer: PaymentCardIssuerKeys;
  issuerName: string;
  matchRegEx: RegExp;
}

const issuersMap: { [key in PaymentCardIssuer]: PaymentCardIssuerKeys } = {
  visa: 'V',
  mastercard: 'MC',
  amex: 'AE',
  discover: 'D',
};

export default class {
  creditCards: PaymentCardConfigParams[];

  constructor(params: PaymentCardConfigData) {
    this.creditCards = params.credit_cards.map((item) => ({
      issuer: issuersMap[item.brand],
      issuerName: item.label,
      matchRegEx: item.match,
    }));
  }

  getIssuerKeyByCardNumber = (cardNumber: string) => {
    return this.creditCards.find((item) =>
      cardNumber.replace(/\D/g, '0').match(item.matchRegEx),
    )?.issuer;
  };
}
