export function isMyScriptLoaded(url: string) {
  const scripts = Array.from(document.getElementsByTagName('script'));
  return scripts.some((script) => script.src === url);
}

export function loadScript(src: string, attributes: {}) {
  if (isMyScriptLoaded(src)) {
    return Promise.resolve();
  }

  return new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    Object.assign(s, attributes);
    document.head.appendChild(s);
  });
}
