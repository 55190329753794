import OriginForm from './Form';
import Field from './Field';
import CardNumberField from './CardNumberField';
import CardExpirationDateField from './CardExpirationDateField';
import Label from './Label';
import ErrorMessage from './ErrorMessage';
import Debugger from './Debugger';
import Button from './Button';

type FormProps = typeof OriginForm & {
  Field: typeof Field;
  CardNumberField: typeof CardNumberField;
  CardExpirationDateField: typeof CardExpirationDateField;
  Label: typeof Label;
  ErrorMessage: typeof ErrorMessage;
  Button: typeof Button;
  Debugger: typeof Debugger;
};

const Form = OriginForm as FormProps;

Form.Field = Field;
Form.CardNumberField = CardNumberField;
Form.CardExpirationDateField = CardExpirationDateField;
Form.Label = Label;
Form.ErrorMessage = ErrorMessage;
Form.Debugger = Debugger;
Form.Button = Button;

export default Form;
