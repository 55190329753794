import axios from 'axios';

import { MaintenanceResponse } from '@root/interfaces/contract/MaintenanceResponse';

type MaintenanceStatus = MaintenanceResponse['maintenance_status'];

const fetchMaintenaceStatus = async (): Promise<MaintenanceStatus> => {
  const maintenanceUrl = process.env.REACT_APP_DTC_HEALTH_CHECK_API_URL as string;
  const res = await axios.get<MaintenanceResponse>(maintenanceUrl);
  return res.data.maintenance_status;
};

export default fetchMaintenaceStatus;
