import { useEffect, useRef } from 'react';

const useInterval = (
  handler: () => void,
  periodInMilliseconds: number,
  activityFlag: boolean,
) => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = handler;
  }, [handler]);

  useEffect(() => {
    let id = 0;
    if (activityFlag) {
      id = window.setInterval(() => {
        savedCallback.current?.call(null);
      }, periodInMilliseconds);
    }
    return () => {
      clearInterval(id);
    };
  }, [periodInMilliseconds, activityFlag]);
};

export default useInterval;
