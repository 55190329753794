import smartlookClient from 'smartlook-client';

class SmartlookAPI {
  readonly app: typeof smartlookClient | null = null;

  constructor(apiKey?: string) {
    if (apiKey && apiKey !== 'none') {
      smartlookClient.init(apiKey);
      this.app = smartlookClient;
    }
  }
}

export default SmartlookAPI;
