import React, { useEffect, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';
import { format } from 'date-fns';

import useStore from '@root/hooks/useStore';
import usePaymentCards from '@root/hooks/usePaymentCards';
import useConfirmationDialog from '@root/hooks/useConfirmationDialog';

import {
  Typography,
  Grid,
  Modal,
  MediaMatcher,
  Logo,
  UserAvatar,
  Button,
} from '@components/common';

import PaymentCardAllDetailsDialog from '@components/common/PaymentCardAllDetailsDialog';
import PaymentCardRemoveConfirmUi from '@components/common/PaymentCardSelect/PaymentCardRemoveConfirmUi';
import PaymentCardReducedDetailsDialog from '@components/common/PaymentCardReducedDetailsDialog';

import { UserAccountStore } from '@root/stores';

import { getQueryString } from '@utils/url';
import delay from '@utils/delay';

import { showToast } from '@services/toasts';

import { MODAL_TYPE } from '@root/constants/queryParams';

import ROUTE_PATHS from '@root/routes/paths';

import EditPhotoButton from './components/EditPhotoButton';
import GeneralUserInfoItem from './components/GeneralUserInfoItem';
import AddButton from './components/AddButton';
import ChangeNameForm, { ChangeNameFormParams } from './components/ChangeNameForm';
import ChangeDobForm, { ChangeDobFormParams } from './components/ChangeDobForm';
import ProfilePaymentCards from './components/ProfilePaymentCards';
import AccountDeletionModal from './components/AccountDeletionModal';

import Store, { UserFieldsModalType } from './Store';

type Props = typeof PaymentCardAllDetailsDialog | typeof PaymentCardReducedDetailsDialog;
type SubmitPaymentCardType = Parameters<React.ComponentProps<Props>['onSubmit']>['0'];

const DATA_TEST_ID_PREFIX = 'user-account-profile';
const SHOW_TOAST_DELAY = 600;

const UserAccountProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const store = useLocalStore(() => new Store());

  const [isPaymentCardDialogOpened, setIsPaymentCardDialogOpened] = useState(false);

  /**
   * set location query MODAL_QUERY_KEY as UserFieldsModalType to open modal
   * @param type UserFieldsModalType
   */
  const openUserFieldsModal = (type: UserFieldsModalType) => {
    history.push({
      search: `?${new URLSearchParams({ [MODAL_TYPE]: type }).toString()}`,
    });
  };

  /**
   * clear query to close modal
   */
  const clearModalQuery = () => {
    history.push({ search: '' });
  };

  const {
    paymentCards,
    editableCardAllValues,
    removeCard,
    addCard,
    updateCard,
    isLoading,
    editableCard,
    setEditableCardId,
    editableCardId,
  } = usePaymentCards();

  const confirmationModalDialog = (onConfirm: () => void, onDecline: () => void) => (
    <PaymentCardRemoveConfirmUi
      cardInfo={editableCard ?? null}
      t={t}
      onConfirm={onConfirm}
      onDecline={onDecline}
    />
  );

  const {
    open: openConfirmationModal,
    close: closeConfirmationModal,
  } = useConfirmationDialog({ content: confirmationModalDialog });

  useEffect(() => {
    const setupModalByQuery = (locationSearch: string) => {
      const modal = qs.parse(locationSearch, { ignoreQueryPrefix: true })[MODAL_TYPE] as
        | UserFieldsModalType
        | undefined;
      return store.setActiveUserFieldsModalType(modal || null);
    };

    setupModalByQuery(getQueryString());

    history.listen(({ search }) => {
      setupModalByQuery(search);
    });
  }, []);

  const generalInfoOptions = [
    {
      title: t('Name'),
      value: userAccountStore.fullName ?? '', // TODO: remove the todo when BRP-776 merged
      editButtonText: t('Change your name'),
      onEdit: () => openUserFieldsModal('name'),
      dataTestId: `${DATA_TEST_ID_PREFIX}-edit-name-btn`,
    },
    {
      title: t('Phone Number'),
      value: userAccountStore.profile.phone,
      editButtonText: t('Change your phone number'),
      onEdit: store.showCustomerSupportModal,
      dataTestId: `${DATA_TEST_ID_PREFIX}-edit-phone-btn`,
    },
    {
      title: t('Email'),
      value: userAccountStore.profile.email,
      editButtonText: t('Change your email'),
      onEdit: store.showCustomerSupportModal,
      dataTestId: `${DATA_TEST_ID_PREFIX}-edit-email-btn`,
    },
    {
      title: t('Date of Birth'),
      value: userAccountStore.dob ? format(userAccountStore.dob, 'MM/dd/yyyy') : '',
      editButtonText: t('Change your date of birth'),
      onEdit: () => openUserFieldsModal('dob'),
      dataTestId: `${DATA_TEST_ID_PREFIX}-edit-dob-btn`,
    },
  ];

  const handleNameFormSubmit = async (values: ChangeNameFormParams) => {
    await userAccountStore.saveUserName({
      firstName: values.firstName,
      lastName: values.lastName,
    });
    userAccountStore.getProfile();
    clearModalQuery();
  };

  const handleDobFormSubmit = async (values: ChangeDobFormParams) => {
    if (values.dob) {
      await userAccountStore.saveDateOfBirth(values.dob);
    }
    clearModalQuery();
  };

  const changeNameForm = (
    <ChangeNameForm
      onSubmit={handleNameFormSubmit}
      firstName={userAccountStore.profile.firstName}
      lastName={userAccountStore.profile.lastName}
      dataTestPrefix={DATA_TEST_ID_PREFIX}
    />
  );

  const changeNameFormTitle = t('Change name');

  const userFieldsModalOptions: {
    [key in UserFieldsModalType]: { title: string; component: React.ReactNode };
  } = {
    name: {
      title: userAccountStore.isLoaded ? changeNameFormTitle : '',
      component: userAccountStore.isLoaded && changeNameForm,
    },
    dob: {
      title: t('Change Date of Birth'),
      component: (
        <ChangeDobForm
          onSubmit={handleDobFormSubmit}
          dob={userAccountStore.dob ?? undefined}
          dataTestPrefix={DATA_TEST_ID_PREFIX}
        />
      ),
    },
  };

  const handleUserAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      userAccountStore.saveAvatar(e.target.files[0]);
    }
  };

  const handleAddNewCardClick = () => {
    setIsPaymentCardDialogOpened(true);
  };

  const handlePaymentCardEditClick = (id: string) => {
    setEditableCardId(id);
    setIsPaymentCardDialogOpened(true);
  };

  const handleDialogCloseAnimationEnd = () => {
    setEditableCardId(undefined);
  };

  const handleClosePaymentCardDialog = () => {
    setIsPaymentCardDialogOpened(false);
  };

  const selectedUserFieldsModal = store.userFieldsModalType
    ? userFieldsModalOptions[store.userFieldsModalType]
    : null;

  const handleRemoveCard = async (id: string) => {
    const isConfirmed = await openConfirmationModal();
    if (isConfirmed) {
      closeConfirmationModal();
      await removeCard(id);
      setEditableCardId(undefined);
      setIsPaymentCardDialogOpened(false);
      await delay(SHOW_TOAST_DELAY);
      showToast.success(`Payment card was successfully deleted`);
    } else {
      closeConfirmationModal();
    }
  };

  const handleSubmitPaymentCard = async (values: SubmitPaymentCardType) => {
    if (editableCardId) {
      const card = await updateCard(values, editableCardId);
      setIsPaymentCardDialogOpened(false);
      await delay(SHOW_TOAST_DELAY);
      if (card) {
        showToast.success(`Payment card ${card.maskedNumber} was successfully updated`);
      }
    } else {
      const card = await addCard(values);
      setIsPaymentCardDialogOpened(false);
      await delay(SHOW_TOAST_DELAY);
      if (card) {
        showToast.success(`Payment card ${card.maskedNumber} was successfully added`);
      }
    }
  };

  const handleDeleteUserAccountBtnClick = async () => {
    store.showAccountDeletionModal();
  };

  const handleConfirmDeleteUserAccount = async () => {
    await userAccountStore.deleteUserAccountAndLogout();
    showToast.success(t('Your account was successfully deleted!'));
    history.replace(ROUTE_PATHS.LOGIN);
  };

  const handleDeleteAccountCancelClick = () => {
    store.hideAccountDeletionModal();
    clearModalQuery();
  };

  return (
    <>
      <MediaMatcher isNotMobile>
        <Typography.Title level={1} className="mb-8 flex items-center">
          {t('My Profile')}
        </Typography.Title>
      </MediaMatcher>
      <div className="flex justify-center md:justify-start mb-4">
        <EditPhotoButton
          id="user-photo"
          onChange={handleUserAvatarChange}
          dataTestId={`${DATA_TEST_ID_PREFIX}-edit-photo`}
        >
          <UserAvatar
            avatarUrl={userAccountStore.profile.avatarURL}
            altText={userAccountStore.shortName}
          />
        </EditPhotoButton>
      </div>

      <Grid.Row>
        <Grid.Col span={9}>
          <Grid.Row>
            {generalInfoOptions.map(
              (item) =>
                item && (
                  <Grid.Col key={item.title} span={{ sm: 12, md: 6 }} className="mt-6">
                    <GeneralUserInfoItem {...item} />
                  </Grid.Col>
                ),
            )}
          </Grid.Row>

          <Typography.Title level={3} className="mb-2 mt-32">
            {t('Payment Methods')}
          </Typography.Title>
          {paymentCards && (
            <Grid.Row className="mb-6">
              <ProfilePaymentCards
                data={paymentCards}
                onSelect={handlePaymentCardEditClick}
                dataTestPrefix={DATA_TEST_ID_PREFIX}
              />
            </Grid.Row>
          )}

          <Grid.Row>
            <AddButton
              onClick={handleAddNewCardClick}
              dataTestId={`${DATA_TEST_ID_PREFIX}-add-new-card-btn`}
              isDisabled={isLoading || userAccountStore.isLoading}
            >
              {t('Add new')}
            </AddButton>
          </Grid.Row>

          <Grid.Row className="mt-8">
            <Button
              shape="text"
              color="red"
              size="lg"
              onClick={handleDeleteUserAccountBtnClick}
            >
              {t('Delete account')}
            </Button>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>

      <PaymentCardAllDetailsDialog
        isOpen={isPaymentCardDialogOpened}
        values={editableCardAllValues}
        isLoading={isLoading}
        handleId={editableCardId}
        onSubmit={handleSubmitPaymentCard}
        onRemove={handleRemoveCard}
        onClose={handleClosePaymentCardDialog}
        onDialogCloseAnimationEnd={handleDialogCloseAnimationEnd}
        countryCode={userAccountStore.userCountryOfOrigin}
      />

      <Modal
        isOpen={
          store.userFieldsModalType === 'name' || store.userFieldsModalType === 'dob'
        }
        header={selectedUserFieldsModal?.title}
        closeBtnDataTestId={`${DATA_TEST_ID_PREFIX}-edit-modal-close-btn`}
        isLoading={userAccountStore.isLoading}
        onRequestClose={clearModalQuery}
      >
        <div className="br-payment-card-form m-auto">
          {selectedUserFieldsModal?.component}
        </div>
      </Modal>
      {/* Contact customer support modal */}
      <Modal
        header={<Logo className="w-24" />}
        closeBtnDataTestId={`${DATA_TEST_ID_PREFIX}-edit-support-modal-close-btn`}
        isOpen={store.isCustomerSupportModalOpen}
        onRequestClose={store.hideCustomerSupportModal}
      >
        <p className="text-center text-2xl">{t('Please contact customer support')}</p>
      </Modal>
      <AccountDeletionModal
        onRequestClose={handleDeleteAccountCancelClick}
        isOpen={
          store.isAccountDeletionModalOpen ||
          location.hash.includes(ROUTE_PATHS.ACC_DELETE_MODAL)
        }
        onConfirmClick={handleConfirmDeleteUserAccount}
      />
    </>
  );
};

export default observer(UserAccountProfile);
