import CountryCode from '@root/interfaces/CountryCode';

type CallingSupportContacts = {
  [key in CountryCode]?: { language: string; phone: string }[];
};

export const CALLING_HELP_PHONES_LANDLINE: CallingSupportContacts = {
  US: [
    {
      language: 'English', // t('English')
      phone: '716-271-BOSS (2677)',
    },
    {
      language: 'Spanish', // t('Spanish')
      phone: '716-770-BOSS (2677)',
    },
    {
      language: 'Creole', // t('Creole')
      phone: '716-328-1927',
    },
    {
      language: 'French', // t('French')
      phone: '716-328-9007',
    },
    {
      language: 'Arabic', // t('Arabic')
      phone: '716-328-9057',
    },
    {
      language: 'Amharic', // t('Amharic')
      phone: '716-328-9057',
    },
  ],
  CA: [
    {
      language: 'English', // t('English')
      phone: '438-793-4384',
    },
    {
      language: 'Spanish', // t('Spanish')
      phone: '438-800-1789',
    },
    {
      language: 'French', // t('French')
      phone: '438-793-4370',
    },
  ],
  GB: [
    // https://www.bossrevolution.co.uk/en-gb/services/international-calling
    {
      language: 'Cantonese', // t('Cantonese)
      phone: '03307771229',
    },
    {
      language: 'English', // t('English)
      phone: '03307771222',
    },
    {
      language: 'Mandarin', // t('Mandarin'),
      phone: '03307771228',
    },
    {
      language: 'Polish', // t('Polish')
      phone: '03307771223',
    },
    {
      language: 'Portuguese', // t('Portuguese')
      phone: '03307771221',
    },
    {
      language: 'Romanian', // t('Romanian')
      phone: '03307771224',
    },
    {
      language: 'Spanish', // t('Spanish')
      phone: '03307771220',
    },
    {
      language: 'Tagalog', // t('Tagalog')
      phone: '03307771225',
    },
  ],
};

export const CALLING_HELP_PHONES_TOL_ACCESS: CallingSupportContacts = {
  US: [
    {
      language: 'English', // t('English')
      phone: '1-800-374-4922',
    },
    {
      language: 'Spanish', // t('Spanish')
      phone: '1-800-376-5077',
    },
  ],
  CA: [
    {
      language: 'English', // t('English')
      phone: '1-888-656-4748',
    },
    {
      language: 'Spanish', // t('Spanish')
      phone: '1-888-656-4753',
    },
    {
      language: 'French', // t('French')
      phone: '1-888-656-4752',
    },
  ],
  GB: [
    {
      language: 'English', // t('English')
      phone: '08000147837',
    },
    {
      language: 'Spanish', // t('Spanish')
      phone: '08006527861',
    },
  ],
};

type SupportInfoConfig = {
  [key in CountryCode]?: {
    textTpl: string;
    variables: { [key: string]: string };
  };
};

export const SUPPORT_INFO_CONFIG: SupportInfoConfig = {
  US: {
    textTpl:
      'Please note that a {{fee}} per minute surcharge applies when using toll-free access numbers', // t('Please note that a {{fee}} per minute surcharge applies when using toll-free access numbers')
    variables: {
      fee: '1.5¢',
    },
  },
  CA: {
    textTpl:
      'Please note that a {{fee}} per minute surcharge applies when using toll-free access numbers', // t('Please note that a {{fee}} per minute surcharge applies when using toll-free access numbers')
    variables: {
      fee: '1.5¢',
    },
  },
  GB: {
    textTpl:
      'Calls made using a toll free access number will incur a {{landlineFee}} per minute surcharge from a landline and {{mobileFee}} per minute surcharge from a mobile. Calls made using access numbers beginning with 01 or 02 will incur a {{feeForNumbersStartedWith0102}} per minute surcharge', // t('Calls made using a toll free access number will incur a {{landlineFee}} per minute surcharge from a landline and {{mobileFee}} per minute surcharge from a mobile. Calls made using access numbers beginning with 01 or 02 will incur a {{feeForNumbersStartedWith0102}} per minute surcharge')
    variables: {
      landlineFee: '1.5p',
      mobileFee: '4p',
      feeForNumbersStartedWith0102: '0.5p',
    },
  },
};
