import { useTranslation } from 'react-i18next';
import { normalizeStringCompound } from '@utils/string';

import { Icon } from '@components/common';

interface Props {
  onEditClick(): void;
  onCloseBtnClick?(): void;
  isEditMode?: boolean;
  className?: string;
}

const TopControls = (props: Props) => {
  const { onEditClick, onCloseBtnClick, isEditMode, className } = props;

  const { t } = useTranslation();

  return (
    <div className={className}>
      <div className="flex items-center z-10 px-3 md:px-5 pt-[19px] w-full">
        <button
          className="relative z-50 text-[17px] flex-1 flex items-start"
          onClick={onEditClick}
        >
          <span
            className={normalizeStringCompound([
              isEditMode ? 'text-red-400' : 'text-blue-400',
            ])}
          >
            {isEditMode ? t('Cancel') : t('Edit')}
          </span>
        </button>
        <div className="md:hidden [flex-grow:1] text-center font-medium text-[20px] py-[10px] px-[20px] text-black-200">
          {t(isEditMode ? t('Edit Credit Cards') : t('Select Payment'))}
        </div>
        <div className="flex-1 text-right flex items-center justify-end">
          <Icon name="Cross" className="text-[25px]" onClick={onCloseBtnClick} />
        </div>
      </div>
    </div>
  );
};

export default TopControls;
