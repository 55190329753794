// eslint-disable-next-line import/prefer-default-export
export enum CallingApiErrorCode {
  CarrierProblemContactCarrier = 'carrier_problem_contact_carrier',
  CarrierProblemTryAgain = 'carrier_problem_try_again',
  FailedCardRestricted = 'failed_card_restricted',
  FailedContactCustomerSupport = 'failed_contact_customer_support',
  FailedNoCredit = 'failed_no_credit',
  FailedToTransferBalance = 'failed_to_transfer_balance',
  FailedTryAgain = 'failed_try_again',
  IneligiblePlan = 'ineligible_plan',
  InvalidAccountType = 'invalid_account_type',
  InvalidRechargeAmountContactCarrier = 'invalid_recharge_amount_contact_carrier',
  InvalidMsisdn = 'invalid_msisdn',
  InvalidMsisdnOrWrongCarrier = 'invalid_msisdn_or_wrong_carrier',
  InvalidProduct = 'invalid_product',
  LimitExceeded = 'limit_exceeded',
  MsisdnNotActive = 'msisdn_not_active',
  MsisdnNotPrepaid = 'msisdn_not_prepaid',
  NotAllowed = 'not_allowed',
  TopupTooSoon = 'topup_too_soon',
  PaymentCommunicationFailure = 'payment_communication_failure',
  ProcessingRequest = 'processing_request',
  ProductUnavailable = 'product_unavailable',
  Unknown = 'unknown',
  Failed = 'failed',
  FailedNoCard = 'failed_no_card',
  FailedCardDeclined = 'failed_card_declined',
  FailedCardExpired = 'failed_card_expired',
  FailedLostOrStolenCc = 'failed_lost_or_stolen_cc',
  FailedInvalidCardNumber = 'failed_invalid_card_number',
  FailedInvalidCardInfo = 'failed_invalid_card_info',
  VerifyWithRecipient = 'verify_with_recipient',
  Pending3Ds = '3ds_pending',
  Failed3Ds = '3ds_failed',
}
