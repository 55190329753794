import React from 'react';
import { useMediaQuery } from 'react-responsive';

import themeConstants from '@theme/constants.json';

interface Props {
  isMobile?: boolean;
  isNotMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
  isNotDesktop?: boolean;
}

const { md: mdSize, lg: lgSize } = themeConstants.screen;

interface MediaQueryCmpProps {
  children: JSX.Element;
}

const mobileQuery = {
  maxWidth: mdSize - 1,
};

const tabletQuery = {
  minWidth: mdSize,
  maxWidth: lgSize - 1,
};

const desktopQuery = {
  minWidth: lgSize,
};

const preDesktopQuery = {
  maxWidth: lgSize - 1,
};

const postMobileQuery = {
  minWidth: mdSize,
};

export const useIsMobile = () => {
  return useMediaQuery(mobileQuery);
};

export const useIsTablet = () => {
  return useMediaQuery(tabletQuery);
};

export const useIsDesktop = () => {
  return useMediaQuery(desktopQuery);
};

export const useIsNotMobile = () => {
  return useMediaQuery(postMobileQuery);
};

const MediaDesktop = (props: MediaQueryCmpProps) => {
  const { children } = props;
  const isDesktop = useIsDesktop();
  return isDesktop ? children : null;
};

const MediaNotDesktop = (props: MediaQueryCmpProps) => {
  const { children } = props;
  const isTablet = useMediaQuery(preDesktopQuery);
  return isTablet ? children : null;
};

const MediaTablet = (props: MediaQueryCmpProps) => {
  const { children } = props;
  const isTablet = useIsTablet();
  return isTablet ? children : null;
};

const MediaNotMobile = (props: MediaQueryCmpProps) => {
  const { children } = props;
  const isTablet = useMediaQuery(postMobileQuery);
  return isTablet ? children : null;
};

const MediaMobile = (props: MediaQueryCmpProps) => {
  const { children } = props;
  const isMobile = useIsMobile();
  return isMobile ? children : null;
};

const MediaMatcher: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { isMobile, isNotMobile, isTablet, isDesktop, isNotDesktop, children } = props;
  switch (true) {
    case isMobile:
      return (
        <MediaMobile>
          <>{children}</>
        </MediaMobile>
      );
    case isNotMobile:
      return (
        <MediaNotMobile>
          <>{children}</>
        </MediaNotMobile>
      );
    case isTablet:
      return (
        <MediaTablet>
          <>{children}</>
        </MediaTablet>
      );
    case isDesktop:
      return (
        <MediaDesktop>
          <>{children}</>
        </MediaDesktop>
      );
    case isNotDesktop:
      return (
        <MediaNotDesktop>
          <>{children}</>
        </MediaNotDesktop>
      );
    default:
      return <>{children}</>;
  }
};

export default MediaMatcher;
