import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { MT_IS_EDIT_TXN } from '@root/constants/queryParams';

import Step from './Step';

interface StepData extends TestIdDataAttr {
  index: number;
  path: string;
  isCurrent: boolean;
  isDisabled: boolean;
}

interface Common {
  current: number;
  className?: string;
  paths: string[];
  basePath?: string;
  isEditTxnMode?: boolean;
}

interface WithDefaultSteps extends Common {
  titles?: string[];
  component?: never;
}

interface WithCustomSteps extends Common {
  titles?: never;
  component: (params: StepData) => React.ReactNode;
}

type Props = (WithCustomSteps | WithDefaultSteps) & TestIdDataAttr;

const Stepper: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    current,
    paths,
    component,
    titles = [],
    className,
    basePath,
    dataTestId,
    isEditTxnMode,
  } = props;

  const renderSteps = (params: StepData) => {
    return component ? (
      component(params)
    ) : (
      <Step
        path={`${params.path}${isEditTxnMode ? `?${MT_IS_EDIT_TXN}=true` : ''}`}
        title={titles[params.index]}
        isCurrent={params.isCurrent}
        isDisabled={params.isDisabled}
        dataTestId={params.dataTestId}
        index={params.index}
      />
    );
  };

  return (
    <div className={normalizeStringCompound(['flex w-full', className])}>
      {paths.map((item, index) => (
        <div key={item} className="inline-flex w-full group">
          {renderSteps({
            index,
            path: `${basePath}${item}`,
            isCurrent: index === current,
            isDisabled: current < index,
            dataTestId: `${dataTestId}-${index}`,
          })}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
