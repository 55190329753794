import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@components/common';
import { useIsMobile } from '@components/common/MediaQueryMatchers';

import { liveChatStart } from '@services/liveChat';

const AdaptiveHelpButton = () => {
  const isMobile = useIsMobile();
  const { t } = useTranslation();

  const handleHelpButtonClick = () => {
    liveChatStart();
  };

  return isMobile ? (
    <Icon
      name="SupportCircleAlt"
      onClick={handleHelpButtonClick}
      className="text-2xl rounded-full text-blue-400"
    />
  ) : (
    <Button
      shape="pill"
      isOutlined
      size="sm"
      color="blue"
      className="mr-4"
      onClick={handleHelpButtonClick}
    >
      {t('Help')}
    </Button>
  );
};

export default AdaptiveHelpButton;
