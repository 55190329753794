import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { RadioGroup } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import SelectRechargeItem from '../SelectRechargeItem';

import './styles.scss';

interface SelectRechargeOption {
  price: number;
  symbol: string;
  currency: string;
}

interface Props extends TestIdDataAttr {
  value?: number;
  options: SelectRechargeOption[];
  onChange: (value: string) => void;
  className?: string;
  displayPromoAmount?: string;
}

const SelectRecharge: FC<React.PropsWithChildren<Props>> = (props) => {
  const { value, options, displayPromoAmount, className, dataTestId, onChange } = props;

  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  const normalizedOptions = useMemo(
    () =>
      options.map((item) => ({
        value: String(item.price),
        component: (
          <SelectRechargeItem
            isSelected={item.price === value}
            promoText={
              displayPromoAmount
                ? t('Get +{{bonus}} bonus', { bonus: displayPromoAmount })
                : undefined
            }
          >
            {`${item.symbol}${item.price} ${item.currency}`}
          </SelectRechargeItem>
        ),
      })),
    [options, value, displayPromoAmount],
  );

  return (
    <div className={normalizeStringCompound(['overflow-x-auto', className])}>
      <RadioGroup
        className="flex justify-between"
        value={String(value)}
        options={normalizedOptions}
        onChange={handleChange}
        optionClassName="recharge-option"
        dataTestId={dataTestId}
      />
    </div>
  );
};

export default SelectRecharge;
