import Yup from '@utils/validation/yup';
import { RequiredStringSchema } from 'yup/lib/string';

import { AnyObject } from 'yup/lib/types';

import CountryCode from '@root/interfaces/CountryCode';

export const getStateProvinceValidationFn = (
  code: CountryCode,
): RequiredStringSchema<string | undefined, AnyObject> => {
  switch (code) {
    case 'CA':
      return Yup.string().usStateAndCaProvinceCode().required();
    case 'US':
    default:
      return Yup.string().usStateCode().required();
  }
};

export const getZipPostalCodeValidationFn = (
  code: CountryCode,
): RequiredStringSchema<string | undefined, AnyObject> => {
  switch (code) {
    case 'CA':
      return Yup.string().postalCode().required();
    case 'GB':
      return Yup.string().postCode().required();
    case 'US':
    default:
      return Yup.string().zip().required();
  }
};
