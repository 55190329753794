/* eslint-disable import/prefer-default-export */
import hotToast from 'react-hot-toast';
import i18n from '../i18n';

export enum ToastType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

function toast(
  this: { type: ToastType },
  messageKey: string,
  messageKeyVariables?: Dictionary<string>,
) {
  const message = i18n.t(messageKey, messageKeyVariables);

  switch (this.type) {
    case ToastType.Success:
      return hotToast.success(message, { id: message });

    case ToastType.Error:
      console.error(message);
      return hotToast.error(message, { id: message });

    default:
    case ToastType.Warning:
      return hotToast(message, { id: message });
  }
}

export const dismissToast = (toastId?: string) => hotToast.dismiss(toastId);

export const showToast = {
  error: toast.bind({ type: ToastType.Error }),
  warning: toast.bind({ type: ToastType.Warning }),
  success: toast.bind({ type: ToastType.Success }),
};
