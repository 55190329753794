import { makeAutoObservable } from 'mobx';

import { showToast } from '@services/toasts';

import { MaintenanceResponse } from '@root/interfaces/contract/MaintenanceResponse';

import fetchMaintenaceStatus from '@services/maintenance';

class MaintenanceStore {
  maintenanceInfo?: MaintenanceResponse['maintenance_status'];

  isLoading?: boolean;

  constructor() {
    makeAutoObservable(this);
  }

  getAppMaintenanceStatus = async () => {
    this.setIsLoading(true);
    try {
      this.maintenanceInfo = await fetchMaintenaceStatus();
    } catch (err) {
      showToast.error(err);
      throw err;
    } finally {
      this.setIsLoading(false);
    }
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  get isUnderMaintenance() {
    return Boolean(this.maintenanceInfo?.length);
  }
}

export default MaintenanceStore;
