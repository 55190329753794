import firebase from 'firebase';

import { PurchaseImtuOptions, PurchaseMtOptions } from '@root/interfaces/Analytics';
import CountryCode from '@root/interfaces/CountryCode';

import BASE_PATHS from '@root/routes/paths';

import { GoogleAnalyticsEvent, PURCHASE_ITEM } from './constants';

interface GaPurchaseMtParams {
  eventName: GoogleAnalyticsEvent;
  transactionId: string;
  item: firebase.analytics.Item;
  amount: number;
  options: PurchaseMtOptions;
  userCountryCode: CountryCode;
}

export const getGaPurchaseMtParams = ({
  eventName,
  transactionId,
  item,
  amount,
  options,
  userCountryCode,
}: GaPurchaseMtParams) => {
  const { fee, paymentMethod, promoCode } = options;

  // FIXME: For some reason not all data fields which are available in GA present in FirebaseAnalyticsTypes types
  const extendedItem: Dictionary<undefined | string | number | object> = {
    ...item,
    price_in_usd: amount,
    // in local currency
    // price: localAmount || amount,
    // FIXME: For some reason when localAmount is assigned to the "price" field then the value of the "price_in_usd" field also becomes equal to localAmount in the Big Query
    price: amount,
    // revenue means price * quantity
    item_revenue_in_usd: amount,
    // item_revenue: localAmount || amount,
    // FIXME: For some reason when localAmount is assigned to the "item_revenue" field then the value of the "item_revenue_in_usd" field also becomes equal to localAmount in the Big Query
    item_revenue: amount,
  };

  const purchaseParams: firebase.analytics.EventParams = {
    affiliation: PURCHASE_ITEM.BRAND,
    coupon: promoCode,
    currency: PURCHASE_ITEM.CURRENCY,
    items: [extendedItem],
    // Use shipping as a fee field
    shipping: fee,
    value: amount,
    transaction_id: transactionId,
    event_category: '', // TODO:
  };

  // TODO: Purchase reporting with a dedicated logPurchase API does not accept custom parameters which are currently needed
  //  for querying most popular POC subPayers on the BMB side; later on the SQL query on the BMB side should be updated
  // analytics().logPurchase(purchaseParams)
  //   .catch((e) => {
  //     console.log("Cannot track GA purchase event", purchaseParams, e);
  //   });

  // TODO: This is a workaround to support backward compatibility for most popular POC subPayers selection
  /* eslint-disable @typescript-eslint/indent */
  const customParams: Dictionary<undefined | string> =
    item.item_name === PURCHASE_ITEM.NAME_MT
      ? {
          item_id: item.item_id,
          item_name: 'Send money', // TODO: replace with constant
          item_brand: PURCHASE_ITEM.BRAND,
          item_variant: item.item_variant,
          item_pocID: item.item_category5,
          item_payerID: item.item_category3,
          item_subPayerID: item.item_category4,
          item_recipientCurrency: item.item_category2,
        }
      : {};
  /* eslint-disable @typescript-eslint/indent */

  return {
    // Official params
    ...purchaseParams,
    // TODO: To be removed because that data is available on items
    // Custom params
    ...customParams,
    // FIXME: We want to have that but it's not included into the FirebaseAnalyticsTypes.PurchaseEventParameters type
    payment_type: paymentMethod,
    userCountryCode,
    // Yet another custom parameters to support MT cancellation reason
    ...(eventName === GoogleAnalyticsEvent.Refund && options.cancellationReasonCode
      ? { cancellation_reason_code: options.cancellationReasonCode }
      : {}),
    ...(eventName === GoogleAnalyticsEvent.Refund && options.cancellationReasonText
      ? { cancellation_reason: options.cancellationReasonText }
      : {}),
  };
};

export const getGaPurchaseImtuParams = ({
  transactionId,
  item,
  amount,
  userCountryCode,
  options,
}: {
  transactionId: string;
  item: firebase.analytics.Item;
  amount: number;
  userCountryCode: CountryCode;
  options: PurchaseImtuOptions;
}) => {
  const { paymentType } = options;

  // FIXME: For some reason not all data fields which are available in GA present in FirebaseAnalyticsTypes types
  const extendedItem: Dictionary<undefined | string | number | object> = {
    ...item,
    price_in_usd: amount,
    // in local currency
    // price: localAmount || amount,
    // FIXME: For some reason when localAmount is assigned to the "price" field then the value of the "price_in_usd" field also becomes equal to localAmount in the Big Query
    price: amount,
    // revenue means price * quantity
    item_revenue_in_usd: amount,
    // item_revenue: localAmount || amount,
    // FIXME: For some reason when localAmount is assigned to the "item_revenue" field then the value of the "item_revenue_in_usd" field also becomes equal to localAmount in the Big Query
    item_revenue: amount,
  };

  const purchaseParams: firebase.analytics.EventParams = {
    affiliation: PURCHASE_ITEM.BRAND,
    currency: PURCHASE_ITEM.CURRENCY,
    items: [extendedItem],
    value: amount,
    transaction_id: transactionId,
    event_category: '', // TODO:
  };

  // TODO: Purchase reporting with a dedicated logPurchase API does not accept custom parameters which are currently needed
  //  for querying most popular POC subPayers on the BMB side; later on the SQL query on the BMB side should be updated
  // analytics().logPurchase(purchaseParams)
  //   .catch((e) => {
  //     console.log("Cannot track GA purchase event", purchaseParams, e);
  //   });

  return {
    // Official params
    ...purchaseParams,
    userCountryCode,
    item_name: item.item_name, // ex: Claro - carrier name
    item_variant: item.item_variant, // ex: GT, MX - CountryCode
    // FIXME: We want to have that but it's not included into the FirebaseAnalyticsTypes.PurchaseEventParameters type
    payment_type: paymentType,
  };
};

export const isMtFlow = () => window.location.pathname.includes(BASE_PATHS.SEND_MONEY);
