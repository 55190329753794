import React from 'react';
import { Img } from 'react-image';

import { Flag, Modal } from '@components/common';

import CarrierLogoGeneric from '@components/modules/Imtu/components/CarrierLogoGeneric';

import { ModalProps } from '@root/interfaces/components/Modal';
import CountryCode from '@root/interfaces/CountryCode';

interface Props extends ModalProps {
  testId?: string;
  countryCode?: CountryCode;
  carrierLogoUrl?: string;
}

const DetailsModal: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { testId, countryCode, carrierLogoUrl, children, isOpen, onRequestClose } = props;

  const header = countryCode && (
    <div className="flex flex-row-reverse justify-end h-20">
      <div className="bg-gray-200 flex w-20 h-20 ml-[-20px] border-0 rounded-full overflow-hidden">
        <Flag className="w-full" code={countryCode} />
      </div>
      <div className="bg-gray-200 flex w-20 h-20 items-center flex-shrink-0 border-0 rounded-full overflow-hidden">
        <Img
          className="p-4"
          src={carrierLogoUrl || ''}
          unloader={<CarrierLogoGeneric />}
        />
      </div>
    </div>
  );

  return (
    <Modal
      header={header}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="max-w-md mx-auto"
      closeBtnDataTestId={testId}
    >
      {children}
    </Modal>
  );
};

export default DetailsModal;
