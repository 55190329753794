import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import ImtuPromoClass from '@root/models/ImtuPromoClass';

import { promoTermsText } from '../../constants';

import DetailsModal from './index';

interface Props extends React.ComponentProps<typeof DetailsModal> {
  promo?: ImtuPromoClass;
}

const PromoDetailsModal: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { testId, promo, isOpen, onRequestClose } = props;

  const { t } = useTranslation();

  return (
    <DetailsModal
      testId={testId}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      countryCode={promo?.countryCode}
      carrierLogoUrl={promo?.carrier.logoUrl}
    >
      <div className="flex flex-col gap-4">
        <div>
          <div className="text-2xl font-bold">{promo?.title}</div>
          {promo?.subTitle}
          {promo?.promoEndDate && (
            <div className="text-gray-400 capitalize">
              {t('expires')} {format(promo?.promoEndDate, 'MM/dd/yyyy h:mm a')}
            </div>
          )}
        </div>
        <div>
          <div className="text-gray-400 text-xl md:text-lg">
            {promo?.description.split('\r\n').map((l) => (
              <p key={l}>{l}</p>
            ))}
          </div>
        </div>
      </div>
      <div className="border-gray-200 border-t my-5" />
      <p className="md:text-sm">{t(promoTermsText)}</p>
    </DetailsModal>
  );
};

export default PromoDetailsModal;
