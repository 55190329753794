import React from 'react';

import { Icon } from '@components/common';

interface Props {
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  isOpen?: boolean;
}

const CountryCodeInputAddonAfter = (props: Props) => {
  const { onClick, isOpen } = props;
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex items-center h-full w-full"
      tabIndex={-1}
    >
      <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} className="mr-4" />
    </button>
  );
};

export default CountryCodeInputAddonAfter;
