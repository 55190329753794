import React from 'react';
import { observer } from 'mobx-react';

import useStore from '@root/hooks/useStore';

import { Grid, MediaMatcher } from '@components/common';

import { trackLogout } from '@services/analytics';

import { AppConfigStore, LoginStore, UserAccountStore } from '@root/stores';

import { getAvailableLanguageOptions } from '@helpers/language';

import NavigationMenuDropdown from './components/NavigationMenuDropdown';
import TopNavigationBar from './components/TopNavigationBar';

const HeaderNavigation = () => {
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const loginStore: LoginStore = useStore('LoginStore');
  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const handleLogoutClick = async () => {
    await loginStore.sessionLogOut();
    trackLogout(userAccountStore.financeProfile?.brCustomerId || ''); // TODO: replace bmbCustomerId to profile.id after completely switch to DTC backend
    window.location.reload();
  };

  const featureAvailability = appConfigStore.availableMainFeaturesCfg;

  const languageOptions = getAvailableLanguageOptions(
    userAccountStore.userCountryOfOrigin,
  );

  return (
    <Grid.Container>
      <MediaMatcher isMobile>
        <NavigationMenuDropdown
          featureAvailability={featureAvailability}
          languageLocale={userAccountStore.currentLanguageLocale}
          languagesOptions={languageOptions}
          onLogoutClick={handleLogoutClick}
          avatarAltText={userAccountStore.shortName}
          avatarUrl={userAccountStore.profile.avatarURL}
        />
      </MediaMatcher>

      <MediaMatcher isNotMobile>
        <TopNavigationBar
          featureAvailability={featureAvailability}
          languageLocale={userAccountStore.currentLanguageLocale}
          languagesOptions={languageOptions}
          avatarAltText={userAccountStore.shortName}
          avatarUrl={userAccountStore.profile.avatarURL}
          onLogoutClick={handleLogoutClick}
        />
      </MediaMatcher>
    </Grid.Container>
  );
};

export default observer(HeaderNavigation);
