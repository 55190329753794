import { ReactNode, Children, forwardRef, ForwardedRef } from 'react';

import { Grid } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  className?: string;
  children: ReactNode;
}
// bg-gradient-to-t from-white-70 to-transparent
const BottomControlsWrapper = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, className } = props;

    const elClassNames = normalizeStringCompound([
      className,
      'bottom-0 fixed z-100 center-fixed bg-white py-[4px] w-full page-sub-title flex items-center border-b border-gray-200 w-full backdrop-filter backdrop-blur-sm bg-opacity-80 firefox:bg-opacity-100 print:hidden',
    ]);

    const containerDivClassNames = normalizeStringCompound([
      'flex flex-col items-center md:justify-center md:flex-row-reverse md:px-14 md:py-2',
      Children.toArray(children).length > 1 ? 'md:justify-between' : 'md:justify-center',
    ]);

    return (
      <div className={elClassNames} ref={ref}>
        <Grid.Container>
          <Grid.Row>
            <Grid.Col className="m-auto" span={{ sm: 12, md: 11, lg: 7 }}>
              <Grid.MobileContainerWrapper>
                <div className={containerDivClassNames}>{children}</div>
              </Grid.MobileContainerWrapper>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      </div>
    );
  },
);

export default BottomControlsWrapper;
