import React from 'react';

import PATHS from '@root/routes/paths';

import ServiceTemplate from '@components/templates/ServiceTemplate';

import Calling from '@components/modules/Calling';

const CallingPage = () => {
  return (
    <ServiceTemplate>
      <Calling basePath={PATHS.CALLING} />
    </ServiceTemplate>
  );
};

export default CallingPage;
