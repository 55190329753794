import { useTranslation } from 'react-i18next';

import { Icon } from '@components/common';

interface Props {
  hasCards?: boolean;
}

const PlaceHolder = (props: Props) => {
  const { hasCards } = props;

  const { t } = useTranslation();

  return (
    <div className="font-medium text-blue-400 text-[16px] flex items-center">
      <div className="w-[30px] h-[30px] rounded-full bg-white flex items-center justify-center mr-[15px]">
        <Icon name={hasCards ? 'ChevronDownAlt' : 'PlusAlt'} className="text-[12px]" />
      </div>
      <span className="leading-none gap-4 space-y-2">
        {hasCards ? t('Select payment card') : t('Add payment card')}
      </span>
    </div>
  );
};

export default PlaceHolder;
