/* eslint-disable import/prefer-default-export */

import { LanguageLocale } from '@root/interfaces/Languages';

// workaround until we get all the marketing pages links available or at least redirected
export const getFallbackLocaleForLegalUrls = (langLocale: string): LanguageLocale => {
  const [lang, locale] = langLocale.split('-');
  if (['us', 'ca', 'gb'].includes(locale)) {
    return langLocale as LanguageLocale;
  }

  return `${lang}-us` as LanguageLocale;
};
