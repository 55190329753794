/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */

// remove keys from object which have certain values e.g. null, '123', undefined ,2
export const normalizeObject = (obj: { [key: string]: any }, replacers: any[]) => {
  Object.keys(obj).forEach((key) => {
    if (replacers.includes(obj[key])) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    }
  });
};
