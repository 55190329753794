import CryptoJS from 'crypto-js';

import { getSessionDataFromStorage } from '@services/auth';

const IFRAME_ID = 'online-metrix';

export const getThreatMetrixSessionId = () => {
  const session = getSessionDataFromStorage();
  return session?.profileId && session?.accessToken
    ? String(CryptoJS.SHA256(`${session.profileId}.${session.accessToken}`))
    : null;
};

const addIframe = (sessionId: string) => {
  const iframe = document.createElement('iframe');
  iframe.src = `${process.env.REACT_APP_THREAT_METRIX_API_URL}${sessionId}`;
  iframe.id = IFRAME_ID;
  iframe.width = '0px';
  iframe.height = '0px';
  document.body.appendChild(iframe);
};

export const initThreatMetrix = () => {
  document.getElementById(IFRAME_ID)?.remove();
  const threatMetrixClientSessionId = getThreatMetrixSessionId();
  if (threatMetrixClientSessionId) {
    addIframe(threatMetrixClientSessionId);
  }
};
