import { Languages } from '@root/interfaces/Languages';

const HARDCODED_VARS = {
  MT_SENDER_ADDRESS: '520 Broad Street', // force user add this type of data before it send transaction, decide how we store this data cause BE don't store
  MT_SENDER_CITY: 'Teaneck',
  MT_SENDER_POSTAL_CODE: '229873',
  MT_SENDER_STATE: 'NJ',
  MT_SENDER_COUNTRY: 'US',
  MT_DEFAULT_AMOUNT: 100,
  DEFAULT_SENDER_COUNTRY: 'US',
  DEFAULT_LANGUAGE: Languages.En,
};

export default HARDCODED_VARS;
