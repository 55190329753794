import { BrowserRouter, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';

import Login from '@components/modules/Login';

import Container from '@components/common/Grid/Container';
import OneScreenTemplate from '@components/templates/OneScreenTemplate';
import LanguageSwitcher from '@components/modules/HeaderNavigation/components/LanguageSwitcher';

import ROUTE_PATHS from '@root/routes/paths';

import { getAvailableLanguageOptions } from '@helpers/language';

const LoginPage = () => {
  const history = useHistory();

  const handleComplete = (postLoginRedirectUrl?: string) => {
    if (postLoginRedirectUrl) {
      window.location.href = postLoginRedirectUrl;
    } else {
      history.push(ROUTE_PATHS.BASE);
    }
  };

  return (
    <Container className="relative">
      <LanguageSwitcher
        className="!absolute right-[12px] md:right-[20px] top-[20px] md:top-[48px] text-black-100"
        languagesOptions={getAvailableLanguageOptions()}
      />
      <OneScreenTemplate>
        <BrowserRouter basename={ROUTE_PATHS.LOGIN}>
          <Login onComplete={handleComplete} />
        </BrowserRouter>
      </OneScreenTemplate>
    </Container>
  );
};

export default observer(LoginPage);
