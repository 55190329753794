export const LOGIN_PATHS = {
  BASE: '/',
  PHONE_CODE_VERIFICATION: '/phone-code-verification',
  EMAIL: '/email',
  EMAIL_SELECTION: '/email-selection',
  EMAIL_SENT: '/email-sent',
  EMAIL_IN_USE_ERROR: '/email-in-use-error',
  EMAIL_APPLE_RELAY_ERROR: '/email-apple-relay-error',
  FINANCE_PROFILE: '/finance-profile',
};

export const visibleBackButtonPaths = [
  LOGIN_PATHS.EMAIL,
  LOGIN_PATHS.PHONE_CODE_VERIFICATION,
];
