import { API_ERROR_MSG } from '@root/constants/errorMessages';

export const CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
} as { [key: string]: string };

export const FIREBASE_APP_NAMES = {
  AUTH: 'AUTH',
};

export const ERROR_CODES = {
  AUTH_INVALID_CAPTCHA: 'auth/captcha-check-failed',
  AUTH_INVALID_PHONE_CODE: 'auth/invalid-verification-code',
  AUTH_EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  AUTH_INVALID_EMAIL: 'auth/invalid-email',
  AUTH_EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
  AUTH_USER_DISABLED: 'auth/user-disabled',
  AUTH_UNSUPPORTED_BROWSER: 'auth/web-storage-unsupported',
};

export const FIREBASE_ERROR_MESSAGES_MAP = {
  [ERROR_CODES.AUTH_INVALID_PHONE_CODE]: API_ERROR_MSG.FIREBASE_PHONE_CODE_INVALID,
  [ERROR_CODES.AUTH_EXPIRED_ACTION_CODE]: API_ERROR_MSG.FIREBASE_EMAIL_LINK_EXPIRED,
  [ERROR_CODES.INVALID_ACTION_CODE]: API_ERROR_MSG.FIREBASE_EMAIL_LINK_EXPIRED,
  [ERROR_CODES.AUTH_INVALID_EMAIL]: API_ERROR_MSG.FIREBASE_EMAIL_INVALID,
  [ERROR_CODES.AUTH_EMAIL_ALREADY_IN_USE]: API_ERROR_MSG.FIREBASE_EMAIL_ALREADY_IN_USE,
  [ERROR_CODES.AUTH_USER_DISABLED]: API_ERROR_MSG.FIREBASE_USER_DISABLED,
  [ERROR_CODES.AUTH_UNSUPPORTED_BROWSER]: API_ERROR_MSG.FIREBASE_UNSUPPORTED_BROWSER,
  default: API_ERROR_MSG.FIREBASE_DEFAULT,
};
