import React from 'react';

import { Icon } from '@components/common';

interface Props {
  className?: string;
  isToggled?: boolean;
  onChange?(): void;
}

const ToggleButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { onChange, isToggled, className } = props;

  const handleChange = () => {
    if (onChange) {
      onChange();
    }
  };

  return (
    <Icon
      onClick={handleChange}
      className={className}
      name={isToggled ? 'Cross' : 'Menu'}
    />
  );
};

export default ToggleButton;
