import { Languages } from '@root/interfaces/Languages';
import CountryCode from '@root/interfaces/CountryCode';

import { LANGUAGES } from '@root/constants/languages';

export const getLanguageCode = (lang?: string): string | undefined => lang?.split('-')[0];

export const getLanguageCodeByAvailable = (lang?: string) => {
  const langCode = getLanguageCode(lang);

  if (langCode && Object.values(Languages).includes(langCode as Languages)) {
    return langCode as Languages;
  }

  return undefined;
};

export const getAvailableLanguageOptions = (userCountryOfOrigin?: CountryCode) => {
  const languagesOptions = [
    { value: Languages.En, text: LANGUAGES.en.name },
    { value: Languages.Es, text: LANGUAGES.es.name },
    { value: Languages.Fr, text: LANGUAGES.fr.name },
  ];
  if (userCountryOfOrigin === 'US') {
    return languagesOptions.filter((option) => {
      return option.value !== Languages.Fr;
    });
  }
  return languagesOptions;
};

export const getLanguageValues = () => {
  return Array.from(
    new Set(
      Object.keys(Languages).map((key) => {
        return key.toLowerCase();
      }),
    ),
  );
};
