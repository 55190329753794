export default function registerAppServiceWorker() {
  const swPath = `${process.env.PUBLIC_URL}/service-worker.js`;
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register(swPath).then(() => {
        if (process.env.NODE_ENV !== 'production') {
          // eslint-disable-next-line no-console
          console.log('Service worker registered');
        }
      });
    });
  }
}
