import { makeAutoObservable } from 'mobx';

import ImtuTransaction from '@root/models/ImtuTransaction';

import { fetchImtuTxns } from '@services/api/v1/dtcImtuK2';
import { showToast } from '@services/toasts';

class ImtuHistoryStore {
  transactions: ImtuTransaction[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getTransactions() {
    this.isLoading = true;
    try {
      this.transactions = await fetchImtuTxns();
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.isLoading = false;
    }
  }
}

export default ImtuHistoryStore;
