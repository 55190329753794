import axios, { AxiosRequestConfig } from 'axios';
import { dtcbProfileAxios } from '@services/axios';

import {
  UpdateUserProfileRequest,
  UserProfileResponse,
} from '@root/interfaces/contract/UserAccount';

import UserAccountClass from '@root/models/UserAccountClass';

import { normalizeObject } from '@utils/object';

import { DTC_PROFILE_ENDPOINTS } from '@services/api/v1/constants/dtc';

// DTC account

export const fetchUserProfile = async (accessToken?: string) => {
  let requestConfig: AxiosRequestConfig = {};
  if (accessToken) {
    requestConfig = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }
  const res = await dtcbProfileAxios.get<UserProfileResponse>(
    DTC_PROFILE_ENDPOINTS.PROFILE,
    {
      ...requestConfig,
    },
  );

  return new UserAccountClass(res.data);
};

export const updateUserProfile = async (params: UpdateUserProfileRequest) => {
  const data = {
    avatar_url: params.avatarUrl,
    dob: params.dob,
    firstname: params.firstName,
    lastname: params.lastName,
    language: params.language,
  };

  normalizeObject(data, [undefined]);

  const res = await dtcbProfileAxios.put<UserProfileResponse>(
    DTC_PROFILE_ENDPOINTS.PROFILE,
    data,
  );

  return new UserAccountClass(res.data);
};

export const uploadProfileAvatar = async (file: File) => {
  const uploadUrlRes = await dtcbProfileAxios.post<{
    object_url: string;
    upload_url: string;
  }>(`${DTC_PROFILE_ENDPOINTS.PROFILE}/avatar/uploadurl/${file.type.split('/')[1]}`);

  const { object_url: avatarUrl, upload_url: uploadUrl } = uploadUrlRes.data;

  await axios.put(uploadUrl, file, {
    headers: {
      'Content-type': file.type,
    },
  });

  return avatarUrl;
};
