import React, { useState, useEffect } from 'react';
import { Formik, Form as FormikForm, FormikValues } from 'formik';

import { FormProps } from '@root/interfaces/components/Form';

const Form = <T extends FormikValues>(props: FormProps<T>) => {
  const { config, className, children } = props;
  const { onFocus, onBlur, onChange, onSubmitValidationError, ...formikConfig } = config;

  const [values, setValues] = useState<T | {}>({});

  useEffect(() => {
    if (onChange) {
      onChange(values);
    }
  }, [values]);

  const handleChange = (e: React.FocusEvent<HTMLFormElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <Formik {...formikConfig}>
      {(formik) => (
        <FormikForm
          onSubmit={async (params) => {
            params.preventDefault();
            const validationResult = await formik.validateForm();
            formik.handleSubmit(params);
            if (Object.keys(validationResult).length) {
              onSubmitValidationError?.();
            }
          }}
          className={className}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange ? handleChange : undefined}
          data-recording-ignore="mask" // data-recording-ignore="mask" - we keep all forms data protected from Smartlook analytics
        >
          {typeof children === 'function' ? children(formik) : children}
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
