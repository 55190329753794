import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  isVertical?: boolean;
  className?: string;
}

const Divider: FC<React.PropsWithChildren<Props>> = (props) => {
  const { className, isVertical } = props;

  const classNames = normalizeStringCompound([
    isVertical ? 'w-px h-4 inline-block align-middle mx-3 relative' : 'border-t my-3',
    className,
  ]);

  return <div className={classNames} />;
};

Divider.defaultProps = {
  isVertical: false,
};

export default Divider;
