import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  isOpen?: boolean;
  className?: string;
}

const overlayInlineStyles: React.CSSProperties = {
  top: '-0.5rem',
  left: '-0.5rem',
  width: 'calc(100% + 1rem)',
  height: 'calc(100% + 1rem)',
  boxShadow: '0 0 4px rgba(0, 0, 0, 0.2)', // TODO: to add as Tailwind util
};

const insetShadowInlineStyles: React.CSSProperties = {
  boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.2)',
};

const DropdownEar: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, isOpen, className } = props;

  const classNames = normalizeStringCompound(['relative z-10', className]);

  return (
    <div className={classNames}>
      {isOpen && (
        <div className="absolute rounded-t-2xl w-full h-full" style={overlayInlineStyles}>
          <div className="overflow-hidden absolute h-5 w-5 bg-white -right-5 bottom-0">
            <div
              className="w-15 h-15 rounded-2xl absolute bottom-0"
              style={insetShadowInlineStyles}
            />
          </div>
          <div className="absolute w-full h-2 bg-white bottom-0 left-0 transform translate-y-1/2" />
        </div>
      )}
      {children}
    </div>
  );
};

export default DropdownEar;
