import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { Typography, Grid, UserAvatar } from '@components/common';

import useStore from '@root/hooks/useStore';
import { AppConfigStore, UserAccountStore } from '@root/stores';

import { normalizeStringCompound } from '@utils/string';

import ROUTE_PATHS from '@root/routes/paths';

import './styles.scss';

import AccountOptionButton, {
  AccountOptionButtonProps,
} from './components/AccountOptionButton';

type Button = AccountOptionButtonProps & { isHidden?: boolean };

const DATA_TEST_ID_PREFIX = 'user-account';

const UserAccount = () => {
  const { t } = useTranslation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const availableFeatures = appConfigStore.availableMainFeaturesCfg;

  const config: Button[] = [
    {
      title: t('Send Money'),
      iconName: 'MoneyTransfer',
      link: ROUTE_PATHS.MT_MODAL,
      dataTestId: `${DATA_TEST_ID_PREFIX}-mt-btn`,
      isHidden: !appConfigStore.isMtAvailableForCountry(
        userAccountStore.userCountryOfOrigin,
      ),
    },
    {
      title: t('Send Mobile Top-Up'),
      iconName: 'MobileTopUp',
      link: ROUTE_PATHS.IMTU,
      dataTestId: `${DATA_TEST_ID_PREFIX}-mtu-btn`,
      isHidden: !availableFeatures.isImtuAvailable,
    },
    {
      title: t('Calling'),
      iconName: 'Repeat',
      link: ROUTE_PATHS.CALLING,
      dataTestId: `${DATA_TEST_ID_PREFIX}-calling-btn`,
      isHidden: !availableFeatures.isCallingAvailable,
    },
  ];

  const welcomeText = normalizeStringCompound(
    [t('Welcome'), userAccountStore.shortName],
    ', ',
  );

  return (
    <Grid.Row>
      <Grid.Col span={{ sm: 12, lg: 10 }}>
        <div className="mb-12 text-center">
          <Typography.Title level={3} className="mb-5" data-recording-sensitive>
            {welcomeText}
          </Typography.Title>
          <div className="mx-auto h-32 w-32">
            <UserAvatar
              avatarUrl={userAccountStore.profile.avatarURL}
              altText={userAccountStore.shortName}
            />
          </div>
        </div>
        <div className="grid gap-6 md:grid-cols-4">
          {config.map(
            (item) =>
              !item.isHidden && (
                <AccountOptionButton
                  {...item}
                  className="account-option-button col-span-2"
                />
              ),
          )}
        </div>
      </Grid.Col>
    </Grid.Row>
  );
};

export default observer(UserAccount);
