import React, { FC } from 'react';

const ErrorAlert: FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;

  return (
    <div className="text-red-400 bg-red-200 bg-opacity-25 border border-red-400 p-4 mb-4 rounded-md">
      {children}
    </div>
  );
};

export default ErrorAlert;
