/* eslint-disable import/prefer-default-export */

import CountryCode from '@root/interfaces/CountryCode';

export const MARKETING_SITE_COUNTRIES: CountryCode[] = [
  'US',
  'CA',
  'ES',
  'DE',
  'SG',
  'AU',
  'GB',
];
