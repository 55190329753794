import React, { useEffect, useRef } from 'react';

const ESC_KEY_CODE = 'Escape';

const useOnClickOutside = (
  callback: (e: MouseEvent | KeyboardEvent) => void,
  ...elRefs: React.RefObject<HTMLElement | undefined>[]
) => {
  // track the latest ver of the callback in the ref itself
  const callbackRef = useRef<typeof callback>();
  callbackRef.current = callback;

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        elRefs
          ?.filter((r) => r?.current)
          .every((ref) => {
            return !ref?.current || !ref?.current.contains(e.target as Node);
          })
      ) {
        if (callbackRef?.current) {
          callbackRef.current(e);
        }
      }
    };

    const handleEscKeyDown = (e: KeyboardEvent) => {
      if (e.code === ESC_KEY_CODE && callbackRef.current) {
        callbackRef.current(e);
      }
    };

    document.addEventListener('mousedown', handleClickOutside, true);
    document.addEventListener('keydown', handleEscKeyDown, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('keydown', handleEscKeyDown, true);
    };
  }, [callbackRef, elRefs]);
};

export default useOnClickOutside;
