import React from 'react';

import SimpleBar, { Props as ModalProps } from 'simplebar-react';

import './styles.scss';

const Scrollbar = (props: ModalProps) => {
  const { children, ...restProps } = props;

  return <SimpleBar {...restProps}>{children}</SimpleBar>;
};

export default Scrollbar;
