import React from 'react';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import { ReactComponent as MasterCardIcon } from '@src/static/assets/images/pcards/master-card.svg';
import { ReactComponent as VisaIcon } from '@src/static/assets/images/pcards/visa.svg';
import { ReactComponent as AmExIcon } from '@src/static/assets/images/pcards/american-express.svg';
import { ReactComponent as DiscoverIcon } from '@src/static/assets/images/pcards/discover.svg';

interface Props {
  issuer: PaymentCardIssuerKeys;
  className?: string;
}

const getPaymentCardIcon = ({
  issuer,
  className,
}: {
  issuer: PaymentCardIssuerKeys;
  className?: string;
}) => {
  return {
    MC: <MasterCardIcon className={className} />,
    V: <VisaIcon className={className} />,
    AE: <AmExIcon className={className} />,
    D: <DiscoverIcon className={className} />,
  }[issuer];
};

const PaymentCardIcon: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { issuer, className } = props;

  return getPaymentCardIcon({ issuer, className });
};

export default PaymentCardIcon;
