import { overwrite, getName } from 'country-list';
import CountryCode from '@root/interfaces/CountryCode';

overwrite([
  {
    code: 'US',
    name: 'United States',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
  },
]);

// eslint-disable-next-line import/prefer-default-export
export const getCountryNameByCode = (countryCode?: CountryCode) => {
  if (countryCode) {
    return getName(countryCode);
  }

  return undefined;
};
