import React, { ReactElement } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import qs from 'qs';

import PATHS from '@root/routes/paths';

import { AUTH_DEFAULT_COUNTRY, AUTH_REDIRECT_URL } from '@root/constants/queryParams';
import { LANGUAGE_STORE_KEY } from '@root/constants/languages';
import HARDCODED_VARS from '@root/constants/hardcodedDevVariables';

import { getIsLoggedInStatusFromStorage } from '@services/auth';

const getRedirectUrlWithLanguage = (query: string): string => {
  const parsedQuery = qs.parse(query, { ignoreQueryPrefix: true });
  const {
    [LANGUAGE_STORE_KEY]: queryLanguage = HARDCODED_VARS.DEFAULT_LANGUAGE,
    [AUTH_DEFAULT_COUNTRY]: queryAuthDefaultCountry = HARDCODED_VARS.DEFAULT_SENDER_COUNTRY,
  } = parsedQuery;
  return `${PATHS.LOGIN}?${AUTH_REDIRECT_URL}=${encodeURIComponent(
    window.location.href,
  )}&${LANGUAGE_STORE_KEY}=${queryLanguage}&${AUTH_DEFAULT_COUNTRY}=${queryAuthDefaultCountry}`;
};

export default ({ component: Component, ...rest }: RouteProps): ReactElement => {
  return getIsLoggedInStatusFromStorage() ? (
    <Route {...rest} component={Component} />
  ) : (
    <Redirect to={getRedirectUrlWithLanguage(window.location.search)} />
  );
};
