/* eslint-disable react/no-array-index-key */
import React, { FC, useState, useEffect } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { PaginationProps } from '@root/interfaces/components/Pagination';

import { isString } from '@utils/typeGuards';

import { getPaginationArray } from './helpers';

import Button from '../Button';
import PageNumber from './PageNumber';

import './styles.scss';

const Pagination: FC<React.PropsWithChildren<PaginationProps>> = (props) => {
  const size = 'sm';
  const {
    prevButtonText,
    nextButtonText,
    className,
    currentPageIdx,
    total,
    onChange,
    dataTestId,
  } = props;

  const [pageIdx, setPageIdx] = useState(currentPageIdx);

  useEffect(() => {
    setPageIdx(currentPageIdx);
  }, [currentPageIdx]);

  const handleSetNewPageIdx = (index: number) => {
    setPageIdx(index);

    if (onChange) {
      onChange(index);
    }
  };

  const handlePrevClick = () => {
    if (pageIdx > 0) {
      const newPage = pageIdx - 1;

      handleSetNewPageIdx(newPage);
    }
  };

  const handleNextClick = () => {
    if (pageIdx < total) {
      const newPage = pageIdx + 1;

      handleSetNewPageIdx(newPage);
    }
  };

  const paginationOptions = getPaginationArray(pageIdx, total);

  return (
    <ul className={normalizeStringCompound(['pagination flex', className])}>
      <li>
        <Button
          shape="text"
          size={size}
          onClick={handlePrevClick}
          disabled={pageIdx <= 0}
          dataTestId={`${dataTestId}-prev`}
        >
          {prevButtonText}
        </Button>
      </li>

      {paginationOptions.map((option, index) => (
        <li key={index}>
          {isString(option) ? (
            <span className="text-gray-400">...</span>
          ) : (
            <PageNumber
              pageIdx={option.index}
              pageLabel={option.value}
              isActive={option.index === pageIdx}
              onClick={handleSetNewPageIdx}
              dataTestId={`${dataTestId}-page-${index}`}
            />
          )}
        </li>
      ))}

      <li>
        <Button
          shape="text"
          size={size}
          onClick={handleNextClick}
          disabled={pageIdx + 1 === total}
          dataTestId={`${dataTestId}-next`}
        >
          {nextButtonText}
        </Button>
      </li>
    </ul>
  );
};

Pagination.defaultProps = {
  prevButtonText: 'Previous',
  nextButtonText: 'Next',
};

export default Pagination;
