import { fromUnixTime } from 'date-fns';

import { RechargeHistory } from '@root/interfaces/contract/callingRechargePinless/RechargeHistory';

class CallingRechargeTransactionClass {
  id: string;

  amount: number;

  currencySymbol: string;

  currency: string;

  isPromotion: boolean;

  isRefund: boolean;

  date: Date;

  constructor(data: RechargeHistory) {
    this.date = fromUnixTime(Math.floor(data.created_at / 1000));
    // In case of refund we always receive { "id": "0" } which makes no sense. Let's fix it on our side.
    this.id =
      data.transaction_id === '0' ? String(this.date.getTime()) : data.transaction_id;
    this.amount = Number(data.amount.display_amount);
    this.currencySymbol = data.amount.currency_symbol;
    this.currency = data.amount.currency;
    this.isPromotion = data.billing_type === 'Promotion';
    this.isRefund = ['Credit Card Refund', 'Debit Card Refund'].includes(
      data.billing_type,
    );
  }
}

export default CallingRechargeTransactionClass;
