// https://swiperjs.com/react/
import React, { FC, PropsWithChildren } from 'react';
import SwiperCore, {
  SwiperOptions,
  Pagination,
  Navigation,
  Mousewheel,
  Keyboard,
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { normalizeStringCompound } from '@utils/string';

import 'swiper/swiper.scss';
import './styles.scss';

interface Props extends SwiperOptions {
  style?: React.CSSProperties;
  isOverflowVisible?: boolean;
  hasAddOption?: boolean;
  isNavigationEnabled?: boolean;
  isPaginationEnabled?: boolean;
}

SwiperCore.use([Pagination, Navigation, Mousewheel, Keyboard]);

const Carousel: FC<PropsWithChildren<Props>> = (props) => {
  const {
    children,
    slideToClickedSlide = true,
    isOverflowVisible,
    hasAddOption,
    isNavigationEnabled,
    isPaginationEnabled,
    ...rest
  } = props;

  const renderBullet = (index: number, bulletClassName: string) => {
    const isAddOption = hasAddOption && index === 0;

    const bulletClassNames = normalizeStringCompound([
      bulletClassName,
      isAddOption ? 'plus' : 'dot',
    ]);

    return `<div class="${bulletClassNames}">${
      isAddOption ? '<span class="inset-center">+<span/>' : ''
    }</div>`;
  };

  const slides = React.Children.map(children, (child) => {
    return <SwiperSlide className="flex justify-center">{child}</SwiperSlide>;
  });

  const isPaginationActive = isPaginationEnabled && slides && slides.length > 1;

  return (
    <Swiper
      slideToClickedSlide={slideToClickedSlide}
      style={isOverflowVisible ? { overflow: 'visible' } : undefined}
      threshold={5}
      pagination={isPaginationActive ? { clickable: true, renderBullet } : false}
      navigation={Boolean(isNavigationEnabled)}
      mousewheel
      {...rest}
    >
      {slides}
    </Swiper>
  );
};

export default Carousel;
