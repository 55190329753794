export const defaultPhoneMask = '9999999999999'; // prefix+dialCode+' '+defaultFormat

export const uniqFormatMasks: { [key: string]: string } = {
  AR: '99 99999999',
  AU: '99 9999 9999',
  BY: '99 999 99 99',
  BE: '999 99 99 99',
  BD: '999 999 9999',
  BR: '99 999999999',
  CA: '999 999 9999',
  CI: '99 99 999 999',
  CN: '99 999999999',
  CR: '9999 9999',
  CY: '99 999999',
  DK: '99 99 99 99',
  SV: '9999 9999',
  EE: '9999 999999',
  FI: '99 999 99 99',
  FR: '9 99 99 99 99',
  DE: '9999 99999999',
  GT: '9999 9999',
  HT: '9999 9999',
  HK: '9999 9999',
  IS: '999 9999',
  IN: '99999 99999',
  IE: '99 9999999',
  IL: '999 999 9999',
  IT: '999 9999999',
  JP: '99 9999 9999',
  KZ: '999 999 99 99',
  MY: '99 9999 9999',
  MX: '999 999 9999',
  MD: '99 99 99 99',
  NL: '99 99999999',
  NZ: '999 999 9999',
  NO: '999 99 999',
  PK: '999 9999999',
  PH: '9999 9999999',
  PL: '999 999 999',
  RU: '999 999 99 99',
  SG: '9999 9999',
  KR: '999 9999 9999',
  ES: '999 999 999',
  SE: '999 999 999',
  CH: '99 999 99 99',
  TR: '999 999 99 99',
  UA: '99 999 99 99',
  GB: '9999 999999',
  US: '999 999 9999',
  VA: '99 9999 9999',
};

// AE is missed Arabic Emirates
