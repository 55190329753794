import React from 'react';

import { Scrollbar } from '@components/common';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import useList from '@root/hooks/useList';

import { normalizeStringCompound } from '@utils/string';
import { Item, OperationItem } from './OperationItem';

interface Props extends TestIdDataAttr {
  data: OperationItem[];
  selectedItem?: OperationItem;
  onSelect: (e: React.MouseEvent, value: OperationItem) => void;
  className?: string;
}

function OperationsHistoryList(props: Props) {
  const { data, selectedItem, onSelect, className, dataTestId } = props;

  const [applySelectHandler, localSelectedItem] = useList(data, onSelect, selectedItem);

  const classNames = normalizeStringCompound(['flex flex-row items-start ', className]);

  return (
    <Scrollbar>
      <div className={classNames}>
        {data.map((item, index) => {
          return (
            <Item
              key={item.id}
              data={item}
              onSelect={applySelectHandler}
              isActive={localSelectedItem?.id === item.id}
              dataTestId={`${dataTestId}-${index}`}
            />
          );
        })}
      </div>
    </Scrollbar>
  );
}

export default OperationsHistoryList;
