/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as stores from './index';

const createStores = (allStores: { [key: string]: any }) => {
  return Object.keys(allStores).reduce(
    (accum: { [key: string]: { [key: string]: any } }, storeKey) => {
      accum[storeKey] = new allStores[storeKey]();
      return accum;
    },
    {},
  );
};

export default createStores(stores);
