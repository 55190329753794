import OriginTypography from './Typography';
import Text from './Text';
import Paragraph from './Paragraph';
import Link from './Link';
import Title from './Title';

import './styles.scss';

type TypographyProps = typeof OriginTypography & {
  Link: typeof Link;
  Paragraph: typeof Paragraph;
  Text: typeof Text;
  Title: typeof Title;
};

const Typography = OriginTypography as TypographyProps;

Typography.Link = Link;
Typography.Paragraph = Paragraph;
Typography.Text = Text;
Typography.Title = Title;

export default Typography;
