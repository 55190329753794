import React, { FC, PropsWithChildren } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { GridContainerProps } from '@root/interfaces/components/Grid';

const MobileContainerWrapper: FC<PropsWithChildren<GridContainerProps>> = (props) => {
  const { children, className, style } = props;

  const classNames = normalizeStringCompound([
    'mobile-container-wrapper print:max-w-full',
    className,
  ]);

  return (
    <div style={style} className={classNames}>
      {children}
    </div>
  );
};

export default MobileContainerWrapper;
