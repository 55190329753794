import React, { useState } from 'react';

import ImtuProductClass from '@root/models/ImtuProductClass';

import ImtuProductCard from '@components/modules/Imtu/components/CardItem/ImtuProductCard';
import ProductDetailsModal from '@components/modules/Imtu/components/DetailsModal/ProductDetailsModal';

interface Props {
  data: ImtuProductClass[];
  selectedItemId?: string;
  onItemSelect?(id: string): void;
}

// there must be a better place to use Modal - some kind of ProductsListWithDetails or even ProductList module
const ProductsList: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { data, selectedItemId, onItemSelect } = props;

  const [isDetailsModalVisible, setDetailsModalVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState<ImtuProductClass | undefined>(
    undefined,
  );

  const showDetailsModal = () => {
    setDetailsModalVisible(true);
  };

  const hideDetailsModal = () => {
    setDetailsModalVisible(false);
  };

  const handleProductSelect = (e: React.MouseEvent, id: string) => {
    onItemSelect?.(id);
  };

  const handleProductDetailsClick = (e: React.MouseEvent, value: string) => {
    const productDetails = data.find((item) => {
      return item.code === value;
    });
    if (productDetails) {
      setSelectedDetails(productDetails);
      showDetailsModal();
    }
  };

  const handleProductDetailsClose = () => {
    hideDetailsModal();
    setSelectedDetails(undefined);
  };

  return (
    <>
      {data.map((item) => {
        return (
          <ImtuProductCard
            key={item.code}
            data={item}
            isActive={item.code === selectedItemId}
            onClick={handleProductSelect}
            onDetailsClick={handleProductDetailsClick}
          />
        );
      })}
      <ProductDetailsModal
        product={selectedDetails}
        isOpen={isDetailsModalVisible}
        onRequestClose={handleProductDetailsClose}
      />
    </>
  );
};

export default ProductsList;
