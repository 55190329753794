import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';

import { liveChatStart } from '@services/liveChat';

import { UserAvatar } from '@components/common';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { LanguageOption } from '@root/interfaces/components/LanguageSwitcher';

import BASE_PATHS from '@root/routes/paths';

import { normalizeStringCompound } from '@utils/string';

import LanguageSwitcher from '../LanguageSwitcher';
import NavigationBar from './ActionsList';

import { NavigationListItemProps } from '../../types';

import './styles.scss';

interface Props {
  userAvatarUrl: string | null;
  avatarAltText?: string;
  languagesOptions: LanguageOption[];
  onLogoutClick?(): void;
}

const ANIMATION_DURATION = 150;

const UserAvatarDropdownMenu = (props: Props) => {
  const { avatarAltText, userAvatarUrl, languagesOptions, onLogoutClick } = props;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const userAvatarRef = useRef<HTMLButtonElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  const hideNavigationBar = () => {
    setIsOpen(false);
  };

  useOnClickOutside(hideNavigationBar, dropdownRef, userAvatarRef);

  const { t } = useTranslation();

  const handleChatLinkClick = () => {
    liveChatStart();
  };

  const handleLogoutClick = () => {
    if (onLogoutClick) {
      onLogoutClick();
    }
  };

  // TODO: put in constants
  const NAVIGATION_SCHEME: NavigationListItemProps = [
    {
      id: 'account',
      path: BASE_PATHS.ACCOUNT,
      name: t('Dashboard'),
    },
    {
      id: 'profile',
      path: BASE_PATHS.ACCOUNT_PROFILE,
      name: t('My Profile'),
    },
    {
      id: 'history',
      path: BASE_PATHS.TRANSACTIONS_HISTORY,
      name: t('Transaction History'),
    },
    {
      id: 'chat',
      name: t('Chat'),
      onClick: handleChatLinkClick,
    },
    {
      id: 'logout',
      name: t('Logout'),
      onClick: handleLogoutClick,
    },
  ];

  const handleBtnClick = () => {
    setIsOpen((isNavOpen) => !isNavOpen);
    userAvatarRef.current?.blur();
  };

  const userAvatarListItemClassNames = normalizeStringCompound([
    'flex-shrink-0 self-stretch h-full flex items-center main-navigation-item',
    isOpen ? 'user-avatar_active' : 'user-avatar',
  ]);

  return (
    <div className="relative h-full flex justify-end">
      <ul className="flex items-center justify-end h-full">
        <li className="flex-shrink-0 mr-4">
          <LanguageSwitcher
            className="md:text-white"
            languagesOptions={languagesOptions}
          />
        </li>
        <li className={userAvatarListItemClassNames}>
          <button
            onClick={handleBtnClick}
            className="border border-white rounded-full w-8 h-8"
            aria-label="profile navigation"
            ref={userAvatarRef}
          >
            <UserAvatar avatarUrl={userAvatarUrl} altText={avatarAltText} />
          </button>
        </li>
      </ul>
      {/* with transitions */}
      <CSSTransition
        in={isOpen}
        timeout={ANIMATION_DURATION}
        unmountOnExit
        classNames="user-actions-bar"
      >
        <div
          ref={dropdownRef}
          className="absolute right-0 transform h-12 m-h-full"
          style={{ top: '96%' }} // to not create separate stylesheet
        >
          <NavigationBar links={NAVIGATION_SCHEME} onItemSelect={hideNavigationBar} />
        </div>
      </CSSTransition>
    </div>
  );
};

export default UserAvatarDropdownMenu;
