/* eslint-disable import/prefer-default-export */

import { LanguageLocale } from '@root/interfaces/Languages';

const IMTU_TOC_SHORT_TEXT_TPL_US =
  'All products and services for domestic and international mobile recharge ("MTU") processed through BOSS Revolution are subject to terms and conditions and will only recharge the airtime of an existing prepaid mobile phone of certain mobile operators in certain countries. See {{url}} for complete terms regarding the MTU service. IDT is not responsible for any error due to incorrect information supplied by purchaser/sender to process the MTU transaction. All MTU transactions are final and may not be exchanged, canceled or refunded, except in IDT’s sole discretion. The MTU service is provided by IDT Domestic Telecom, Inc. and/or its affiliates (collectively, "IDT").';
// t('All products and services for domestic and international mobile recharge ("MTU") processed through BOSS Revolution are subject to terms and conditions and will only recharge the airtime of an existing prepaid mobile phone of certain mobile operators in certain countries. See {{url}} for complete terms regarding the MTU service. IDT is not responsible for any error due to incorrect information supplied by purchaser/sender to process the MTU transaction. All MTU transactions are final and may not be exchanged, canceled or refunded, except in IDT’s sole discretion. The MTU service is provided by IDT Domestic Telecom, Inc. and/or its affiliates (collectively, "IDT").')

export const IMTU_TOC_SHORT_TEXT_TPL = {
  US: IMTU_TOC_SHORT_TEXT_TPL_US,
  CA: IMTU_TOC_SHORT_TEXT_TPL_US, // this is the same. Checked on CA marketing pages
};

export const IMTU_TOC_TERMS_DISPLAY_URLS = {
  [LanguageLocale.EnUs]: 'www.bossrevolution.com/terms',
  [LanguageLocale.EsUs]: 'www.bossrevolution.com/terms',
  [LanguageLocale.EnCa]: 'www.bossrevolution.com/terms',
  [LanguageLocale.EsCa]: 'www.bossrevolution.com/terms',
  [LanguageLocale.FrCa]: 'www.bossrevolution.com/terms', // US and CA urls are same, but it will be different for other countries
};
