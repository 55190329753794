import { deserializeCardToken } from '@helpers/paymentCards';
import { TokenizerAction } from '@root/interfaces/contract/Tokenizer';
import { SERVICES_URL } from '@services/api/v1/constants/cdnAndOthers';

// eslint-disable-next-line import/prefer-default-export
export const generateToken = async (data: string, action: TokenizerAction) => {
  // TODO: switch to axios
  const response = await fetch(
    `${SERVICES_URL.CARDSEC}/cs?action=${action}&data=${data}`,
  );
  const token = await response.text();

  return deserializeCardToken(token);
};
