import React from 'react';
import { useTranslation } from 'react-i18next';

import { AutoRechargeIcon } from '@components/common/Icons';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { normalizeStringCompound } from '@utils/string';

interface Props extends TestIdDataAttr {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isArEnabled?: boolean;
  isDisabled?: boolean;
}

const AutoRechargeButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { isDisabled, isArEnabled, dataTestId, onClick } = props;

  const { t } = useTranslation();

  const btnClassNames = normalizeStringCompound([
    'flex flex-col items-center',
    isArEnabled ? 'text-green-200' : 'text-gray-400',
  ]);

  const descriptionClassNames = normalizeStringCompound([
    'text-3xl font-bold first-letter:uppercase',
    isArEnabled ? 'text-green-200' : 'text-red-400',
  ]);

  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={btnClassNames}
      data-test-id={dataTestId}
    >
      <div className="w-24 h-24 p-2">
        <AutoRechargeIcon />
      </div>
      <div className={descriptionClassNames}>
        {isArEnabled ? t('auto-recharge is on') : t('auto-recharge is off')}
      </div>
    </button>
  );
};

export default AutoRechargeButton;
