class GoogleAnalytics {
  trackerName?: string;

  getTrackerName = () => {
    if (this.trackerName) {
      return this.trackerName;
    }

    const trackers = window.ga?.getAll?.();
    const trackerName = trackers?.[0]?.get('name');
    this.trackerName = trackerName;
    return trackerName;
  };

  logEvent = (name: string, params?: { [key: string]: unknown }) => {
    window?.ga?.(`${this.getTrackerName()}.send`, 'event', {
      eventCategory: name, // TODO: no idea on their old Google Analytics event categories
      eventAction: name, // TODO: no idea on their old Google Analytics event actions
      eventLabel: name,
      transport: 'beacon',
      ...params,
    });
  };

  trackTransactionAction = (name: string, params?: { [key: string]: unknown }) => {
    window?.ga?.(`${this.getTrackerName()}:setAction`, name, {
      eventCategory: name, // TODO: no idea on their old Google Analytics event categories
      eventAction: name, // TODO: no idea on their old Google Analytics event actions
      eventLabel: name,
      transport: 'beacon',
      ...params,
    });
    window.ga?.(`${this.getTrackerName()}.send`, 'pageview');
  };

  trackPageView = (location: string) => {
    window.ga?.(`${this.getTrackerName()}.set`, 'page', location);
    window.ga?.(`${this.getTrackerName()}.send`, 'pageview');
  };

  setUserId = (userId: string) => {
    window.ga?.(`${this.getTrackerName()}.set`, 'userId', userId);
  };

  setUserProperties = (properties: { [key: string]: unknown }) => {
    window.ga?.(`${this.getTrackerName()}.set`, 'user_properties', properties);
  };
}

export default GoogleAnalytics;
