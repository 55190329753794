import React from 'react';

import { Icon } from '@components/common';

import { LanguageOptionProps } from '@root/interfaces/components/LanguageSwitcher';
import { normalizeStringCompound } from '@utils/string';

const LanguageOptionItem = (props: LanguageOptionProps) => {
  const { onClick, option, isSelected } = props;

  const handleClick = () => {
    onClick(option);
  };

  const checkmarkClassNames = normalizeStringCompound([
    'w-5 h-5 rounded-full border border-black-100 mr-3 flex items-center justify-center',
    isSelected ? 'bg-black-100 text-white' : 'text-black-100',
  ]);

  return (
    <li className="not-last-child:mb-4 leading-none text-left group bg-bl">
      <button onClick={handleClick} className="flex items-center hover:text-blue-400">
        <div className={checkmarkClassNames}>
          {isSelected && <Icon name="Checkmark" />}
        </div>
        <span>{option.text}</span>
      </button>
    </li>
  );
};

export default LanguageOptionItem;
