import React, { FC, useState, useEffect, useMemo } from 'react';
import { FormikConfig } from 'formik';
import { useTranslation } from 'react-i18next';

import { Form } from '@components/common';

import { getTimeFromMs } from '@utils/date';
import Yup from '@utils/validation/yup';

import useInterval from '@root/hooks/useInterval';

import { trackButtonPress } from '@services/analytics';

import { AnalyticsContext, AnalyticsScreen } from '@root/interfaces/Analytics';

import { VERIFICATION_CODE_MASK } from '@root/constants/inputMasks';

import SubmitButton from '../../../components/SubmitButton';

export interface PhoneVerificationFormValues {
  verificationCode: string;
}

interface Props {
  onSubmit: (values: PhoneVerificationFormValues) => void;
  onResend: () => void;
  dataTestPrefix?: string;
}

const RESEND_TIMEOUT = 120000;

const BTN_TEXT = {
  RESEND_SMS: 'Resend SMS',
  SUBMIT: 'Submit',
};

const EnterPhoneNumber: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onSubmit, onResend, dataTestPrefix } = props;
  const { t } = useTranslation();

  const [isResendCounterVisible, setIsResendCounterVisible] = useState(false);
  const [sentTime, setSentTime] = useState(RESEND_TIMEOUT);

  const handleSubmit = (values: PhoneVerificationFormValues) => {
    trackButtonPress({
      button_name: BTN_TEXT.SUBMIT,
      screen: AnalyticsScreen.ConfirmSmsCode,
      context: AnalyticsContext.Login,
    });
    onSubmit(values);
  };

  const CODE_LENGTH = 6;

  const formInitializeProps: FormikConfig<PhoneVerificationFormValues> = useMemo(
    () => ({
      initialValues: { verificationCode: '' },
      onSubmit: handleSubmit,
      validationSchema: Yup.object().shape({
        verificationCode: Yup.string()
          .length(CODE_LENGTH, t('Verification code is not valid. Please try again.'))
          .required(),
      }),
      validateOnMount: true,
    }),
    [],
  );

  useEffect(() => {
    setIsResendCounterVisible(true);
  }, []);

  useInterval(
    () => {
      const leftMs = sentTime - 1000;
      if (leftMs <= 0) {
        setSentTime(RESEND_TIMEOUT);
        setIsResendCounterVisible(false);
      } else {
        setSentTime(leftMs);
      }
    },
    1000,
    isResendCounterVisible,
  );

  const handleResendClick = () => {
    trackButtonPress({
      button_name: BTN_TEXT.RESEND_SMS,
      screen: AnalyticsScreen.ConfirmSmsCode,
      context: AnalyticsContext.Login,
    });
    setIsResendCounterVisible(true);
    onResend();
  };

  return (
    <>
      <Form config={formInitializeProps}>
        <Form.Field
          type="maskedText"
          mask={VERIFICATION_CODE_MASK}
          name="verificationCode"
          placeholder={t('Enter verification code')}
          dataTestId={`${dataTestPrefix}-input`}
          autoFocus
        />
        {/* TODO: don't allow send request if field is empty */}
        <Form.Button
          customComponent={SubmitButton}
          dataTestId={`${dataTestPrefix}-submit-btn`}
        >
          {t(BTN_TEXT.SUBMIT)}
        </Form.Button>
      </Form>
      <div className="flex items-center text-xl justify-between mt-6 mb-4">
        {isResendCounterVisible ? (
          <>
            <span>{t(BTN_TEXT.RESEND_SMS)}</span>
            <span>{getTimeFromMs(sentTime)}</span>
          </>
        ) : (
          <SubmitButton
            onClick={handleResendClick}
            dataTestId={`${dataTestPrefix}-resend-btn`}
          >
            {t(BTN_TEXT.RESEND_SMS)}
          </SubmitButton>
        )}
      </div>
    </>
  );
};

export default EnterPhoneNumber;
