import { forwardRef, Ref } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { GridContainerProps } from '@root/interfaces/components/Grid';

const Container = forwardRef((props: GridContainerProps, ref: Ref<HTMLDivElement>) => {
  const { children, className, style } = props;

  const classNames = normalizeStringCompound(['container', className]);

  return (
    <div ref={ref} style={style} className={classNames}>
      {children}
    </div>
  );
});

export default Container;
