import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';

import CallingRechargeReceiptClass from '@root/models/CallingRechargeReceiptClass';
import CallingPaymentInfoClass from '@root/models/CallingPaymentInfoClass';

import { Grid, Button } from '@components/common';

import Balance from '../components/Balance';
import Receipt from '../components/Receipt';
import AutoRechargePromoInfo from '../components/AutoRechargePromoInfo';

interface Props {
  rechargeReceipt: CallingRechargeReceiptClass;
  paymentInfo: CallingPaymentInfoClass;
  cardNumber: string;
  userCountryOfOrigin: CountryCode;
  onFinish: () => void;
  dataTestPrefix?: string;
}

const Confirmation: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    userCountryOfOrigin,
    rechargeReceipt,
    cardNumber,
    onFinish,
    paymentInfo,
    dataTestPrefix,
  } = props;

  const { t } = useTranslation();

  return (
    <Grid.Container>
      <Grid.Row>
        <Grid.Col span={{ sm: 12, md: 6, lg: 4 }} offset={{ md: 3, lg: 4 }}>
          <Balance
            title={t('Your new balance is')}
            currencySymbol={rechargeReceipt.currencySymbol}
            currency={rechargeReceipt.currency}
            amount={rechargeReceipt.newBalance}
            className="mb-4"
          />

          <Receipt
            {...rechargeReceipt}
            cardNumber={cardNumber}
            userCountryOfOrigin={userCountryOfOrigin}
            className="mb-5"
          />

          {(paymentInfo.arPromoInfo?.description || paymentInfo.arPromoBullets) && (
            <AutoRechargePromoInfo
              title={paymentInfo.arPromoInfo?.description}
              description={paymentInfo.arPromoBullets}
              className="text-center mb-5"
            />
          )}

          <div className="text-center mt-10">
            <Button
              shape="pill"
              isOutlined
              size="lg"
              onClick={onFinish}
              dataTestId={`${dataTestPrefix}-confirmation`}
            >
              {t('Manage auto-recharge')}
            </Button>
          </div>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Confirmation;
