import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import storageInterface from '@services/storage';

interface UseHandle3dsTxnIdParams {
  storeKey: string;
}

interface UrlParams {
  id?: string;
}

// Handle txnId from store or url query while processing 3ds secure transaction
// returns final txnId either from store or url query
const useHandle3dsTxnId = ({ storeKey }: UseHandle3dsTxnIdParams) => {
  const { id: txnIdFromUrl } = useParams<UrlParams>();

  const txnIdFinal = useMemo(() => {
    const tnxIdFromStore = storageInterface.getItem(storeKey);
    if (txnIdFromUrl && txnIdFromUrl !== storeKey) {
      storageInterface.setItem(storeKey, txnIdFromUrl);
      return txnIdFromUrl;
    }
    storageInterface.removeItem(storeKey);
    return tnxIdFromStore;
  }, [txnIdFromUrl]);

  return { txnId: txnIdFinal };
};

export default useHandle3dsTxnId;
