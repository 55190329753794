import { GEOLOCATION_ERROR_MSG } from '@root/constants/errorMessages';

// TODO: decide where to put this constant (someFileInSomFolder.ts)
export const GEOLOCATION_ERROR_CODE = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3,
};

export const getErrorMessageByGeopositionErrorCode = (
  errorCode: GeolocationPositionError['code'],
) => {
  switch (errorCode) {
    case GEOLOCATION_ERROR_CODE.PERMISSION_DENIED:
      return GEOLOCATION_ERROR_MSG.PERMISSION_DENIED;
    case GEOLOCATION_ERROR_CODE.POSITION_UNAVAILABLE:
      return GEOLOCATION_ERROR_MSG.POSITION_UNAVAILABLE;
    case GEOLOCATION_ERROR_CODE.TIMEOUT:
      return GEOLOCATION_ERROR_MSG.TIMEOUT;
    default:
      return GEOLOCATION_ERROR_MSG.POSITION_UNAVAILABLE;
  }
};
