import { useEffect } from 'react';

// I was sick and tired of searching for a useEffect with an empty array in dependencies

const useDidMount = (callback: () => unknown): void => {
  useEffect(() => {
    if (typeof callback === 'function') {
      callback();
    }
  }, []);
};

export default useDidMount;
