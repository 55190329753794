import React from 'react';
import { Typography } from '@components/common';

const TitleText: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;
  return (
    <Typography.Title level={5} className="text-center mb-6">
      {children}
    </Typography.Title>
  );
};

export default TitleText;
