import { makeAutoObservable } from 'mobx';

import { getRechargeHistory } from '@services/api/v1/calling';
import { showToast } from '@services/toasts';

import CallingRechargeTransactionClass from '@root/models/CallingRechargeTransactionClass';

class RechargeHistoryStore {
  transactions: CallingRechargeTransactionClass[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getTransactions = async () => {
    try {
      this.isLoading = true;

      this.transactions = await getRechargeHistory();
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.isLoading = false;
    }
  };
}

export default RechargeHistoryStore;
