import { makeAutoObservable } from '@nodeModules/mobx';

import { fetchImtuOffers } from '@services/api/v1/dtcImtuK2';

import ImtuProductClass from '@root/models/ImtuProductClass';

class ImtuProductsStore {
  products?: ImtuProductClass[];

  isLoading = false;

  isInitialDataRetrieved = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchImtuProducts = async ({
    recipientPhone,
    carrierCode,
  }: {
    recipientPhone: string;
    carrierCode: string;
  }) => {
    this.isLoading = true;
    try {
      this.products = await fetchImtuOffers({
        recipientPhone,
        carrierCode,
      });
    } finally {
      this.isLoading = false;
      this.isInitialDataRetrieved = true;
    }
  };

  get isDataReady() {
    return !this.isLoading && this.isInitialDataRetrieved;
  }
}

export default ImtuProductsStore;
