import { Logo, Typography } from '@components/common';

import { LanguageLocale } from '@root/interfaces/Languages';

interface Props {
  className?: string;
  languageLocale: LanguageLocale;
}

const LogotypeLink = (props: Props) => {
  const { className, languageLocale } = props;

  return (
    <Typography.Link
      className={className}
      title="Logo"
      to={`https://www.bossrevolution.com/${languageLocale}`}
      isNative
    >
      <Logo className="inline-flex h-8" isWhiteLogo />
    </Typography.Link>
  );
};

export default LogotypeLink;
