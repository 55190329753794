import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  className?: string;
}

const Paragraph: FC<React.PropsWithChildren<Props>> = (props) => {
  const { className, ...rest } = props;

  const classNames = normalizeStringCompound(['paragraph', className]);

  return <p className={classNames} {...rest} />;
};

export default Paragraph;
