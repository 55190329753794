import React from 'react';
import { Button } from '@components/common';

import { ButtonProps } from '@root/interfaces/components/Button';

const SubmitButton: React.FC<React.PropsWithChildren<ButtonProps>> = (props) => {
  const { children, ...rest } = props;
  return (
    <Button shape="pill" isOutlined className="w-full" size="lg" {...rest}>
      {children}
    </Button>
  );
};

export default SubmitButton;
