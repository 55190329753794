/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { forwardRef, Ref } from 'react';

import { normalizeStringCompound } from '@utils/string';

import InputProps from '@root/interfaces/components/Input';

import './styles.scss';

const BASIC_CLASS = 'input';

const Input = forwardRef((props: InputProps, ref: Ref<HTMLInputElement>) => {
  const {
    value,
    name,
    id,
    className,
    onChange,
    onFocus,
    onBlur,
    onClick,
    placeholder,
    size,
    type,
    shape,
    align,
    addonBefore,
    addonAfter,
    readOnly,
    disabled,
    autoFocus,
    dataTestId,
    ariaLabel,
    isSecureContent = false,
    ...rest
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${size}`,
    `${BASIC_CLASS}-${size}-${shape}`,
    disabled ? '' : 'hover:border-blue-400',
    'text-xl border-gray-300 focus-within:border-blue-400 transition',
    className,
  ]);

  const inputClassName = normalizeStringCompound([
    align === 'center' ? 'text-center' : '',
    className,
  ]);

  return (
    <div
      className={classNames}
      onClick={handleWrapperClick}
      data-test-id={`${dataTestId}-dropdown`}
    >
      {addonBefore && (
        <span className={`${BASIC_CLASS}-addon ${BASIC_CLASS}-addon-before`}>
          {addonBefore}
        </span>
      )}
      <input
        value={value}
        type={type}
        name={name}
        id={id}
        className={inputClassName}
        placeholder={placeholder}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        readOnly={readOnly}
        disabled={disabled}
        ref={ref}
        autoFocus={autoFocus}
        data-test-id={dataTestId}
        aria-label={ariaLabel}
        data-recording-ignore={isSecureContent ? 'mask' : 'none'}
        {...rest}
      />
      {addonAfter && (
        <span className={`${BASIC_CLASS}-addon ${BASIC_CLASS}-addon-after`}>
          {addonAfter}
        </span>
      )}
    </div>
  );
});

Input.defaultProps = {
  size: 'md',
  type: 'text',
  shape: 'round',
  align: 'left',
};

export default Input;
