import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  isSelected: boolean;
  promoText?: string;
}

const SelectRechargeOption: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, isSelected, promoText } = props;

  const classNames = normalizeStringCompound([
    'border border-transparent hover:border-red-400 px-5 py-3 mx-1 rounded-full transition',
    'text-xl font-bold',
    isSelected ? 'bg-red-400 text-white' : undefined,
  ]);

  const bonusClassNames = normalizeStringCompound([
    'font-bold uppercase absolute pt-2',
    promoText ? 'text-red-400' : 'text-gray-400',
  ]);

  return (
    <div className="relative h-24 whitespace-nowrap">
      <div className={classNames}>{children}</div>
      {isSelected && <div className={bonusClassNames}>{promoText}</div>}
    </div>
  );
};

export default SelectRechargeOption;
