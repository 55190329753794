import { Link } from 'react-router-dom';

import { Grid, UserAvatar } from '@components/common';

import BASE_PATHS from '@root/routes/paths';

import { LanguageLocale } from '@root/interfaces/Languages';

import LogotypeLink from '../LogotypeLink';
import ToggleButton from '../ToggleButton';

interface Props {
  onToggleButtonClick(): void;
  isToggleButtonToggled: boolean;
  avatarUrl: string | null;
  avatarAltText?: string;
  languageLocale: LanguageLocale;
}

const TopControls = (props: Props) => {
  const {
    isToggleButtonToggled,
    avatarAltText,
    avatarUrl,
    onToggleButtonClick,
    languageLocale,
  } = props;

  return (
    <Grid.Row className="relative z-10 items-center h-16 bg-red-400">
      <Grid.Col span={3} className="flex items-center">
        <ToggleButton
          isToggled={isToggleButtonToggled}
          onChange={onToggleButtonClick}
          className="text-3xl text-white"
        />
      </Grid.Col>
      <Grid.Col span={6} className="flex justify-center">
        <LogotypeLink className="w-32" languageLocale={languageLocale} />
      </Grid.Col>
      {avatarUrl && (
        <Grid.Col span={3}>
          <Link
            to={BASE_PATHS.ACCOUNT}
            className="block w-10 h-10 ml-auto border border-white rounded-full"
          >
            <UserAvatar avatarUrl={avatarUrl} altText={avatarAltText} />
          </Link>
        </Grid.Col>
      )}
    </Grid.Row>
  );
};

export default TopControls;
