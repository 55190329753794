import { makeAutoObservable } from 'mobx';

import { formatMoneyToText } from '@helpers/money';

interface Data {
  offerId: string;
  promoId: string;
  priceTotal: number;
  fee: number;
  discount: number;
  priceTotalDiscounted: number;
  priceCurrency: string;
  description: string;
}

class ImtuPromoOfferDetailsClass {
  offerId: string;

  promoId: string;

  priceTotal: number;

  fee: number;

  discount: number;

  priceTotalDiscounted: number;

  priceCurrency: string;

  description: string;

  constructor(data: Data) {
    makeAutoObservable(this);

    this.offerId = data.offerId;
    this.promoId = data.promoId;
    this.priceTotal = data.priceTotal;
    this.fee = data.fee;
    this.discount = data.discount;
    this.priceTotalDiscounted = data.priceTotalDiscounted;
    this.priceCurrency = data.priceCurrency;
    this.description = data.description;
  }

  get priceTotalDiscountedText() {
    return formatMoneyToText(this.priceTotalDiscounted, this.priceCurrency);
  }

  get discountText() {
    return formatMoneyToText(this.discount, this.priceCurrency);
  }

  get priceTotalText() {
    return formatMoneyToText(this.priceTotal, this.priceCurrency);
  }

  get feeText() {
    return formatMoneyToText(this.fee, this.priceCurrency);
  }
}

export default ImtuPromoOfferDetailsClass;
