import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useStore from '@root/hooks/useStore';
import useDidMount from '@root/hooks/useDidMount';

import { LoginStore, UserAccountStore } from '@root/stores';

import { ErrorAlert } from '@components/common';

import { formatPhone } from '@helpers/phone';

import VerifyPhoneCode, { PhoneVerificationFormValues } from './components/PhoneCodeForm';

import TitleText from '../../components/TitleText';
import ContentText from '../../components/ContentText';

import { LOGIN_PATHS } from '../../constants';

interface Props {
  onComplete: () => void;
  dataTestPrefix?: string;
}

const SmsCodeVerification: FC<React.PropsWithChildren<Props>> = (props) => {
  const { dataTestPrefix, onComplete } = props;

  const { t } = useTranslation();

  const history = useHistory();

  const loginStore: LoginStore = useStore('LoginStore');
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  useDidMount(() => {
    loginStore.resetError();
    if (!loginStore.phoneNumber) {
      history.push(LOGIN_PATHS.BASE);
    }
  });

  const handleReSubmitPhoneNumber = async () => {
    await loginStore.submitPhoneNumber();
  };

  const handleSubmitVerificationCode = async (values: PhoneVerificationFormValues) => {
    const res = await loginStore.loginSimpleWithoutMigration(values.verificationCode);

    switch (true) {
      case res?.success:
        loginStore.applySession();
        await userAccountStore.updateLoggedInStatus();
        onComplete();
        break;
      default:
        break;
    }
  };

  return (
    <div data-test-id="screen-confirmation-code">
      <TitleText>{t('We sent a verification code to your mobile device')}</TitleText>
      <ContentText isSecureContent>
        {t('Please enter the SMS code we sent to {{phoneNumber}}', {
          phoneNumber: formatPhone(loginStore.phoneNumber),
        })}
      </ContentText>
      {loginStore.error && <ErrorAlert>{loginStore.error?.message}</ErrorAlert>}
      <VerifyPhoneCode
        onSubmit={handleSubmitVerificationCode}
        onResend={handleReSubmitPhoneNumber}
        dataTestPrefix={`${dataTestPrefix}-confirmation-code`}
      />
    </div>
  );
};

export default observer(SmsCodeVerification);
