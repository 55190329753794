import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import PATHS from '@root/routes/paths';

import BottomControlsWrapper from '@components/common/BottomControlsWrapper';
import StepButton from '@components/common/Button/StepButton';
import { Button } from '@components/common';

interface Props {
  dataTestPrefix?: string;
}

const Receipt: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { dataTestPrefix } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const handleHistoryButtonClick = () => {
    history.push(PATHS.TRANSACTIONS_HISTORY);
  };

  const handleReceiptClick = () => {
    window.print();
  };

  const handleSendAnotherTopupBtnClick = () => {
    history.push(PATHS.IMTU);
  };

  return (
    <BottomControlsWrapper className="print:hidden">
      <div className="flex flex-col w-full items-center justify-center">
        <StepButton
          onClick={handleSendAnotherTopupBtnClick}
          dataTestId={`${dataTestPrefix}-next-btn`}
        >
          {t('send another top-up')}
        </StepButton>
        <Button
          shape="text"
          size="lg"
          className="first-letter:uppercase"
          onClick={handleHistoryButtonClick}
          dataTestId={`${dataTestPrefix}-next-btn`}
        >
          {t('see transaction history')}
        </Button>
        <Button
          shape="text"
          size="lg"
          className="first-letter:uppercase"
          onClick={handleReceiptClick}
          dataTestId={`${dataTestPrefix}-next-btn`}
        >
          {t('print confirmation')}
        </Button>
      </div>
    </BottomControlsWrapper>
  );
};

export default observer(Receipt);
