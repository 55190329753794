import React from 'react';

import { Icon } from '@components/common';
import { normalizeStringCompound } from '@utils/string';

interface Props {
  className?: string;
}

const CarrierLogoGeneric: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { className } = props;
  const classNames = normalizeStringCompound([
    'flex justify-center w-full text-blue-400 text-5xl',
    className,
  ]);
  return (
    <div className={classNames}>
      <Icon name="MobileTopUp" />
    </div>
  );
};

export default CarrierLogoGeneric;
