export const BRAZE_API_KEY = process.env.REACT_APP_BRAZE_API_KEY as string;

const SAFARI_WEBSITE_PUSH_ID = 'web.dev.bossmoney';

export const CONFIG = {
  enableLogging: process.env.REACT_APP_BRAZE_LOGGING === 'active',
  baseUrl: process.env.REACT_APP_BRAZE_BASE_URL as string,
  safariWebsitePushId: SAFARI_WEBSITE_PUSH_ID,
  enableHtmlInAppMessages: true,
  // manageServiceWorkerExternally: true,
};
