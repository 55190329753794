import React, { FC } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BASE_PATHS from '@root/routes/paths';

import useStore from '@root/hooks/useStore';
import useWizard from '@root/hooks/useWizard';
import useDidMount from '@root/hooks/useDidMount';

import { UserAccountStore } from '@root/stores';

import {
  Spinner,
  PageTitle,
  AdaptiveCancelButton,
  Modal,
  Logo,
  AdaptiveHelpButton,
} from '@components/common';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';

import { Steps } from './constants';

import Recharge, { RechargeFinishParams } from './containers/Recharge';
import Confirmation from './containers/Confirmation';
import AutoRecharge from './containers/AutoRecharge';

import Store from './Store';

interface Props {
  basePath: string;
}

export interface StepModule {
  component: React.ReactNode;
}

const DATA_TEST_ID_PREFIX = 'calling';

const Calling: FC<React.PropsWithChildren<Props>> = (props) => {
  const { basePath } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const isDesktop = useIsDesktop();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const store = useLocalStore(() => new Store());

  useDidMount(async () => {
    await store.getCallingInfo();
  });

  const {
    stepPaths,
    availableSteps,
    redirectToNextStep,
    currentStepIndex,
    redirectToStep,
  } = useWizard({
    basePath,
    steps: Steps,
  });

  const handleCancelButtonClick = () => {
    // TODO: we need better design for this calling button since it not always a CANCEL button
    if (currentStepIndex) {
      history.push(basePath);
    } else {
      history.push(BASE_PATHS.ACCOUNT);
    }
  };

  const handleRechargeStepFinish = async (params: RechargeFinishParams) => {
    const { amount, isAutoRechargeEnabled, paymentCard, validatedPromoCode } = params;

    await store.submitTransaction({
      amount,
      isAutoRechargeEnabled,
      paymentCard,
      userCountryCode: userAccountStore.userCountryOfOrigin,
      validatedPromoCode,
    });

    redirectToNextStep();
  };

  const handleAutoRechargeToggle = (value: boolean) => {
    if (value && store.paymentInfo) {
      store.setupAutoRechargeAmount(store.paymentInfo.defaultAutoRechargeAmount);
    } else {
      store.disableAutoRecharge();
    }
  };

  const handleAutoRechargeUpdate = (value: number) => {
    store.setupAutoRechargeAmount(value, handleCancelButtonClick);
  };

  const handleManageArClick = () => {
    redirectToStep(Steps.AutoRecharge);
  };

  const handleCallingAvailabilityModalClose = () => {
    store.hideCallingAvailabilityModal();
  };

  const stepModules: StepModule[] = [];

  stepModules[Steps.Recharge] = {
    component: store.paymentInfo && !store.isPaymentInfoLoading && (
      <Recharge
        paymentInfo={store.paymentInfo}
        isAutoRechargeEnabled={store.isAutoRechargeRecommended || store.isAutoRechargeSet}
        onFinish={handleRechargeStepFinish}
        onManageArClick={handleManageArClick}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        isCallingFeatureAvailable={store.paymentInfo.isCallingAvailable}
        isLoading={store.isRechargeLoading || userAccountStore.isLoading}
        userCountryOfOrigin={userAccountStore.userCountryOfOrigin}
      />
    ),
  };

  stepModules[Steps.Confirmation] = {
    component:
      store.rechargeReceipt && store.selectedPaymentCard && store.paymentInfo ? (
        <Confirmation
          userCountryOfOrigin={userAccountStore.userCountryOfOrigin}
          rechargeReceipt={store.rechargeReceipt}
          paymentInfo={store.paymentInfo}
          cardNumber={store.selectedPaymentCard.maskedNumber}
          onFinish={handleManageArClick}
          dataTestPrefix={DATA_TEST_ID_PREFIX}
        />
      ) : null,
  };

  stepModules[Steps.AutoRecharge] = {
    component: store.paymentInfo ? (
      <AutoRecharge
        paymentInfo={store.paymentInfo}
        isAutoRechargeEnabled={store.isAutoRechargeRecommended || store.isAutoRechargeSet}
        onAutoRechargeToggle={handleAutoRechargeToggle}
        onAutoRechargeUpdate={handleAutoRechargeUpdate}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
      />
    ) : null,
  };

  return (
    <Spinner isSpinning={store.isAnythingLoading}>
      <PageTitle
        title={
          currentStepIndex === Steps.AutoRecharge
            ? t('manage auto-recharge')
            : t('recharge balance')
        }
        buttonsNode={
          <>
            <AdaptiveHelpButton />
            <AdaptiveCancelButton
              onClick={handleCancelButtonClick}
              dataTestId={`${DATA_TEST_ID_PREFIX}-cancel-btn`}
            />
          </>
        }
        hasBossLogoLink={isDesktop}
        hasFixedPosition
      />

      <div className="mt-10 mb-24">
        <Switch>
          {availableSteps.map((step) => {
            const module = stepModules[step];

            return (
              <Route
                key={stepPaths[step].toLowerCase()}
                path={`${basePath}${stepPaths[step]}`}
                render={() => module.component}
              />
            );
          })}
        </Switch>
      </div>
      <Modal
        isOpen={store.isCallingAvailabilityModalVisible}
        onRequestClose={handleCallingAvailabilityModalClose}
        header={<Logo className="w-24" />}
      >
        {t(
          'We are sorry. At this time BOSS Revolution does not provide this service in your country.',
        )}
      </Modal>
    </Spinner>
  );
};

export default observer(Calling);
