import React from 'react';

import { normalizeStringCompound } from '@utils/string';

type SectionColors = 'blue';

const colorsMap: { [key: string]: string } = {
  blue: 'text-blue-400',
};

interface Props {
  color?: SectionColors;
}

const SectionHeader: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { color, children } = props;

  const classNames = normalizeStringCompound([
    'text-xl font-bold capitalize',
    color && colorsMap[color],
  ]);

  return <div className={classNames}>{children}</div>;
};

export default SectionHeader;
