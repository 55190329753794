/* eslint-disable import/prefer-default-export */
import CountryCode from '@root/interfaces/CountryCode';
import { SupportedCountries } from '@root/interfaces/SupportedCountries';

export const DEFAULT_COUNTRIES_LIST: CountryCode[] = [
  'AD',
  'AE',
  'AF',
  'AG',
  'AI',
  'AL',
  'AM',
  'AO',
  'AR',
  'AS',
  'AT',
  'AU',
  'AW',
  'AX',
  'AZ',
  'BA',
  'BB',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BL',
  'BM',
  'BN',
  'BO',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BW',
  'BY',
  'BZ',
  'CA',
  'CC',
  'CD',
  'CF',
  'CG',
  'CH',
  'CI',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CR',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DE',
  'DJ',
  'DK',
  'DM',
  'DO',
  'DZ',
  'EC',
  'EE',
  'EG',
  'EH',
  'ER',
  'ES',
  'ET',
  'FI',
  'FJ',
  'FK',
  'FM',
  'FO',
  'FR',
  'GA',
  'GB',
  'GD',
  'GE',
  'GG',
  'GH',
  'GI',
  'GL',
  'GM',
  'GN',
  'GQ',
  'GR',
  'GT',
  'GU',
  'GW',
  'GY',
  'HK',
  'HN',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IM',
  'IN',
  'IO',
  'IQ',
  'IR',
  'IS',
  'IT',
  'JE',
  'JM',
  'JO',
  'JP',
  'KE',
  'KG',
  'KH',
  'KM',
  'KN',
  'KP',
  'KR',
  'KW',
  'KY',
  'KZ',
  'LA',
  'LB',
  'LC',
  'LI',
  'LK',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LY',
  'MA',
  'MC',
  'MD',
  'ME',
  'MG',
  'MH',
  'MK',
  'ML',
  'MM',
  'MN',
  'MO',
  'MP',
  'MQ',
  'MR',
  'MS',
  'MT',
  'MU',
  'MV',
  'MW',
  'MX',
  'MY',
  'MZ',
  'NA',
  'NE',
  'NF',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'NR',
  'NU',
  'NZ',
  'OM',
  'PA',
  'PE',
  'PF',
  'PG',
  'PH',
  'PK',
  'PL',
  'PR',
  'PS',
  'PT',
  'PW',
  'PY',
  'QA',
  'RO',
  'RS',
  'RU',
  'RW',
  'SA',
  'SB',
  'SC',
  'SD',
  'SE',
  'SG',
  'SK',
  'SL',
  'SM',
  'SN',
  'SO',
  'SR',
  'SS',
  'ST',
  'SV',
  'SX',
  'SY',
  'TC',
  'TD',
  'TG',
  'TH',
  'TJ',
  'TK',
  'TL',
  'TM',
  'TN',
  'TO',
  'TR',
  'TT',
  'TV',
  'TW',
  'TZ',
  'UA',
  'UG',
  'US',
  'UY',
  'UZ',
  'VA',
  'VC',
  'VE',
  'VG',
  'VI',
  'VN',
  'VU',
  'WS',
  'XK',
  'YE',
  'ZA',
  'ZM',
  'ZW',
];

// translations for country names (needed for gathering these strings to template.json

// t("Argentina")
// t("Bangladesh")
// t("Benin")
// t("Bolivia")
// t("Bolivia, Plurinational State of")
// t("Brazil")
// t("Burkina Faso")
// t("Cameroon")
// t("Canada")
// t("Cayman Islands")
// t("Chad")
// t("Colombia")
// t("Congo, Democratic Republic of the")
// t("Costa Rica")
// t("Cote d’Ivoire")
// t("Côte d'Ivoire"),
// t("Democratic Republic of the Congo")
// t("Dominican Republic")
// t("Ecuador")
// t("Egypt")
// t("El Salvador")
// t("Ethiopia")
// t("France")
// t("Gabon")
// t("Gambia")
// t("Germany")
// t("Ghana")
// t("Greece")
// t("Guatemala")
// t("Guinea")
// t("Guyana")
// t("Haiti")
// t("Honduras")
// t("India")
// t("Iran")
// t("Ireland")
// t("Italy")
// t("Ivory Coast")
// t("Jamaica")
// t("Kenya")
// t("Korea, Democratic People's Republic of"),
// t("Lao People's Democratic Republic"),
// t("Liberia")
// t("Magadascar")
// t("Malawi")
// t("Mali")
// t("Mexico")
// t("Morocco")
// t("Mozambique")
// t("Nepal")
// t("Netherlands")
// t("Nicaragua")
// t("Niger")
// t("Nigeria")
// t("Pakistan")
// t("Panama")
// t("Paraguay")
// t("Peru")
// t("Philippines")
// t("Poland")
// t("Portugal")
// t("Rwanda")
// t("Senegal")
// t("Sierra Leone")
// t("South Africa")
// t("Spain")
// t("Sri Lanka")
// t("Sweden")
// t("Tanzania")
// t("Tanzania, United Republic of")
// t("Togo")
// t("Turkey")
// t("Uganda")
// t("Ukraine")
// t("United Kingdom")
// t("United States")
// t("Uruguay")
// t("Venezuela")
// t("Zimbabwe")
// t('Afghanistan'),
// t('Albania'),
// t('Algeria'),
// t('American Samoa'),
// t('Andorra'),
// t('Angola'),
// t('Anguilla'),
// t('Antigua and Barbuda'),
// t('Armenia'),
// t('Aruba'),
// t('Australia'),
// t('Austria'),
// t('Azerbaijan'),
// t('Bahamas'),
// t('Bahrain'),
// t('Bangladesh'),
// t('Barbados'),
// t('Belarus'),
// t('Belgium'),
// t('Belize'),
// t('Bermuda'),
// t('Bhutan'),
// t('Bonaire, Sint Eustatius and Saba'),
// t('Bosnia and Herzegovina'),
// t('Botswana'),
// t('British Indian Ocean Territory'),
// t('Brunei Darussalam'),
// t('Bulgaria'),
// t('Burundi'),
// t('Cabo Verde'),
// t('Cambodia'),
// t('Central African Republic'),
// t('Chile'),
// t('China'),
// t('Christmas Island'),
// t('Cocos (Keeling) Islands'),
// t('Comoros'),
// t('Congo'),
// t('Cook Islands'),
// t('Croatia'),
// t('Cuba'),
// t('Curaçao'),
// t('Cyprus'),
// t('Czechia'),
// t('Denmark'),
// t('Djibouti'),
// t('Dominica'),
// t('Equatorial Guinea'),
// t('Eritrea'),
// t('Estonia'),
// t('Falkland Islands (Malvinas)'),
// t('Faroe Islands'),
// t('Fiji'),
// t('Finland'),
// t('French Polynesia'),
// t('Georgia'),
// t('Gibraltar'),
// t('Greenland'),
// t('Grenada'),
// t('Guam'),
// t('Guernsey'),
// t('Guinea-Bissau'),
// t('Guyana'),
// t('Holy See'),
// t('Hong Kong'),
// t('Hungary'),
// t('Iceland'),
// t('Indonesia'),
// t('Iran, Islamic Republic of'),
// t('Iraq'),
// t('Isle of Man'),
// t('Israel'),
// t('Japan'),
// t('Jersey'),
// t('Jordan'),
// t('Kazakhstan'),
// t('Korea, Republic of'),
// t('Kuwait'),
// t('Kyrgyzstan'),
// t('Latvia'),
// t('Lebanon'),
// t('Lesotho'),
// t('Libya'),
// t('Liechtenstein'),
// t('Lithuania'),
// t('Luxembourg'),
// t('Macao'),
// t('Madagascar'),
// t('Malaysia'),
// t('Maldives'),
// t('Malta'),
// t('Marshall Islands'),
// t('Martinique'),
// t('Mauritania'),
// t('Mauritius'),
// t('Micronesia, Federated States of'),
// t('Moldova, Republic of'),
// t('Monaco'),
// t('Mongolia'),
// t('Montenegro'),
// t('Montserrat'),
// t('Myanmar'),
// t('Namibia'),
// t('Nauru'),
// t('New Zealand'),
// t('Niue'),
// t('Norfolk Island'),
// t('North Macedonia'),
// t('Northern Mariana Islands'),
// t('Norway'),
// t('Oman'),
// t('Palau'),
// t('Palestine, State of'),
// t('Papua New Guinea'),
// t('Philippines'),
// t('Puerto Rico'),
// t('Qatar'),
// t('Romania'),
// t('Russian Federation'),
// t('Saint Barthélemy'),
// t('Saint Kitts and Nevis'),
// t('Saint Lucia'),
// t('Saint Vincent and the Grenadines'),
// t('Samoa'),
// t('San Marino'),
// t('Sao Tome and Principe'),
// t('Saudi Arabia'),
// t('Serbia'),
// t('Seychelles'),
// t('Singapore'),
// t('Sint Maarten, (Dutch part)'),
// t('Slovakia'),
// t('Solomon Islands'),
// t('Somalia'),
// t('South Sudan'),
// t('Sudan'),
// t('Suriname'),
// t('Switzerland'),
// t('Syrian Arab Republic'),
// t('Taiwan, Province of China'),
// t('Tajikistan'),
// t('Thailand'),
// t('Timor-Leste'),
// t('Tokelau'),
// t('Tonga'),
// t('Trinidad and Tobago'),
// t('Tunisia'),
// t('Turkmenistan'),
// t('Turks and Caicos Islands'),
// t('Tuvalu'),
// t('United Arab Emirates'),
// t('United Kingdom of Great Britain and Northern Ireland'),
// t('United States'),
// t('Uzbekistan'),
// t('Vanuatu'),
// t('Venezuela, Bolivarian Republic of'),
// t('Viet Nam'),
// t('Virgin Islands, British'),
// t('Virgin Islands, U.S.'),
// t('Western Sahara'),
// t('Yemen'),
// t('Zambia'),
// t('Åland Islands'),

export const SUPPORTED_COUNTRIES: SupportedCountries[] = [
  SupportedCountries.CA,
  SupportedCountries.GB,
  SupportedCountries.US,
];
