import { RefObject, useEffect, useRef, useState } from 'react';

const KEY_STEP_MAP: { [key: string]: number } = {
  Down: 1,
  ArrowDown: 1,
  Up: -1,
  ArrowUp: -1,
  // Left: -1,
  // ArrowLeft: -1,
  // Right: 1,
  // ArrowRight: 1,
};

const useNavigation = (
  containerEl: RefObject<HTMLDivElement>,
  childSelector: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dependencies: any[],
) => {
  const [children, setChildrenState] = useState<NodeListOf<HTMLElement> | undefined>();
  const [focusIndex, setFocusIndexState] = useState<number | undefined>();

  const childrenRef = useRef(children);
  const setChildren = (data: NodeListOf<HTMLElement> | undefined) => {
    childrenRef.current = data;
    setChildrenState(data);
  };

  const focusIndexRef = useRef(focusIndex);
  const setFocusIndex = (data?: number) => {
    focusIndexRef.current = data;
    setFocusIndexState(data);
  };

  const updateChildrenList = () => {
    if (containerEl.current) {
      setChildren(containerEl.current.querySelectorAll<HTMLElement>(childSelector));
    }
  };

  const focusChildAtIndex = (index: number) => {
    if (childrenRef?.current?.length) {
      childrenRef.current[index]?.focus();
      setFocusIndex(index);
    }
  };

  const getNextIndexToFocus = (step: number) => {
    const maxItemsLength = childrenRef?.current?.length || 0;
    const index =
      focusIndexRef.current === undefined ||
      focusIndexRef.current < 0 ||
      focusIndexRef.current > maxItemsLength
        ? 0
        : focusIndexRef.current + step;

    switch (true) {
      case index < 0:
        return maxItemsLength - 1;
      case index >= maxItemsLength:
        return 0;
      default:
        return index;
    }
  };

  const handleKeydown = (e: KeyboardEvent) => {
    if (!childrenRef?.current?.length) {
      updateChildrenList();
    }

    const step = KEY_STEP_MAP[e.key] || 0;

    if (step) {
      e.preventDefault();
      e.stopPropagation();
      const newIndex = getNextIndexToFocus(step);
      focusChildAtIndex(newIndex);
    }
  };

  useEffect(() => {
    containerEl.current?.removeEventListener('keydown', handleKeydown);
    containerEl.current?.addEventListener('keydown', handleKeydown);
    updateChildrenList();
    setFocusIndex(undefined);
    return () => {
      containerEl.current?.removeEventListener('keydown', handleKeydown);
    };
  }, [containerEl.current, childSelector, ...dependencies]);
};

export default useNavigation;
