import brazeAPI from '@services/braze';

const { app: brazeApp } = brazeAPI;

const MSG = {
  KEY: 'msg-id',
  KEY_VALUE: 'push-primer',
};

export const setupWebPushNotifications = () => {
  brazeApp.registerAppboyPushMessages();
};

export const setupInAppNotifications = () => {
  brazeApp.subscribeToInAppMessage((inAppMessage) => {
    let shouldDisplay = true;

    if (inAppMessage instanceof brazeApp.InAppMessage) {
      // Read the key-value pair for msg-id
      const msgId = inAppMessage.extras[MSG.KEY];

      // If this is our push primer message
      if (msgId === MSG.KEY_VALUE) {
        // We don't want to display the soft push prompt to users on browsers that don't support push, or if the user
        // has already granted/blocked permission
        if (
          !brazeApp.isPushSupported() ||
          brazeApp.isPushPermissionGranted() ||
          brazeApp.isPushBlocked()
        ) {
          shouldDisplay = false;
        }

        inAppMessage.subscribeToClickedEvent(setupWebPushNotifications);
      }
    }

    // Display the message
    if (shouldDisplay) {
      brazeApp.display.showInAppMessage(inAppMessage);
    }
  });
};
