export const DTC_AUTH_ENDPOINTS = {
  ACCOUNT: '/account',
  PASSWORD: '/password',
  REFRESH: '/refresh',
  SESSION: '/session',
  SMS_VERIFICATION: '/sms/verification',
  FIREBASE: '/firebase',
  SUPPORTED_COUNTRIES: '/supportedcountries',
};
export const DTC_PROFILE_ENDPOINTS = {
  PROFILE: '/me',
  CONSTACTS: '/contacts',
  PUSH_TOKEN: '/pushtoken',
  PAYMENT_CARDS: '/creditcards',
  PAYMENT_CARDS_CONFIG: '/creditcardsconfig',
};

export const DTC_LOGGING_ENDPOINTS = {
  HTTP_GELF: '/gelf',
};
