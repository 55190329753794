import React from 'react';
import { useTranslation } from '@nodeModules/react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';

import BottomControlsWrapper from '@components/common/BottomControlsWrapper';
import StepButton from '@components/common/Button/StepButton';
import CountrySelect from '@components/common/CountrySelect';

import { getCountryNameByCode } from '@services/countryList';
import { showToast } from '@services/toasts';

import Store from './Store';
import { VALIDATION_ERRORS } from './constants';

import SectionHeader from '../../components/Layout/SectionHeader';
import SectionContainer from '../../components/Layout/SectionContainer';

interface Props extends StepModule {
  selectedCountryCode?: CountryCode;
  countries: CountryCode[];
  onFinish(args: { countryCode: CountryCode }): void;
}

const Country: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { selectedCountryCode, countries, dataTestPrefix, onFinish } = props;

  const { t } = useTranslation();

  const store = useLocalStore(
    () =>
      new Store({
        countryCode: selectedCountryCode,
      }),
  );

  const handleFinish = () => {
    if (store.countryCode) {
      onFinish({
        countryCode: store.countryCode,
      });
    } else {
      showToast.warning(VALIDATION_ERRORS.COUNTRY_NOT_SELECTED);
    }
  };

  const handleCountrySelect = (value: string) => {
    store.setCountryCode(value as CountryCode); // TODO: revisit select interface to make it's value to be generic
    handleFinish(); // automatically finish the step on country selection
  };

  return (
    <SectionContainer>
      <SectionHeader color="blue">{t("select the recipient's country")}</SectionHeader>
      <div className="w-full max-w-sm">
        <CountrySelect
          value={store.countryCode}
          data={countries.map((item) => {
            return {
              code: item,
              value: item,
              textLabel: t(getCountryNameByCode(item) || ''),
            };
          })}
          isOpen={!store.countryCode}
          hasSearch
          placeholder={t('Select or type a country')}
          onChange={handleCountrySelect}
        />
      </div>
      <BottomControlsWrapper>
        <StepButton onClick={handleFinish} dataTestId={`${dataTestPrefix}-next-btn`}>
          {t('continue')}
        </StepButton>
      </BottomControlsWrapper>
    </SectionContainer>
  );
};

export default observer(Country);
