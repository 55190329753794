import React from 'react';

import { LanguageOptionProps } from '@root/interfaces/components/LanguageSwitcher';

import { Icon } from '@components/common';

const Option = (props: LanguageOptionProps) => {
  const { option, onClick, isSelected } = props;

  const handleClick = () => {
    onClick(option);
  };

  return (
    <li className="not-last-child:mb-4 leading-none" key={option.value}>
      <button
        className="flex items-center w-full hover:text-blue-400 text-black-100"
        onClick={handleClick}
      >
        <div className="w-5 h-5 rounded-full border border-blue-400 text-blue-400 mr-3 items-center justify-center">
          {isSelected && <Icon name="Checkmark" />}
        </div>
        <div className="text-lg md:text-base whitespace-nowrap">
          {option.text} - {option.value.toUpperCase()}
        </div>
      </button>
    </li>
  );
};

export default Option;
