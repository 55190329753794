import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { GridColProps } from '@root/interfaces/components/Grid';

import { getColClassesByOptions } from './helpers';

const Col: FC<React.PropsWithChildren<GridColProps>> = (props) => {
  const { children, span, offset, className } = props;

  const spans = getColClassesByOptions({
    config: span,
    prefix: 'col',
  });
  const offsets = getColClassesByOptions({
    config: offset,
    prefix: 'offset',
  });
  const classNames = normalizeStringCompound(['col', ...spans, ...offsets, className]);

  return <div className={classNames}>{children}</div>;
};

export default Col;
