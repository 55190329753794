import * as CONSTANTS from './constants';

import AuthApp from './AuthApp';
import RemoteConfigApi from './RemoteConfigApi';
// import Firestore from './Firestore';

export const firebaseAuthAPI = new AuthApp({
  appOptions: CONSTANTS.CONFIG,
  appName: CONSTANTS.FIREBASE_APP_NAMES.AUTH,
});

export const remoteConfigApi = new RemoteConfigApi(firebaseAuthAPI);

// export const firestoreAPI = new Firestore(firebaseAuthAPI);
