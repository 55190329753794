/* eslint-disable i18next/no-literal-string */
import { useFormikContext } from 'formik';

import { Typography } from '@components/common';

const Debugger = () => {
  const { values, errors, touched } = useFormikContext();
  const formState = { values, errors, touched };
  return (
    <div className="bg-gray-200 text-sm p-12">
      <Typography.Title level={5} className="mb-6">
        Form State
      </Typography.Title>
      <pre>{JSON.stringify(formState, null, 4)}</pre>
    </div>
  );
};

export default Debugger;
