import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import useDidMount from '@root/hooks/useDidMount';

import { showToast } from '@services/toasts';

import { StepModule } from '@root/interfaces/StepModules';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import BottomControlsWrapper from '@components/common/BottomControlsWrapper';
import StepButton from '@components/common/Button/StepButton';
import { Spinner } from '@components/common';

import Store from './Store';
import ImtuCarriersStore from './ImtuCarriersStore';
import CarriersList from './components/CarriersList';

import SectionHeader from '../../components/Layout/SectionHeader';
import SectionContainer from '../../components/Layout/SectionContainer';

const UI_MESSAGES = {
  PLEASE_SELECT_CARRIER: 'Please select a carrier', // t('Please select a carrier')
};

interface Props extends StepModule {
  recipientPhone: string;
  selectedCarrier?: ImtuCarrierClass;
  onFinish(args: { carrier: ImtuCarrierClass }): void;
}

const Carriers: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { selectedCarrier, recipientPhone, dataTestPrefix, onFinish } = props;

  const { t } = useTranslation();

  const store = useLocalStore(
    () =>
      new Store({
        carrier: selectedCarrier,
      }),
  );
  const imtuCarriersStore = useLocalStore(() => new ImtuCarriersStore());

  const handleFinish = () => {
    if (store.carrier) {
      onFinish({
        carrier: store.carrier,
      });
    } else {
      showToast.warning(UI_MESSAGES.PLEASE_SELECT_CARRIER);
    }
  };

  useDidMount(async () => {
    await imtuCarriersStore.fetchImtuCarriers(recipientPhone);
    if (!selectedCarrier) {
      if (imtuCarriersStore.carriers?.length === 1) {
        store.setCarrier(imtuCarriersStore.carriers[0]);
        handleFinish();
      } else if (imtuCarriersStore.detectedCarrier) {
        store.setCarrier(imtuCarriersStore.detectedCarrier);
      }
    }
  });

  const handleCarrierSelect = (value: string) => {
    const carrier = imtuCarriersStore.carriers?.find((item) => {
      return item.code === value;
    });
    if (carrier) {
      store.setCarrier(carrier);
      handleFinish();
    }
  };

  return (
    <SectionContainer>
      <SectionHeader color="blue">{t('pick the carrier')}</SectionHeader>
      {imtuCarriersStore.isDataReady ? (
        <div className="flex flex-col justify-center gap-y-4">
          {imtuCarriersStore.carriers?.length ? (
            <CarriersList
              data={imtuCarriersStore.carriers}
              selectedItemId={store.carrier?.code}
              onItemSelect={handleCarrierSelect}
            />
          ) : (
            <div className="first-letter:uppercase">
              {t('no carriers found for this phone number')}
            </div>
          )}
        </div>
      ) : (
        <Spinner isSpinning hasNoBg />
      )}
      <BottomControlsWrapper>
        <StepButton onClick={handleFinish} dataTestId={`${dataTestPrefix}-next-btn`}>
          {t('continue')}
        </StepButton>
      </BottomControlsWrapper>
    </SectionContainer>
  );
};

export default observer(Carriers);
