import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  className?: string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: React.ReactNode;
}

const Title: FC<React.PropsWithChildren<Props>> = (props) => {
  const { level, children, className, ...rest } = props;

  const hLevel = `h${level}`;
  const classNames = normalizeStringCompound([hLevel, className]);

  return React.createElement(hLevel, { className: classNames, ...rest }, children);
};

export default Title;
