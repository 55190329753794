import BASE_PATHS from '@root/routes/paths';

import { MaintenanceCodes } from '@root/constants/maintenance';

const shouldMaintenanceModalOpen = (maintenanceCode: MaintenanceCodes, path: string) => {
  switch (true) {
    case [MaintenanceCodes.MajorOutage, MaintenanceCodes.WebOutage].includes(
      maintenanceCode,
    ):
    case maintenanceCode === MaintenanceCodes.Funding &&
      (path.includes(BASE_PATHS.IMTU) || path.includes(BASE_PATHS.CALLING)):
    case maintenanceCode === MaintenanceCodes.SignUp && path.includes(BASE_PATHS.LOGIN):
      return true;
    default:
      return false;
  }
};

export default shouldMaintenanceModalOpen;
