import BrazeAPI from './Braze';

import * as CONSTANTS from './constants';

const brazeAPI = new BrazeAPI({
  brazeApiKey: CONSTANTS.BRAZE_API_KEY,
  brazeConfig: CONSTANTS.CONFIG,
});

export default brazeAPI;
