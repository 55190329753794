import { isObjKey } from '@utils/typeGuards';

import CountryCode from '@root/interfaces/CountryCode';
import { LanguageLocale } from '@root/interfaces/Languages';

import { IMTU_TERMS_PDF_URLS } from '@root/constants/termsAndConditions';

import { IMTU_TOC_SHORT_TEXT_TPL, IMTU_TOC_TERMS_DISPLAY_URLS } from './constants';

export const getImtuTocShortTextTpl = ({ countryCode }: { countryCode: CountryCode }) => {
  return isObjKey(countryCode, IMTU_TOC_SHORT_TEXT_TPL)
    ? IMTU_TOC_SHORT_TEXT_TPL[countryCode]
    : '';
};

export const getImtuTocPdfUrl = (languageLocale: LanguageLocale) => {
  const [languageLocaleStr] = Object.values(languageLocale);

  const [, country] = languageLocaleStr.split('-');

  let fallbackLocale: LanguageLocale; // fallback for unknown locales like fr-us or fr-gb
  switch (country) {
    case 'gb':
      fallbackLocale = LanguageLocale.EnGb;
      break;
    case 'us':
      fallbackLocale = LanguageLocale.EnUs;
      break;
    default:
      fallbackLocale = LanguageLocale.EnUs;
      break;
  }

  return isObjKey(languageLocale, IMTU_TERMS_PDF_URLS)
    ? IMTU_TERMS_PDF_URLS[languageLocale]
    : IMTU_TERMS_PDF_URLS[fallbackLocale];
};

export const getImtuTocDisplayUrl = ({
  languageLocale,
}: {
  languageLocale: LanguageLocale;
}) => {
  return isObjKey(languageLocale, IMTU_TOC_TERMS_DISPLAY_URLS)
    ? IMTU_TOC_TERMS_DISPLAY_URLS[languageLocale]
    : '';
};
