import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { parseAuthDefaultCountryFromQuery } from '@helpers/urlQuery';

import ROUTE_PATHS from '@root/routes/paths';
import CountryCode from '@root/interfaces/CountryCode';

import { LOGIN_DESCRIPTIONS, LOGIN_TITLES } from './constants/login';
import { getCanonicalAlternateUrlVariantsCfg } from './helpers';

const TITLE_DEFAULT = 'BOSS Revolution';
const DESCRIPTION_DEFAULT = '';

type ConfigKeys = string | keyof typeof ROUTE_PATHS;
type ConfigValues = {
  titles: Record<string | CountryCode, string>;
  descriptions: Record<string | CountryCode, string>;
};
type Config = Record<ConfigKeys, ConfigValues>;

const Seo: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const parsedAuthDefaultCountry = parseAuthDefaultCountryFromQuery();

  const location = useLocation();

  const config: Config = {
    [ROUTE_PATHS.LOGIN]: {
      titles: LOGIN_TITLES,
      descriptions: LOGIN_DESCRIPTIONS,
    },
  };

  const metaTitleByPath =
    parsedAuthDefaultCountry &&
    config[location.pathname]?.titles[parsedAuthDefaultCountry];

  const metaDescriptionByPath =
    parsedAuthDefaultCountry &&
    config[location.pathname]?.descriptions[parsedAuthDefaultCountry];

  const title = metaTitleByPath || t(TITLE_DEFAULT);
  const description = metaDescriptionByPath || t(DESCRIPTION_DEFAULT);

  const canonicalAlternateLinksCfg = getCanonicalAlternateUrlVariantsCfg(location.search);

  return (
    <Helmet>
      {process.env.REACT_APP_NOINDEX === 'true' && (
        <meta name="robots" content="nofollow,noindex" />
      )}
      <title>{title}</title>
      <meta name="description" content={description} />
      {canonicalAlternateLinksCfg.map((cfgItem) => {
        return (
          <link
            key={cfgItem.href}
            rel={cfgItem.linkType}
            href={cfgItem.href}
            hrefLang={cfgItem.hrefLang}
          />
        );
      })}
    </Helmet>
  );
};

export default Seo;
