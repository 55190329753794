import { loadScript } from '@utils/script';

import { liveChatStorage } from '@services/localDB';

import { LiveChatUserData } from '@root/interfaces/LiveChat';

const ZOHO_SCRIPT_ID = 'zsiqscript'; // doesn't work with any other id
const ZOHO_SCRIPT_URL = 'https://salesiq.zoho.com/widget'; // doesn't work with any other id

const waitUntilLiveChatLoaded = () => {
  return new Promise((resolve) => {
    const loadCheckingInterval = setInterval(() => {
      if (window.$zoho?.salesiq?.values?.uvid || window.$ZSIQChatWindow) {
        clearInterval(loadCheckingInterval);
        resolve(true);
      }
    }, 300);
  });
};

export const liveChatSetupUser = (params?: LiveChatUserData) => {
  if (params) {
    liveChatStorage.addItem(params);
  }

  const userData = liveChatStorage.getCollection()[0];

  window.$zoho?.salesiq?.visitor?.id(userData.id);
  window.$zoho?.salesiq?.visitor?.name(userData.name);
  window.$zoho?.salesiq?.visitor?.contactnumber(userData.phoneNumber);
  window.$zoho?.salesiq?.visitor?.email(userData.email);
};

// eslint-disable-next-line import/prefer-default-export
export const liveChatStart = async () => {
  window.$zoho = window.$zoho || {};
  window.$zoho.salesiq = window.$zoho.salesiq || {
    widgetcode: process.env.REACT_APP_ZOHO_LIVE_CHAT_API_KEY,
    values: {},
    ready() {
      // this doesn't work properly so we use loadScript and waitUntilLiveChatLoaded,
    },
  };

  await loadScript(ZOHO_SCRIPT_URL, {
    id: ZOHO_SCRIPT_ID,
    defer: true,
  });

  window.$zoho?.salesiq?.floatbutton?.visible('hide');

  await waitUntilLiveChatLoaded();
  window.$zoho?.salesiq?.chat?.start();

  window.$ZSIQChatWindow?.openChatWindow();

  liveChatSetupUser();
};

export const liveChatRefresh = () => {
  window.$zoho?.salesiq?.reset();
};

export const liveChatLogout = () => {
  window.$zoho?.salesiq?.visitor?.destroy();
};
