import { Icon } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

import { Languages } from '@root/interfaces/Languages';

interface ToggleProps {
  isToggled?: boolean;
  onClick?(): void;
  value: Languages;
  className?: string;
  isDisabled?: boolean;
}

const Toggler = (props: ToggleProps) => {
  const { onClick, value, isDisabled } = props;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      onClick={handleClick}
      className={normalizeStringCompound([
        'flex items-center',
        isDisabled ? 'animate-pulse' : '',
      ])}
      disabled={isDisabled}
    >
      <Icon name="Language" className="mr-1 text-3xl md:text-2xl  md:hidden lg:block" />
      <div className="self-end mr-1 text-xl font-medium md:text-base md:self-center md:leading-none">
        {value.toUpperCase()}
      </div>
      <Icon className="self-center text-sm" name="ChevronDownAlt" />
    </button>
  );
};

export default Toggler;
