import React, { FC, useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useDidUpdate from '@root/hooks/useDidUpdate';
import { useCountryCodePhoneOptions } from '@root/hooks/useCountryCodePhoneOptions';

import { getPhoneMaskByCountryCode } from '@helpers/phone';

import {
  CountryCodeOption,
  PhoneInputProps,
} from '@root/interfaces/components/PhoneInput';

import { DEFAULT_COUNTRIES_LIST } from '@root/constants/countries';

import MaskedInput from '../MaskedInput';
import Dropdown from './components/Dropdown';
import DropdownItem from './components/DropdownItem';

import Flag from '../Flag';
import Input from '../Input';

import CountryCodeInputAddonAfter from './components/CountryCodeInputAddonAfter';

import { useIsDesktop } from '../MediaQueryMatchers';

import {
  getDefaultCountryCode,
  getDefaultPhoneNumber,
  getNormalizedCountryDialCode,
} from './helpers';

import { FAKE_CHANGE_EVENT } from './constants';

const PhoneInput: FC<React.PropsWithChildren<PhoneInputProps>> = (props) => {
  const {
    defaultCountry,
    availableCountries = DEFAULT_COUNTRIES_LIST,
    onChange,
    disabled,
    placeholder,
    initialPhoneNumber,
    size,
    dataTestId,
    ...rest
  } = props;

  const hasCountriesToSelect = availableCountries.length > 1;

  // run once for getting initial state values
  const { defaultCountryCode, defaultDialCode, defaultPhoneNumber } = useMemo(() => {
    const countryCode = getDefaultCountryCode({
      initialPhoneNumber,
      defaultCountryCode: defaultCountry,
      availableCountryCodes: availableCountries,
    });

    const dialCode = getNormalizedCountryDialCode(countryCode);

    const phoneNumber = getDefaultPhoneNumber(initialPhoneNumber);

    return {
      defaultCountryCode: countryCode,
      defaultDialCode: dialCode,
      defaultPhoneNumber: phoneNumber,
    };
  }, [initialPhoneNumber, defaultCountry, availableCountries]);

  const countryCodeInputRef = useRef<HTMLInputElement>();
  const phoneNumberInputRef = useRef<HTMLInputElement>(null);

  const [countryCodeInputUiValue, setCountryCodeInputUiValue] = useState<string>();
  const [countryDialCodeValue, setCountryDialCodeValue] = useState(defaultDialCode);
  const [countryCodeValue, setCountryCodeValue] = useState(defaultCountryCode);
  const [phoneNumberValue, setPhoneNumberValue] = useState(defaultPhoneNumber);

  const [isSearch, setIsSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const isDesktopResolution = useIsDesktop();

  const { t } = useTranslation();

  const { handleSearch, filteredOptions } = useCountryCodePhoneOptions(
    availableCountries,
    countryCodeValue,
    t,
  );

  const clearSearchQuery = () => {
    // reset search query to show all available options in dropdown list
    handleSearch(FAKE_CHANGE_EVENT);
  };

  useDidUpdate(() => {
    phoneNumberInputRef.current?.focus();
  }, [countryCodeValue]);

  useDidUpdate(() => {
    setCountryDialCodeValue(defaultDialCode);
  }, [defaultDialCode]);

  useDidUpdate(() => {
    setPhoneNumberValue(defaultPhoneNumber);
  }, [defaultPhoneNumber]);

  useDidUpdate(() => {
    setCountryCodeValue(defaultCountryCode);
  }, [defaultCountryCode]);

  useEffect(() => {
    const cleanedUpPhoneNumberValue = phoneNumberValue?.replace(/[^0-9]/g, '');
    if (onChange) {
      const internationalPhoneNumberValue = `${countryDialCodeValue ?? ''}${
        cleanedUpPhoneNumberValue ?? ''
      }`;
      onChange(internationalPhoneNumberValue);
    }
  }, [phoneNumberValue, countryCodeValue]);

  useEffect(() => {
    if (isDesktopResolution) {
      clearSearchQuery();
    } else {
      setTimeout(() => {
        clearSearchQuery();
      }, 200);
    }
    if (isOpen && isDesktopResolution) {
      countryCodeInputRef.current?.focus();
    }
  }, [isOpen]);

  const handleCountryInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSearch(true);
    setCountryCodeInputUiValue(e.target.value);
    handleSearch(e); // update search query value
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhoneNumberValue(event.target.value);
  };

  const handleCountryCodeItemSelect = (option: CountryCodeOption) => {
    setCountryCodeInputUiValue(option.dialCode);
    setCountryDialCodeValue(option.dialCode);
    setCountryCodeValue(option.code);
    setIsOpen(false);
    phoneNumberInputRef.current?.focus();
  };

  const handleCountryCodeAddonAfterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  const handleCountryCodeInputRef = (ref: HTMLInputElement | null) => {
    if (ref) {
      countryCodeInputRef.current = ref;
    }
  };

  const handleCountryCodesInputFocus = () => {
    setCountryCodeInputUiValue(countryDialCodeValue);
    setIsOpen(true);
  };

  const handleMaskedInputFocus = () => {
    setIsOpen(false);
  };

  const handleCountryCodeInputBlur = () => {
    setIsSearch(false);
  };

  const handleClickDropdownOutside = () => {
    setIsOpen(false);
  };

  const countryCodeAddonBefore = countryCodeValue && (
    <Flag
      code={countryCodeValue}
      className="rounded-full overflow-hidden w-8 h-8 lg:w-10 lg:h-10 ml-3 -mr-2"
    />
  );

  const countryCodeInputAddonAfter = hasCountriesToSelect && (
    <CountryCodeInputAddonAfter
      onClick={handleCountryCodeAddonAfterClick}
      isOpen={isOpen}
    />
  );

  const countryCodesOptionsToRender = filteredOptions?.map((item) => (
    <DropdownItem
      key={item.code}
      countryCodeOption={item}
      onClick={handleCountryCodeItemSelect}
      dataTestId={`${dataTestId}-dropdown-option-${item.code}`}
    />
  ));

  const isDropdownOpen = Boolean(
    isOpen &&
      hasCountriesToSelect &&
      (countryCodesOptionsToRender.length || !isDesktopResolution),
  );

  const phoneNumberInputMask = getPhoneMaskByCountryCode(countryCodeValue, {
    shouldStripDialCode: true,
  });

  return (
    <Dropdown
      overlay={countryCodesOptionsToRender}
      isOpen={isDropdownOpen}
      onClickOutside={handleClickDropdownOutside}
    >
      <Input
        onFocus={handleCountryCodesInputFocus}
        addonBefore={countryCodeAddonBefore}
        addonAfter={countryCodeInputAddonAfter}
        onChange={handleCountryInputChange}
        onBlur={handleCountryCodeInputBlur}
        ref={handleCountryCodeInputRef}
        className="mb-2 relative w-full"
        placeholder={t('Country')}
        value={isSearch ? countryCodeInputUiValue : countryDialCodeValue ?? ''}
        disabled={disabled}
        size={size}
        autoComplete="nope"
        dataTestId={`${dataTestId}-code`}
      />
      <MaskedInput
        placeholder={placeholder}
        mask={phoneNumberInputMask}
        inputRef={phoneNumberInputRef}
        onChange={handlePhoneNumberChange}
        onFocus={handleMaskedInputFocus}
        disabled={disabled}
        size={size}
        isSecureContent
        dataTestId={`${dataTestId}-masked`}
        {...rest}
        value={phoneNumberValue} // this prop goes last to override default rest "value" prop
      />
    </Dropdown>
  );
};

export default PhoneInput;
