import { AutoRechargePromoResponse } from '@root/interfaces/contract/callingRechargePinless/AutoRechargePromo';
import { CallingPaymentInfoResponse } from '@root/interfaces/contract/callingRechargePinless/CallingPaymentInfo';

import CallingArPromoClass from '@root/models/CallingArPromoClass';

interface CreditCardTier {
  price: number;
  currency: string;
  symbol: string;
}

const AUTORECHARGE_THRESHOLD_KEY = 'AUTORECHARGE_THRESHOLD';

class CallingPaymentInfoClass {
  balanceAmount: number;

  divisor: number;

  currency: string;

  currencySymbol: string;

  creditCardTiers: CreditCardTier[];

  handleId?: string;

  thresholdAmount = 0;

  arAmount?: number;

  defaultRechargeAmount?: number;

  defaultAutoRechargeAmount: number;

  arPromoBullets?: string[];

  arPromoInfo?: CallingArPromoClass;

  isAutoRechargeAvailable = false;

  isAutoRechargeRecommended = false;

  isImtuAvailable = false;

  isCallingAvailable = false;

  isMtAvailable = false;

  constructor(data: CallingPaymentInfoResponse, arPromo?: AutoRechargePromoResponse) {
    this.balanceAmount = Number(data.balance.display_amount);
    this.currency = data.balance.currency;
    this.currencySymbol = data.balance.currency_symbol;
    this.divisor = data.balance.currency_divisor;
    this.thresholdAmount = Number(data.autorecharge_threshold?.display_amount);

    this.defaultRechargeAmount = data.credit_card_topup_default_amount
      ? data.credit_card_topup_default_amount.amount /
        data.credit_card_topup_default_amount.currency_divisor
      : undefined;

    this.defaultAutoRechargeAmount =
      data.autorecharge_default_amount?.amount /
      data.autorecharge_default_amount?.currency_divisor;
    this.arAmount =
      'amount' in data.autorecharge
        ? data.autorecharge.amount / data.autorecharge.currency_divisor
        : 0;
    this.creditCardTiers = data.creditcard_tiers.map((item) => ({
      price: Number(item.display_price),
      currency: item.price_currency,
      symbol: item.price_currency_symbol,
    }));

    // yes, force_auto_recharge == disable auto recharge (back-end design)
    // MVNO - mobile virtual network operator (e.g. boss wireless)
    this.isAutoRechargeAvailable = !(
      data.payment_features.force_auto_recharge || data.account_details.is_mvno_account
    );
    this.isAutoRechargeRecommended =
      this.isAutoRechargeAvailable && data.auto_recharge_recommended;

    this.isCallingAvailable = Boolean(data.creditcard_tiers.length);
    this.isImtuAvailable = data.payment_features.is_mobile_topup_available_via_app;
    this.isMtAvailable = false; // BMP-423

    if (data.autorecharge_promo) {
      let arPromoDescription;
      if (arPromo) {
        arPromoDescription = arPromo.cc_topup_success_ar_promo_description_ar_on;
        if (arPromo.cc_topup_success_ar_promo_bullets_ar_on) {
          this.arPromoBullets = arPromo.cc_topup_success_ar_promo_bullets_ar_on.map(
            (item) =>
              item.replace(
                AUTORECHARGE_THRESHOLD_KEY,
                `${this.currencySymbol}${this.thresholdAmount}`,
              ),
          );
        }
      }
      this.arPromoInfo = new CallingArPromoClass({
        data: data.autorecharge_promo,
        description: arPromoDescription,
      });
    }
    this.handleId = data.creditcard?.handle_id;
  }
}

export default CallingPaymentInfoClass;
