import { makeAutoObservable } from 'mobx';

import PaymentCardClass from '@root/models/PaymentCardClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import CountryCode from '@root/interfaces/CountryCode';

class Store {
  countryCode?: CountryCode;

  recipientPhone?: string;

  carrier?: ImtuCarrierClass;

  product?: ImtuProductClass;

  paymentInfo?: PaymentCardClass;

  isImtuAvailabilityModalVisible = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCountryCode(countryCode?: CountryCode) {
    this.countryCode = countryCode;
  }

  setRecipientPhone(phone?: string) {
    this.recipientPhone = phone;
  }

  setCarrier(carrier?: ImtuCarrierClass) {
    this.carrier = carrier;
  }

  setProduct(product?: ImtuProductClass) {
    this.product = product;
  }

  setPaymentInfo(paymentInfo: PaymentCardClass) {
    this.paymentInfo = paymentInfo;
  }

  setCvv(cvv: string) {
    this.paymentInfo?.setCvv(cvv);
  }

  reset() {
    this.recipientPhone = undefined;
    this.carrier = undefined;
    this.product = undefined;
    this.paymentInfo = undefined;
  }

  showImtuAvailabilityModal = () => {
    this.isImtuAvailabilityModalVisible = true;
  };

  hideImtuAvailabilityModal = () => {
    this.isImtuAvailabilityModalVisible = false;
  };
}

export default Store;
