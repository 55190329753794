export enum Languages {
  En = 'en',
  Es = 'es',
  Fr = 'fr',
}

export enum LanguageLocale {
  EnUs = 'en-us',
  EsUs = 'es-us',
  EnCa = 'en-ca',
  EsCa = 'es-ca',
  FrCa = 'fr-ca',
  EnGb = 'en-gb',
  EsGb = 'es-gb',
}
