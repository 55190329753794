import { FormikConfig } from 'formik';

import { PickFromUnion, DistributiveOmit } from '@root/types';

import { InputProps } from './Input';
import { SelectProps } from './Select';
import { PhoneInputProps } from './PhoneInput';
import { DatePickerProps } from './DatePicker';
import { RadioGroupProps } from './Radio';
import { CheckboxGroupProps } from './Checkbox';
import { PasswordInputProps } from './PasswordInput';
import { MaskedInputProps } from './MaskedInput';

export type FormConfig<T> = FormikConfig<T> & {
  onFocus?: (e: React.FocusEvent<HTMLFormElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLFormElement>) => void;
  onSubmitValidationError?(): void;
  onChange?: (values: T | {}) => void;
};

export interface FormProps<T> {
  config: FormConfig<T>;
  children: React.ReactNode;
  className?: string;
}

export type FormFieldType =
  | 'email'
  | 'password'
  | 'phone'
  | 'datePicker'
  | 'radioGroup'
  | 'checkboxGroup'
  | 'select'
  | 'text'
  | 'maskedText';

interface FieldProps {
  name: string;
  label?: string;
  required?: boolean;
  className?: string;
}

type PhoneInputFieldProps = Omit<PhoneInputProps, 'name' | 'type'> & {
  type: PickFromUnion<FormFieldType, 'phone'>;
} & FieldProps;

type DatePickerFieldProps = Omit<DatePickerProps, 'name' | 'type'> & {
  type: PickFromUnion<FormFieldType, 'datePicker'>;
} & FieldProps;

type RadioGroupFieldProps = RadioGroupProps & {
  type: PickFromUnion<FormFieldType, 'radioGroup'>;
} & FieldProps;

type CheckboxGroupFieldProps = CheckboxGroupProps & {
  type: PickFromUnion<FormFieldType, 'checkboxGroup'>;
} & FieldProps;

type SelectFieldProps = Omit<SelectProps, 'name' | 'type'> & {
  type: PickFromUnion<FormFieldType, 'select'>;
} & FieldProps;

type PasswordFieldProps = Omit<PasswordInputProps, 'name' | 'type'> & {
  type: PickFromUnion<FormFieldType, 'password'>;
} & FieldProps;

type MaskedFieldProps = Omit<MaskedInputProps, 'name' | 'type'> & {
  type: PickFromUnion<FormFieldType, 'maskedText'>;
} & FieldProps;

export enum AutoCapitalizeValues {
  Characters = 'characters',
  None = 'none',
}

export const AUTOCAPITALIZE_KEY = 'autoCapitalize';

type OtherFields = Omit<InputProps, 'type'> & {
  type: 'email' | 'text';
} & FieldProps & { [AUTOCAPITALIZE_KEY]?: AutoCapitalizeValues };

export type FieldComponentProps =
  | PhoneInputFieldProps
  | DatePickerFieldProps
  | OtherFields
  | RadioGroupFieldProps
  | CheckboxGroupFieldProps
  | SelectFieldProps
  | PasswordFieldProps
  | MaskedFieldProps;

type Handlers = 'onChange' | 'onBlur' | 'onFocus' | 'onClick';

export type FormFieldProps = DistributiveOmit<FieldComponentProps, Handlers>;
