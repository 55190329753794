import React, { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { AdaptiveCancelButtonProps } from '@root/interfaces/components/AdaptiveCancelButton';

import { trackAction } from '@services/analytics';

import { AnalyticsContext } from '@root/interfaces/Analytics';

import { isMtFlow } from '@services/analytics/helpers';

import { AnalyticsAction } from '@services/analytics/constants';

import Button from '../Button';
import Icon from '../Icon';
import MediaMatcher from '../MediaQueryMatchers';

const AdaptiveCancelButton: FC<PropsWithChildren<AdaptiveCancelButtonProps>> = (
  props,
) => {
  const { onClick, dataTestId } = props;
  const { t } = useTranslation(); // TODO: should be moved to container

  const handleClick = () => {
    if (onClick) {
      onClick();
      trackAction({
        name: AnalyticsAction.Cancel,
        context: isMtFlow() ? AnalyticsContext.MoneyTransfer : AnalyticsContext.Imtu,
      });
    }
  };

  return (
    <>
      <MediaMatcher isMobile>
        <Icon
          onClick={handleClick}
          name="Cross"
          dataTestId={dataTestId}
          className="text-2xl"
        />
      </MediaMatcher>
      <MediaMatcher isNotMobile>
        <Button
          shape="pill"
          isOutlined
          size="sm"
          color="red"
          onClick={handleClick}
          dataTestId={dataTestId}
        >
          {t('Cancel')}
        </Button>
      </MediaMatcher>
    </>
  );
};

export default AdaptiveCancelButton;
