import React, { FC } from 'react';

import { CountryCodeOption } from '@root/interfaces/components/PhoneInput';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import Flag from '../../Flag';

interface Props extends TestIdDataAttr {
  countryCodeOption: CountryCodeOption;
  onClick: (code: CountryCodeOption) => void;
}

const DropdownItem: FC<React.PropsWithChildren<Props>> = (props) => {
  const { countryCodeOption, onClick, dataTestId } = props;

  const handleClick = () => {
    onClick(countryCodeOption);
  };

  return (
    <button
      className="text-left px-3 py-2 flex items-center w-full rounded-md hover:bg-gray-200 not-last-child:mb-2 md:not-last-child:mb-0"
      type="button"
      onClick={handleClick}
      data-test-id={dataTestId}
    >
      <Flag
        code={countryCodeOption.code}
        className="rounded-full overflow-hidden w-14 h-14 md:w-10 md:h-10"
      />
      <div className="ml-2 flex flex-col justify-center text-xl md:text-base">
        <span>{countryCodeOption.dialCode}</span>
        <span className="text-gray-400">{countryCodeOption.countryName}</span>
      </div>
    </button>
  );
};

export default DropdownItem;
