import CountryCode from '../interfaces/CountryCode';
import { Languages } from '../interfaces/Languages';

interface LanguageInfo {
  name: string;
  countryCode: CountryCode;
}

export const LANGUAGES: Record<Languages, LanguageInfo> = {
  en: {
    name: 'English',
    countryCode: 'US', // t('English')
  },
  es: {
    name: 'Spanish',
    countryCode: 'ES', // t('Spanish')
  },
  fr: {
    name: 'French', // t('French')
    countryCode: 'FR',
  },
};

export const LANGUAGE_STORE_KEY = 'language';
