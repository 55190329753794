import { useTranslation } from 'react-i18next';

import { FormikConfig } from 'formik';

import { Form } from '@components/common';

import Yup from '@utils/validation/yup';
import { normalizeStringCompound } from '@utils/string';

import FormProps from '@root/interfaces/Form';

import { CVV_INPUT_MASK } from '@root/constants/inputMasks';
import {
  zipFieldLableMap,
  zipFieldMaskMap,
  zipFieldTypeMap,
  zipFiledInputModeMap,
  ZIP_POSTAL_MAX_LENGTH,
  zipFieldTypeDefault,
  zipFieldMaskDefault,
  zipFieldLabelDefault,
  zipFiledInputModeDefault,
} from '../PaymentCardBillingAddressForm/constants';

import { getZipPostalCodeValidationFn } from '../PaymentCardBillingAddressForm/helpers';

export interface PaymentCardReducedDetailsFormValues {
  cardNumber: string;
  expDateString: string;
  cvv: string;
  zip: string;
}

const PaymentCardReducedDetailsForm = (
  props: FormProps<PaymentCardReducedDetailsFormValues>,
) => {
  const {
    isEdit,
    dataTestPrefixId,
    submitBtnText,
    className,
    values,
    formHeader,
    formControls,
    onSubmit,
    countryCode,
  } = props;

  const { t } = useTranslation();

  const validationSchema = {
    cardNumber: Yup.lazy(() => {
      return isEdit ? Yup.string() : Yup.string().cardNumber().required();
    }),
    expDateString: Yup.string().expirationDateString().required(),
    zip: getZipPostalCodeValidationFn(countryCode),
    cvv: Yup.lazy(() => {
      return isEdit ? Yup.string() : Yup.string().cvv().required();
    }),
  };

  const formConfig: FormikConfig<PaymentCardReducedDetailsFormValues> = {
    enableReinitialize: true,
    initialValues: values,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit,
  };

  const formSubmitBtn = (
    <Form.Button
      dataTestId={`${dataTestPrefixId}-submit-button`}
      shape="pill"
      size="lg"
      className="w-full"
    >
      {submitBtnText}
    </Form.Button>
  );

  const formControlsSection = (
    <div className="py-[5px] text-center">
      {formSubmitBtn}
      {formControls ?? null}
    </div>
  );

  return (
    <Form config={formConfig} className={normalizeStringCompound([className, 'w-full'])}>
      {formHeader && formHeader}
      <div className="overflow-y-auto py-[24px]">
        <Form.CardNumberField
          required
          disabled={isEdit}
          name="cardNumber"
          label={t('Card number')}
          dataTestId={`${dataTestPrefixId}-card-number-input`}
        />
        <div className={normalizeStringCompound([isEdit ? 'mb-5' : 'flex space-x-5'])}>
          <Form.CardExpirationDateField
            name="expDateString"
            label={t('Expiration date')}
            dataTestId={`${dataTestPrefixId}-exp-date-input`}
            required
          />
          {!isEdit && (
            <Form.Field
              type="maskedText"
              name="cvv"
              label={t('CVV')}
              mask={CVV_INPUT_MASK}
              required
              inputMode="numeric"
              dataTestId={`${dataTestPrefixId}-cvv-input`}
              className={normalizeStringCompound([
                !isEdit ? 'mb-5' : '',
                'flex flex-col self-start',
              ])}
              isPassword
            />
          )}
        </div>
        <Form.Field
          type={zipFieldTypeMap[countryCode] || zipFieldTypeDefault}
          mask={zipFieldMaskMap[countryCode] || zipFieldMaskDefault}
          name="zip"
          label={t(zipFieldLableMap[countryCode] || zipFieldLabelDefault)}
          required
          dataTestId={`${dataTestPrefixId}-zip-input`}
          inputMode={zipFiledInputModeMap[countryCode] || zipFiledInputModeDefault}
          maxlength={ZIP_POSTAL_MAX_LENGTH}
        />
      </div>
      {formControlsSection}
    </Form>
  );
};

export default PaymentCardReducedDetailsForm;
