import { ValidatePromoResponse } from '@root/interfaces/contract/callingRechargePinless/ValidatePromo';
import { AutoRechargePromo } from '@root/interfaces/contract/callingRechargePinless/CallingPaymentInfo';

class CallingArPromoClass {
  code: string;

  type: ValidatePromoResponse['promo']['type'];

  percentage: number;

  description?: string;

  constructor({ data, description }: { data: AutoRechargePromo; description?: string }) {
    this.code = data.promo_code;
    this.type = data.type;
    this.percentage = data.percentage;
    this.description = description;
  }
}

export default CallingArPromoClass;
