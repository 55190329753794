/* eslint-disable import/prefer-default-export */

import { GridResponseSizes } from '@root/interfaces/components/Grid';

export const getColClassesByOptions = (options: {
  config?: { [key in GridResponseSizes]?: number } | number;
  prefix: string;
}) => {
  const allSizes: GridResponseSizes[] = ['sm', 'md', 'lg'];

  const { config, prefix } = options;

  switch (typeof config) {
    case 'number':
      return [`${prefix}-${config}`];
    case 'object':
      return allSizes
        .filter((size) => config[size] !== undefined)
        .map((size) => {
          return size === 'sm'
            ? `${prefix}-${config[size]}`
            : `${size}:${prefix}-${config[size]}`;
        });
    default:
      return [];
  }
};
