import { useLocalStore } from 'mobx-react';
import { format } from 'date-fns-tz';

import PaymentCardsStore from '@root/stores/PaymentCardsStore';
import PaymentCardStore from '@root/stores/PaymentCardStore';

import { parseDateFromExpDateString } from '@helpers/paymentCards';

import { PaymentCardDetailsFormValues } from '@components/common/PaymentCardDetailsForm';
import { PaymentCardBillingAddressFormValues } from '@components/common/PaymentCardBillingAddressForm';
import { PaymentCardReducedDetailsFormValues } from '@components/common/PaymentCardReducedDetailsForm';

import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';

import PaymentCardClass from '@root/models/PaymentCardClass';

import useDidMount from './useDidMount';

export interface UsePaymentCardsParams {
  onCardAdded?(card: PaymentCardClass): void;
  onCardEdited?(card: PaymentCardClass): void;
  onCardRemoved?(): void;
  onChangeSelectedCard?(): void;
}

type SubmitCardType =
  | (PaymentCardDetailsFormValues & PaymentCardBillingAddressFormValues)
  | PaymentCardReducedDetailsFormValues;

const usePaymentCards = (params: UsePaymentCardsParams = {}) => {
  const { onCardAdded, onCardEdited, onCardRemoved } = params;

  const paymentCardStore = useLocalStore(() => new PaymentCardStore());
  const paymentCardsStore = useLocalStore(() => new PaymentCardsStore());

  useDidMount(async () => {
    await paymentCardsStore.getPaymentCards();
    await paymentCardStore.getPaymentCardConfigs();
    return undefined;
  });

  /* eslint-disable @typescript-eslint/indent */
  /**
   * TODO: temp code, we need to comment async req for all cards,
   * sometimes backend sends stale cards array without added/updated/deleted card
   */
  const addCard = async (values: SubmitCardType) => {
    // await paymentCardsStore.getPaymentCards();
    // add new card to array with existed cards - add mode
    const expDate = parseDateFromExpDateString(values.expDateString);
    const cardIssuer = paymentCardStore.getCardIssuer(values.cardNumber);

    if (expDate && cardIssuer) {
      const submitParams =
        'cvv' in values
          ? {
              cardNumber: values.cardNumber,
              expDate,
              cvv: values.cvv,
              zip: values.zip,
              issuer: cardIssuer,
            }
          : {
              cardNumber: values.cardNumber,
              holderName: values.holderName,
              expDate,
              zip: values.zip,
              issuer: cardIssuer ?? '',
              city: values.city ?? '',
              state: values.state ?? '',
              street1: values.street1 ?? '',
              street2: values.street2 ?? '',
            };
      const card = await paymentCardStore.submitNewCard(submitParams);
      const cards = paymentCardsStore.paymentCards;
      if (cards) {
        paymentCardsStore.setPaymentCards([...cards, card]);
      }
      onCardAdded?.(card);
      return card;
    }
    return undefined;
  };

  const updateCard = async (values: SubmitCardType, id: string) => {
    const expDate = parseDateFromExpDateString(values.expDateString);
    if (expDate) {
      const updateParams =
        'cvv' in values
          ? {
              expDate,
              cvv: values.cvv,
              zip: values.zip,
              handleId: id,
            }
          : {
              holderName: values.holderName,
              expDate,
              zip: values.zip,
              city: values.city,
              state: values.state,
              street1: values.street1,
              street2: values.street2,
              handleId: id,
            };
      const card = await paymentCardStore.updateCard(updateParams);

      const cards = paymentCardsStore.paymentCards?.map((currentCard) =>
        currentCard.id === card.id ? card : currentCard,
      );
      if (cards) {
        paymentCardsStore.setPaymentCards(cards);
      }
      onCardEdited?.(card);
      return card;
    }
    return undefined;
  };
  /* eslint-disable @typescript-eslint/indent */

  const removeCard = async (id: string) => {
    // await paymentCardsStore.getPaymentCards();
    await paymentCardStore.removeCardByHandleId(id);

    const cards = paymentCardsStore.paymentCards?.filter((card) => card.id !== id);
    if (cards) {
      paymentCardsStore.setPaymentCards(cards);
    }
    // setSelectedPaymentCardId(undefined);
    onCardRemoved?.();
  };

  const expDateString = paymentCardStore.editableCard?.expDate
    ? format(paymentCardStore.editableCard?.expDate, CARD_EXP_DATE_FORMAT)
    : '';

  const editableCardAllValues: PaymentCardDetailsFormValues &
    PaymentCardBillingAddressFormValues = {
    cardNumber: paymentCardStore.editableCard?.maskedNumber ?? '',
    holderName: paymentCardStore.editableCard?.accountHolder ?? '',
    expDateString,
    street1: paymentCardStore.editableCard?.address1 ?? '',
    street2: paymentCardStore.editableCard?.address2 ?? '',
    city: paymentCardStore.editableCard?.city ?? '',
    zip: paymentCardStore.editableCard?.zip ?? '',
    state: paymentCardStore.editableCard?.state ?? '',
  };

  const editableCardReducedValues: PaymentCardReducedDetailsFormValues = {
    cardNumber: paymentCardStore.editableCard?.maskedNumber ?? '',
    expDateString,
    zip: paymentCardStore.editableCard?.zip ?? '',
    cvv: '',
  };

  const isLoading = paymentCardsStore.isLoading || paymentCardStore.isLoading;

  const handleSetEditableCardId = (id?: string) => {
    if (id) {
      paymentCardStore.getEditableCardByHandleId(id);
    } else {
      paymentCardStore.clearEditableCard();
    }
  };

  return {
    isLoading,
    hasPaymentCards: paymentCardsStore.hasCards,
    paymentCards: paymentCardsStore.paymentCards,
    selectedPaymentCardId: paymentCardStore.editableCard?.id,
    editableCard: paymentCardStore.editableCard,
    removeCard,
    updateCard,
    addCard,
    editableCardAllValues,
    editableCardReducedValues,
    setEditableCardId: handleSetEditableCardId,
    editableCardId: paymentCardStore.editableCard?.handleId,
  };
};

export default usePaymentCards;
