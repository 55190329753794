import { makeAutoObservable } from 'mobx';

export type UserFieldsModalType = 'name' | 'dob';

class Store {
  userFieldsModalType: UserFieldsModalType | null = null;

  isLoading = false;

  isCustomerSupportModalOpen = false;

  isAccountDeletionModalOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  setActiveUserFieldsModalType = (type: UserFieldsModalType | null) => {
    this.userFieldsModalType = type;
  };

  showCustomerSupportModal = () => {
    this.isCustomerSupportModalOpen = true;
  };

  hideCustomerSupportModal = () => {
    this.isCustomerSupportModalOpen = false;
  };

  showAccountDeletionModal = () => {
    this.isAccountDeletionModalOpen = true;
  };

  hideAccountDeletionModal = () => {
    this.isAccountDeletionModalOpen = false;
  };
}

export default Store;
