import braze from '@braze/web-sdk';

type InitializationOptions = braze.InitializationOptions;

interface ConfigProps {
  brazeApiKey: string;
  brazeConfig: InitializationOptions;
}

class BrazeAPI {
  readonly app: typeof braze;

  constructor(config: ConfigProps) {
    braze.initialize(config.brazeApiKey, config.brazeConfig);
    this.app = braze;
    try {
      this.app?.openSession();
    } catch (err) {
      if (process.env.NODE_ENV !== 'test') {
        // eslint-disable-next-line no-console
        console.log('braze init error:', err);
      }
    }
  }
}

export default BrazeAPI;
