import React from 'react';
import { Img } from 'react-image';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { Icon } from '@components/common';

import CarrierLogoGeneric from '@components/modules/Imtu/components/CarrierLogoGeneric';

import CardItem from './index';

interface Props extends TestIdDataAttr {
  data: ImtuCarrierClass;
  isActive?: boolean;
  onClick: (e: React.MouseEvent, value: string) => void;
}

const ImtuCarrierCard: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, isActive, onClick } = props;

  const handleClick = (e: React.MouseEvent) => {
    onClick(e, data.code);
  };

  return (
    <CardItem
      leftPart={
        <Img
          className="w-18 h-18 p-4"
          src={data.logoUrl}
          unloader={<CarrierLogoGeneric className="w-18 h-18" />}
        />
      }
      middlePart={
        <div className="text-center text-2xl">
          <div className="line-clamp-3">{data.name}</div>
        </div>
      }
      rightPart={
        <div className="flex justify-end h-full text-xl">
          <Icon className="text-gray-300" name="ChevronRightAlt" />
        </div>
      }
      value={data.name}
      isActive={isActive}
      onClick={handleClick}
    />
  );
};

export default ImtuCarrierCard;
