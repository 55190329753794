import React, { useEffect, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import RlgModal from '@components/common/RouteLeavingGuard/RlgModal';

import { isHtmlElement } from '@utils/typeGuards';

import { externalUrlRegexp } from '@root/constants/regexpPatterns';

export type ButtonTypes = 'OK' | 'Cancel';
export type ButtonConfigItem = {
  label: string;
  type: ButtonTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: () => void;
};
interface Location {
  pathname: string;
}

interface Props {
  when?: boolean;
  title: string;
  subTitle?: string;
  shouldBlockNavigation?: (location: Location) => boolean;
  buttonConfig: ButtonConfigItem[];
}

const TARGET_BLANK = '_blank';

/**
 * React-router Prompt hacked component to prevent leaving a page without confirmation which also supports callbacks
 * @param props
 * @constructor
 */
const RouteLeavingGuard = (props: Props) => {
  const { title, subTitle, when, shouldBlockNavigation, buttonConfig } = props;

  const [isModalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [isNavigationConfirmed, setIsNavigationConfirmed] = useState(false);

  const history = useHistory();

  const closeModal = () => {
    setModalVisible(false);
  };

  const showModal = () => {
    setModalVisible(true);
  };

  const handleBlockedNavigation = (nextLocation: Location): string | boolean => {
    if (
      !isNavigationConfirmed &&
      shouldBlockNavigation &&
      shouldBlockNavigation(nextLocation)
    ) {
      showModal();
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleOk = () => {
    closeModal();
    setIsNavigationConfirmed(true);
  };

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const { target } = e;
      if (isHtmlElement(target) && target.closest('a') && when) {
        const link = target.closest('a');
        const href = link?.getAttribute('href');
        const shouldOpenedInNewTab = link?.getAttribute('target') === TARGET_BLANK;
        if (!shouldOpenedInNewTab && href && externalUrlRegexp.test(href)) {
          e.preventDefault();
          history.push(href);
        }
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => document.removeEventListener('click', handleDocumentClick);
  }, [when]);

  useEffect(() => {
    if (isNavigationConfirmed && lastLocation) {
      // Navigate to the previous blocked location
      if (externalUrlRegexp.test(lastLocation.pathname)) {
        const match = lastLocation.pathname.match(externalUrlRegexp);
        if (match?.index) {
          const href = lastLocation.pathname.substring(match.index);
          window.location.href = href;
        }
      } else {
        history.push(lastLocation.pathname);
      }
    }
  }, [isNavigationConfirmed, lastLocation]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <RlgModal
        title={title}
        subTitle={subTitle}
        isModalVisible={isModalVisible}
        buttonConfig={buttonConfig}
        onOk={handleOk}
        onCancel={closeModal}
      />
    </>
  );
};

export default RouteLeavingGuard;
