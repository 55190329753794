import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { Typography } from '@components/common';

import { getFormattedAmount } from '@helpers/money';

interface Props {
  amount: number;
  currencySymbol: string;
  currency: string;
  title: string;
  className?: string;
}

const Balance: FC<React.PropsWithChildren<Props>> = (props) => {
  const { amount, currencySymbol, currency, title, className } = props;

  return (
    <div className={normalizeStringCompound(['text-center font-bold', className])}>
      <Typography.Title level={4} className="mb-2">
        {title}
      </Typography.Title>
      <div className="text-7xl tracking-wide">
        {`${currencySymbol}${getFormattedAmount(amount)}`}
        <span className="font-regular text-2xl align-top">{currency}</span>
      </div>
    </div>
  );
};

export default Balance;
