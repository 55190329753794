import { makeAutoObservable } from 'mobx';

class Store {
  selectedAmount?: number;

  isAutoRechargeEnabled = false;

  hasUnsavedChanges = false;

  constructor({
    arAmount,
    isAutoRechargeEnabled,
  }: {
    arAmount?: number | string;
    isAutoRechargeEnabled: boolean;
  }) {
    this.setSelectedAmount(arAmount);
    this.setAutoRechargeEnabled(isAutoRechargeEnabled);
    makeAutoObservable(this);
  }

  setSelectedAmount = (value?: number | string) => {
    this.selectedAmount = Number(value) || undefined;
  };

  setAutoRechargeEnabled = (value = true) => {
    this.isAutoRechargeEnabled = value;
  };

  setHasUnsavedChanges = (value = true) => {
    this.hasUnsavedChanges = value;
  };
}

export default Store;
