import React, { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { liveChatStart } from '@services/liveChat';

import { Grid, Typography, Divider } from '@components/common';

import { AppConfigStore, UserAccountStore } from '@root/stores';

import { STORE_APP_URLS } from '@root/constants/storeUrls';

import { getAvailableLanguageOptions } from '@helpers/language';

import useStore from '@root/hooks/useStore';

import { ReactComponent as AppStoreBadgeIcon } from '@src/static/assets/images/appStoreBadge.svg';
import { ReactComponent as GooglePlayBadgeIcon } from '@src/static/assets/images/googlePlayBadge.svg';

import LinksList from './components/LinksList';
import FooterSettings from './components/FooterSettings';

import { getBottomLinks, getServiceLinks, getSupportLinks } from './helpers';

import { socialLinks, LINK_ACTIONS, sellLinks } from './constants';

import './styles.scss';

const Footer: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const handleClickChatNow = () => {
    liveChatStart();
  };

  const featureAvailability = appConfigStore.availableMainFeaturesCfg;

  const supportLinkActions = {
    [LINK_ACTIONS.CHAT_NOW]: handleClickChatNow,
    // put another actions here ...
  };

  return (
    <footer className="footer">
      <Grid.Row className="footer-top">
        <Grid.Col span={{ sm: 12, md: 4 }}>
          <LinksList
            title={t('Services')}
            list={getServiceLinks({
              isCallingAvailable: featureAvailability.isCallingAvailable,
              isMtAvailable: featureAvailability.isMtAvailable,
              isImtuAvailable: featureAvailability.isImtuAvailable,
            })}
          />
          <LinksList title={t('Social')} list={socialLinks} />
        </Grid.Col>

        <Grid.Col span={{ sm: 12, md: 4 }}>
          <LinksList
            title={t('Support')}
            list={getSupportLinks({
              isCallingAvailable: featureAvailability.isCallingAvailable,
              isMtAvailable: featureAvailability.isMtAvailable,
              langLocale: userAccountStore.currentLanguageLocale,
              countryCode: userAccountStore.userCountryOfOrigin,
              mailtoSubject: t('Support'),
            })}
            listActions={supportLinkActions}
          />
        </Grid.Col>

        <Grid.Col span={{ sm: 12, md: 4 }}>
          <LinksList
            title={t('Become a Reseller')}
            list={sellLinks}
            listActions={supportLinkActions}
          />
          <Typography.Title level={5} className="mb-6">
            {t('Download our apps')}
          </Typography.Title>
          <div className="md:flex md:justify-between mb-8">
            <div className="mb-2 md:mb-0 flex-initial md:mr-1 lg:mr-4">
              <Typography.Link
                isNative
                target="_blank"
                to={STORE_APP_URLS.APP_STORE.ALL_APPS}
                className="block"
              >
                <AppStoreBadgeIcon className="h-16 max-w-full" />
              </Typography.Link>
            </div>
            <div className="flex-initial">
              <Typography.Link
                isNative
                target="_blank"
                to={STORE_APP_URLS.GOOGLE_PLAY.ALL_APPS}
                className="block"
              >
                <GooglePlayBadgeIcon className="h-16 max-w-full" />
              </Typography.Link>
            </div>
          </div>
          <FooterSettings
            className="mb-12 md:mb-auto"
            languagesOptions={getAvailableLanguageOptions(
              userAccountStore.userCountryOfOrigin,
            )}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row className="mb-6 footer-bot md:mb-10">
        <Grid.Col span={12}>
          <div className="text-base">
            <div className="flex flex-col mb-2 md:block">
              {getBottomLinks({
                langLocale: userAccountStore.currentLanguageLocale,
                countryCode: userAccountStore.userCountryOfOrigin,
              }).map((item, index) => (
                <Fragment key={item.to.toString()}>
                  {index !== 0 && (
                    <Divider isVertical className="hidden md:inline-block bg-gray-900" />
                  )}
                  <Typography.Link
                    to={item.to}
                    isNative={item.isNative}
                    className="mb-4 md:mb-0"
                  >
                    {item.text}
                  </Typography.Link>
                </Fragment>
              ))}
            </div>
            <p>
              {t('Copyright © {{currentYear}} IDT Corporation. All rights reserved.', {
                currentYear: new Date().getFullYear(),
              })}
            </p>
          </div>
        </Grid.Col>
      </Grid.Row>
    </footer>
  );
};

export default observer(Footer);
