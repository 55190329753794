import React from 'react';
import CallingPromoClass from '@root/models/CallingPromoClass';
import { useTranslation } from 'react-i18next';

interface Props {
  promoInfo: CallingPromoClass;
}

const ManualPromoDescription: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { promoInfo } = props;

  const { t } = useTranslation();

  const promoDisplayValue =
    promoInfo.type === 'FIXEDAMOUNT'
      ? `${promoInfo.currencySymbol}${promoInfo.amount} ${promoInfo.currency}`
      : `${promoInfo.percentage}%`;

  return (
    <>
      <p className="text-xl mt-5">
        {t('Valid Code: Extra {{promoDisplayValue}}', {
          promoDisplayValue,
        })}
      </p>
      <p className="text-xl font-bold text-green-400 mt-5">{promoInfo.description}</p>
    </>
  );
};

export default ManualPromoDescription;
