import { getTime } from 'date-fns';

import { UserSessionResponse } from '@root/interfaces/contract/Auth';

export class SessionClass {
  accessToken = '';

  accountStatus?: 'new' | 'reverify';

  expirationTime = 0;

  isNewUser?: boolean;

  jwtAuthToken = '';

  profileId = '';

  refreshToken = '';

  tokenType?: 'bearer';

  shouldVerifyEmail = false; // TODO: remove this workaround when login switched from email lto pin

  constructor(session: UserSessionResponse) {
    this.accessToken = session.access_token;
    this.accountStatus = session.account_status;
    this.expirationTime = this.getExpirationTime(session.expires_in);
    this.isNewUser = session.is_new_user;
    this.jwtAuthToken = session.jwt_auth_token;
    this.profileId = session.profile_id;
    this.refreshToken = session.refresh_token;
    this.tokenType = session.token_type;
  }

  getExpirationTime = (shiftFromNow: number) => {
    const TIME_BEFORE_EXPIRE = 3000;

    return getTime(new Date()) + shiftFromNow - TIME_BEFORE_EXPIRE;
  };
}

export default SessionClass;
