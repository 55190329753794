import { FunctionComponent } from 'react';

const CommonTemplate: FunctionComponent<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;

  return (
    <div className="bg-white w-full flex flex-col justify-between print:pt-0 min-h-screen">
      <div className="print:min-h-0">
        <div>{children}</div>
      </div>
    </div>
  );
};

export default CommonTemplate;
