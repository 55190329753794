import React from 'react';
import { useTranslation } from 'react-i18next';

import TransactionHistory from '@components/modules/TransactionHistory';
import SideBarTemplate from '@components/templates/SideBarTemplate';

const TransactionsHistoryPage = () => {
  const { t } = useTranslation();

  return (
    <SideBarTemplate
      titleConfig={{
        title: t('my transactions'),
      }}
    >
      <TransactionHistory />
    </SideBarTemplate>
  );
};

export default TransactionsHistoryPage;
