import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { Drawer, Modal, Spinner, MediaMatcher } from '@components/common';

import { PaymentCardReducedDetailsDialogProps } from '@root/interfaces/components/PaymentCardDialog';

import PaymentCardReducedDetailsForm from '../PaymentCardReducedDetailsForm';
import PaymentCardDialogHeader from '../PaymentCardDialogFormHeader';
import Button from '../Button';

import { useIsMobile } from '../MediaQueryMatchers';

const PaymentCardReducedDetailsDialog = (props: PaymentCardReducedDetailsDialogProps) => {
  const {
    isOpen,
    onRemove,
    onSubmit,
    onClose,
    values,
    isLoading,
    handleId,
    countryCode,
    onDialogCloseAnimationEnd,
  } = props;

  const { t } = useTranslation();

  const isMobile = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      const reducedDetailsDrawerRoot = document.createElement('div');
      reducedDetailsDrawerRoot.className =
        'z-[11111] relative reduced-details-form-container [--drawer-content-wrapper-height:auto]';
      document.body.appendChild(reducedDetailsDrawerRoot);

      return () => {
        document.body.removeChild(reducedDetailsDrawerRoot);
      };
    }
    return undefined;
  }, [isMobile]);

  const handleOnRemove = () => {
    if (handleId) {
      onRemove(handleId);
    }
  };

  const handleDialogClose = () => {
    onClose();
    if (!isMobile) {
      onDialogCloseAnimationEnd?.();
    }
  };

  const divRef = useRef<HTMLDivElement>(null);

  const paymentCardReducedDetailsForm = (
    <div ref={divRef}>
      <Spinner isSpinning={Boolean(isLoading)} className="flex">
        <PaymentCardReducedDetailsForm
          countryCode={countryCode}
          isEdit={Boolean(handleId)}
          dataTestPrefixId=""
          className="flex flex-col px-[25px]"
          submitBtnText={handleId ? t('Update card') : t('Save card')}
          values={values}
          formHeader={
            <PaymentCardDialogHeader
              title={handleId ? t('Edit payment card') : t('Add payment method')}
              onClose={handleDialogClose}
            />
          }
          formControls={
            handleId && (
              <Button shape="text" color="red" onClick={handleOnRemove} className="my-3">
                {t('Remove this card')}
              </Button>
            )
          }
          key={handleId ?? uuidv4()}
          onSubmit={onSubmit}
        />
      </Spinner>
    </div>
  );

  return (
    <>
      <MediaMatcher isMobile>
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          handler={false}
          getContainer=".reduced-details-form-container"
          placement="bottom"
          level={null}
          onCloseAnimationEnd={onDialogCloseAnimationEnd}
        >
          {paymentCardReducedDetailsForm}
        </Drawer>
      </MediaMatcher>
      <MediaMatcher isNotMobile>
        <Modal isOpen={isOpen} onRequestClose={handleDialogClose} isClosable={false}>
          {paymentCardReducedDetailsForm}
        </Modal>
      </MediaMatcher>
    </>
  );
};

export default PaymentCardReducedDetailsDialog;
