import React, { FC, useState } from 'react';

import Input from '@components/common/Input/';
import Button from '@src/components/common/Button';

import { PasswordInputProps } from '@root/interfaces/components/PasswordInput';

const PasswordInput: FC<React.PropsWithChildren<PasswordInputProps>> = (props) => {
  const { size, isTogglerVisible, showBtnText, hideBtnText, ...rest } = props;

  const [isHidden, setIsHidden] = useState(true);

  const handleClick = () => {
    setIsHidden(!isHidden);
  };

  const button = isTogglerVisible ? (
    <Button size={size} type="button" shape="text" onClick={handleClick}>
      {isHidden ? showBtnText : hideBtnText}
    </Button>
  ) : null;

  return (
    <Input
      type={isHidden ? 'password' : 'text'}
      {...rest}
      size={size}
      addonAfter={button}
    />
  );
};

PasswordInput.defaultProps = {
  isTogglerVisible: true,
  showBtnText: 'Show',
  hideBtnText: 'Hide',
};

export default PasswordInput;
