import { dtcbAuthAxios } from '@services/axios';

import SessionClass from '@root/models/SessionClass';

import {
  ConfirmPhoneRequest,
  RefreshSessionRequest,
  SupportedCountriesResponse,
  UserSessionResponse,
} from '@root/interfaces/contract/Auth';

import { API_ERROR_MSG } from '@root/constants/errorMessages';

import { trackLogin, trackSignUp } from '@services/analytics';

import { DTC_AUTH_ENDPOINTS } from '@services/api/v1/constants/dtc';

export const fetchSupportedCountries = async () => {
  const res = await dtcbAuthAxios.get<SupportedCountriesResponse>(
    `${DTC_AUTH_ENDPOINTS.SUPPORTED_COUNTRIES}?application_id=${process.env.REACT_APP_APPLICATION_ID}`,
  );

  return res.data.supported_countries;
};

export const requestSmsCodeToPhoneNumber = async (phoneNumber: string) => {
  const res = await dtcbAuthAxios.post(`${DTC_AUTH_ENDPOINTS.SMS_VERIFICATION}`, {
    application_id: process.env.REACT_APP_APPLICATION_ID,
    installation_id: '123qwe',
    msisdn: phoneNumber,
  });

  return res.data;
};

// creates a session for existing customer
export const createSessionWithSmsToken = async (
  phoneNumber: string,
  phoneVerificationToken: string,
) => {
  try {
    const res = await dtcbAuthAxios.post<UserSessionResponse>(
      `${DTC_AUTH_ENDPOINTS.SMS_VERIFICATION}/session`,
      {
        application_id: process.env.REACT_APP_APPLICATION_ID,
        installation_id: '123qwe',
        msisdn: phoneNumber,
        token: phoneVerificationToken,
      },
    );

    trackLogin();

    return new SessionClass(res.data);
  } catch (err) {
    if (err.message === API_ERROR_MSG.NOT_FOUND) {
      const res = await dtcbAuthAxios.post<UserSessionResponse>(
        `${DTC_AUTH_ENDPOINTS.SMS_VERIFICATION}/signup`,
        {
          application_id: process.env.REACT_APP_APPLICATION_ID,
          installation_id: '123qwe',
          msisdn: phoneNumber,
          token: phoneVerificationToken,
        },
      );

      const session = new SessionClass(res.data);

      trackSignUp(session.profileId);

      return session;
    }
    throw err;
  }
};

export const verifyPhoneWithSmsCode = async (data: ConfirmPhoneRequest) => {
  const res = await dtcbAuthAxios.put<{ token: string }>(
    `${DTC_AUTH_ENDPOINTS.SMS_VERIFICATION}`,
    {
      application_id: process.env.REACT_APP_APPLICATION_ID,
      installation_id: '123qwe',
      msisdn: data.phoneNumber,
      verification_code: data.verificationCode,
    },
  );
  return res.data;
};

export const refreshSession = async (data: RefreshSessionRequest) => {
  const res = await dtcbAuthAxios.post<UserSessionResponse>(
    `${DTC_AUTH_ENDPOINTS.REFRESH}/session`,
    {
      application_id: process.env.REACT_APP_APPLICATION_ID,
      profile_id: data.profileId,
      refresh_token: data.refreshToken,
    },
  );

  return new SessionClass(res.data);
};

export const deleteUserAccount = async (id: string) => {
  return dtcbAuthAxios.delete(`${DTC_AUTH_ENDPOINTS.ACCOUNT}`, {
    params: { id },
  });
};
