import { makeAutoObservable } from 'mobx';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';
import { CallingApiErrorCode } from '@services/axios/constants';
import { DtcImtuK2Transaction } from '@root/interfaces/contract/dtcImtuK2/Transactions';
import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';

type TransactionData = DtcImtuK2Transaction;

class ImtuTransaction {
  id = '';

  productCode = '';

  carrier?: ImtuCarrierClass;

  product?: ImtuProductClass;

  submitDate = new Date();

  recipientId = '';

  senderAmount = 0;

  senderCurrency = '';

  recipientAmount? = 0;

  recipientCurrency? = '';

  recipientCountryCode: CountryCode;

  recipientPhoneNumber: string;

  status: ImtuStatus;

  errorCode?: CallingApiErrorCode;

  paymentCardId: string;

  redirectUrl?: string;

  constructor(data: TransactionData) {
    makeAutoObservable(this);

    this.id = data.txid;

    if (data.carrier && data.pricing_information) {
      this.carrier = new ImtuCarrierClass({
        code: data.carrier.code,
        image_url: data.carrier.image_url,
        display_name: data.carrier.display_name,
      });

      const pricingInfo = data.pricing_information;
      this.senderAmount = Number(pricingInfo.price.amount);
      this.senderCurrency = pricingInfo.price.currency;

      this.recipientAmount = pricingInfo.value?.amount
        ? Number(pricingInfo.value?.amount)
        : this.senderAmount;
      this.recipientCurrency = pricingInfo.value?.currency || this.senderCurrency;

      this.product = new ImtuProductClass({
        code: data.offer_id,
        countryCode: data.recipient_country,
        name: data.product_name,
        carrier: this.carrier,
        senderCurrency: data.pricing_information.base_price.currency,
        senderAmount: Number(data.pricing_information.base_price.amount),
        senderTotalAmount: Number(data.pricing_information.price.amount),
        fee: Number(data.pricing_information.fees?.amount),
        recipientAmount: Number(data.pricing_information.value?.amount),
        recipientCurrency: data.pricing_information.value?.currency,
        description: data.product_description.split('\n'),
      });
    }

    this.submitDate = new Date(data.purchase_started_at);
    this.recipientId = data.recipient_msisdn;

    this.recipientCountryCode = data.recipient_country;
    this.recipientPhoneNumber = data.recipient_msisdn;

    this.redirectUrl = data.redirect_url;

    this.paymentCardId = data.handle_id;

    this.status = data.purchase_status;
    this.errorCode = data.error_reason;
  }
}

export default ImtuTransaction;
