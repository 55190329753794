import React from 'react';

import useHandle3dsTxnId from '@root/hooks/useHandle3dsTxnId';

import ServiceTemplate from '@components/templates/ServiceTemplate';

import ImtuReceipt from '@components/modules/ImtuReceipt';

import { IMTU_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

const ImtuPage = () => {
  const { txnId } = useHandle3dsTxnId({ storeKey: IMTU_3DS_PENDING_TXN_ID_STORE_KEY });

  return <ServiceTemplate>{txnId && <ImtuReceipt txnId={txnId} />}</ServiceTemplate>;
};

export default ImtuPage;
