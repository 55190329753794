/* eslint-disable import/prefer-default-export */

export const CALLING_ENDPOINTS = {
  INIT_TOP_UP: '/inittopup',
  INIT_MOBILE_TOP_UP: '/initmobiletopup',
  CARRIERS: '/getmobiletopupproducts',
  PRODUCTS: '/getmobiletopupproducts',
  TOP_UP_HISTORY: '/getmobiletopuphistory',
  SEND_MONEY: '/topupmobile',
  SET_AUTO_RECHARGE: '/setautorecharge',
  VALIDATE_PROMO: '/validatepromo',
  TOP_UP_CREDIT_CARD: '/topupcreditcard',
  RECHARGE_HISTORY: '/getfundinghistory',
};
