import React, { ChangeEvent, FC, useState } from 'react';
import InputMask from 'react-input-mask';
import ReactDatePicker from 'react-datepicker';

import { DatePickerProps } from '@root/interfaces/components/DatePicker';

import { Input } from '@components/common';

import { useIsDesktop } from '../MediaQueryMatchers';

import './styles.scss';

const DatePicker: FC<React.PropsWithChildren<DatePickerProps>> = (props) => {
  const { maxDate, minDate, onChange, onFocus, onBlur, value, name, ...rest } = props;

  const [currentDate, setCurrentDate] = useState<Date | null>(value || null);

  const isDesktop = useIsDesktop();

  const renderMaskedInput = (
    <InputMask mask="99-99-9999" maskChar="">
      {() => (
        <Input
          name={name}
          type="text"
          placeholder="mm-dd-yyyy"
          autoComplete="nope"
          {...rest}
        />
      )}
    </InputMask>
  );

  const handleChange = (date: Date | null) => {
    if (onChange) {
      onChange(date);
    }
    setCurrentDate(date);
  };

  /**
   * workaround for react-datepicker old bug which is not being fixed
   * when onChange event not being triggered in Safari.
   * Also a fix for the weird date parsing when the value is not complete
   * BRP-1316
   */
  const handleChangeRaw = (e: ChangeEvent<HTMLInputElement> | MouseEvent) => {
    if (e.target && 'value' in e.target) {
      const [month, day, year] = e.target.value.split('-');
      const isValueComplete = month && day && year?.length === 4;
      if (isValueComplete) {
        const newValue = new Date(Number(year), Number(month) - 1, Number(day));
        handleChange(newValue);
      }
    }
  };

  /* eslint-disable @typescript-eslint/indent */
  const otherDatepickerProps = !isDesktop
    ? {
        open: false, // make datepicker hidden on mobile
        showTimeInput: true /* TODO: 
            issue reference: https://github.com/Hacker0x01/react-datepicker/issues/2028#issuecomment-721961181
            we need this prop set to true to make onBlur handler possible to be called and field validation happens */,
      }
    : {};
  /* eslint-disable @typescript-eslint/indent */

  return (
    <ReactDatePicker
      selected={value || currentDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={handleChange}
      onChangeRaw={handleChangeRaw}
      onFocus={onFocus}
      onBlur={onBlur}
      customInput={renderMaskedInput}
      {...otherDatepickerProps}
    />
  );
};
export default DatePicker;
