import { luhnCheck, getPaymentCardInfo } from '@helpers/paymentCards';
import { onlyDigitsRegexp } from '@root/constants/regexpPatterns';

export const isValidPaymentCard = (cardNumber: string) => {
  const cardInfo = onlyDigitsRegexp.test(cardNumber) && getPaymentCardInfo(cardNumber);

  return Boolean(
    cardInfo && cardInfo.lengths.indexOf(cardNumber.length) >= 0 && luhnCheck(cardNumber),
  );
};

export default isValidPaymentCard;
