import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { OptionProps } from '@root/interfaces/components/Select';

interface Props extends OptionProps {
  onClick: (option: OptionProps) => void;
  className?: string;
}

const Option: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onClick, dataTestId, className, ...option } = props;

  const handleClick = () => {
    onClick(option);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter') {
      onClick(option); // TODO: double check is it really necessary to call this handler
    }
  };

  return (
    <button
      className={normalizeStringCompound([
        'block text-left px-3 py-2 w-full rounded-md hover:bg-gray-200 transition',
        className,
      ])}
      onClick={handleClick}
      onKeyUp={handleKeyUp}
      data-test-id={dataTestId}
      disabled={option.isDisabled}
    >
      {option.label || option.text}
    </button>
  );
};

export default Option;
