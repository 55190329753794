import { ForwardedRef, forwardRef, useLayoutEffect, useRef, useState } from 'react';

import Grid from '@components/common/Grid';
import Drawer from '@components/common/Drawer';
import Scrollbar from '@components/common/Scrollbar';

import { normalizeStringCompound } from '@utils/string';

import { DropdownProps } from '@root/interfaces/components/Dropdown';

import MediaMatcher, { useIsMobile, useIsNotMobile } from '../MediaQueryMatchers';

import SelectSkin from '../Select/constants';

import './styles.scss';

const dropdownDefaultHeight = 350;
const DEFAULT_DRAWER_CONTAINER_TAG_NAME = 'html';
const SKIN = 'default';
const SIZE = 'md';

const Dropdown = forwardRef((props: DropdownProps, ref: ForwardedRef<HTMLDivElement>) => {
  const pageTopOffset = useRef(window.pageYOffset);

  const {
    isOpen,
    children,
    overlay,
    containerRef,
    containerClassName,
    className,
    size = SIZE,
    skin = SKIN,
    dropdownClassName,
    dropdownAddonTop,
    dropdownAddonBottom,
    drawerContentWrapperClassName,
    isChildrenVisible = true,
    minDropdownBottomOffset = 0,
  } = props;

  const [dropdownDirection, setDropdownDirection] = useState<'down' | 'up'>('down');

  const sizeClassNames = { sm: 'pt-16', md: 'pt-20', lg: 'pt-24' };

  const dropdownHeight = useRef(dropdownDefaultHeight);
  const childrenWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownWrapperRef = useRef<HTMLDivElement>(null);
  const dropdownAddonBottomRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const isMobile = useIsMobile();
  const isNotMobile = useIsNotMobile();

  useLayoutEffect(() => {
    if (isOpen || !isMobile) {
      pageTopOffset.current = window.pageYOffset;
      const wrapperRect = childrenWrapperRef?.current?.getBoundingClientRect();
      if (wrapperRect?.height && wrapperRect.top && dropdownWrapperRef.current) {
        // TODO: temp code, find another solution without relying on the "skin" prop
        if (skin === SelectSkin.DEFAULT) {
          const availableHeight =
            document.documentElement.clientHeight -
            wrapperRect.top -
            parseFloat(getComputedStyle(dropdownWrapperRef.current).paddingTop) -
            minDropdownBottomOffset;
          dropdownHeight.current = availableHeight;
          return undefined;
        }
        // configure dropdown direction
        if (
          containerRef?.current &&
          dropdownWrapperRef.current &&
          overlayRef.current &&
          dropdownAddonBottomRef.current
        ) {
          const dropdownFullHeight =
            containerRef.current.clientHeight +
            overlayRef.current.clientHeight +
            dropdownAddonBottomRef.current.clientHeight;
          const availableHeightForDropdown =
            document.documentElement.clientHeight -
            wrapperRect.top -
            minDropdownBottomOffset;
          setDropdownDirection(
            dropdownFullHeight > availableHeightForDropdown ? 'up' : 'down',
          );
          return undefined;
        }
      }
    }
    return undefined;
  }, [isOpen, isMobile, skin, minDropdownBottomOffset, containerRef]);

  const isDropdownOpen = isNotMobile && isOpen;
  const isDrawerOpen = isMobile && isOpen;

  const dropdownWrapperClassNames = normalizeStringCompound([
    'relative',
    isDropdownOpen ? 'z-50' : undefined,
    className,
  ]);

  const childrenWrapperClassNames = normalizeStringCompound([
    'relative',
    isDropdownOpen ? 'z-50' : undefined,
  ]);

  // TODO: temp code, find another solution without relying on the "skin" prop
  const scrollbarStyles =
    skin === SelectSkin.DEFAULT ? { maxHeight: `${dropdownHeight.current}px` } : {};

  return (
    <div ref={containerRef} className={dropdownWrapperClassNames}>
      <div ref={childrenWrapperRef} className={childrenWrapperClassNames}>
        {children}
      </div>
      {isDropdownOpen && (
        <div
          ref={dropdownWrapperRef}
          className={normalizeStringCompound([
            'absolute overflow-hidden rounded-2xl border-gray-300 bg-white [width:calc(100%+1rem)]',
            skin === 'default' ? 'top-[-0.5rem] left-[-0.5rem] shadow-lg' : '',
            dropdownDirection === 'down'
              ? 'top-0 [--dropdown-direction:top]'
              : 'bottom-0 [--dropdown-direction:bottom]',
            sizeClassNames[size],
            dropdownClassName,
          ])}
        >
          {dropdownAddonTop}
          <Scrollbar style={scrollbarStyles} forceVisible autoHide={false}>
            <div ref={overlayRef} className="p-2">
              {overlay}
            </div>
          </Scrollbar>
          {dropdownAddonBottom && (
            <div ref={dropdownAddonBottomRef}>{dropdownAddonBottom}</div>
          )}
        </div>
      )}
      <MediaMatcher isMobile>
        <Drawer
          placement="bottom"
          isOpen={isDrawerOpen}
          level={null}
          handler={false}
          className={drawerContentWrapperClassName}
          getContainer={containerClassName ?? DEFAULT_DRAWER_CONTAINER_TAG_NAME}
        >
          <Grid.Container ref={ref} className="flex h-full flex-col">
            {dropdownAddonTop}
            <div className="flex-1">
              {isChildrenVisible && (
                <div className="sticky top-0 z-10 bg-white py-5">{children}</div>
              )}
              {overlay}
            </div>
            {dropdownAddonBottom}
          </Grid.Container>
        </Drawer>
      </MediaMatcher>
    </div>
  );
});

export default Dropdown;
