import { AxiosRequestConfig, AxiosInstance } from 'axios';
import { setup } from 'axios-cache-adapter';

class Axios {
  instance: AxiosInstance;

  constructor(config: AxiosRequestConfig) {
    const defaultConfig = {
      withCredentials: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    };

    this.instance = setup({
      ...defaultConfig,
      ...config,
      cache: {
        ignoreCache: true, // for already existing app it is better to setup individual cached requests to not ruin anything
        exclude: { query: false, methods: ['put', 'patch', 'delete'] },
        // debug(...args: unknown[]) {
        //   console.log(...args); // uncomment for debugging
        // },
      },
    });
  }
}

export default Axios;
