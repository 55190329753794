import React, { FC } from 'react';

import { PageNumberProps } from '@root/interfaces/components/Pagination';

import Button from '../Button';

const PageNumber: FC<React.PropsWithChildren<PageNumberProps>> = (props) => {
  const { pageIdx, isActive, onClick, dataTestId, pageLabel } = props;

  const handleClick = () => {
    onClick(pageIdx);
  };

  return (
    <Button
      shape="round"
      color={isActive ? 'blue' : 'white'}
      size="sm"
      onClick={handleClick}
      className="hover:bg-blue-400 hover:text-white hover:border-blue-400"
      dataTestId={dataTestId}
    >
      {pageLabel}
    </Button>
  );
};

export default PageNumber;
