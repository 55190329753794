import { makeAutoObservable } from 'mobx';

import { trackBackendFailure, trackSentImtuTransaction } from '@services/analytics';

import { IMTU_STATUSES } from '@root/constants/mobileTopUp/imtuStatuses';
import { API_ERROR_MSG } from '@root/constants/errorMessages';

import {
  BackendFailureCallName,
  BackendFailureCategory,
} from '@services/analytics/constants';

import { ImtuProductType, ImtuStatus } from '@root/interfaces/MobileTopUp';
import CountryCode from '@root/interfaces/CountryCode';

import ImtuProductClass from '@root/models/ImtuProductClass';
import PaymentCardClass from '@root/models/PaymentCardClass';

import { sendImtuTxn } from '@services/api/v1/dtcImtuK2';

import { getCallingApiErrorMessage } from '@services/axios/helpers';

class SendImtuTransactionStore {
  isLoading = false;

  errorTextStatus = '';

  status?: ImtuStatus;

  txnId?: string;

  constructor() {
    makeAutoObservable(this);
  }

  sendTransaction = async (params: {
    product: ImtuProductClass;
    paymentInfo: PaymentCardClass;
    userCountryCode: CountryCode;
    recipientPhone: string;
    promoCode?: string;
  }) => {
    const { product, paymentInfo, recipientPhone, userCountryCode, promoCode } = params;
    this.setIsLoading(true);
    try {
      const sendImtuTxnRes = await sendImtuTxn({
        recipientPhone,
        offerId: product.code,
        cardHandleId: paymentInfo.handleId,
        cvv: paymentInfo.cvv,
        promoId: promoCode,
      });

      const txnFromRes = sendImtuTxnRes.items[0].mtu_tx;
      const txnId = txnFromRes.txid;

      this.status = txnFromRes.purchase_status;
      this.txnId = txnId;

      if (this.status === IMTU_STATUSES.FAILED) {
        throw Error(txnFromRes.error_reason);
      }

      if (paymentInfo.type) {
        trackSentImtuTransaction({
          transactionId: txnId,
          paymentType: paymentInfo.type,
          amount: product.senderAmount,
          userCountryCode,
          purchaseParams: {
            phone: recipientPhone,
            plan: product.code,
            type: ImtuProductType.International,
            country: product.countryCode,
            carrier: product.carrier.code,
          },
        });
      }
    } catch (err) {
      trackBackendFailure({
        category: BackendFailureCategory.Mtu,
        callName: BackendFailureCallName.TransactionSetup,
        message: String(err),
      });
      this.status = IMTU_STATUSES.FAILED;
      this.errorTextStatus =
        getCallingApiErrorMessage(String(err)) || API_ERROR_MSG.APP_DEFAULT;
    } finally {
      this.setIsLoading(false);
    }
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };
}

export default SendImtuTransactionStore;
