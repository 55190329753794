import React, { FC, PropsWithChildren } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { ButtonProps } from '@root/interfaces/components/Button';

import './styles.scss';

const BASIC_CLASS = 'btn';

const Button: FC<PropsWithChildren<ButtonProps>> = (props) => {
  const {
    children,
    className,
    onClick,
    shape,
    size,
    color,
    isOutlined,
    disabled,
    type,
    style,
    dataTestId,
    ...rest
  } = props;

  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    'text-center',
    `${BASIC_CLASS}-${shape}`,
    `${BASIC_CLASS}-${size}`,
    `${BASIC_CLASS}-${color}${isOutlined && shape !== 'text' ? '-outline' : ''}`,
    className,
  ]);

  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames}
      onClick={onClick}
      style={style}
      data-test-id={dataTestId}
      {...rest}
    >
      <span>{children}</span>
    </button>
  );
};

Button.defaultProps = {
  shape: 'round',
  size: 'md',
  color: 'blue',
  type: 'button',
};

export default Button;
