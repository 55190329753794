import CountryCode from '@root/interfaces/CountryCode';

import { ASSETS_URL } from '@services/api/v1/constants/cdnAndOthers';

export const getPointOfContactSrcFileName = (pocName: string) => {
  return pocName
    .toLowerCase()
    .trim()
    .replace(/[\s/]+/g, '_');
};

export const getPointOfContactLogoSrc = (pocName: string) => {
  const srcFileName = getPointOfContactSrcFileName(pocName);
  return `https://cdn.bossrevolution.com/Content/img/money-transfer/payers/${srcFileName}.png`;
};

export const getCountryBackgroundImgSrc = (
  countryCode: CountryCode,
  variantIndex = 0,
) => {
  return `${ASSETS_URL.COUNTRY_IMAGES}/${countryCode}/${variantIndex}.jpg`;
};
