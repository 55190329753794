import React, { useState, FC, PropsWithChildren } from 'react';

import { Checkbox } from '@components/common';

import useDidUpdate from '@root/hooks/useDidUpdate';

import { CheckboxGroupProps } from '@src/root/interfaces/components/Checkbox';

import { isString } from '@utils/typeGuards';

const CheckboxGroup: FC<PropsWithChildren<CheckboxGroupProps>> = (props) => {
  const { options, value, defaultValues, onChange, className, ...restProps } = props;

  const [checkboxValues, setCheckboxValues] = useState(value || defaultValues || []);

  const getOptions = () => {
    if (options?.length) {
      return options.map((option) => {
        if (isString(option)) {
          return {
            label: option,
            value: option,
          };
        }
        return option;
      });
    }
    return [];
  };

  useDidUpdate(() => {
    if (onChange) {
      onChange(checkboxValues);
    }
  }, [checkboxValues]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: val } = event.target;
    setCheckboxValues((prevState) => {
      if (prevState.indexOf(val) === -1) {
        return [...prevState, val];
      }
      if (prevState.indexOf(val) !== -1) {
        return prevState.filter((option) => option !== val);
      }
      return prevState;
    });
  };

  const renderCheckboxes = () => {
    return getOptions().map((option) => {
      return (
        <Checkbox
          key={option.value}
          value={option.value}
          checked={checkboxValues.indexOf(option.value) !== -1}
          onChange={handleChange}
          disabled={option.disabled}
          className={option.className}
          {...restProps}
        >
          {option.label}
        </Checkbox>
      );
    });
  };

  return <div className={className}>{renderCheckboxes()}</div>;
};

export default CheckboxGroup;
