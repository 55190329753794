import { TFunction } from 'i18next';

import { Button } from '@components/common';

import { capitalizeFirstLetterInEachWord, normalizeStringCompound } from '@utils/string';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';
import { PaymentCardTypeShort } from '@root/constants/moneyTransfer/paymentCards';

import PaymentCardClass from '@root/models/PaymentCardClass';

import {
  PaymentCardFundingMap,
  PAYMENT_CARD_NAME,
} from '@root/constants/paymentCards/paymentCardIssuers';

interface Props {
  cardInfo?: string;
  onConfirm?(): void;
  onDecline?(): void;
  disabledPaymentCardsIssuers?: PaymentCardIssuerKeys[];
  disabledPaymentMethods?: PaymentCardTypeShort[];
  unsupportedCard?: PaymentCardClass;
  t: TFunction;
}

const UnsupportedCardDialogContent = (props: Props) => {
  const {
    onConfirm,
    disabledPaymentCardsIssuers,
    disabledPaymentMethods,
    unsupportedCard,
    t,
  } = props;

  const unsupportedCardIssuer = disabledPaymentCardsIssuers?.find(
    (i) => i === unsupportedCard?.paySource,
  );
  const unsupportedCardType = disabledPaymentMethods?.find(
    (cardType) => cardType === unsupportedCard?.type,
  );

  const cardInfo =
    unsupportedCard &&
    normalizeStringCompound([
      capitalizeFirstLetterInEachWord(
        unsupportedCardType ? PaymentCardFundingMap[unsupportedCardType] : '',
      ),
      unsupportedCardIssuer && PAYMENT_CARD_NAME[unsupportedCardIssuer],
    ]);

  return (
    <div className="flex flex-col items-center text-center">
      <div className="mb-3 text-xl font-bold">{t('Card not supported')}</div>
      <div>
        {t(
          "Unfortunately we don't support {{card}} cards for money transfers. Please choose another card.",
          cardInfo ? { card: t(cardInfo) } : undefined,
        )}
      </div>
      <Button onClick={onConfirm} shape="pill" className="mt-5 !px-10">
        {t('Ok')}
      </Button>
    </div>
  );
};

export default UnsupportedCardDialogContent;
