/* eslint-disable import/prefer-default-export */

interface PaginationOption {
  index: number;
  value: number;
}

/**
 * @param currentIdx current page index
 * @param total total pages number
 * @param borderPagesNumber max number of border pages
 * @param offset min number of pages before and after current
 * @returns list of desired page numbers with ellipsis for unimportant pages
 *
 * example: (7, 20, 1) => [1, '...', 7, 8, 9, '...', 20]
 */
export const getPaginationArray = (
  currentIdx: number,
  total: number,
  borderPagesNumber = 4,
  offset = 1,
) => {
  const current = currentIdx + 1;
  const leftOffset = current - offset;
  const rightOffset = current + offset + 1;

  const pageNumbers = Array.from({ length: total }).reduce(
    (acc: PaginationOption[], _, index) => {
      const page = {
        index,
        value: index + 1,
      };

      // if current page at the beginning of the list
      if (current <= borderPagesNumber && page.value <= borderPagesNumber) {
        return [...acc, page];
      }

      // if current page at the end of the list
      if (current > total - borderPagesNumber && page.value > total - borderPagesNumber) {
        return [...acc, page];
      }

      // if current page at the middle of the list
      if (
        page.value === 1 ||
        page.value === total ||
        (page.value >= leftOffset && page.value < rightOffset)
      ) {
        return [...acc, page];
      }

      return acc;
    },
    [],
  );

  const pageNumbersWithEllipsis = pageNumbers.reduce(
    (acc: Array<PaginationOption | string>, item, index, arr) => {
      const prevItem = arr[index - 1];

      if (prevItem && item.value - prevItem.value !== 1) {
        return [...acc, '...', item];
      }

      return [...acc, item];
    },
    [],
  );

  return pageNumbersWithEllipsis;
};
