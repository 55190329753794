import Field from './Field';

interface Props {
  dataTestId: string;
  name: string;
  label: string;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

const CardNumberField = (props: Props) => {
  const { dataTestId, name, label, placeholder, className, disabled, required } = props;

  return (
    <Field
      type="maskedText"
      name={name}
      inputMode="numeric"
      label={label}
      mask={disabled ? '*****' : '9999 9999 9999 9999'}
      dataTestId={`${dataTestId}-card-number-input`}
      placeholder={placeholder}
      className={className}
      disabled={disabled}
      required={required}
    />
  );
};

export default CardNumberField;
