import { makeAutoObservable } from '@nodeModules/mobx';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import { fetchImtuCarriers } from '@services/api/v1/dtcImtuK2';

class ImtuCarriersStore {
  carriers?: ImtuCarrierClass[];

  detectedCarrier?: ImtuCarrierClass;

  isLoading = false;

  isInitialDataRetrieved = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchImtuCarriers = async (recipientPhone: string) => {
    try {
      const res = await fetchImtuCarriers(recipientPhone);
      this.carriers = res.carriers;
      this.detectedCarrier = res.detectedCarrier;
    } finally {
      this.isLoading = false;
      this.isInitialDataRetrieved = true;
    }
  };

  get isDataReady() {
    return !this.isLoading && this.isInitialDataRetrieved;
  }
}

export default ImtuCarriersStore;
