import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, PaymentCard, Modal } from '@components/common';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import {
  CARD_CVV_MAX_LENGTH,
  CARD_CVV_MIN_LENGTH,
} from '@root/constants/paymentCards/paymentCardsData';

interface Props extends TestIdDataAttr {
  isOpen: boolean;
  onConfirm: (value: string) => void;
  onCancel: () => void;
}

const CvvModal = (props: Props) => {
  const { isOpen, onConfirm, onCancel, dataTestId } = props;

  const { t } = useTranslation();

  const [value, setValue] = useState('');

  const handleClick = () => {
    onConfirm(value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (!isOpen) {
      setValue('');
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      className="flex flex-col"
      header={t('Please enter CVV')}
      onRequestClose={onCancel}
      closeBtnDataTestId={`${dataTestId}-close-btn`}
    >
      <div className="flex flex-col items-center">
        <div className="mb-2 w-full">
          <PaymentCard
            isCvvVisible
            onChangeCvv={handleChange}
            cvvNumber={value}
            className="mx-auto"
            dataTestId={dataTestId}
          />
        </div>
        <Button
          shape="text"
          size="md"
          color="red"
          onClick={onCancel}
          dataTestId={`${dataTestId}-cancel`}
        >
          {t('Cancel')}
        </Button>
        <Button
          shape="pill"
          isOutlined
          size="md"
          className="w-80"
          onClick={handleClick}
          disabled={
            value.length < CARD_CVV_MIN_LENGTH || value.length > CARD_CVV_MAX_LENGTH
          }
          dataTestId={`${dataTestId}-place-order`}
        >
          {t('Send money')}
        </Button>
      </div>
    </Modal>
  );
};

export default CvvModal;
